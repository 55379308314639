import React, { Component, useState, useEffect, useRef } from "react";
import { store } from 'react-notifications-component';
import { nullcheck, notify } from '../../Utils/Helper'
import axios from "axios";
import fundCodeValidationSchema from "../../Validation/FundCodeValidation";
import { Preloader } from "../../Utils/Preloader";
import notification from "../../utility/notification";

import ValidationMessageComponent from "./ValidationMessage";
import { Table,Modal, Input, InputNumber,message, Popconfirm, Form, Typography,Menu,Dropdown, Select,Button,Checkbox } from 'antd';
import { BsPencilSquare } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';
// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

function formatDefaultDate(dateString) {

  var newDate = new Date(dateString);
  newDate.setDate(newDate.getDate() + 1);

  return newDate.toISOString().split("T")[0];
}

export default class FundsCodeSegment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      fundSegmentData: [],
      fundsSegment: "",
      code: "",
      loading: false,
      deleteId: "",
      edit: false,
      editId: "",
      message: "",
      showNotification: false,
      notificationSuccess: false,
        notificationMessage: "Successfully saved!",

        deleteModal: false,
      search:"",
    };
  }
  componentDidMount() {
    this.getFundSegments();
  }

  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  getEmployee=(id)=>{
      const employeesData=this.props.employees;
      const employeeInfo=employeesData.filter((employee)=>{

          return employee.id==id;
      })

      return employeeInfo.length==0?"":employeeInfo[0].firstName+ ' '+employeeInfo[0].lastName

  }

  handleSelectedFundSegmentChange = (selectedFundSegment) => {
    this.setState(
      { selectedFundSegment, fundSegmentData: selectedFundSegment },
      () => console.log(`Option selected:`, selectedFundSegment)
    );
  };
  handleSubmit = () => {
    let fundSegmentName = this.state.fundsSegment;
      let fundSegmentCode = this.state.code;
      let enteredBy = this.props.employeeId.toString(); 
      let updatedBy = this.props.employeeId.toString(); 

      let data = { fundSegmentName, fundSegmentCode, enteredBy,updatedBy };
      

    fundCodeValidationSchema
      .validate(data)
      .then((message) => {
        this.setState({
          loader: true,
          message: "",
        });
        axios
          .post(`${BASE_API_URL}/fundsegment`, { ...data })
          .then((response) => {
            this.setState({
              fundsSegment: "",
              code: "",
              loader: false,
              message: "",
            });
              // notify(store, 'success', 'fund segment added successfully');
              notification({
                title: 'Successful',
                message: 'fund segment added successfully',
                type: 'success'
          });
            this.getFundSegments();
          }).catch((err) => {
              console.log(err)
              this.setState({
                  loader: false,
                  message: nullcheck(err.response) == "" ? "server error" : err.response.data,
                  
                  notificationMessage: err.message,
              });
          });
      })
      .catch((err) => {
        this.setState({
          loader: false,
          message: err.errors,
        });
      });
  };

    handleDelete = () => {
        let id = this.state.deleteId;
    this.setState({
      loader: true,
        message: "",
        deleteModal: false,
        deleteId: '',
    });

    axios
      .delete(`${BASE_API_URL}/fundsegment?id=${id}`)
      .then((response) => {
        this.setState({
          edit: false,
          editId: "",
          fundsSegment: "",
          code: "",
          loader: false,
            message: "",
            deleteModal: false,
            deleteId: '',
        });
        //  notify(store, 'success', 'fund segment deleted successfully');
         notification({
          title: 'Successful',
          message: 'fund segment deleted successfully',
          type: 'success'
    });
        this.getFundSegments();
      })
      .catch((err) => {
        this.setState({
          loader: false,
            message: nullcheck(err.response) == "" ? "server error" : err.response.data,
            deleteModal: false,
            deleteId: '',
        });
      });
  };

  handleEdit = (data) => {
    this.setState({
      fundsSegment: data?.fundSegmentName,
      code: data.fundSegmentCode,
      edit: true,
      editId: data.id,
    });
    };

   

  handleEditSubmit = () => {
    let fundSegmentName = this.state.fundsSegment;
    let fundSegmentCode = this.state.code;

    let id = this.state.editId;
      let updatedBy = this.props.employeeId.toString(); 
      let data = { fundSegmentName, fundSegmentCode, id, updatedBy };

    fundCodeValidationSchema
      .validate(data)
      .then((message) => {
        this.setState({
          loader: true,
          message: "",
        });
        axios
          .put(`${BASE_API_URL}/fundsegment`, { ...data })
          .then((response) => {
            this.setState({
              edit: false,
              editId: "",
              fundsSegment: "",
              code: "",
              loader: false,
              message: "",
            });
              // notify(store, 'success', 'fund segment updated successfully');
              notification({
                title: 'Successful',
                message: 'fund segment updated successfully',
                type: 'success'
          });
            this.getFundSegments();
          })
            .catch((err) => {

                this.setState({                
                    loader: false,
                    message: nullcheck(err.response) == "" ? "server error" : err.response.data,
                });
              
          });
      })
      .catch((err) => {
        this.setState({
          loader: false,
          message: err.message,
        });
      });
    };
    handelCancel = () => {
        this.setState({
            edit: false,
            editId: "",
            fundsSegment: "",
            code: "",
            loader: false,
            message: "",
            deleteModal: false,
            deleteId: '',
        });
    }

  getFundSegments = () => {
    this.setState({
      loader: true,
      message: "",
    });
    axios
      .get(`${BASE_API_URL}/fundsegment`)
      .then((response) => {
        this.setState({
          fundSegmentData: response.data,
          loader: false,
          message: "",
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
          message: err.message,
        });
      });
  };

    render() {
     
        let fundSegments = this.state.fundSegmentData.filter((fundSegment) => {

            return (fundSegment.fundSegmentName.toLowerCase()).indexOf(this.state.search.toLowerCase()) !== -1;
        })

        let allfundsSegment=  fundSegments.map((fundSegment) => {
            return (
              <tr
                style={{
                  borderBottom: "1px solid #C7C7C7",
                }}
              >
                <td className="px-6 py-4 whitespace-nowrap">
                  <div
                    className="bg-pen"
                    onClick={() => this.handleEdit(fundSegment)}
                  >
                            <BsPencilSquare style={{backgroundColor:"#43425D", color:"white", fontSize:"26px", padding:"5px"}}/>
                    {/* <svg
                      width="18"
                      height="18"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="#fff"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                      />
                    </svg> */}
                  </div>
                </td>
                <td className="px-6 py-4  text-sm font-avenir-medium table-color">
                  {fundSegment.fundSegmentName}
                </td>
                <td className="px-6 py-4  text-sm font-avenir-medium table-color">
                  {fundSegment.fundSegmentCode}
                </td>
                <td className="px-6 py-4  text-sm font-avenir-medium table-color">
                  {this.getEmployee(fundSegment.updatedBy)}
                </td>
                <td className="px-6 py-4  text-sm font-avenir-medium table-color">
                  {formatDefaultDate(fundSegment.dateCreated)}
                </td>
                <td className="px-6 py-4  text-sm font-avenir-medium table-color">
                        <a

                            className="cursor-pointer"
                            onClick={() =>
                                this.setState({
                                    deleteModal: !this.state.deleteModal,
                                    deleteId: fundSegment.id

                                })
                            }
                        >

                            {/* <img
                                src="/delete-icon.png"
                                className="ml-5 h-5 w-5"
                            /> */}
                            <AiOutlineDelete style={{backgroundColor:"#43425D", color:"white", fontSize:"26px", padding:"5px"}}/>

                        </a>
                </td>
              </tr>
            );
          });
    return (
      <React.Fragment>
            {/* {this.state.loader === true ? <Preloader /> : ""} */}
            {this.state.deleteModal && (
                <>
                    <div className="overlay justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="p-10 relative w-3/12 my-6 mx-auto bg-white p-4">
                            {/*content*/}
                            <div className=" py-4 px-4 shadow-sm overflow-hidden cursor-pointer">
                                <p className="flex-1 mt-2 text-sm font-medium font-avenir-black text-color text-center">
                                    Are you sure you want to
                                    delete?
                                                    </p>
                            </div>
                            <div class="flex justify-center">
                                <button onClick={() => this.handleDelete()}
                                    type="submit"
                                    class="mr-5 w-40 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"

                                >

                                    Yes
                                                    </button>
                                <button
                                    type="button"
                                    class="w-40 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-color bg-gray-200 hover:bg-gray-50 focus:outline-none"
                                    onClick={() =>
                                        this.setState({
                                            deleteModal: !this.state.deleteModal,
                                        })
                                    }
                                >
                                    No
                                                    </button>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            )}


        {this.state.open ? (
          <div className="mb-3">
            <div
              className="bg-card-header py-4 px-4 shadow-sm overflow-hidden bb-green cursor-pointer"
              onClick={() => {
                this.getFundSegments();
                this.setState({ open: !this.state.open })}}
                    >
                        <div class="flex">

                            <div class="flex-1">
                                <p className=" text-sm font-medium font-avenir-black text-color">
                                    Funds Code / Segment Setup
              </p>
                            </div>

                            <input
                                placeholder="Search"
                                className="border border-solid p-5 h-8 w-50 mt-2"
                                style={{
                                    backgroundColor: "transparent",
                                    borderColor: "#707070",
                                    border:"1px solid",
                                    width:"25%"

                                }}
                                name='search'
                                onClick={(e) => e.stopPropagation()}
                                onChange={this.handleChange}
                            />
                            </div>

            
            </div>
            <div className="w-100  p-10 flex bg-white py-4 pl-4 shadow-sm cursor-pointer">
                            <div style={{border:""}} className=" w-4  w-80 m-r-10">
                                <div class="mt-3grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div class="sm:col-span-12">
                    {this.state.message !== "" ? (
                      <ValidationMessageComponent
                        message={this.state.message}
                      ></ValidationMessageComponent>
                    ) : (
                      ""
                    )}
                    <label
                      for="fundsSegment"
                      class="block  text-sm font-medium label-color"
                    >
                      Funds Segment
                    </label>
                    <div class="mt-1">
                      <Input
                        type="text"
                        name="fundsSegment"
                        id="fundsSegment"
                        value={this.state.fundsSegment}
                        class="shadow-sm focus:outline-none block w-full sm:text-sm"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div class="sm:col-span-12">
                    <label
                      for="code"
                      class="block  text-sm font-medium label-color"
                    >
                      Code
                    </label>
                    <div class="mt-1">
                      <Input
                        type="text"
                        name="code"
                        id="code"
                        value={this.state.code}
                        class="shadow-sm focus:outline-none block w-full sm:text-sm"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div class="pt-5 sm:col-span-12">
                    <div class="flex">
                      <button
                        type="submit"
                        class="mr-5 w-40 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                        onClick={
                          this.state.edit
                            ? this.handleEditSubmit
                            : this.handleSubmit
                        }
                      >
                        {this.state.loading && (
                          <svg
                            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              class="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              stroke-width="4"
                            ></circle>
                            <path
                              class="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        )}
                        Save Record
                      </button>
                                        <button onClick={this.handelCancel }
                        type="button"
                        class="w-40 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-color bg-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div  className="w-100 md:flex-grow" >
                                <div class="w-100 flex " >
                                    <div class="w-100 -my-2 overflow-y-scroll" >
                        <table  class="min-w-full w-100">
                          <thead class="bg-table-header">
                            <tr>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                              >
                                <svg
                                  version="1.0"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 1278.000000 1280.000000"
                                  preserveAspectRatio="xMidYMid meet"
                                >
                                </svg>
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                              >
                                Segment
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                              >
                                Code
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                              >
                                Updated By
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                              >
                                Date
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody class="bg-white">{allfundsSegment}</tbody>
                        </table>
                        {true && (
                          <p className=" text-sm font-normal text-color py-4">
                         
                          </p>
                        )}
                      </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="bg-white py-4 px-4 shadow-sm overflow-hidden cursor-pointer mb-3"
            // onClick={() => this.setState({ open: !this.state.open })}
            onClick={() => {
              this.getFundSegments();
              this.setState({ open: !this.state.open })}}
          >
            <p className=" text-sm font-medium text-color">
              Funds Code / Segment Setup
            </p>
          </div>
        )}
      </React.Fragment>
    );
  }

  componentDidUpdate(prevProps) {}
}
