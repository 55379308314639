import React, { useState,useEffect } from 'react';
import { Table,Modal,Button, Input, InputNumber, Popconfirm, Form, Typography } from 'antd';
import axios from "axios";
import MonthlyBudgetFormPreview from "../layouts/MonthlyBudgetFormPreview";
import { AiOutlineClose } from 'react-icons/ai';
import { nullcheck, notify } from '../../Utils/Helper'
import notification from "../../utility/notification";
import { decodeToken } from "../../utility/auth";
import { setDisplayName } from 'recompose';
import {
  CloseOutlined,
  SearchOutlined,
} from '@ant-design/icons';

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';
// const BASE_API_URL = 'http://localhost:5000/api/v1';

const originData = [];

// for (let i = 0; i < 100; i++) {
//   originData.push({
//     key: i.toString(),
//     name: `Edrward ${i}`,
//     age: 32,
//     address: `London Park no. ${i}`,
//   });
// }

const { TextArea } = Input;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : (inputType === 'textArea')? <TextArea rows={4} />: <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const CommentTable = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [mdaId, setMdaId] = useState([]);
  const [username, setUsername] = useState();
  const [userId, setUserId] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const [formData, setFormData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [displayEdit, setDisplayEdit] = useState(false);
  const [disabledd, setDisabledd] = useState(false);
  const [approversComments, setapproversComments] = useState('');
console.log(props)
  const isEditing = (record) => record.key === editingKey;
  console.log(props?.projectParams)

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
      setDisabledd(true)

      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
    // let count = 1
    const toReview = ()=>{
        setDisplayEdit(!displayEdit)
    }
    // const toApprove = ()=>{
    //     alert('Approved')
    // }

    useEffect (()=>{
      //   getMdas()
      //   getEmployees()
      
      
        const r = localStorage.getItem('r');
        let fn = localStorage.getItem('fn');
        let ln = localStorage.getItem('ln');
        let ui = localStorage.getItem('ui');
        let mi = localStorage.getItem('mi');
      
        // r && setRole(decodeToken('r').r);
        if (fn && ln) {
          fn = decodeToken('fn').fn;
          ln = decodeToken('ln').ln;
          ui = decodeToken('ui').ui;
          mi = decodeToken('mi').mi;
          setUsername(`${fn} ${ln}`);
          // setUsername(`${fn} ${ln}`);
        // getPending(ui)
        // getBudgetPlanningSummary(ui)
        // getBudgetPendingComents(ui)
        // getPendingBudgetSectors(+ui,)
        
          console.log(ui)
          setUserId(ui)
          setMdaId(mi)
        }
      
      },[])
      
    const sendComment = ()=>{

       
        console.log(props.userIdz)
        let postData =   {
            dateCreated: props.projectParams.dateCreated,
            enteredBy: props.userIdz+'',
            dateUpdated: '2022-02-12T14:07:40.545Z',
            updatedBy: props.userIdz+"",
            isDelete: false,
            dateDeleted: '2022-02-12T14:07:40.545Z',
            text: approversComments,
            approvalPlanId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            budgetPlanId:props.projectParams.batchId,
            approverId: 0
          }
        // let poster = arrayHolder.map((a)=>())
        axios.post(`${BASE_API_URL}/CommentPlan`, postData, {status:{}}).then((res)=>{
          notification({
            title: 'Successful',
            message: 'Comments have been sent successfully',
            type: 'success'
      });
      props.closeModal(false)

        }).catch((err) => {
          // this.setState({
          //     loader: false,
          //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
          // });

          notification({
            title: 'ERROR MESSAGE',
            message: `
            ${nullcheck(err.response) == "" ? "Something went wrong please check network" : err.response.data}`,
            type: 'danger',
            // container:'top-center'
            // animationIn: ['animate__animated', 'animate__slideIn'],
          });
      });
      
    }

   
      
    const toApprove = (status)=>{

        let postData =
          
        {
            id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            dateCreated: '2022-02-14T15:33:43.390Z',
            enteredBy: props.userIdz+'',
            dateUpdated: '2022-02-14T15:33:43.390Z',
            updatedBy: props.userIdz+'',
            isDelete: false,
            dateDeleted: '2022-02-14T15:33:43.390Z',
            text: approversComments,
            batchId: props.projectParams.batchId,
            mdaId: +mdaId,
            approverId: props.userIdz
          }


    
        // let poster = arrayHolder.map((a)=>())
        axios.post(`${BASE_API_URL}/ApprovalProcess/AddApprovalPlanProcess`, postData, {params:{status}})
        .then((res)=>{
            console.log(res)
            notification({
                title: 'Successful',
                message: 'Allocated Mda budget has been sent successfully',
                type: 'success'
          });
                    props.closeModal(false)

        })
        .catch((err) => {
          // this.setState({
          //     loader: false,
          //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
          // });

          notification({
            title: 'ERROR MESSAGE',
            message: `
            ${nullcheck(err.response) == "" ? "Something went wrong please check network" : err.response.data}`,
            type: 'danger',
            // container:'top-center'
            // animationIn: ['animate__animated', 'animate__slideIn'],
          });
      });
      }

    const toPost = ()=>{

        let postData =
        
        data.map((e)=>(
            // {
            //     id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            //     dateCreated: '2022-02-11T09:59:24.439Z',
            //     enteredBy: 'string',
            //     dateUpdated: '2022-02-11T09:59:24.439Z',
            //     updatedBy: 'string',
            //     isDelete: false,
            //     dateDeleted: '2022-02-11T09:59:24.439Z',
            //     budgetPlanId:' 3fa85f64-5717-4562-b3fc-2c963f66afa6',
            //     project: 'string',
            //     programmeCode: 'string',
            //     status: 'string',
            //     projectLocation: 'string',
            //     proposedBudget: 5,
            //     performance: 'string',
            //     actuals: 'string',
            //     mdaId: 11
            //   }
            {
                // key: idx+1,
            
                        id: e.id,
                        // budgetDeptId: e.budgetDeptId,
                        
                        dateCreated: e.dateCreated,
                        enteredBy: e.enteredBy,
                        dateUpdated: e.dateUpdated,
                        updatedBy: e.updatedBy,
                        isDelete: e.isDelete,
                        dateDeleted: e.dateDeleted,
                        project: e.project,
                        budgetPlanId: e.budgetPlanId,
                        programmeCode: e.programmeCode?e.programmeCode:'',
                        economicCode: e.economicCode?e.economicCode:'',
                        status: e.status,
                        projectLocation: e.projectLocation?e.projectLocation:'',
                        proposedBudget: +e.proposedBudget?e.proposedBudget:'',
                        performance: e.performance?e.performance:'',
                        actuals: e.actuals?e.actuals:'',
                        mdaId: e.mdaId
                      }
        ))
        // let poster = arrayHolder.map((a)=>())
        axios.post(`${BASE_API_URL}/BudgetPlanning`, postData 
        
                          )
      }
      
const handleCancel2 = ()=>{
setShowPreview(!showPreview)
}
useEffect(()=>{
    let dat = props?.project?.map((e,idx)=>(
        {
    key: idx+1,

            id: e.id,
approvalStatus: e.approvalStatus,
// actuals:e.actuals,
actuals:e.approvalStatus,


            budgetDeptId: e.budgetDeptId,
            
            budgetPlanId: e.batchId,
            dateCreated: e.dateCreated,
            enteredBy: e.enteredBy,
            dateUpdated: e.dateUpdated,
            updatedBy: e.updatedBy,
            isDelete: e.isDelete,
            dateDeleted: e.dateDeleted,
            project: e.project,
            programmeCode: e.programmeCode?e.programmeCode:'',
            economicCode: e.economicCode?e.economicCode:'',
            status: e.status,
            projectLocation: e.projectLocation?e.projectLocation:'',
            proposedBudget: +e.proposedBudget?e.proposedBudget:0,
            performance: e.performance?e.performance:'',
            actuals: e.actuals?e.actuals:'',
            mdaId: e.mdaId,

            functionalCode: e.functionalCode,
fundCode: e.functionalCode,
geoCode: e.functionalCode,
id: e.functionalCode,
pId: e.functionalCode,
programmeName: e.functionalCode,
stage: e.functionalCode,
title: e.functionalCode,
          }


      ))
    console.log('dat:',dat)
    setData(dat)
},[])
console.log(data)
  
const showMOnthlyBudget = (departmentBudgetId)=>{
  axios.get(`${BASE_API_URL}/BudgetInformation/GetBudgetInformationByDepartmentBudgetId?departmentBudgetId=${departmentBudgetId}&isAllValues=${true}`).then((res)=>{
    console.log(res.data)
    setFormData(res.data)
    setShowPreview(!showPreview)
    
//     notification({
//       title: 'Successful',
//       message: 'Comments have been sent successfully',
//       type: 'success'
// });
  })

}
  
  const columns1 =[
    {
      title: 'Economic Code',
      dataIndex: 'economicCode',
      // width: '25%',
      editable: true,
    },
    {
      title: 'Functional Code',
      dataIndex: 'functionalCode',
      // width: '25%',
      editable: true,
    },
    {
      title: 'Programme Code',
      dataIndex: 'programmeCode',
      // width: '25%',
      editable: true,
    },
    {
      title: 'Fund Code',
      dataIndex: 'fundCode',
      // width: '25%',
      editable: true,
    },
    {
      title: 'Project/Line Item',
      dataIndex: 'project',
      // width: '25%',
      editable: true,
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   // width: '40%',
    //   editable: true,
    // },
    {
      title: 'Project Location',
      dataIndex: 'projectLocation',
      // width: '40%',
      editable: true,
    },
    
    {
        title: 'proposedBudget',
        dataIndex: 'proposedBudget',
        // width: '40%',
        editable: true,
      },
      {
        title: 'Action',
        dataIndex: 'action',
        // width: '40%',
        // editable: true,
        render: (_, record) => {
          const editable = isEditing(record);
          return(
           
              <a onClick={()=>{showMOnthlyBudget(record.budgetDeptId)}}>View Monthly Budget Allocation</a>
          ) 
        },
      },
      // {
      //   title: 'actuals',
      //   dataIndex: 'actuals',
      //   width: '40%',
      //   editable: true,
      // },
    // {
    //   title: 'operation',
    //   dataIndex: 'operation',
    //   render: (_, record) => {
    //     const editable = isEditing(record);
    //     return editable ? (
    //       <span>
    //         <Typography.Link
    //           onClick={() => save(record.key)}
    //           style={{
    //             marginRight: 8,
    //           }}
    //         >
    //           Save
    //         </Typography.Link>
    //         <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
    //           <a>Cancel</a>
    //         </Popconfirm>
    //       </span>
    //     ) : (
    //       <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
    //         {displayEdit &&<p>
    //             Edit
    //         </p>}
    //       </Typography.Link>
    //     );
    //   },
    // },
  ];

  const columns2 = [
    {
      title: 'project',
      dataIndex: 'project',
      width: '25%',
      editable: true,
    },
    {
      title: 'programmeCode',
      dataIndex: 'programmeCode',
      width: '10%',
      editable: true,
    },
    {
      title: 'project Location',
      dataIndex: 'projectLocation',
      width: '20%',
      editable: true,
    },
    {
        title: 'proposedBudget',
        dataIndex: 'proposedBudget',
        width: '10%',
        editable: true,
      },
    //   {
    //     title: 'actuals',
    //     dataIndex: 'actuals',
    //     width: '10%',
    //     editable: true,
    //   },
      {
        title: 'Comment',
        dataIndex: 'comment',
        width: '40%',
        editable: true,
      },
      {
        title: 'Action',
        dataIndex: 'action',
        // width: '40%',
        // editable: true,
        render: (_, record) => {
          const editable = isEditing(record);
          return(
           
              <a onClick={()=>{showMOnthlyBudget(record.budgetDeptId)}}>View Monthly Budget</a>
          ) 
        },
      },

    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            {displayEdit &&<p>
                Edit
            </p>}
          </Typography.Link>
        );
      },
    },
  ];




//   const columns = displayEdit?columns2:columns1
  const columns = columns1




  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: (col.dataIndex === 'proposedBudget') ? 'number' : (col.dataIndex === 'comment') ?'textArea':'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form style={{width:'100%'}}  form={form} component={false}>
        <div style={{display:"flex", width:'100%'}}>
        <div style={{ width:'100%'}}>
          <Table
          style={{ width:'100%'}}
      components={{
      body: {
      cell: EditableCell,
      },
      }}
      bordered
      dataSource={data}
      columns={mergedColumns}
      rowClassName="editable-row"
      pagination={{
      onChange: cancel,
      }}
      />

      
     {displayEdit &&  <div className=' p-20 w-100 flex' style={{ justifyContent:'center'}}>
       
     <div style={{width:"60%", padding:"20px", backgroundColor:"white"}}>
              {
                 props.commentsz.map((e)=>(
                  // e?.updatedBy !==  props?.userIdz+''?<p style={{border:"2px solid red", width:"50%"}} className='bold m-b-5'>Comments From Approver</p>:""
                  e?.updatedBy !==  props?.userIdz+''?
                  <div style={{ width:"100%", display:'flex', justifyContent:'flex-start'}}>
                  <div style={{width:"50%",}}>
                   <div className='m-b-5 bold' style={{color:"blue", fontSize:'11px'}}>{e.name}</div>
                   <p>{e.text}</p>
                  </div>
                  
                </div>:
                <div style={{ width:"100%", display:'flex', justifyContent:'flex-end'}}>
                <div style={{border:"", width:"50%",}}>
                <div className='m-b-5 bold' style={{color:"blue", fontSize:'11px'}}>{e.name}</div>

                <p>{e.text}</p>

                </div>
                
              </div>
                ))
                
                }
                {/* <p className='bold m-b-5'>Comments From Approver</p>
                <TextArea readOnly value={props.commentsz[0]?.text} onChange={(e)=>{setapproversComments(e.target.value)}} style={{ border:"2px solid blue"}}  rows={2}/>
           
                <p className='bold m-b-5'>Comments From User</p>
                <TextArea readOnly value={props.commentsz[0]?.text} onChange={(e)=>{setapproversComments(e.target.value)}} style={{ border:"2px solid blue"}}  rows={2}/> */}
            </div>

            <div style={{width:"60%", padding:"20px", backgroundColor:"white"}}>
                <p className='bold m-b-5'>Enter comments and send back to initiator</p>
                <TextArea value={approversComments} onChange={(e)=>{setapproversComments(e.target.value)}} style={{ border:"2px solid blue"}}  rows={4}/>
            </div>

       </div>}

   { props.higherApprover? 
        <div style={{marginLeft:"20px", display:"flex", justifyContent:"space-between"}}>
            <Button  onClick = {toReview}>{displayEdit ? 'Revert':'Review'}</Button>
            { !displayEdit?<Popconfirm
            placement="leftBottom"
            title={
            <div>
            <p className='bold'> Satisfied & Sure to Approve?</p>
            <p>Once Approved, it cannot be reversed.</p>
            </div>
            } onConfirm={()=>(toApprove('approved'))}>
            <Button >Approve</Button>
            
            </Popconfirm>
            :
            <Button onClick={()=>(toApprove('approved'))}>Approve</Button>
            }
      </div>
       
            :
       
       <div style={{marginLeft:"20px", display:"flex", justifyContent:"space-between"}}>
            <Button  onClick = {toReview}>{displayEdit ? 'Revert':'Review'}</Button>
            { !displayEdit?<Popconfirm
            placement="leftBottom"
            title={
            <div>
            <p className='bold'> Satisfied & Sure to Approve?</p>
            <p>Once Approved, it cannot be reversed.</p>
            </div>
            } onConfirm={()=>(toApprove('approved'))}>
            <Button disabled={disabledd}>Approve</Button>
            
            </Popconfirm>
            :
            <Button onClick={sendComment}>Send for Review</Button>
            }
      </div>
       }
      
      </div>

      <div>
          <AiOutlineClose onClick={()=>{props.closeModal(false)}} style={{backgroundColor:"red",cursor:"pointer", marginLeft:'10px', color:"white", fontSize:"26px"}}/>
      </div>
        </div>
      
        <Modal
                    width="80%"
                   
                    footer={false}
                    // okText={<p style={{ maxWidth: "100px" }}>Boss</p>}
                    closeIcon={<CloseOutlined style={{ fontSize: "25px", backgroundColor: "red", color: "white", padding: "10px", marginLeft:'60px' }} />}
                    visible={showPreview} onCancel={handleCancel2}
                    >
                    <div style={{marginTop:"-23px",}}>
                    
                    <MonthlyBudgetFormPreview formdata = {formData[0]}/>
                    </div>
        </Modal> 
    </Form>
  );
};

export default CommentTable