import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Nav } from "../Components/Nav";
// import { SummaryReportC } from "../Components/SummaryReportC";
import SummaryReportC from '../layouts/SummaryReportC'
import Greeting from '../common/Greeting';
import LocationDetails from '../layouts/LocationDetails';

import Cookies from 'js-cookie'
const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';

export default class SummaryReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        {/* <Nav
          {...this.props}
          children={ */}
            <main
              class="flex-1 w-100 relative overflow-y-auto focus:outline-none"
              tabindex="0"
              style={{ outline: "none" }}
            >
                <div className="w-100 flex space-between flex-v-baseline">
        <Greeting />
        <LocationDetails />
      </div>
              <div class="m-t-20 py-10 max-w-full mx-auto px-2 sm:px-4 md:px-8">
                <div class="flex">
                  <div className="flex-1 flex">
                    <h1 class=" text-base font-medium text-color mr-10">
                      Budget Summary Report
                    </h1>
                  </div>
                 
                </div>
                <div className="py-6">
                  <SummaryReportC {...this.props} />
                </div>
              </div>
            </main>
          {/* }
        /> */}
      </React.Fragment>
    );
  }
}
