import React, { Component } from "react";
// import { store } from 'react-notifications-component';
import { nullcheck, notify } from '../../Utils/Helper'
// import { Nav } from "../Components/Nav";
import  DepartmentBudgetItemsC  from "../layouts/DepartmentBudgetItemsC";
import { BsPencilSquare } from 'react-icons/bs';
import  UploadFile  from "../layouts/UploadFile";
// import { UploadFile } from "../Components/UploadFile";
import axios from "axios";
import { Preloader } from "../../Utils/Preloader";
import cookies from 'js-cookie'
import Greeting from '../common/Greeting';
import LocationDetails from '../layouts/LocationDetails';


// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';

export default class BudgetItemsSetupC extends Component {
  constructor(props) {

        super(props);
        this.state = {
            fullName: "",
            role: "",
            time: "",
            authorized: true,
            newauthorisedMdaId:0


      };

    }
    componentDidMount() {
        this.getAuthorisedUser();

    }

    getAuthorisedUser = () => {
        // const { cookies } = this.props;
        if (typeof cookies.get('userId') !== "undefined") {
            let authUser = cookies.get('userId');
            // let time = authUser.lastLoginTime.split("T")[1]
            this.setState({
                fullName: authUser.firstName + ' ' + authUser.lastName,
                role: authUser.role,
                // time: time
            })

        }



    }


  render() {
    return (
      <React.Fragment>
     
            <main
              class="w-100 flex-1 relative overflow-y-auto focus:outline-none"
              tabindex="0"
              style={{ outline: "none" }}
            >
               <div className="w-100 flex space-between flex-v-baseline">
        <Greeting />
        <LocationDetails />
      </div>
              <div class="py-10 m-b-20 max-w-full mx-auto px-2 sm:px-4 md:px-8">
                <div class="flex" style={{marginTop:"30px", marginBottom:"30px"}}>
                  <h1 class="flex-1 text-base font-medium text-color">
                   Budget Items Setup
                  </h1>
                  <div className="flex">
                    {/* <p className="text-sm font-normal mr-3">

                      <br />
                      <span className="text-xs ml-5">
                                      Time of login: {this.state.time }
                      </span>
                    </p> */}
                    {/* <img src="/location-icon@2x.png" /> */}
                    {/* <div style={{ height: 40, width: 40 }}>
                      <img src="/icn.png" />
                    </div> */}
                  </div>
                </div>
                      <div className="py-6" style={{marginTop:"-15px"}}>
                          <DepartmentBudgetItemsC {...this.props} />
                          {/* <UploadFile   {...this.props} /> */}

                </div>
              </div>
            </main>
        
      </React.Fragment>
    );
  }
}
