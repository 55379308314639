import * as yup from "yup"
const fundItemValidationSchema = yup.object().shape({
    fundTypeId: yup.string().required('fund type is required'),
    fundName: yup.string().required('fund name is required'),
    fundCode: yup.string().required('fund code is required'),


});
//  let data = { fundTypeId, fundName, fundCode };
export default fundItemValidationSchema
