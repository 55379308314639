// import React from 'react'
// import { Collapse, Form, Input, Button, Checkbox } from 'antd';
// import Tablez from '../layouts/Tablez';
// import GeneralReportTable from '../layouts/GeneralReportTable';
// import BudgetDetailsSummary from '../layouts/BudgetDetailsSummary';
// import ItemSetUpTable from '../layouts/ItemSetUpTable';
// import CodificationTable from '../layouts/CodificationTable';


// const { Panel } = Collapse;

// const GeneralReport = ()=>{

//     const onFinish = (values) => {
//         console.log('Success:', values);
//       };
    
//       const onFinishFailed = (errorInfo) => {
//         console.log('Failed:', errorInfo);
//       };

  
// function callback(key) {
//     console.log(key);
//   }
//     return(
//         <div style={{width:'100%'}}>





// <div className='bold'>Budget Report By Item Category</div>
   
//       <div className='flex-between'>
//       <div>
//           <div>Report By Item Category</div>
//           <Input value={'Select Period'}/>
//       </div>
//       <div>
//             <div>MDA Agency</div>
//           <Input value={'Select Period'}/>
//       </div>
//       <div>
//             <div>Budget Period</div>
//           <Input value={'Select Period'}/>
//       </div>
     
//       </div>

//       <div className='m-t-20'>
//         <div  className='flex-between'>
//             <p>search results</p>
//             <div  className='flex'>
//           <Input placeholder={'Show'}/>
//           <Input placeholder={'Search'}/>

//             </div>
//         </div>
//       </div>
//       {/* <CodificationTable/> */}
//       <GeneralReportTable/>

   
   
 







//         </div>
//     )
// }
// export default GeneralReport

import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Nav } from "../Components/Nav";gggg
import ReportC  from "../layouts/ReportC";
import cookies from 'js-cookie'
import Greeting from '../common/Greeting';
import LocationDetails from '../layouts/LocationDetails';



export default class GeneralReport extends Component {
    constructor(props) {

        super(props);
        this.state = {
            fullName: "",
            role: "",
            // time: "",


        };
    }
    componentDidMount() {
        this.getAuthorisedUser();
    }

    getAuthorisedUser = () => {
        // const { cookies } = this.props;
        if (typeof cookies.get('userId') !== "undefined") {
            let authUser = cookies.get('userId');
            // let time = authUser.lastLoginTime.split("T")[1]
            this.setState({
                fullName: authUser.firstName + ' ' + authUser.lastName,
                role: authUser.role,
                // time: time
            })
        }

    }

  render() {
    return (
      <React.Fragment>
       
            <main
              class="flex-1 w-100 relative overflow-y-auto focus:outline-none"
              tabindex="0"
              style={{ outline: "none" }}
            >
              <div className="w-100 flex space-between flex-v-baseline">
        <Greeting />
        <LocationDetails />
      </div>
              <div class="m-t-20 py-10 w-100 max-w-full mx-auto px-2 sm:px-4 md:px-8">
                <div class="flex-between w-100">
                  <div className="flex-1 flex ">
                    <h1 class=" text-base font-medium text-color mr-10">
                      Budget Report by Item Category
                    </h1>
                  </div>
                 
                </div>
                <div className="py-6">
                  <ReportC {...this.props} />
                </div>
              </div>
            </main>
         
      </React.Fragment>
    );
  }
}
