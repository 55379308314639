import React, { Component, useState, useEffect, useRef } from "react";
import { Preloader } from "../../Utils/Preloader";
// import ValidationMessageComponent from "../Components/ValidationMessage";
import budgetInformationSchema from "../../Validation/ValidateBudgetInformation";

import Select from "react-select";
import { Link } from "react-router-dom";
import {Button } from 'antd';
import { AiOutlineFileExcel,AiOutlinePrinter } from 'react-icons/ai';

import axios from "axios";
import cookies from 'js-cookie'
import { BsPencilSquare } from 'react-icons/bs';

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';

// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
//const BASE_API_URL = 'http://localhost:5000/api/v1';

function formatDefaultDate(dateString) {
    console.log(dateString);
    var newDate = new Date(dateString);
    newDate.setDate(newDate.getDate() + 1);

    return newDate.toISOString().split("T")[0];
}

export default class FinanceC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            budgetInformationId: "",
            item: "",
            geoCode: "",
            economicCode: "",
            functionalCode: "",
            programmeCode: "",
            fundsCode: "",
            allBudgetDepartment: [],
            getMdaData:[],
            approvalInformationData: [],
            programmeActivities: "",
            open: true,
            geoCodeData: "",
            localGovernmentCouncilData: [],
            localGovernmentCouncil: "",
            allDepartmentBudget: [],
            departmentBudget:"",
            activityData: null,
            activity: "",
            wardCouncil: "",

            allBudgetInformationData: [],

            allCurrencyData: [],
            currency:"",
            allProgramme: [],
            programme:"",
            search: "",
            budgetInformation: "",
            loading: false,
            deleteId: "",
            edit: false,
            editId: "",
            submenu: false,
            showModal: false,
            total: 0,
            showBudgetModal: false,
            mCategory: "",
            mItem: "",
            mCurrency: "",
            mJan: 0,
            mFeb: 0,
            mMar: 0,
            mApr: 0,
            mMay: 0,
            mJun: 0,
            mJul: 0,
            mAug: 0,
            mSep: 0,
            mOct: 0,
            mNov: 0,
            mDec: 0,
            mTotal: 0,
            mAssumptions: "",
            mJustification: "",
            fullName: "",
            role: "",
            employeeId: 0,
            loader: false,
            auhtorizedMdaId: 0,
          
            message: ""
        };
    }
    componentDidMount() {
        this.getBudgetInformation();
        this.getAllPendingApproval();
       // this.getDepartmentBudget();
        this.getAuthorisedUser();
        this.getMdas();
        this.getCurrency();
        this.getProgramme();
    }
    handleItemChange = (ev) => {
        const re = /^[0-9\b]+$/;
        if (ev.target.value === "" || re.test(ev.target.value)) {
            let targetvalue = ev.target.value == "" ? "0" : ev.target.value;
            let total = this.state.mTotal;
            let prevSate = this.state.[ev.target.name]
            if (parseFloat(prevSate) != 0) {
                total = total - parseFloat(prevSate)
                total = total + parseFloat(targetvalue)

            } else {
                total = parseFloat(targetvalue) + total
            }

            this.setState({
                [ev.target.name]: parseFloat(targetvalue),
                mTotal: total
            });
        } else {
            this.setState({
                [ev.target.name]: 0,
               
            });
        }
    };

    handleChange = (ev) => {
        this.setState({
            [ev.target.name]: ev.target.value,
        });
    };
    handleSelectedCurrencyChange = (selectedCurrency) => {
        this.setState({ selectedCurrency, currency: selectedCurrency }, () =>
            console.log(`Option selected:`, selectedCurrency)
        );
    };
    getCurrency = () => {
        axios
            .get(`${BASE_API_URL}/currency/getcurrency`)
            .then((response) => {
                console.log(response.data);
                this.setState({
                    allCurrencyData: response.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    getProgramme = () => {
        this.setState({
            loader: true,
            message: "",
        });
        axios
            .get(`${BASE_API_URL}/programme/getprogram`)
            .then((response) => {
                console.log(response.data);
                this.setState({
                    allProgramme: response.data,
                    programme: response.data.length == 0 ? "" : response.data[0],
                    loader: false,
                    message: "",
                });
            })
            .catch((err) => {
                this.setState({
                    loader: false,
                    message: err.message,
                });
            });
    };

    getBudgetItem = (budgetInformation) => {
        console.log(budgetInformation)
        ///added here deliberately
        //this.setState({
        //    auhtorizedMdaId: budgetInformation.departmentBudget.mdaId
        //})

        let currency = { value: budgetInformation.currency.id, label: budgetInformation.currency.title }
        this.handleSelectedCurrencyChange(currency);

        let programmeInfomation = { value: budgetInformation.departmentBudget.programmes.id, label: budgetInformation.departmentBudget.programmes.programmeName }
        console.log(programmeInfomation)
        this.handleSelectedProgrammeChange(programmeInfomation, budgetInformation.departmentBudget.mdaId)
        let total =
            budgetInformation.jan_val
            + budgetInformation.feb_val
            + budgetInformation.mar_val
            + budgetInformation.apr_val
            + budgetInformation.may_val
            + budgetInformation.june_val
            + budgetInformation.july_val
            + budgetInformation.aug_val
            + budgetInformation.sept_val
            + budgetInformation.oct_val
            + budgetInformation.nov_val
            + budgetInformation.dec_val
        this.setState({
            showBudgetModal: !this.state.showBudgetModal,
            mJan: budgetInformation.jan_val,
            mFeb: budgetInformation.feb_val,
            mMar: budgetInformation.mar_val,
            mApr: budgetInformation.apr_val,
            mMay: budgetInformation.may_val,
            mJun: budgetInformation.june_val,
            mJul: budgetInformation.july_val,
            mAug: budgetInformation.aug_val,
            mSep: budgetInformation.sept_val,
            mOct: budgetInformation.oct_val,
            mNov: budgetInformation.nov_val,
            mDec: budgetInformation.dec_val,
            mJustification: budgetInformation.justifications,
            mAssumptions: budgetInformation.assumptions,
            mTotal: total,
            budgetInformationId: budgetInformation.id
      


        })
    }


    getBudgetInformation = () => {
        axios
            .get(`${BASE_API_URL}/budgetinformation/getallbudgetinformation`)
            .then((response) => {
                console.log(response.data);
                this.setState({
                    allBudgetInformationData: response.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    handleSelectedProgrammeChange = (selectedProgramme,mdaId) => {
        this.setState({ selectedProgramme, programme: selectedProgramme }, () =>
            console.log(`Option selected:`, selectedProgramme)
        );
        this.getDepartmentBudgetByProgrammeID(selectedProgramme.value, mdaId);
    };

    getAllPendingApproval = () => {
        axios
            .get(`${BASE_API_URL}/approvalprocessinformation/getApprovalprocessinformationpendingfinance`)
            .then((response) => {
                console.log(response.data);
                this.setState({
                    approvalInformationData: response.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getAuthorisedUser = () => {
        // const { cookies } = this.props;
        if (typeof cookies.get("userId") !== "undefined") {
            let authUser = cookies.get("userId");
            this.setState({
                fullName: authUser.firstName + " " + authUser.lastName,
                role: authUser.role,
                employeeId: authUser.id

            });
            
        }

    };
    handlePrint = (table_id) => {
        var divToPrint = document.getElementById(table_id);
        let newWin = window.open("");
        newWin.document.write(divToPrint.outerHTML);
        newWin.print();
        newWin.close();
    };
    handleSubmit = (newbudgetReviewInformation) => {
        let optionId = `option${newbudgetReviewInformation.approvalProcessInformations.id}`;
        let commentId = `comment${newbudgetReviewInformation.approvalProcessInformations.id}`;

        let employeeId = this.state.employeeId.toString();
        let status = parseInt(document.getElementById(optionId).value);
        let comment = document.getElementById(commentId).value;
        let approvalProcessInformationId = newbudgetReviewInformation.approvalProcessInformations.id
        let data = { status, comment, employeeId, approvalProcessInformationId };

        if (comment == "") {
            this.setState({
               
                message: "comment is required",
            });
        } else {
            this.setState({
                loader: true,
                message: "",
            });
            axios.post(`${BASE_API_URL}/ApprovalProcessInformation/AddBudgetReviewInformation`, { ...data }).then((response) => {
                this.setState({
                    loader: false,
                    message: "",
                });
                this.getAllPendingApproval();
            }).catch((err) => {
                console.log(err)
                this.setState({
                    loader: false,
                    message: err.message
                });
            })
        }


    }

    updateRecord = () => {

        let jan_val = this.state.mJan;
        let feb_val = this.state.mFeb;
        let mar_val = this.state.mMar;
        let apr_val = this.state.mApr;
        let may_val = this.state.mMay;
        let june_val = this.state.mJun;
        let july_val = this.state.mJul;

        let aug_val = this.state.mAug;
        let sept_val = this.state.mSep;
        let oct_val = this.state.mOct;
        let nov_val = this.state.mNov;
        let dec_val = this.state.mDec;
        let departmentBudgetId = this.state.departmentBudget.value;
        let currencyId = this.state.currency.value;
        let updatedBy = this.props.employeeId.toString();
        let id = this.state.budgetInformationId

        let justifications = this.state.mJustification
        let assumptions = this.state.mAssumptions



        let data = {
            id,
            currencyId,
            departmentBudgetId,
            jan_val,
            feb_val,
            mar_val,
            apr_val,
            may_val,
            june_val,
            july_val,
            aug_val,
            sept_val,
            oct_val,
            nov_val,
            dec_val,
            updatedBy,
            justifications,
            assumptions

        };
        
        budgetInformationSchema
            .validate(data)
            .then((message) => {
                this.setState({
                    loader: true,
                    message: "",
                    showBudgetModal: false,
                });
                axios
                    .put(`${BASE_API_URL}/budgetInformation/UpdateBudgetInformationsForFinance`, {
                        ...data,
                    })
                    .then((response) => {
                        this.getBudgetInformation(this.state.auhtorizedMdaId);
                        this.setState({
                            showBudgetModal: false,
                            janVal: "0",
                            febVal: "0",
                            marVal: "0",
                            aprVal: "0",
                            mayVal: "0",
                            junVal: "0",
                            julVal: "0",
                            augVal: "0",
                            sepVal: "0",
                            octVal: "0",
                            novVal: "0",
                            decVal: "0",
                            loader: false,
                            messsage: "",
                        });
                    })
                    .catch((err) => {
                        console.log(err)
                        this.setState({
                            loader: false,
                            message: err.response.data,
                            showBudgetModal: true,
                        });
                    });
            })
            .catch((err) => {
                this.setState({
                    loader: false,
                    message: err.errors,
                    showModal: true,
                });
            });
    }

    //getDepartmentBudget() {
    //    this.setState({
    //        loader: true,
    //        message: "",
    //    });
    //    axios
    //        .get(`${BASE_API_URL}/departmentbudget`)
    //        .then((response) => {
    //            console.log(response.data);
    //            this.setState({
    //                allBudgetDepartment: response.data,
    //                loader: false,
    //                message: "",
    //            });
    //        })
    //        .catch((err) => {
    //            this.setState({
    //                loader: false,
    //                message: err.message,
    //            });
    //        });
    //}

    getMdas() {
        this.setState({
            loader: true,
            message: "",

        });
        axios
            .get(`${BASE_API_URL}/mda`)
            .then((response) => {

                
                this.setState({
                    getMdaData: response.data,
                    loader: false,
                    message: "",
                });
            })
            .catch((err) => {
                this.setState({
                    loader: false,
                    message: err.message,

                });
            });
    }
    getBudgetInformationByMda() {
        let mdaId = document.getElementById('mda').value;

        if (mdaId != "") {
            this.setState({
                loader: true,
            });
            axios
                .get(`${BASE_API_URL}/budgetinformation/GetBudgetmentInformationByMdaId?mdaId=${mdaId}`)
                .then((response) => {
                    console.log(response.data)
                    this.setState({
                        allBudgetInformationData: response.data,
                        loader: false,
                    });
                })
                .catch((err) => {
                    this.setState({
                        message: err.message,
                        loader: false,
                    });
                });
        }

    }

    getDepartmentBudgetByProgrammeID = (programmeId,mdaId) => {
        this.setState({
            loader: true,
            message: "",
        });
       
        axios
            .get(
                `${BASE_API_URL}/departmentBudget/GetDepartmentBudgetByProgrammeIDAndMda?programmeId=${programmeId}&mdaId=${mdaId}`
            )
            .then((response) => {
                console.log(response.data);
                this.setState({
                    allDepartmentBudget: response.data,
                    departmentBudget: response.data.length == 0 ? "" : response.data[0],
                    loader: false,
                    message: "",
                });
            })
            .catch((err) => {
                this.setState({
                    loader: false,
                    message: err.message,
                });
            });
    };

    //getMda = (departmentBudgetId) => {
    //    let mdaName = "";
    //    let departmentBudget = this.state.allBudgetDepartment.filter(item => item.id == departmentBudgetId);
    //    if (departmentBudget.length !== 0)
    //        mdaName = departmentBudget[0].mdas.name
    //    return mdaName
    //}
    handleExportCsv = (table_id, separator = ",") => {
        // Select rows from table_id
        var rows = document.querySelectorAll("table#" + table_id + " tr");
        // Construct csv
        var csv = [];
        for (var i = 0; i < rows.length; i++) {
            var row = [],
                cols = rows[i].querySelectorAll("td, th");
            for (var j = 0; j < cols.length; j++) {
                // Clean innertext to remove multiple spaces and jumpline (break csv)
                var data = cols[j].innerText
                    .replace(/(\r\n|\n|\r)/gm, "")
                    .replace(/(\s\s)/gm, " ");
                // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
                data = data.replace(/"/g, '""');
                // Push escaped string
                row.push('"' + data + '"');
            }
            csv.push(row.join(separator));
        }
        var csv_string = csv.join("\n");
        // Download it
        var filename =
            "export_" + table_id + "_" + new Date().toLocaleDateString() + ".csv";
        var link = document.createElement("a");
        link.style.display = "none";
        link.setAttribute("target", "_blank");
        link.setAttribute(
            "href",
            "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
        );
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    handlePrint = (table_id) => {
        var divToPrint = document.getElementById(table_id);
        let newWin = window.open("");
        newWin.document.write(divToPrint.outerHTML);
        newWin.print();
        newWin.close();
    };
    render() {

        let mdas = typeof this.state.getMdaData == "undefined" ? [] : this.state.getMdaData;

        console.log(mdas)
        let allMdaOption = mdas.map((mda) => {
            return (
                <option value={mda.id}>{mda.name}</option>
            )
        })

        var alltotal = 0;
        // const budgetInformations = this.state.allBudgetInformationData;
        var janVal = 0;
        var febVal = 0;
        var marVal = 0;
        var aprVal = 0;
        var mayVal = 0;
        var junVal = 0;
        var julVal = 0;
        var augVal = 0;
        var sepVal = 0;
        var octVal = 0;
        var novVal = 0;
        var decVal = 0;
        
        const budgetInformations = this.state.allBudgetInformationData.filter(
            (budgetInformation) => {
                return budgetInformation.departmentBudget.mdas.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
            }

        );

       
        var allBudgetInformations = budgetInformations.map((budgetInformation) => {
            let url = `/schedule-manager/finance-detail:${budgetInformation.departmentBudget.mdaId}`;
            //total += budgetInformation.total;
            janVal += parseFloat(budgetInformation.jan_val);
            febVal += parseFloat(budgetInformation.feb_val);
            marVal += parseFloat(budgetInformation.mar_val);

            aprVal += parseFloat(budgetInformation.apr_val);
            mayVal += parseFloat(budgetInformation.may_val);
            junVal += parseFloat(budgetInformation.june_val);
            julVal += parseFloat(budgetInformation.july_val);
            augVal += parseFloat(budgetInformation.aug_val);
            sepVal += parseFloat(budgetInformation.sept_val);
            octVal += parseFloat(budgetInformation.oct_val);
            novVal += parseFloat(budgetInformation.nov_val);
            decVal += parseFloat(budgetInformation.dec_val);
            let total =
                budgetInformation.jan_val
                + budgetInformation.feb_val
                + budgetInformation.mar_val
                + budgetInformation.apr_val
                + budgetInformation.may_val
                + budgetInformation.june_val
                + budgetInformation.july_val
                + budgetInformation.aug_val
                + budgetInformation.sept_val
                + budgetInformation.oct_val
                + budgetInformation.nov_val
                + budgetInformation.dec_val
            alltotal+=total
            return (

                <tr>
                    <td className="table-border-right p-2 text-sm font-medium text-color">
                        <div
                            className="bg-pen"
                            onClick={() => this.getBudgetItem(budgetInformation)}
                        >
                            {/* <svg
                                width="18"
                                height="18"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="#fff"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                />
                            </svg> */}
                            <BsPencilSquare style={{fontSize:"20px", backgroundColor:"green", color:'white', cursor:"pointer"}}/>
                        </div>
                        <Link to={url} className="underline text-green-600">
                            {budgetInformation.departmentBudget.mdas.name}
                        </Link>
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {total}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">0</td>
                    <td className="table-border-right p-2 text-sm text-color">0</td>
                    <td className="table-border-right4 p-2 text-sm text-color">0</td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.jan_val}
                    </td>

                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.feb_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.mar_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.apr_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.may_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.june_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.july_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.aug_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.sept_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.oct_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.nov_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.dec_val}
                    </td>
                </tr>
            );
        });
        return (
            <React.Fragment>
                {this.state.loader === true ? <Preloader /> : ""}
                <div
                    className=" w-100  bg-white py-4 px-4 shadow-sm overflow-hidden cursor-pointer"
                    onClick={() => this.setState({ open: !this.state.open })}
                >
                    <p className="flex-1 mt-2 text-sm text-color">


                        <span className="font-medium font-avenir-black">
                            Budget Period:
            </span>{" "}
                        <span className="font-normal">2021 Financial Year</span>

                      
                    </p>
                   
                    <div className='flex-align flex-between'>
                    <div class="m-t-10 relative z-20 pb-1">
                            <select id="mda" className='border' style={{lineHeight:"200px", fontSize:'18px', padding:'5px'}} onChange={() => this.getBudgetInformationByMda()} class="relative pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm">
                                <option> Select MDA</option>
                                {allMdaOption}
                            </select>
                        </div>
                        <div className='flex-align flex-between'>
                            <button
                        onClick={() => {
                        this.handleExportCsv("financeTable");
                        }}
                        type="submit"
                        className="m-r-5 h-10 p-5 inline-flex py-2 pr-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"

                        // className="mr-3 h-10 inline-flex py-2 pr-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                        >
                        {/* <img
                        src="/upload-excel.png"
                        style={{ width: 18, height: 18 }}
                        className="mx-3"
                        /> */}
                         <div className='flex-align'>
                                <AiOutlineFileExcel style={{fontSize:"20px", marginRight:'5px'}}/>
                            <p>
                            Excel Export
                            </p>
                            </div>
                       
                        </button>
                        <button
                        type="submit"
                        className="m-r-5 p-5 h-10 inline-flex py-2 pr-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"

                        // className="mr-3 h-10 inline-flex py-2 pr-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                        onClick={() =>
                        this.setState({
                        showModal: !this.state.showModal,
                        })
                        }
                        >
                        <span style={{ width: 18, height: 18 }} />
                        Approval Status
                        </button>
                        <input
                        onChange={this.handleChange}
                        name="search"
                        id="search"
                        
                        value={this.state.search}
                        placeholder="Search"
                        className="border border-solid p-5 px-2 h-10 w-50"
                        style={{
                        backgroundColor: "transparent",
                        borderColor: "#707070",
                        }}
                        />
                        </div>
                    </div>
                </div>
                <div class="flex flex-col">
                    <div class="shadow overflow-y-scroll">
                        <table className="min-w-full" id="financeTable">
                            <thead class="bg-gray-table-header">
                                <tr>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        (N‘000mn)
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        Proposed Budget 2020
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        2019 Pre Actuals
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        % on Total
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right4 px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        % Growth
                  </th>{" "}
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        Jan
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        Feb
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        Mar
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        Apr
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        May
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        Jun
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        Jul
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        Aug
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        Sept
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        Oct
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        Nov
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        Dec
                  </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                {allBudgetInformations}

                                <tr>
                                    <td class="table-border-right p-2 text-sm font-medium font-avenir-black text-color">
                                        Total
                  </td>
                                    <td class="table-border-right p-2 text-sm font-medium font-avenir-black text-color">
                                        {alltotal}
                                    </td>
                                    <td class="table-border-right p-2 text-sm font-medium font-avenir-black text-color">
                                        0
                  </td>
                                    <td class="table-border-right p-2 text-sm font-medium font-avenir-black text-color">
                                        0
                  </td>
                                    <td class="table-border-right4 p-2 text-sm font-medium font-avenir-black text-color">
                                        {((alltotal - 0) / alltotal) * 100}
                                    </td>
                                    <td class="table-border-right p-2 text-sm font-medium font-avenir-black text-color">
                                        {janVal}
                                    </td>
                                    <td class="table-border-right p-2 text-sm font-medium font-avenir-black text-color">
                                        {febVal}
                                    </td>
                                    <td class="table-border-right p-2 text-sm font-extrabold text-color">
                                        {marVal}
                                    </td>
                                    <td class="table-border-right p-2 text-sm font-medium font-avenir-black text-color">
                                        {aprVal}
                                    </td>
                                    <td class="table-border-right p-2 text-sm font-medium font-avenir-black text-color">
                                        {mayVal}
                                    </td>
                                    <td class="table-border-right p-2 text-sm font-medium font-avenir-black text-color">
                                        {junVal}
                                    </td>
                                    <td class="table-border-right p-2 text-sm font-medium font-avenir-black text-color">
                                        {julVal}
                                    </td>
                                    <td class="table-border-right p-2 text-sm font-medium font-avenir-black text-color">
                                        {augVal}
                                    </td>
                                    <td class="table-border-right p-2 text-sm font-extrabold text-color">
                                        {sepVal}
                                    </td>
                                    <td class="table-border-right p-2 text-sm font-medium font-avenir-black text-color">
                                        {octVal}
                                    </td>
                                    <td class="table-border-right p-2 text-sm font-medium font-avenir-black text-color">
                                        {novVal}
                                    </td>
                                    <td class="table-border-right p-2 text-sm font-medium font-avenir-black text-color">
                                        {decVal}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <p className="text-sm font-normal text-color p-4"></p>
                 Approval Officer Comments
            
                {
                    this.state.approvalInformationData.map((approvalInformation) => {
                        let optionId = `option${approvalInformation.approvalProcessInformations.id}`;
                        let commentId = `comment${approvalInformation.approvalProcessInformations.id}`;
                        return (
                            <div className="mt-10">
                              
                                <div className="flex">
                                    <div className="bg-white shadow-sm w-6/12">

                                        <div
                                            className="flex py-4 px-4"
                                            style={{ borderBottom: "1px solid #D5D5D6" }}
                                        >
                                            <div className="flex-1">
                                                <div class="mt-1 relative">
                                                    <select name="status"
                                                        id={optionId}
                                                        name="status"
                                                        aria-haspopup="listbox"
                                                        aria-expanded="true"
                                                        aria-labelledby="listbox-label"
                                                        class="relative border shadow-sm w-28 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm"
                                                    >

                                                        <option value="1">Approve</option>
                                                        <option value="2">Decline</option>



                                                    </select>

                                                  
                                                </div>
                                            </div>

                                            <div className="flex">
                                                <p className="text-color font-medium text-sm mr-3 text-right">
                                                    <span className="font-avenir-black">{ this.state.fullName  }</span>
                                                    <br />
                                                    <span className="font-normal text-xs ml-16">{this.state.role}</span>
                                                </p>
                                                <img class="h-8 w-8 rounded-full" src={this.props.profilePicture } alt="" />
                                            </div>
                                        </div>
                                        <textarea
                                            
                                            name="commenttext"
                                            id={commentId}
                                            name=""
                                            rows=""
                                            class="shadow-sm block w-full sm:text-sm"
                                            style={{ height: "13rem", border: "none" }}
                                        ></textarea>
                                    </div>
                                </div>
                                <button onClick={() => this.handleSubmit(approvalInformation) }
                                    type="submit"
                                    class="mr-5 w-6/12 mt-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white primary-bg-green focus:outline-none"
                                >
                                    Submit for Review and Approval ({approvalInformation.mda.name})
                          </button>
                            </div>

                        )
                    })

                }
            

                {this.state.showModal ? (
                    <>
                        <div className="overlay justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-7/12 my-6 mx-auto">
                                {/*content*/}
                                <div
                                    className="flex bg-card-header py-4 px-4 shadow-sm overflow-hidden cursor-pointer"
                                    onClick={() =>
                                        this.setState({
                                            showModal: !this.state.showModal,
                                        })
                                    }
                                >
                                    <p className="flex-1 mt-2 text font-medium font-avenir-black text-color">
                                        Approval Information
                  </p>
                                </div>
                                <div class="flex flex-col bg-white">
                                    <table class="">
                                        <thead class="bg-gray-table-header">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    class="table-border-right table-border-bottom px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                                >
                                                    Approval Role
                        </th>
                                                <th
                                                    scope="col"
                                                    class="table-border-right table-border-bottom px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                                >
                                                    Approved By
                        </th>
                                                <th
                                                    scope="col"
                                                    class="table-border-right table-border-bottom px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                                >
                                                    Status
                        </th>
                                                <th
                                                    scope="col"
                                                    class="table-border-right table-border-bottom px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                                >
                                                    Date Initiated
                        </th>
                                                <th
                                                    scope="col"
                                                    class="px-3 py-3 table-border-bottom text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                                >
                                                    Comment
                        </th>
                                            </tr>
                                        </thead>
                                        <tbody class="bg-white divide-y divide-gray-200">
                                            <tr>
                                                <td class="table-border-right table-border-bottom px-4 py-2 text-sm font-medium text-gray-900">
                                                    Approver 1
                        </td>
                                                <td class="table-border-right table-border-bottom px-4 py-2 text-sm text-color">
                                                    Ugochukwu Chukwunonso
                        </td>
                                                <td class="table-border-right table-border-bottom px-4 py-2 text-sm text-color">
                                                    Completed
                        </td>
                                                <td class="table-border-right table-border-bottom px-4 py-2 text-sm text-color">
                                                    04-Mar-2019
                        </td>
                                                <td class="p-2 table-border-bottom text-sm text-color"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                   
                                </div>
                                <div className="flex-align flex-between w-100 flex-between colorW p-2 mb-2">
                                        <p className="flex-1 text-sm font-normal text-color p-4">
                                            Showing 1 to 2 of 2 entries
                    </p>
                                        <button
                                            type="button"
                                            class="w-32 h-10 pointer mt-5 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-bold text-color bg-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={() =>
                                                this.setState({
                                                    showModal: !this.state.showModal,
                                                })
                                            }
                                        >
                                            Close
                    </button>
                                    </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}

                {this.state.showBudgetModal ? (
                    
                    <>
                        <div className="overlay p-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div style={{width:"80%"}} className="p-20 w-60 relative w-7/12 my-6 mx-auto">
                                {this.state.message !== "" ? (
                                    // <ValidationMessageComponent
                                    //     message={this.state.message}
                                    // ></ValidationMessageComponent>
                                    <p>tt</p>
                                ) : (
                                        ""
                                    )}
                                <div className="flex-between w-100 bg-card-header py-4 px-4 shadow-sm overflow-hidden cursor-pointer">
                                    <p className="flex-1 bold mt-2 text-sm font-bold font-avenir-black text-color">
                                        Budget Information
                  </p>
                                    <div
                                        style={{
                                            width: 38,
                                            height: 38,
                                            backgroundColor: "#FFB831",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <img
                                            src="/share-icon.png"
                                            style={{
                                                width: 15,
                                                position: "absolute",
                                                top: 28,
                                                right: 28,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="bg-white py-4 px-4 shadow-sm overflow-hidden">
                                    <div class="mt-3 w-100 flex-between gap-y-6 gap-x-4 sm:grid-cols-12">
                                        <div style={{border:""}} class="w-100 m-r-5  sm:col-span-3">
                                            <label
                                                for="mCategory"
                                                class="block text-sm font-medium text-gray-700"
                                            >
                                                Category
                      </label>
                                            <div class="mt-1 w-100">
                                                <Select
                                                    
                                                    type="mCategory"
                                                    name="mCategory"
                                                    id="mCategory"
                                                    value={this.state.programme}
                                                    options={this.state.allProgramme}
                                                    class="w-100 shadow-sm focus:outline-none block w-full sm:text-sm"
                                                    onChange={this.handleSelectedProgrammeChange}
                                                />
                                            </div>
                                        </div>
                                        <div class="sm:col-span-6 w-100">
                                            <label
                                                for="mItem"
                                                class="block text-sm font-medium text-gray-700"
                                            >
                                                Item
                      </label>
                                            <div class="mt-1">
                                                <Select
                                                   
                                                    type="mItem"
                                                    name="mItem"
                                                    id="mItem"
                                                    value={this.state.departmentBudget}
                                                    options={this.state.allDepartmentBudget}
                                                    class="w-100 shadow-sm w-100 border focus:outline-none block w-full sm:text-sm"
                                                    onChange={this.handleSelectedDepartmentBudgetChange}
                                                />
                                            </div>
                                        </div>
                                        <div class="sm:col-span-3 m-r-5  w-100">
                                            <label
                                                for="mCurrency"
                                                class="block text-sm font-medium text-gray-700"
                                            >
                                                Currency
                      </label>
                                            <div class="mt-1">
                                                <Select
                                                    name="mCurrency"
                                                    id="mCurrency"
                                                    value={this.state.currency}
                                                    options={this.state.allCurrencyData}
                                                    class="w-100 shadow-sm focus:outline-none block w-full sm:text-sm"
                                                    onChange={this.handleSelectedCurrencyChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-3 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                                        <div className='m-t-10 flex-between flex-align'>
                                            
                                            <div class="w-100  m-r-5 sm:col-span-3">
                                        <label
                                        for="mJan"
                                        class="block text-sm font-medium text-gray-700"
                                        >
                                        Jan
                                        </label>
                                        <div class="mt-1">
                                        <input
                                        
                                        type="text"
                                        name="mJan"
                                        id="mJan"
                                        value={this.state.mJan}
                                        class=" border w-100 p-5 shadow-sm focus:outline-none block w-full sm:text-sm"
                                        onChange={this.handleItemChange}
                                        />
                                        </div>
                                        </div>
                                        <div class="w-100 m-r-5  sm:col-span-3">
                                        <label
                                        for="mFeb"
                                        class="block text-sm font-medium text-gray-700"
                                        >
                                        Feb
                                        </label>
                                        <div class="mt-1">
                                        <input
                                        
                                        type="text"
                                        name="mFeb"
                                        id="mFeb"
                                        value={this.state.mFeb}
                                        class=" border w-100 p-5 shadow-sm focus:outline-none block w-full sm:text-sm"
                                        onChange={this.handleItemChange}
                                        />
                                        </div>
                                        </div>
                                        <div class="w-100 m-r-5  sm:col-span-3">
                                        <label
                                        for="mMar"
                                        class="block text-sm font-medium text-gray-700"
                                        >
                                        Mar
                                        </label>
                                        <div class="mt-1">
                                        <input
                                        
                                        type="text"
                                        name="mMar"
                                        id="mMar"
                                        value={this.state.mMar}
                                        class=" border w-100 p-5 shadow-sm focus:outline-none block w-full sm:text-sm"
                                        onChange={this.handleItemChange}
                                        />
                                        </div>
                                        </div>
                                        <div class="w-100 m-r-5  sm:col-span-3">
                                        <label
                                        for="mApr"
                                        class="block text-sm font-medium text-gray-700"
                                        >
                                        Apr
                                        </label>
                                        <div class="mt-1">
                                        <input
                                        
                                        type="text"
                                        name="mApr"
                                        id="mApr"
                                        value={this.state.mApr}
                                        class=" border w-100 p-5 shadow-sm focus:outline-none block w-full sm:text-sm"
                                        onChange={this.handleItemChange}
                                        />
                                        </div>

                                        </div>
                                        
                                        </div>

                                        <div className='flex-between m-t-10 flex-align'>
                                            
                                            <div class=" w-100 m-r-5  sm:col-span-3">
                                        <label
                                        for="mMay"
                                        class="block text-sm font-medium text-gray-700"
                                        >
                                        May
                                        </label>
                                        <div class="mt-1">
                                        <input
                                        
                                        type="text"
                                        name="mMay"
                                        id="mMay"
                                        value={this.state.mMay}
                                        class=" border w-100 p-5 shadow-sm focus:outline-none block w-full sm:text-sm"
                                        onChange={this.handleItemChange}
                                        />
                                        </div>
                                        </div>

                                        <div class="w-100 m-r-5 sm:col-span-3">
                                        <label
                                        for="mJun"
                                        class="block text-sm font-medium text-gray-700"
                                        >
                                        Jun
                                        </label>
                                        <div class="mt-1">
                                        <input
                                        
                                        type="text"
                                        name="mJun"
                                        id="mJun"
                                        value={this.state.mJun}
                                        class=" border w-100 p-5 shadow-sm focus:outline-none block w-full sm:text-sm"
                                        onChange={this.handleItemChange}
                                        />
                                        </div>
                                        </div>

                                        <div class="w-100 m-r-5  sm:col-span-3">
                                        <label
                                        for="mJul"
                                        class="block text-sm font-medium text-gray-700"
                                        >
                                        Jul
                                        </label>
                                        <div class="mt-1">
                                        <input
                                        
                                        type="text"
                                        name="mJul"
                                        id="mJul"
                                        value={this.state.mJul}
                                        class=" border w-100 p-5 shadow-sm focus:outline-none block w-full sm:text-sm"
                                        onChange={this.handleItemChange}
                                        />
                                        </div>
                                        </div>

                                        <div class="w-100 m-r-5 m-r-5   sm:col-span-3">
                                        <label
                                        for="mAug"
                                        class="block text-sm font-medium text-gray-700"
                                        >
                                        Aug
                                        </label>
                                        <div class="mt-1">
                                        <input
                                        
                                        type="text"
                                        name="mAug"
                                        id="mAug"
                                        value={this.state.mAug}
                                        class=" border w-100 p-5 shadow-sm focus:outline-none block w-full sm:text-sm"
                                        onChange={this.handleItemChange}
                                        />
                                        </div>
                                        </div>
                                        
                                        </div>


                                        <div className='flex-align m-t-10 flex-between'>
                                            <div class="w-100 m-r-5  sm:col-span-3">
                                        <label
                                        for="mSep"
                                        class="block text-sm font-medium text-gray-700"
                                        >
                                        Sep
                                        </label>
                                        <div class="mt-1">
                                        <input
                                        
                                        type="text"
                                        name="mSep"
                                        id="mSep"
                                        value={this.state.mSep}
                                        class=" border w-100 p-5 shadow-sm focus:outline-none block w-full sm:text-sm"
                                        onChange={this.handleItemChange}
                                        />
                                        </div>
                                        </div>
                                        <div class="w-100 m-r-5  sm:col-span-3">
                                        <label
                                        for="mOct"
                                        class="block text-sm font-medium text-gray-700"
                                        >
                                        Oct
                                        </label>
                                        <div class="mt-1">
                                        <input
                                        
                                        type="text"
                                        name="mOct"
                                        id="mOct"
                                        value={this.state.mOct}
                                        class=" border w-100 p-5 shadow-sm focus:outline-none block w-full sm:text-sm"
                                        onChange={this.handleItemChange}
                                        />
                                        </div>
                                        </div>
                                        <div class="w-100 m-r-5  sm:col-span-3">
                                        <label
                                        for="mNov"
                                        class="block text-sm font-medium text-gray-700"
                                        >
                                        Nov
                                        </label>
                                        <div class="mt-1">
                                        <input
                                        
                                        type="text"
                                        name="mNov"
                                        id="mNov"
                                        value={this.state.mNov}
                                        class=" border w-100 p-5 shadow-sm focus:outline-none block w-full sm:text-sm"
                                        onChange={this.handleItemChange}
                                        />
                                        </div>
                                        </div>
                                        <div class="w-100 m-r-5  sm:col-span-3">
                                        <label
                                        for="mDec"
                                        class="block text-sm font-medium text-gray-700"
                                        >
                                        Dec
                                        </label>
                                        <div class="mt-1">
                                        <input
                                        
                                        type="text"
                                        name="mDec"
                                        id="mDec"
                                        value={this.state.mDec}
                                        class=" border w-100 p-5 shadow-sm focus:outline-none block w-full sm:text-sm"
                                        onChange={this.handleItemChange}
                                        />
                                        </div>
                                        </div>
                                        </div>

                                        <div class="m-t-10 w-100 m-r-5  sm:col-span-3">
                                            <label
                                                for="mTotal"
                                                class="block text-sm font-medium text-gray-700"
                                            >
                                                Total
                      </label>
                                            <div class="mt-1">
                                                <input
                                                    readOnly={true}
                                                    type="text"
                                                    name="mTotal"
                                                    id="mTotal"
                                                    value={this.state.mTotal}
                                                    class=" border w-100 p-5 shadow-sm focus:outline-none block w-full sm:text-sm"
                                                    onChange={this.handleItemChange}
                                                />
                                            </div>
                                        </div>
                                        <div class="m-t-10 sm:col-span-9">
                                            <label
                                                for="mAssumptions"
                                                class="block text-sm font-medium text-gray-700"
                                            >
                                                Assumptions
                      </label>
                                            <div class="mt-1">
                                                <input
                                                  
                                                    type="text"
                                                    name="mAssumptions"
                                                    id="mAssumptions"
                                                    value={this.state.mAssumptions}
                                                    class=" border w-100 p-5 shadow-sm focus:outline-none block w-full sm:text-sm"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div class="m-t-10 sm:col-span-12">
                                            <label
                                                for="mJustification"
                                                class="block text-sm font-medium text-gray-700"
                                            >
                                                Justification
                      </label>
                                            <div class="mt-1">
                                                <input
                                                   
                                                    type="text"
                                                    name="mJustification"
                                                    id="mJustification"
                                                    value={this.state.mJustification}
                                                    class=" border w-100 p-5 shadow-sm focus:outline-none block w-full sm:text-sm"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div class="m-t-10 pt-5 sm:col-span-12 grid place-content-end">
                                            <div class="flex">
                                                <button onClick={()=>this.updateRecord() }
                                                    type="submit"
                                                    class="mr-5 w-36 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                                                >
                                                    Update Record
                        </button>
                                                <button
                                                    type="button"
                                                    class="mr-5 w-32 bg-clear-entry py-2 px-4 border border-gray-300 shadow-sm text-sm font-bold text-color bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    Clear Entry
                        </button>
                                                <button
                                                    type="button"
                                                    class="w-32 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-bold text-color bg-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    onClick={() =>
                                                        this.setState({
                                                            showBudgetModal: !this.state.showBudgetModal,
                                                        })
                                                    }
                                                >
                                                    Close
                        </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
            </React.Fragment>
        );
    }
}
