import React, { useEffect, useState } from 'react';

import LocationIcon from '../../assets/images/location-icon-100.png';
import { decodeToken } from '../../utility/auth';

const LocationDetails = ({ details = {} }) => {
  const [geoLocate, setGeoLocate] = useState('');

  useEffect(() => {
    const getLocation = () => {
      const gl = localStorage.getItem('gl');
      gl && setGeoLocate(decodeToken('gl').gl);
    };

    getLocation();

    if (geoLocate.length < 1) {
      setTimeout(() => getLocation(), 10000);
    }
  }, [geoLocate]);

  return (
    <div className="location-details flex flex-v-center">
      <div className="m-r-5 right-text" style={{ paddingTop: '3px' }}>
        <p className="location">{geoLocate || 'Unknown Location'}</p>
        <p className="login-time">{details.loginTime || 'Time of login: 08:45am'}</p>
      </div>
      <div className="flex">
        <img src={LocationIcon} alt="user avater" />
      </div>
    </div>
  );
};

export default LocationDetails;
