import * as yup from "yup"
const projectTypeValidationSchema = yup.object().shape({
    programmeId: yup.string().required('poject is required'),
    projectCode: yup.string().required('project code is required'),
    projectDescription: yup.string().required('project name is required'),



});
// let data = { programmeId, projectDescription, projectCode };
export default projectTypeValidationSchema
