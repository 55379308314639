


import React, { Component, useState, useEffect, useRef } from "react";
import { AiOutlineFileExcel,AiOutlinePrinter, AiOutlineSearch } from 'react-icons/ai';

// import { DataTypes } from "../data/Types";
import Select from "react-select";
import axios from "axios";
// import { Link } from "react-router-dom";
import {nullcheck,formatNaira} from "../../Utils/Helper";
import { AiOutlineEye } from 'react-icons/ai';
import cookies from 'js-cookie'
const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';
// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

function formatDefaultDate(dateString) {
  console.log(dateString);
  var newDate = new Date(dateString);
  newDate.setDate(newDate.getDate() + 1);

  // return newDate.toISOString().split("T")[0];
}

export default class ReportC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      mdas:[],
      budgetPeriods:[],
      budgetPeriod:"",
      allBudgetInformationData:[],
      programmes:[],

      mda:"",
      programme:"",
      loading: false,
      deleteId: "",
      edit: false,
      editId: "",
      submenu: false,
      showModal: false,
      total: 0,
      message:"",
      loader:"",
      search:"",
    };
  }
  componentDidMount() {
    this.getBudgetInformations();
    this.getMdas();
    this.getBudgetPeriods();
    this.getCategories();

  }
  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };
  handlePrint = (table_id) => {
    var divToPrint = document.getElementById(table_id);
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  };
  handleSearch=()=>{
    let budgetPeriodId=this.state.budgetPeriod;
    let mdaId=this.state.mda;
    let code=this.state.programme;
    let budgetInfomations=this.state.allBudgetInformationData;

    let parameters=`mdaId=${mdaId}&code=${code}&budgetPeriodId=${budgetPeriodId}`;
    axios.get(`${BASE_API_URL}/BudgetInformation/GetFilteredReportWithExpenditureInformation?${parameters}`).then((response)=>{
      this.setState({
        allBudgetInformationData:response.data
      })
    }).catch((err)=>{
        console.log(err)
    })

    // if(budgetPeriodId=="" && mdaId=="" && code==""){
    //     this.getBudgetInformations();
    // }else{
    //     var filteredBudgetInformation=budgetInfomations.filter((budgetInfomation)=>{
    //         return budgetInfomation.itemCategory==code || budgetInfomation.MdaIdId==mdaId || budgetInfomation.budgetPeriodId==budgetPeriodId;
    //     });
    //     this.setState({
    //         allBudgetInformationData:filteredBudgetInformation,
    //     })
    // }


  }
  getMdas=()=>{
    this.setState({
      loader: true,
      message: "",
    });
    axios.get(`${BASE_API_URL}/mda/GetAllMda`).then((response)=>{
      this.setState({
        mdas:response.data,
        loader: false,
        message: "",
      })
    }).catch((err)=>{
      this.setState({
        loader: false,

        message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      })
    })
  }
  getBudgetPeriods=()=>{
    this.setState({
      loader: true,
      message: "",
    });
    axios.get(`${BASE_API_URL}/BudgetPeriod/GetBudgetPeriod`).then((response)=>{
      this.setState({
        budgetPeriods:response.data,
        loader: false,
        message: "",
      })
    }).catch((err)=>{
      this.setState({
        loader: false,

        message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      })
    })
  }
  getCategories=()=>{
    this.setState({
      loader: true,
      message: "",
    });
    axios.get(`${BASE_API_URL}/DepartmentBudget/GetAllProgrammes`).then((response)=>{
      this.setState({
        programmes:response.data,
        loader: false,
        message: "",
      })
    }).catch((err)=>{
      this.setState({
        loader: false,

        message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      })
    })
    //
  }
  getBudgetInformations=()=>{
    this.setState({
      loader: true,
      message: "",
    });
    axios.get(`${BASE_API_URL}/BudgetInformation/GetReportWithExpenditureInformation`).then((response)=>{
      this.setState({
        allBudgetInformationData:response.data,
        loader: false,
        message: "",
      })
    }).catch((err)=>{
      this.setState({
        loader: false,

        message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      })
    })
    //
  }

  render() {
    let programmes=this.state.programmes.map((programme)=>{
      return(
          <option value={programme.value}>{programme.label}</option>
      )
    })
    let mdas=this.state.mdas.map((mda)=>{
      return(
          <option value={mda.value}>{mda.label}</option>
      )
    })
    let budgetPeriods=this.state.budgetPeriods.map((budgetPeriod)=>{
      return(
          <option value={budgetPeriod.value}>{budgetPeriod.label}</option>
      )
    })


    const budgetInformations = this.state.allBudgetInformationData.filter(
        (budgetInformation) => {
          return budgetInformation.itemCategory.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
        }

    );
    var allBudgetInformations = budgetInformations.map((budgetInformation) => {

      return (
        <tr>
          <td style={{padding:"20px"}} className="table-border-right p-3 p-2 text-sm font-medium text-gray-900">
            {/* <img src="/view.png" /> */}
           <AiOutlineEye style={{fontSize:"30px", padding:"5px", color:"white", backgroundColor:"#1d811c" }}/>
          </td>
          <td className="table-border-right p-3 p-2 text-sm font-medium text-gray-900">

              {budgetInformation.itemCategory}

          </td>
          <td className="table-border-right p-2 p-3 text-right text-sm text-color">
            {formatNaira(budgetInformation.amount)}
          </td>
          <td className="table-border-right p-2 p-3 text-right text-sm text-color">
            {formatNaira(budgetInformation.expended)}
          </td>
          <td className="table-border-right p-2 p-3 text-right text-sm text-color">
            {formatNaira(budgetInformation.difference)}
          </td>

        </tr>
      );
    });
    return (
      <React.Fragment>
        <div class="flex-between w-100 p-10 bg-white shadow-sm ">
            <table className="w-100 p-10 min-w-full shadow overflow-hidden">
              {/* <tr style={{border:"2px solid red"}} className='w-100 flex-between'>
                <td className="text-base font-black py-3  text-sm font-medium font-avenir-black font-extrabold tracking-wider" style={{color:"#000000",textShadow:"1px 0 #000000"}}>Report  By Item Category</td>
                <div class="flex-between">
                    <td className="text-base font-black  py-3  text-sm font-medium font-avenir-black font-extrabold tracking-wider" style={{color:"#000000",textShadow:"1px 0 #000000"}}>MDA/Agency</td>
                <td className="text-base font-black  py-3  text-sm font-medium font-avenir-black font-extrabold tracking-wider" style={{color:"#000000",textShadow:"1px 0 #000000"}}>Budget Period</td>
                </div>
               

              </tr> */}
              {/* <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr> */}
              <tr>
                <td>
                  <div className="">
                      <p>Report By Item Category

</p>
                    <select  className='border' style={{lineHeight:"200px", fontSize:'15px', padding:'5px'}} onChange={this.handleChange} id="programme" name="programme"

                    
                            className="shadow-sm focus:outline-none block w-full sm:text-sm">
                      <option value="">All</option>
                      {programmes}
                    </select>
                  </div>
                </td>
                <td>
                  <div className="">
                      <p>MDA/Agency</p>
                    <select  className='border' style={{lineHeight:"200px", fontSize:'15px', padding:'5px'}} onChange={this.handleChange} id="mda" name="mda"
                            className="shadow-sm focus:outline-none block w-full sm:text-sm">
                      <option value="">All</option>
                      {mdas}
                    </select>
                  </div>
                </td>
                {/*<td>*/}
                {/*  <div className="">*/}
                {/*    <Select*/}
                {/*        name="economicSubType"*/}
                {/*        id="economicSubType"*/}

                {/*        class="shadow-sm focus:outline-none block w-full sm:text-sm"*/}

                {/*    />*/}
                {/*  </div>*/}
                {/*</td>*/}
                <td>
                  <div className="">
                      <p>Report Criteria</p>
                    <select  className='border' style={{lineHeight:"200px", fontSize:'15px', padding:'5px'}} onChange={this.handleChange} name="budgetPeriod" id="budgetPeriod" className="shadow-sm focus:outline-none block w-full sm:text-sm">
                      <option value="">All</option>
                      {budgetPeriods}
                    </select>
                  </div>
                </td>
              
                  <div className="" style={{display:"flex", flexDirection:"column",}}>
                    <div style={{ marginLeft:"-50px"}} className=" cursor-pointer" onClick={this.handleSearch}> <AiOutlineSearch style={{fontSize:'32px',  backgroundColor: '#1d811c', padding:'3px', color:'white'}}/></div>
                    <div style={{ marginLeft:"-50px"}} className=" cursor-pointer" onClick={()=>this.handlePrint("table_info")}><AiOutlinePrinter style={{fontSize:'32px',  backgroundColor: '#1d811c', padding:'3px', color:'white'}}/></div>

                  </div>


              

              </tr>
            </table>
        </div>
        <div
          className="flex flex-between  py-4 px-4 shadow-sm overflow-hidden cursor-pointer"
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <p className=" m-t-10 fz-17 bold font-medium text-color  font-extrabold">
              Search Result
          </p>
          <div>
           <span className="mr-6">Show:
            <select
                className="border border-solid p-5 w-20  m-r-5 mr-3 h-9"
                style={{
                  backgroundColor: "transparent",
                  borderColor: "#707070",
                }}
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="">All</option>

            </select>
            <span className="p-5">
              Entries
            </span>
           </span>
            <input
              placeholder="Search"
              className="border border-solid p-5 h-9 w-50 m-r-5"
              style={{
                backgroundColor: "transparent",
                borderColor: "#707070",
              }}
              name='search'
              onChange={this.handleChange}
            />
          </div>
        </div>

        <div class="flex w-100 flex-col">
          <table class="min-w-full  w-100 shadow overflow-hidden" id="table_info">
            <thead class="bg-gray-table-header  w-100">
              <tr>

                <th
                  scope="col"
                  class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                >

                </th>
                <th
                  scope="col"
                  class="table-border-right px-6 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  Item Category
                </th>
                <th
                  scope="col"
                  class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                  style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  Budget Amount
                </th>
                <th
                  scope="col"
                  class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                  style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  Expended
                </th>
                <th
                  scope="col"
                  class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                  style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                Difference
                </th>

              </tr>
            </thead>
            <tbody class="bg-white px-3 divide-y divide-gray-200">
            {allBudgetInformations}


            </tbody>
          </table>
        </div>
        <p className="text-sm px-3 font-normal text-color mt-4">

        </p>

        {this.state.showModal ? (

          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-7/12 my-6 mx-auto">
                {/*content*/}
                <div className="flex bg-card-header py-4 px-4 shadow-sm overflow-hidden cursor-pointer">
                  <p className="flex-1 mt-2 text-sm font-bold font-avenir-black text-color">
                    Budget Item Codification
                  </p>
                  <div
                    style={{
                      width: 38,
                      height: 38,
                      backgroundColor: "#FFB831",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="/share-icon.png"
                      style={{
                        width: 15,
                        position: "absolute",
                        top: 28,
                        right: 28,
                      }}
                    />
                  </div>
                </div>
                <div className="bg-white py-4 px-4 shadow-sm overflow-hidden">
                  <div class="mt-3 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                    <div class="sm:col-span-8">
                      <label
                        for="item"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Item
                      </label>
                      <div class="mt-1">
                        <input
                          readOnly={true}
                          type="text"
                          name="item"
                          id="item"
                          value={this.state.budgetInformation.categoryName}
                          class="shadow-sm focus:outline-none block w-full sm:text-sm"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-4">
                      <label
                        for="geoCode"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Geo Code
                      </label>
                      <div class="mt-1">
                        <Select
                          name="geoCode"
                          id="geoCode"
                          value={this.state.geoCode}
                          options={this.state.economicSegmentData}
                          class="shadow-sm focus:outline-none block w-full sm:text-sm"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mt-3 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                    <div class="sm:col-span-3">
                      <label
                        for="economicCode"
                        class="block text-sm font-bold text-gray-700"
                      >
                        Economic Code
                      </label>
                      <div class="mt-1">
                        <input
                          type="text"
                          name="economicCode"
                          id="economicCode"
                          value={this.state.economicCode}
                          class="shadow-sm focus:outline-none block w-full sm:text-sm"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label
                        for="functionalCode"
                        class="block text-sm font-bold text-gray-700"
                      >
                        Functional Code
                      </label>
                      <div class="mt-1">
                        <input
                          type="text"
                          name="functionalCode"
                          id="functionalCode"
                          value={this.state.functionalCode}
                          class="shadow-sm focus:outline-none block w-full sm:text-sm"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label
                        for="programmeCode"
                        class="block text-sm font-bold text-gray-700"
                      >
                        Programme Code
                      </label>
                      <div class="mt-1">
                        <input
                          type="text"
                          name="programmeCode"
                          id="programmeCode"
                          value={this.state.programmeCode}
                          class="shadow-sm focus:outline-none block w-full sm:text-sm"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label
                        for="fundsCode"
                        class="block text-sm font-bold text-gray-700"
                      >
                        Funds Code
                      </label>
                      <div class="mt-1">
                        <input
                          type="text"
                          name="fundsCode"
                          id="fundsCode"
                          value={this.state.fundsCode}
                          class="shadow-sm focus:outline-none block w-full sm:text-sm"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label
                        for="economicSegment"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Economic Segment
                      </label>
                      <div class="mt-1">
                        <Select
                          name="economicSegment"
                          id="economicSegment"
                          value={this.state.economicSegment}
                          options={this.state.economicSegmentData}
                          class="shadow-sm focus:outline-none block w-full sm:text-sm"
                          onChange={this.handleSelectedEconomicSegmentChange}
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label
                        for="functionalSegment"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Functional Segment
                      </label>
                      <div class="mt-1">
                        <Select
                          name="functionalSegment"
                          id="functionalSegment"
                          value={this.state.functionalSegment}
                          options={this.state.functionalSegmentData}
                          class="shadow-sm focus:outline-none block w-full sm:text-sm"
                          onChange={this.handleSelectedFunctionalSegmentChange}
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label
                        for="programmeSegment"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Programme Segment
                      </label>
                      <div class="mt-1">
                        <Select
                          name="programmeSegment"
                          id="programmeSegment"
                          value={this.state.programmeSegment}
                          options={this.state.programmeSegmentData}
                          class="shadow-sm focus:outline-none block w-full sm:text-sm"
                          onChange={this.handleSelectedProgrammeChange}
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label
                        for="fundsSegment"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Funds Segment
                      </label>
                      <div class="mt-1">
                        <Select
                          name="fundsSegment"
                          id="fundsSegment"
                          value={this.state.fundSegment}
                          options={this.state.fundSegmentData}
                          class="shadow-sm focus:outline-none block w-full sm:text-sm"
                          onChange={this.handleSelectedFundSegmentChange}
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label
                        for="economicType"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Economic Type
                      </label>
                      <div class="mt-1">
                        <Select
                          name="economicType"
                          id="economicType"
                          value={this.state.economicType}
                          options={this.state.economicTypeData}
                          class="shadow-sm focus:outline-none block w-full sm:text-sm"
                          onChange={this.handleSelectedEconomicTypeChange}
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label
                        for="functionalType"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Sub Function
                      </label>
                      <div class="mt-1">
                        <Select
                          name="functionalType"
                          id="functionalType"
                          value={this.state.subFunction}
                          options={this.state.subFunctionData}
                          class="shadow-sm focus:outline-none block w-full sm:text-sm"
                          onChange={this.handleSelectedSubFunctionChange}
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label
                        for="programmeType"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Programme Type
                      </label>
                      <div class="mt-1">
                        <Select
                          name="programmeType"
                          id="programmeType"
                          value={this.state.project}
                          options={this.state.projectData}
                          class="shadow-sm focus:outline-none block w-full sm:text-sm"
                          onChange={this.handleSelectedProjectChange}
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label
                        for="fundsType"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Funds Type
                      </label>
                      <div class="mt-1">
                        <Select
                          name="fundsType"
                          id="fundsType"
                          value={this.state.fundType}
                          options={this.state.fundTypeData}
                          class="shadow-sm focus:outline-none block w-full sm:text-sm"
                          onChange={this.handleSelectedFundTypeChange}
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label
                        for="economicSubType"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Economic Sub-Types
                      </label>
                      <div class="mt-1">
                        <Select
                          name="economicSubType"
                          id="economicSubType"
                          value={this.state.economicSubType}
                          options={this.state.economicSubTypeData}
                          class="shadow-sm focus:outline-none block w-full sm:text-sm"
                          onChange={this.handleSelectedEconomicSubChange}
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label
                        for="economicTypes"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Functional Item
                      </label>
                      <div class="mt-1">
                        <Select
                          name="economicTypes"
                          id="economicTypes"
                          value={this.state.functionalItem}
                          options={this.state.functionalItemData}
                          class="shadow-sm focus:outline-none block w-full sm:text-sm"
                          // onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label
                        for="objective"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Programme Objective
                      </label>
                      <div class="mt-1">
                        <Select
                          name="objective"
                          id="objective"
                          value={this.state.objective}
                          options={this.state.objectiveData}
                          class="shadow-sm focus:outline-none block w-full sm:text-sm"
                          onChange={this.handleSelectedObjectiveChange}
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label
                        for="fundsSubType"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Fund Sub-Type
                      </label>
                      <div class="mt-1">
                        <Select
                          name="fundsSubType"
                          id="fundsSubType"
                          value={this.state.fundItem}
                          options={this.state.fundItemData}
                          class="shadow-sm focus:outline-none block w-full sm:text-sm"
                          onChange={this.handleSelectedSubFundTypeChange}
                        />
                      </div>
                    </div>

                    <div class="sm:col-span-3">
                      <label
                        for="economicSubSubTypes"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Economic Sub-Sub-Types
                      </label>
                      <div class="mt-1">
                        <Select
                          name="economicSubSubTypes"
                          id="economicSubSubTypes"
                          value={this.state.economicSubSubType}
                          options={this.state.economicSubSubTypeData}
                          class="shadow-sm focus:outline-none block w-full sm:text-sm"
                          onChange={this.handleSelectedEconomicSubSubChange}
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-3" />
                    <div class="sm:col-span-3">
                      <label
                        for="programmeActivities"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Programme Activities
                      </label>
                      <div class="mt-1">
                        <Select
                          name="programmeActivities"
                          id="programmeActivities"
                          value={this.state.activity}
                          options={this.state.activityData}
                          class="shadow-sm focus:outline-none block w-full sm:text-sm"
                          onChange={this.handleSelectedActivityChange}
                        />
                      </div>
                    </div>

                    <div class="pt-5 sm:col-span-12 grid place-content-end">
                      <div class="flex">
                        <button
                          type="submit"
                          class="mr-5 w-32 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                        >
                          Save Record
                        </button>
                        <button
                          type="button"
                          class="mr-5 w-32 bg-clear-entry py-2 px-4 border border-gray-300 shadow-sm text-sm font-bold text-color bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Clear Entry
                        </button>
                        <button
                          type="button"
                          class="w-32 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-bold text-color bg-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() =>
                            this.setState({
                              showModal: !this.state.showModal,
                            })
                          }
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </React.Fragment>
    );
  }
}
