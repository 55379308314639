
import React, { useEffect, useState, useRef, useCallback } from "react";
import "../../assets/css/Dashboard.css";
import { Table,Button, Input, Modal,Select, Tooltip, Form, Card, Typography } from 'antd';
import { IoMdClose } from "react-icons/io";
import axios from "axios";
import Greeting from "../common/Greeting";
import LocationDetails from "../layouts/LocationDetails";
import "../../assets/css/calendar.css";
import DeleteIcon from "@material-ui/icons/Delete";
import { decodeToken } from "../../utility/auth";
import notification from '../../utility/notification';
import { TiPencil } from "react-icons/ti";


const BudgetReq = ({ history }) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

const [name, setName] = useState('')
const [amount, setAmount] = useState('')
const [status, setStatus] = useState('')
const [arrayHolder, setArrayHolder] = useState([])
const [id, setId] = useState(1)
const [paramid, setParamId] = useState(1)
const [paramHolder, setParamHoler] = useState([])
const [approverName, setApproverName] = useState([])
const [approver, setApprover] = useState([]);

const [mdaName, setMdaName] = useState([])
const [senderMdaId, setsenderMdaId] = useState()


const [title, setTile] = useState('')
const [programmeName, setProgrammeName] = useState()
const [programmeId, setProgrammeId] = useState()


  const [isLoading, setIsLoading] = useState(false);
  const [Mda, setMda] = useState([]);
  const [mdaId, setMdaId] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [programmes, setProgrammes] = useState([]);
  
  // const [setIsLoading, setsetIsLoading] = useState([]);


  const [ApproverId, setApproverId] = useState([]);
  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState('');
  // const [userId, setUserId] = useState('');
  let [initiateStatus, setInitiateStatus] = useState([
    {name:'Ongoing', id:1},
    {name:'New', id:2}

]);
  
const [initiateStatusId, setInitiateStatusId] = useState([]);


const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';
// const BASE_API_URL = 'http://localhost:5000/api/v1';

let ui
useEffect (()=>{
  
// ​/api​/v1​/Programme
  getMdas()
  getProgrammes()
  getEmployees()
  // toGetBudgetPlan()
// toCallComments()
  const r = localStorage.getItem('r');
  let fn = localStorage.getItem('fn');
  let ln = localStorage.getItem('ln');
   ui = localStorage.getItem('ui');
  let mi = localStorage.getItem('mi');

  // r && setRole(decodeToken('r').r);
  if (fn && ln) {
    fn = decodeToken('fn').fn;
    ln = decodeToken('ln').ln;
    ui = decodeToken('ui').ui;
    mi = decodeToken('mi').mi;
    setUsername(`${fn} ${ln}`);
  // getPending(ui)

  console.log(mi)
    console.log(ui)
    setUserId(ui)
    setsenderMdaId(mi)
  // toGetEnvelope(ui)

  }

},[])

const makeParams = ()=>{
  setParamId(paramid+1)
mdaId && ApproverId && setParamHoler([...paramHolder, {
  mdaId,ApproverId,paramid,mdaName,approver

},])
setMdaId('')
setApproverId('')
setMdaName('')
setApprover('')
form.setFieldsValue({approvers:''})
form.setFieldsValue({mda:''})


}

console.log(paramHolder)

const deleteParams = (id)=>{
  let p = paramHolder.filter((x)=>(
x.paramid !== id
))

setParamHoler(p)
}

  const makeArray = ()=>{
      setId(id+1)
programmeName && setArrayHolder([...arrayHolder, {

      idz: (id),
    dateCreated: "2022-02-06T10:04:52.986Z",
    enteredBy: ("" + userId),
    dateUpdated: "2022-02-06T10:04:52.987Z",
    updatedBy: ("" + userId),
    isDelete: false,
    dateDeleted: "2022-02-06T10:04:52.987Z",
    projectInitiative: programmeName,
    programmeId:programmeId,
    title:title,
    status: initiateStatusId == 1?"Ongoing":'New',
    mdaId: +senderMdaId,
    batchId: "3fa85f64-5717-4562-b3fc-2c963f66afa6"
  },])
  setProgrammeName('')
  form2.setFieldsValue({programmeName:''})
  form2.setFieldsValue({status:''})
  }

  console.log(arrayHolder)

  const removeItem = (id)=>{
      console.log(id)
const temp = arrayHolder.filter(item =>(
    item.idz !== id
))

setArrayHolder(temp)
  }

  const callBatchId = (id)=>{
    axios.get(`${BASE_API_URL}/BudgetPlanning/GetInitiativesByBatchId`, {params:{id}}).then((response)=>{
      console.log(response.data)   
     
  })
  }
    const clearLog = ()=>{
      setParamHoler([])
      setArrayHolder([])
    }
     

const toPost = ()=>{
  setIsLoading(true)


  let budgetPlanApprovalProcessVM = paramHolder.map((e)=>(
    {
      dateCreated: '2022-02-12T22:06:09.684Z',
      enteredBy: userId+'',
      dateUpdated: '2022-02-12T22:06:09.684Z',
      updatedBy: userId+'',
      isDelete: false,
      dateDeleted: '2022-02-12T22:06:09.684Z',
      text: 'string',
      batchId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      mdaId: e.mdaId,
      approverId: e.ApproverId
    }
  ))


  let addBudgetProjectInitiatives = arrayHolder.map((e)=>(
    {
      // idz: (e.idz),
      dateCreated: e.dateCreated,
      enteredBy: (e.enteredBy),
      dateUpdated:e.dateUpdated,
      updatedBy: (e.updatedBy),
      isDelete: e.isDelete,
      dateDeleted:e.dateDeleted,
      projectInitiative: e.projectInitiative,
      title:e.title,
      programmeId:e.programmeId,
      status: e.status,
      mdaId: e.mdaId,
      batchId: e.batchId
    }

    
  ))

  console.log(addBudgetProjectInitiatives)

  axios.post(`${BASE_API_URL}/BudgetPlanning/BudgetInitiative`,{addBudgetProjectInitiatives,budgetPlanApprovalProcessVM}, )
.then((res)=>{
  setIsLoading(false)

    console.log(res)
    clearLog()
    notification({
      title: 'Successful',
      message: 'Projects have been successfully sent',
      type: 'success'
});
            })
  .catch((err)=>{
  setIsLoading(false)
  console.log(err?.response)

        notification({
          title: 'Sending Failed',
          message: `${err?.response?.data}`,
          type: 'danger'
                    });
              })
}

const getEmployees = ()=>{
  axios.get(`${BASE_API_URL}/employee/getemployeefullname`).then((response)=>{
    console.log(response)
    console.log(ui)
    let m = response.data.filter((e)=>(
          e.value!==+ui
      ))

    setEmployees(m)

    
       
   
})
}
const getProgrammes = ()=>{
  axios.get(`${BASE_API_URL}/programme`).then((response)=>{

    setProgrammes( response.data)
    console.log(response)
       
   
})
}
  const getMdas = ()=> {
   
    axios
        .get(`${BASE_API_URL}/mda`)
        .then((response) => {
            
            console.log("--mdas--")
            console.log(response.data)
            // this.setState({
              setMda(response.data)
               
            // });
        })
        .catch((err) => {
          console.log(err)
            
        });
}


  return (
    <div className="w-100">
     
      <div className="w-100 m-b-50 flex space-between flex-v-baseline">
        <Greeting />
        <LocationDetails />
      </div>

      <p className='bold' style={{fontSize:'30px',}}>Budget Initiation</p>
      <div className="w-40 m-r-10 m-b-10 m-t-20" style={{display:'flex', flexDirection:"column"}}>
          
          <label>Title</label>
          <input className='p-5 border' onChange={(e)=>(setTile(e.target.value))}/>
          
      </div>
     
         {  title && <div>
           
            <div style={{ width: "100%" }}>
            <div className='m-b-10 m-t-20 bg-white w-100 p-10' >
                
                 <Form form = {form} layout="vertical" className="m-r-10 w-40">
                   <Form.Item
                   
                               className='w-100 m-r-10'
                               
                               label="Budget Team Representative"
                               name="approvers"
                               rules={[
                               {
                               required: true,
                               message: 'Please select who you are sending to!',
                               },
                               ]}
                               >
                   <Select
                                 
                                   onChange={(text,index)=>{
                    setApprover(index?.children)
                    setApproverId(index?.value)
                                   
                                   }}
                                   showSearch
                   
                                   filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                   }
                                   filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                   }
                        //  labelInValue
                         allowClear
                         style={{ width: "100%", border: "" }} 
                         placeholder="Please select"
                      
                       >
                           {Array.isArray(employees) && employees?.map((obj) => (
                   
                   
                   
                   <Option value={obj?.value} key={obj?.id}>{`${obj?.label}`}</Option>
                   ))}
                       </Select>
                             
                               </Form.Item>
                   
                               <Form.Item
                               className='w-100 m-r-10'
                               
                               label="MDA"
                               name="mda"
                               rules={[
                               {
                               required: true,
                               message: 'Please select who you are sending to!',
                               },
                               ]}
                               >
                   <Select
                                 
                                   onChange={(text,index)=>{
                    setMdaName(index?.children)
                    setMdaId(index?.value)
                                   
                                   }}
                                   showSearch
                   
                                   filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                   }
                                   filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                   }
                         labelInValue
                         allowClear
                         style={{ width: "100%", border: "" }} 
                         placeholder="Please select"
                       >
                           {Array.isArray(Mda) && Mda?.map((obj) => (
                   
                   
                   
                   <Option value={obj?.id} key={obj?.id}>{`${obj?.name}`}</Option>
                   ))}
                       </Select>
                             
                               </Form.Item>
                              
                            <div>
                    <Button style={{marginBottom:"21px", marginLeft:"5px"}} className='btn-gr' onClick={makeParams}>Add</Button>
                             </div>

                           
                 </Form>

                 <div className='w-100'>{paramHolder?.map((x)=>(
            <div className='flex-between  border w-100 m-t-5' style={{border:"1px solid blue", padding:'5px'}}>
              <div className=' flex' >
              <p className='m-r-5'>{x.approver}</p>
              <p>({x.mdaName})</p>

              </div>
              <div className ='pointer' onClick={()=>{deleteParams(x.paramid)}}>Delete</div>
            </div>

          ))}


          </div>
          </div>
         
         

         

            </div>
            <div className='w-100 flex m-t-20 m-b-20'>
         
<Form form={form2}
layout="vertical"
className='m-t-5 w-40 bg-white p-10 m-r-5'
style={{width:'40% border'}}
>
          

  <Form.Item

  className='w-100'
  
  label="Programme Item"
  name="programmeName"
  // rules={[
  // {
  // required: true,
  // message: 'Please select project status!',
  // },
  // ]}
  >
       <Select
  
      onChange={(text,index)=>{
        setProgrammeId(index?.value);
        setProgrammeName(index?.children);
      }}
   showSearch

                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
    //   mode="multiple"
      // labelInValue
      allowClear
  style={{ width: "100%", border: "" }}
  placeholder="Please select"
  >
  {Array.isArray(programmes) && programmes?.map((obj) => (
  
  
  
  <Option value={obj?.id} key={obj?.id}>{`${obj?.programmeName}`}</Option>
  ))}
  {/* {children} */}
  </Select>
  
  </Form.Item>
  
  <Form.Item

  className='w-100'
  
  label="Project Status"
  name="status"
  // rules={[
  // {
  // required: true,
  // message: 'Please select project status!',
  // },
  // ]}
  >
       <Select
  
      onChange={(text,index)=>{
        setInitiateStatusId(index?.value);
      }}
   showSearch

                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
    //   mode="multiple"
      // labelInValue
      allowClear
  style={{ width: "100%", border: "" }}
  placeholder="Please select"
  >
  {Array.isArray(initiateStatus) && initiateStatus?.map((obj) => (
  
  
  
  <Option value={obj?.id} key={obj?.id}>{`${obj?.name}`}</Option>
  ))}
  {/* {children} */}
  </Select>
  
  </Form.Item>
  
  
  
  
            <button onClick={()=>{makeArray()}} className='p-10 pointer'>Add to table</button>
</Form>
          
          
          <div className='w-60'>


          
         
          
          
          <table className="table-view m-t-10">
          <thead className="table-header">
          <tr className="table-row">
          <th className="th" style={{ padding: "20px 10px" }}>
          <p>Document Title</p>
          </th>
         
          <th className="th" style={{ padding: "20px 10px" }}>
          <p>Status</p>
          </th>
         
          <th className="th" style={{ padding: "20px 10px" }}>
          <p>Actions</p>
          </th>
          </tr>
          </thead>
          <tbody className="table-body">
          {arrayHolder.length !== 0 &&
          arrayHolder.map((item) => (
          <ListItem
          key={item.id}
          detail={item}
          removeItem={removeItem}
         
          />
          ))}
          </tbody>
          </table>
         { arrayHolder.length !== 0 && <Button block loading={isLoading} onClick={toPost} className=" m-t-5 pointer" style={{width:'20%'}}>Send</Button>}
          
          </div>
          
          </div>

          </div>}
          {/* <ApproversView/> */}

           </div>
  );
};

const ListItem = ({ detail, handleDetails, current, removeItem }) => {
  const [itemDetails, setItemDetails] = useState(detail || {});
  const [showDetails, setShowDetails] = useState(false);


  return (
    <>
      <tr
        className={`${showDetails && "active"}  ${
          itemDetails.id
        } table-row pointer`}
        onClick={() => setShowDetails(!showDetails)}
        role="presentation"
      >
        <td className="td p-r" style={{ padding: "20px 10px" }}>
          {itemDetails.projectInitiative}
       
        </td>
       
        <td className="td" style={{ padding: "20px 10px" }}>
          <div className={`status ${detail.status}-status`}>
            {(detail.status)}
          </div>
        </td>
      
        <td
          style={{
            display: "flex",
            right: "0",
            justifyContent: "flex-end",
            padding: "20px 10px",
          }}
        >
          <span
            style={{
              border: "1px solid white",
              height: "30px",
              width: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#ff8566",
            }}
            onClick={()=>{removeItem(detail.idz)}}
          >
            <DeleteIcon style={{ color: "green" }} />
          </span>
         
        </td>
      </tr>
     
    </>
  );
};

export default BudgetReq;
