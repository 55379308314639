import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Nav } from "../Components/Nav";
// import { ProgramTypeSetup } from "../Components/ProgramTypeSetup";
import  EconomicCodeSegment  from "../layouts/EconomicCodeSegment";
import  EconomicType  from "../layouts/EconomicType";
import  EconomicSubType  from "../layouts/EconomicSubType";
import  EconomicSubSubType  from "../layouts/EconomicSubSubType";
import  EconomicItem  from "../layouts/EconomicItem";
// import { UploadFile } from "../layouts/UploadFile";
import axios from "axios";
// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';

export default class EconomicCode extends Component {
    constructor(props) {

        super(props);
        this.state = {
            fullName: "",
            role: "",
            time: "",
            employeeId:0,
            employeeData:[]

        };
    }
    componentDidMount() {
        this.getAuthorisedUser();
        this.getEmployees();
    }

    getAuthorisedUser = () => {
        const { cookies } = this.props;
        // if (typeof cookies.get('userId') !== "undefined") {
        //     let authUser = cookies.get('userId');
        //     let time = authUser.lastLoginTime.split("T")[1]
        //     this.setState({
        //         fullName: authUser.firstName + ' ' + authUser.lastName,
        //         role: authUser.role,
        //         time: time,
        //         employeeId:authUser.id
        //     })
        // }

    }

    getEmployees = () => {
        this.setState({
            loader: true,
            message: "",
        });
        axios
            .get(`${BASE_API_URL}/employee`)
            .then((response) => {
                this.setState({
                    loader: false,
                    message: "",
                    employeeData: response.data,
                });
            })
            .catch((err) => {
                this.setState({
                    loader: false,
                    message: err.message,
                });
            });
    };

  render() {
    return (
      <React.Fragment>
       
            <main
              class="flex-1 relative overflow-y-auto focus:outline-none"
              tabindex="0"
              style={{ outline: "none" }}
            >
              <div class="py-10 max-w-full mx-auto px-2 sm:px-4 md:px-8">
                <div class="flex">
                  <h1 class="flex-1 text-base font-medium text-color">
                    Budget Settings
                  </h1>
                  <div className="flex">
                    <p className="text-sm font-normal mr-3">

                      <br />
                      
                    </p>
                    <div style={{ height: 40, width: 40 }}>
                      <img src="/icn.png" />
                    </div>
                  </div>
                </div>
                <div className="py-6">
                          <EconomicCodeSegment employees={this.state.employeeData} employeeId={ this.state.employeeId} {...this.props} />
                           <div class="m-t-10">
                            <EconomicType employees={this.state.employeeData} employeeId={this.state.employeeId} {...this.props} />
                          </div>
                          <div class="m-t-10">
                            <EconomicSubType employees={this.state.employeeData} employeeId={this.state.employeeId} {...this.props} />
                          </div>
                          <div class="m-t-10">
                            <EconomicSubSubType employees={this.state.employeeData} employeeId={this.state.employeeId} {...this.props} />
                          </div>
                          <div class="m-t-10">
                            <EconomicItem employees={this.state.employeeData} employeeId={this.state.employeeId} {...this.props} />
                          </div>{/*
                          <div class="m-t-10">
                            <UploadFile employeeId={this.state.employeeId} {...this.props} />
                          </div> */}

                </div>
              </div>
            </main>
      
      </React.Fragment>
    );
  }
}
