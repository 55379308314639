import React, { useState,useEffect } from 'react';
import { Table,Button, Input, InputNumber, Popconfirm, Form, Typography } from 'antd';
import axios from "axios";
import notification from "../../utility/notification";
import ViewGovTable from './ViewGovTable';

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';
// const BASE_API_URL = 'http://localhost:5000/api/v1';

const originData = [];

// for (let i = 0; i < 100; i++) {
//   originData.push({
//     key: i.toString(),
//     name: `Edrward ${i}`,
//     age: 32,
//     address: `London Park no. ${i}`,
//   });
// }



const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const GovernorsView = (props) => {

    let params = [
        {  name:  'ADMINISTRATION SECTOR  '},
     {  name:' ECONOMIC SECTOR ',},
     {  name:'LAW AND JUSTICE SECTOR  ', },
   
        ]


        // let admin = [
        //     {  name:  'Ministry of Communication and Orientation',amount: 	' 750,000,000.00' ,percentApproved:'	4.71%'},
        //  {  name:' Office of the Head of Service',	amount: ' 4,000,000.00', 	percentApproved:'0.03%'	 },
        //  {  name:' Directorate of Establishment, Training and Manpower Development	' , amount: '1,000,000.00 ',percentApproved:'	0.01%'	 },
        //  {  name:' Office of the Auditor-General (State)',amount: 	' 30,000,000.00', 	percentApproved:'0.19%'	 },
        //    {name: 'Office of the Auditor-General (Local Govt)',	amount: ' 5,000,000.00', 	percentApproved:'0.03%'	  },
        //  {  name:' Civil Service Commission',	 amount: '14,000,000.00', percentApproved:'	0.09%'	},
        //  {  name:' Edo State Independent Electoral Commission',	amount: ' 180,000,000.00' ,	percentApproved:'1.13%'	 },
        //  {  name:' Public Private Partnership - PPP',	 amount: '810,000,000.00' ,	percentApproved:'5.09%'	  },
        //  { name: ' Sub-Total - Administration Sector',	 amount: '15,923,404,798.51', 	percentApproved:'100.00%'	},
        //     ]

        let admin = props.sectorId

    
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [onAdmin, setOnAdmin] = useState(false);
  const [adminz, setAdminz] = useState([]);
const [govViewHolder, setGovViewHolder] = useState([])
  const [mdaId, setMdaId] = useState([]);
  const [userId, setuserId] = useState()

  
//   const [count, setCount] = useState(1);

// useEffect (()=>{
//   //   getMdas()
//   //   getEmployees()
  
  
//     const r = localStorage.getItem('r');
//     let fn = localStorage.getItem('fn');
//     let ln = localStorage.getItem('ln');
//     let ui = localStorage.getItem('ui');
//     let mi = localStorage.getItem('mi');
  
//     // r && setRole(decodeToken('r').r);
//     if (fn && ln) {
//       fn = decodeToken('fn').fn;
//       ln = decodeToken('ln').ln;
//       ui = decodeToken('ui').ui;
//       mi = decodeToken('mi').mi;
//       setUsername(`${fn} ${ln}`);
//     getPending(ui)
//     // getBudgetPlanningSummary(ui)
//     // getBudgetPendingComents(ui)
//     // getPendingBudgetSectors(+ui,)
    
//       console.log(ui)
//       setUserId(ui)
//       setMdaId(mi)
//     }
  
//   },[])

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
    // let count = 1

    const showAdmin= ()=>{
setOnAdmin(!onAdmin)
    }

    const toPost = ()=>{

        let postData =
        
        data.map((e)=>(
            // {
            //     id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            //     dateCreated: '2022-02-11T09:59:24.439Z',
            //     enteredBy: 'string',
            //     dateUpdated: '2022-02-11T09:59:24.439Z',
            //     updatedBy: 'string',
            //     isDelete: false,
            //     dateDeleted: '2022-02-11T09:59:24.439Z',
            //     budgetPlanId:' 3fa85f64-5717-4562-b3fc-2c963f66afa6',
            //     project: 'string',
            //     programmeCode: 'string',
            //     status: 'string',
            //     projectLocation: 'string',
            //     proposedBudget: 5,
            //     performance: 'string',
            //     actuals: 'string',
            //     mdaId: 11
            //   }
            {
                // key: idx+1,
            
                        sectorId: +e.sectorId,
                        sectorName: e.sectorName,
                       
                      }
        ))

        notification({
                                    title: 'Successful',
                                    message: 'Request has been successfully sent..',
                                    type: 'success'
                              });
        // let poster = arrayHolder.map((a)=>())
        // axios.post(`${BASE_API_URL}/BudgetPlanning`, postData 
        
        //                   ).then(()=>{
        //                     notification({
        //                         title: 'Successful',
        //                         message: 'Costs have been Allocated successfully',
        //                         type: 'success'
        //                   });

        //                   })
      }

useEffect(()=>{
  setuserId(props.userId)
    let dat = props.gov?.map((e)=>(
        {
    key: +e.sectorId,

    sectorId: +e.sectorId,
    sectorName: e.sectorName,
          }
      ))
    console.log('dat:',dat)
    setData(dat)
},[])
console.log(data)
  

const getPendingBudgetApprovalSummary = (userId, sectorId)=>{
  console.log(sectorId)
  axios.get(`${BASE_API_URL}/BudgetPlanning/GetPendingBudgetSummary`, {params:{userId, sectorId}}).then(
    
    (response)=>{
console.log(response.data)
// GetPendingBudgetApprovalSummary(response.data)
// console.log(response.data[0].budgetPlanning)
setGovViewHolder(response.data)
// setCommentsBudgetA(response.data[0]?.budgetPlanning)
   
 }).catch((err)=>{
   console.log(err?.response)
  notification({
    title: 'ERROR MESSAGE',
    message: `
    ${ err?.response?.data}`,
    type: 'danger',
    // container:'top-center'
    // animationIn: ['animate__animated', 'animate__slideIn'],
  });
})
}
console.log(govViewHolder)
  const columns = [
  
    {
      title: 'CAPITAL EXPENDITURE',
      dataIndex: 'sectorName',
      width: '90%',
      editable: true,
    },
   
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = true;
        return editable ? (
          <span>
           <Button onClick = {
               
               ()=>{
                getPendingBudgetApprovalSummary(userId ,record.sectorId, )
                    showAdmin()}
               
               }>View</Button>
          </span>
        ) : (
         ''
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'proposedBudget' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form style={{width:"100%"}} form={form} component={false}>
      <Table
      style={{width:"100%"}}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
      {/* <Button onClick = {toPost}>Send & Approve</Button> */}
     {onAdmin && <div style={{display:"flex"}} className='overlay '>
         <ViewGovTable showAdmin={showAdmin}  govView = {govViewHolder}/>
         <p onClick={()=>{setOnAdmin(!onAdmin)}} className ='p-5 bold pointer colorW' style={{backgroundColor:"red", fontSize:"40px", maxHeight:"70px"}}>X</p>
     
     
     </div>}
    </Form>
  );
};

export default GovernorsView