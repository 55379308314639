import * as yup from "yup"
const budgetCurrencyConservationValidationSchema=yup.object().shape({
    title:yup.string().required('title is required'),
    nairaRate:yup.string().required('naira rate is required'),
    budgetPeriodID:yup.string().required('budget period is required'),


});
//  let data = { title, nairaRate, budgetPeriodID };
export  default budgetCurrencyConservationValidationSchema
