import React, { Component, useState, useEffect, useRef } from "react";
import axios from "axios";
import { store } from 'react-notifications-component';
import { nullcheck, notify } from '../../Utils/Helper'
import { Preloader } from "../../Utils/Preloader";
import ValidationMessageComponent from "./ValidationMessage";
import programTypeValidationSchema from "../../Validation/ProgramTypeValidation";
import { Table,Modal, Input, InputNumber,message, Popconfirm, Form, Typography,Menu,Dropdown, Select,Button,Checkbox } from 'antd';
import { BsPencilSquare } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';
import notification from "../../utility/notification";
 

// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';


export default  class ProgramTypeSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      programTypeData: [],
      programType: "",
      code: "",
      loading: false,
      deleteId: "",
      edit: false,
      editId: "",
      message: "",
      showNotification: false,
      notificationSuccess: false,
      notificationMessage: "Successfully saved!",
        loader: false,
        deleteModal: false,
        search:""
    };
  }

  componentDidMount() {
    this.getProgrammes();
  }
  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  getEmployee=(id)=>{
    const employeesData=this.props.employees;
    const employeeInfo=employeesData.filter((employee)=>{

      return employee.id==id;
    })

    return employeeInfo.length==0?"":employeeInfo[0].firstName+ ' '+employeeInfo[0].lastName

  }

  handleSubmit = () => {
    let programmeName = this.state.programType;
      let programmeCode = this.state.code;
    
      let enteredBy = this.props.employeeId.toString();
      let updatedBy = this.props.employeeId.toString();
     
      let data = { programmeName, programmeCode, enteredBy,updatedBy };
    programTypeValidationSchema
      .validate(data)
      .then((message) => {
        this.setState({
          loader: true,
          message: "",
        });
        axios
          .post(`${BASE_API_URL}/programme`, { ...data })
          .then((response) => {
          
            this.setState({
              code: "",
              programType: "",
              loader: false,
              message: "",
            });
              // notify(store, 'success', 'programme added successfully');
              notification({
                title: 'Successful',
                message: 'programme added successfully',
                type: 'success'
          });
              this.getProgrammes();
          })
          .catch((err) => {
            this.setState({
              loader: false,
                message: nullcheck(err.response) == "" ? "server error" : err.response.data,
            });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loader: false,
          message: err.errors,
        });
      });
  };

    handleDelete = () => {
      let id=this.state.deleteId
    this.setState({
      loader: true,
        message: "",
        deleteModal: false,
        deleteId: '',
    });
    axios
      .delete(`${BASE_API_URL}/programme?id=${id}`)
      .then((response) => {
        this.setState({
          edit: false,
          editId: "",
          code: "",
          programType: "",
          loader: false,
            message: "",
            deleteModal: false,
            deleteId: ''

        });
          // notify(store, 'success', 'programme deleted successfully');
          notification({
            title: 'Successful',
            message: 'programme updated successfully',
            type: 'success'
      });
        this.getProgrammes();
      })
      .catch((err) => {
        this.setState({
          loader: false,
            message: nullcheck(err.response) == "" ? "server error" : err.response.data,
            deleteModal: false,
            deleteId: ''
        });
      });
  };

  handleEdit = (data) => {
    this.setState({
      programType: data.programmeName,
      code: data.programmeCode,
      edit: true,
      editId: data.id,
    });
  };

  handleEditSubmit = () => {
    let programmeName = this.state.programType;
    let programmeCode = this.state.code;
      let id = this.state.editId;
      let updatedBy = this.props.employeeId.toString();
      let data = { programmeName, programmeCode, id, updatedBy };

    programTypeValidationSchema
      .validate(data)
      .then((message) => {
        this.setState({
          loader: true,
          message: "",
        });
        axios
          .put(`${BASE_API_URL}/programme`, { ...data })
          .then((response) => {
          
            this.setState({
              edit: false,
              editId: "",
              code: "",
              programType: "",
              loader: false,
              message: "",
            });
              // notify(store, 'success', 'programme updated successfully');
              notification({
                title: 'Successful',
                message: 'programme updated successfully',
                type: 'success'
          });
              this.getProgrammes();
          })
          .catch((err) => {
            console.log(err.response.data)
            this.setState({
              loader: false,
                message: nullcheck(err.response) == "" ? "server error" : err.response.data,
            });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loader: false,
          message: err.errors,
        });
      });
    };

    handleCancel = () => {
        this.setState({
            edit: false,
            editId: "",
            code: "",
            programType: "",
            loader: false,
            message: "",
            deleteModal: false,
            deleteId: ''

        });
    }
  getProgrammes = () => {
    this.setState({
      loader: true,
      message: "",
    });
    axios
      .get(`${BASE_API_URL}/programme`)
      .then((response) => {
        console.log(response.data);
        this.setState({
          programTypeData: response.data,
          loader: false,
          message: "",
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
            message: nullcheck(err.response) == "" ? "server error" : err.response.data,
        });
      });
  };

    render() {
        let programTypes = this.state.programTypeData.filter((programType) => {

            return (programType.programmeName.toLowerCase()).indexOf(this.state.search.toLowerCase()) !== -1;
        })
        let allProgramTypeData = programTypes.map((programType) => {
      return (
        <tr
          style={{
            borderBottom: "1px solid #C7C7C7",
          }}
        >
          <td className="px-6 py-4 whitespace-nowrap">
            <div
              className="bg-pen"
              onClick={() => this.handleEdit(programType)}
            >
              {/* <svg
                width="18"
                height="18"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#fff"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg> */}
              <BsPencilSquare style={{backgroundColor:"#43425D", color:"white", fontSize:"26px", padding:"5px"}}/>

            </div>
          </td>
          <td className="px-6 py-4  text-sm font-avenir-medium table-color">
            {programType.programmeName}
          </td>
          <td className="px-6 py-4  text-sm font-avenir-medium table-color">
            {programType.programmeCode}
          </td>
          <td className="px-6 py-4  text-sm font-avenir-medium table-color">
            {this.getEmployee(programType.updatedBy)}
          </td>
          <td className="px-6 py-4  text-sm font-avenir-medium table-color">
            Active
          </td>
          <td className="px-6 py-4  text-sm font-avenir-medium ">
                  <a

                      className="cursor-pointer"
                      onClick={() =>
                          this.setState({
                              deleteModal: !this.state.deleteModal,
                              deleteId: programType.id

                          })
                      }
                  >

<AiOutlineDelete style={{backgroundColor:"#43425D", color:"white", fontSize:"26px", padding:"5px"}}/>

                  </a>
          </td>
        </tr>
      );
    });
    return (
      <React.Fragment>
            {this.state.loader === true ? <Preloader /> : ""}
            {this.state.deleteModal && (
                <>
                    <div className=" overlay justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="p-5 relative w-3/12 my-6 mx-auto bg-white p-4">
                            {/*content*/}
                            <div className="p-5 py-4 px-4 shadow-sm overflow-hidden cursor-pointer">
                                <p className="flex-1 mt-2 text-sm font-medium font-avenir-black text-color text-center">
                                    Are you sure you want to
                                    delete?
                                                    </p>
                            </div>
                            <div class="p-5 flex justify-center">
                                <button onClick={() => this.handleDelete()}
                                    type="submit"
                                    class="mr-5 w-40 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"

                                >

                                    Yes
                                                    </button>
                                <button
                                    type="button"
                                    class="w-40 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-color bg-gray-200 hover:bg-gray-50 focus:outline-none"
                                    onClick={() =>
                                        this.setState({
                                            deleteModal: !this.state.deleteModal,
                                        })
                                    }
                                >
                                    No
                                                    </button>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            )}
        {this.state.open ? (
          <div className="mb-3">
            <div
              className="bg-card-header py-4 px-4 shadow-sm overflow-hidden bb-green cursor-pointer"
              onClick={() => {
                this.getProgrammes();
                this.setState({ open: !this.state.open })}}
                    >

                        <div class="flex">

                            <div class="flex-1">
                                <p className=" text-sm font-medium font-avenir-black text-color">
                                   Program Type Setup
              </p>
                            </div>

                            <input
                                placeholder="Search"
                                className="border border-solid px-2 h-8 w-50 mt-2"
                                style={{
                                    backgroundColor: "transparent",
                                    borderColor: "#707070",
                                    width:"25%"
                                }}
                                name='search'
                                onClick={(e) => e.stopPropagation()}
                                onChange={this.handleChange}
                            />
                        </div>
     
            </div>
            <div className="w-100  p-10 flex bg-white py-4 pl-4 shadow-sm cursor-pointer">
                            <div style={{border:""}} className=" w-4  w-80 m-r-10">
                                <div class="mt-3grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div class="sm:col-span-12">
                    {this.state.message !== "" ? (
                      <ValidationMessageComponent
                        message={this.state.message}
                      ></ValidationMessageComponent>
                    ) : (
                      ""
                    )}
                    <label
                      for="programType"
                      class="block  text-sm font-medium label-color"
                    >
                      Program Type
                    </label>
                    <div class="mt-1">
                      <Input
                        type="text"
                        name="programType"
                        id="programType"
                        value={this.state.programType}
                        class="shadow-sm focus:outline-none block w-full sm:text-sm"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div class="sm:col-span-12">
                    <label
                      for="code"
                      class="block  text-sm font-medium label-color"
                    >
                      Code
                    </label>
                    <div class="mt-1">
                      <Input
                        type="text"
                        name="code"
                        id="code"
                        value={this.state.code}
                        class="shadow-sm focus:outline-none block w-full sm:text-sm"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div class="pt-5 sm:col-span-12">
                    <div class="flex">
                      <button
                        type="submit"
                        class="mr-5 w-40 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                        onClick={
                          this.state.edit
                            ? this.handleEditSubmit
                            : this.handleSubmit
                        }
                      >
                        {this.state.loading && (
                          <svg
                            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              class="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              stroke-width="4"
                            ></circle>
                            <path
                              class="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        )}
                        Save Record
                      </button>
                                        <button onClick={this.handleCancel}
                        type="button"
                        class="w-40 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-color bg-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div  className="w-100 md:flex-grow" >
                                <div class="w-100 flex " >
                                    <div class="w-100 -my-2 overflow-y-scroll" >
                        <table class="min-w-full w-100">
                          <thead class="bg-table-header">
                            <tr>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                              >
                                <svg
                                  version="1.0"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 1278.000000 1280.000000"
                                  preserveAspectRatio="xMidYMid meet"
                                >
                                  <g
                                    transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                                    fill="#fff"
                                    stroke="none"
                                  >
                                    <path
                                      d="M5565 12793 c-437 -20 -955 -111 -1410 -249 -859 -261 -1717 -753
                                        -2352 -1348 -51 -47 -95 -86 -97 -86 -3 0 -122 149 -266 330 -144 182 -282
                                        347 -307 368 -24 20 -68 60 -98 88 -74 70 -128 94 -212 94 -114 0 -208 -61
                                        -264 -169 -33 -64 -36 -151 -43 -1161 -4 -481 -11 -1322 -17 -1869 -5 -546 -8
                                        -995 -6 -997 4 -5 -24 -16 1912 766 270 109 738 298 1040 420 303 122 569 233
                                        591 246 53 30 90 74 122 143 33 72 33 152 -2 262 -29 95 -66 146 -130 184 -36
                                        21 -584 207 -858 292 -49 15 -88 30 -88 33 0 14 236 191 377 283 309 200 595
                                        339 927 449 394 131 768 200 1181 217 512 22 983 -45 1478 -210 372 -123 711
                                        -285 1020 -485 54 -35 100 -64 102 -64 3 0 16 18 30 40 l26 41 79 -56 79 -56
                                        34 53 c231 364 837 1361 831 1367 -13 12 -246 160 -334 213 -431 259 -963 489
                                        -1460 632 -642 185 -1242 258 -1885 229z"
                                    />
                                    <path
                                      d="M9310 10957 c0 -8 -16 -125 -35 -259 -36 -246 -187 -1284 -295 -2033
                                        -132 -913 -161 -1115 -181 -1249 -30 -199 -29 -257 6 -329 34 -68 78 -110 152
                                        -144 105 -48 201 -40 295 25 24 17 198 172 387 346 190 173 346 314 347 313 2
                                        -1 10 -47 18 -102 98 -641 32 -1341 -189 -1985 -292 -851 -810 -1542 -1558
                                        -2078 -146 -105 -276 -186 -464 -290 l-152 -84 19 -39 c11 -21 20 -42 20 -46
                                        0 -5 -34 -25 -76 -46 -41 -21 -74 -41 -72 -45 6 -15 731 -1357 750 -1389 l19
                                        -33 62 32 c391 197 862 515 1227 826 792 678 1366 1481 1720 2407 269 705 400
                                        1415 400 2175 0 429 -36 796 -117 1190 -20 96 -38 187 -40 201 l-5 26 264 42
                                        c521 83 548 89 623 125 39 18 90 39 115 46 109 30 184 99 215 196 34 107 -4
                                        239 -88 308 -36 29 -3302 1884 -3349 1902 -12 4 -18 1 -18 -9z"
                                    />
                                    <path
                                      d="M4 7453 c-4 -49 -4 -214 0 -368 22 -980 241 -1847 696 -2755 431
                                        -860 1128 -1639 1965 -2197 435 -289 894 -512 1450 -704 91 -32 139 -53 137
                                        -61 -2 -7 -73 -195 -159 -418 -173 -450 -183 -480 -183 -564 0 -76 26 -131 88
                                        -186 26 -23 72 -66 103 -96 96 -92 206 -124 311 -89 58 19 3339 1905 3335
                                        1918 -6 19 -2978 2350 -3024 2372 -102 49 -253 26 -329 -50 -14 -14 -47 -36
                                        -73 -49 -54 -28 -116 -92 -137 -142 -28 -68 -18 -148 77 -580 50 -229 88 -418
                                        85 -421 -7 -7 -353 163 -481 237 -626 361 -1171 883 -1561 1497 -363 571 -573
                                        1185 -660 1928 -14 113 -18 231 -18 476 l-1 326 -149 7 c-82 3 -445 6 -807 6
                                        l-657 0 -8 -87z"
                                    />
                                  </g>
                                </svg>
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                              >
                                Programme
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                              >
                                Code
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                              >
                                Updated By
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                              >
                                Date
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white">
                            {allProgramTypeData}
                          </tbody>
                        </table>
                        {true && (
                          <p className=" text-sm font-normal text-color py-4"></p>
                        )}
                      </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="bg-white py-4 px-4 shadow-sm overflow-hidden cursor-pointer mb-3"
            // onClick={() => this.setState({ open: !this.state.open })}
            onClick={() => {
              this.getProgrammes();
              this.setState({ open: !this.state.open })}}
          >
            <p className=" text-sm font-medium text-color">
              Program Type Setup
            </p>
          </div>
        )}
      </React.Fragment>
    );
  }
}
