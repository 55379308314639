import React, { Component } from "react";

// import { Nav } from "../Components/Nav";
import { decodeToken } from "../../utility/auth";
import GeoCodeSetup  from "../layouts/GeoCodeSetup";


export default class GeoCode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fullName: "",
            role: "",
            time: "",
            employeeId:0


        };
    }
    componentDidMount() {
        this.getAuthorisedUser();
    }

    getAuthorisedUser = () => {
      let fn = localStorage.getItem('fn');
      let ln = localStorage.getItem('ln');
      let ui = localStorage.getItem('ui');
      let mi = localStorage.getItem('mi');
    
      // r && setRole(decodeToken('r').r);
      if (fn && ln) {
        fn = decodeToken('fn').fn;
        ln = decodeToken('ln').ln;
        ui = decodeToken('ui').ui;
        mi = decodeToken('mi').mi;
      }
            // const { cookies } = this.props;
            // if (typeof cookies.get('userId') !== "undefined") {
            //     let authUser = cookies.get('userId');
            //     let time = authUser.lastLoginTime.split("T")[1]
                this.setState({
                    fullName: fn + ' ' + ln,
                    // role: authUser.role,
                    // time: time,
                    employeeId: ui,
                })
            // }
            // const { cookies } = this.props;
            // if (typeof cookies.get('userId') !== "undefined") {
            //     let authUser = cookies.get('userId');
            //     let time = authUser.lastLoginTime.split("T")[1]
            //     this.setState({
            //         fullName: authUser.firstName + ' ' + authUser.lastName,
            //         role: authUser.role,
            //         time: time,
            //         employeeId:authUser.id
            //     })
            // }
    

    }

  render() {
    return (
      <React.Fragment>
        {/* <Nav
          {...this.props}
          children={ */}
            <main
              class="flex-1 w-100 relative overflow-y-auto focus:outline-none"
              tabindex="0"
              style={{ outline: "none" }}
            >
              <div class="py-10 max-w-full mx-auto px-2 sm:px-4 md:px-8">
                <div class="flex">
                  <h1 class="flex-1 text-base font-medium text-color">
                    Budget Settings
                  </h1>
                  <div className="flex">
                    <p className="text-sm font-normal mr-3">

                      <br />
                                  <span className="text-xs ml-5">
                                      {/* Time of login: {this.state.time} */}
                      </span>
                    </p>
                    <div style={{ height: 40, width: 40 }}>
                      <img src="/icn.png" alt="icon image" />
                    </div>
                  </div>
                </div>

                <div className="py-6">
                          <GeoCodeSetup employeeId={this.state.employeeId} {...this.props} />

                </div>
              </div>
            </main>
          {/* } */}
        
      </React.Fragment>
    );
  }
}
