/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable linebreak-style */
/* eslint-disable array-bracket-spacing */
/* eslint-disable no-empty */
/* eslint-disable keyword-spacing */
/* eslint-disable brace-style */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";
import "../../assets/css/Dashboard.css";
import AutoComplete from "../inputs/AutoComplete";
import CommentTable from "../layouts/CommentTable";

import { IoMdClose } from "react-icons/io";
// import { AiOutlineRedEnvelope } from 'react-icons/ai';
// import { BiCalendar } from 'react-icons/bi';
// import { HiOutlineUserGroup } from 'react-icons/hi';
import Calendar from "react-calendar";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import EventNoteIcon from "@material-ui/icons/EventNote";
import SearchInput from "../inputs/SearchInput";
import RequestDetails from "../layouts/RequestDetails";
import { get } from "../../api-services/fetch";
import { newget } from "../../api-services/newfetch";
import IsLoading from "../common/IsLoading";
import {
  formatSimpleDate,
  formatDate,
  formatDateforPicker,
  
} from "../../utility/dateTime";
import axios from "axios";

// import ExecViewuestForm from "../layouts/ExecViewuestForm";
import Greeting from "../common/Greeting";
import LocationDetails from "../layouts/LocationDetails";
// import { formatStatus } from "../../utility/general";
import notification from "../../utility/notification";
import "../../assets/css/calendar.css";
// import useClickOutsideBox from "../hooks/useClickOutsideBox";
import SelectInputColored from "../inputs/SelectInputColored";
// import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { decodeToken } from "../../utility/auth";
// import RequestQueryForm from "../layouts/QueryForm";
// import QueryThread from "../layouts/QueryThread";
// import Massive from "../layouts/massive";
import GovernorsVIew from './GovernorsView';
import { Table,Button, Input, Modal,Select, Tooltip, Form, Card, Typography } from 'antd';


const ExecView = ({ history }) => {
const [name, setName] = useState('')
const [amount, setAmount] = useState('')
const [status, setStatus] = useState('')
const [arrayHolder, setArrayHolder] = useState([])
const [envelopHolder, setenvelopHolder] = useState([])
const [id, setId] = useState(1)
const [title, setTile] = useState('')
const [totalCost, setTotalCost] = useState('')
const [dateCreated, setDateCreated] = useState('')
const [onShow, setOnShow] = useState(false)



    
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [requestCounts, setRequestCounts] = useState({});
  const [meetingCounts, setMeetingCounts] = useState({});
  const [errorMsg, setErrorMsg] = useState("No Request Yet");
  const [Mda, setMda] = useState([]);
  const [mdaId, setMdaId] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeesId, setEmployeesId] = useState([]);
  const [ApproverId, setApproverId] = useState([]);
  const [mailDisplay, setMailDisplay] = useState(true);
  const [mdaDisplay, setMdaDisplay] = useState(false);
  const [category, setCategory] = useState([]);
  const [searchText, setSearchText] = useState("");
//   const [status, setStatus] = useState("actioned");
  const [ourUrl, setOurUrl] = useState("");
  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState('');
  const [summary, setSummaary] = useState('');
  const [batchId, setBatchId] = useState('');
  const [projectInitiative, setProjectInitiative] = useState('');
  const [onAction, setOnAction] = useState(false);
  const [sectorId, setSectorId] = useState();
  
  const [envelopeHolder, setEnvelopeHolder] = useState([]);
  const [commentsBudget, setCommentsBudget] = useState([]);
  const [commentsBudgetA, setCommentsBudgetA] = useState([]);
  const [budgetPending, setBudgetPending] = useState([]);
  
  
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarFor, setCalendarFor] = useState("");
  const [calendar, setCalendar] = useState(null);
  const [requestCalendar, setRequestCalendar] = useState(null);
  const [meetingCalendar, setMeetingCalendar] = useState(null);
  const [showQueryForm, setShowQueryForm] = useState(false);
  const [showQueryThread, setShowQueryThread] = useState(false);
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySUQiOiIxIiwiRmlyc3ROYW1lIjoiRWRvIEdvdnYiLCJMYXN0TmFtZSI6IkFkbWluIiwiZXhwIjoxNjIxNzg3NTQ1LCJpc3MiOiJzZXJ2ZXIiLCJhdWQiOiJjbGllbnQifQ.JpkTA-250nWIkIxrOyaLZcbw33fJNXuV7z1tPE9zz0w";
  const wrapperRef = useRef(null);
  const focusRef = useRef();
  const [current, setCurrent] = useState("");
  const [currentId, setCurrentId] = useState("");
  console.log(details);
  console.log(errorMsg);

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';
// const BASE_API_URL = 'http://localhost:5000/api/v1';


useEffect (()=>{
//   getMdas()
//   getEmployees()


  const r = localStorage.getItem('r');
  let fn = localStorage.getItem('fn');
  let ln = localStorage.getItem('ln');
  let ui = localStorage.getItem('ui');

  // r && setRole(decodeToken('r').r);
  if (fn && ln) {
    fn = decodeToken('fn').fn;
    ln = decodeToken('ln').ln;
    ui = decodeToken('ui').ui;
    setUsername(`${fn} ${ln}`);
//   getPending(ui)
//   getBudgetPlanningSummary(ui)
//   getBudgetPendingComents(ui)
  getPendingBudgetSectors(+ui,)
  
    console.log(ui)
    setUserId(ui)
  }

},[])



const getPendingBudgetSectors = (userId)=>{
  axios.get(`${BASE_API_URL}/BudgetPlanning/GetPendingBudgetSectors`, {params:{userId}}).then(
    
    (response)=>{
console.log(response)
setSectorId(response.data)

// console.log(response.data[0].budgetPlanning)
// setCommentsBudget(response.data)
// setCommentsBudgetA(response.data[0]?.budgetPlanning)
   
 })
}
console.log(sectorId)




// console.log(budgetPending)

  


  return (
    <div className="w-100">


      

     { sectorId && sectorId.length !==0 && <div onClick={()=>{setOnShow(!onShow)}} className='p-10 pointer m-5 border'>
        Executives VIew
      </div>}
         {onShow && <GovernorsVIew gov = {sectorId} userId = {userId}/>}

    </div>
  );
};



export default ExecView;
