import React, { Component, useState, useEffect, useRef } from "react";
import { store } from 'react-notifications-component';
import { nullcheck, notify } from '../../Utils/Helper'
import axios from "axios";
import { Preloader } from "../../Utils/Preloader";
// import ValidationMessageComponent from "./ValidationMessage";
import cookies from 'js-cookie'



const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export default class UploadFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            file: "",
            message: "",
            showNotification: false,
            notificationSuccess: false,
            notificationMessage: "Successfully saved!",
            loader: false,
            downloadFormat: "",
            fullName: "",
            role: "",
            employeeId: 0,
           authorized: true,
            authorisedMdaId: 0,
            budgetData:[],

        };
    }
    componentDidMount() {
        this.getAuthorisedUser();
    }
    getAuthorisedUser = () => {
        // const { cookies } = this.props;
        if (typeof cookies.get("userId") !== "undefined") {
            let authUser = cookies.get("userId");

            this.setState({
                fullName: authUser.firstName + " " + authUser.lastName,
                role: authUser.role,
                employeeId:authUser.id
            });
            this.checkUserCanInitiate(authUser.id)
        }
    };

    checkUserCanInitiate = (id) => {
        this.setState({
            loader: true,
            message: "",
        });
        axios.get(`${BASE_API_URL}/userprevilege/getuserprevilegebyemployeeId?employeeId=${id}`).then((response) => {
            this.setState({
                loader: false,
                message: "",
            });

            if (response.data.length != 0) {

                this.setState({
                    authorized: true,
                    authorisedMdaId: response.data[0].mdaId

                });
                this.getDepartmentBudget(response.data[0].mdaId)
            }

            else {
                this.setState({
                    authorized: false
                });
                //this.props.history.push("/dashboard");
            }

        }).catch((err) => {
            this.setState({
                loader: false,
                message: nullcheck(err.response) == "" ? "server error" : err.response.data,
            });
        })

    }
    getDepartmentBudget=(mdaId)=>{
        axios.get(`${BASE_API_URL}/DepartmentBudget/getDepartmentBudgetByMdaIdForFormatDownload?mdaId=${mdaId}`)
            .then((response) => {

                this.setState({
                    budgetData:response.data
                })


        }).catch((err)=>{

        })
    }

    handleFile = (e) => {
        var check = e.target.files[0].name.split('.')[1] !== 'csv' ? false : true
        if (check === false) {
            this.setState({
                message: "Please add a valid csv file"
            })
            document.getElementById('file').value = ""

        } else {
            this.setState({
                message: ""
            })
            this.setState({ file: e.target.files[0] });
        }


    };
    handleSubmit = () => {
        let url = this.props.match.url




        ///program-code
        let csvFile = document.getElementById('file').files[0];
        if (typeof csvFile === "undefined") {

            this.setState({
                message: "file not found"

            })
        } else {


            if (url == "/funds-code") {
                this.setState({
                    loader: true,
                    message: "",

                })
                let formData = new FormData();
                formData.append('fundSegmentfile', csvFile)

                axios.post(`${BASE_API_URL}/funditem/uploadfundsegment`, formData).then((response) => {
                    this.setState({
                        loader: false,
                        message: "",

                    })
                    notify(store, 'success', 'fund item uploaded successfully');
                    window.location.reload();
                }).catch((err) => {
                    this.setState({
                        loader: false,
                        message: nullcheck(err.response) == "" ? "server error" : err.response.data,

                    })
                })
            }
            else if (url == '/program-code') {
                this.setState({
                    loader: true,
                    message: "",

                })
                let formData = new FormData();
                formData.append('programmeSegmentFile', csvFile)

                axios.post(`${BASE_API_URL}/activity/uploadprogrammesegment`, formData).then((response) => {
                    notify(store, 'success', 'programme segment uploaded successfully');
                    this.setState({
                        loader: false,
                        message: "",

                    })
                    window.location.reload();
                }).catch((err) => {
                    this.setState({
                        loader: false,
                        message: nullcheck(err.response) == "" ? "server error" : err.response.data,

                    })
                })
            }
            else if (url == '/functional-code') {
                this.setState({
                    loader: true,
                    message: "",

                })
                let formData = new FormData();
                formData.append('functionalSegmentFile', csvFile)

                axios.post(`${BASE_API_URL}/FunctionalItem/UploadFunctionalSegment`, formData).then((response) => {
                    this.setState({
                        loader: false,
                        message: "",

                    })
                    notify(store, 'success', 'functional segment file uploaded successfully');
                    window.location.reload();
                }).catch((err) => {
                    this.setState({
                        loader: false,
                        message: nullcheck(err.response) == "" ? "server error" : err.response.data,

                    })
                })
            }
            else if (url == '/economic-code') {
                this.setState({
                    loader: true,
                    message: "",

                })
                let formData = new FormData();
                formData.append('economicSegmentFile', csvFile)

                axios.post(`${BASE_API_URL}/EconomicItem/UploadEconomicSegment`, formData).then((response) => {
                    this.setState({
                        loader: false,
                        message: "",

                    })
                    notify(store, 'success', 'economic segment file uploaded successfully');
                    window.location.reload();
                }).catch((err) => {
                    this.setState({
                        loader: false,
                        message: nullcheck(err.response) == "" ? "server error" : err.response.data,

                    })
                })
            }
            else if (url == '/budget-items-setup') {

                this.setState({
                    loader: true,
                    message: "",

                })
                let mdaId = this.state.authorisedMdaId

                let formData = new FormData();
                let enteredBy = this.state.employeeId

                formData.append('departmentBudgetFile', csvFile)
                formData.append('enteredBy', enteredBy)
                formData.append('mdaId', mdaId)

                axios.post(`${BASE_API_URL}/DepartmentBudget/UploadDepartmentBudget`, formData).then((response) => {
                    this.setState({
                        loader: false,
                        message: "",

                    })
                    notify(store, 'success', 'department budget file uploaded successfully');
                }).catch((err) => {

                    this.setState({
                        loader: false,
                        message: nullcheck(err.response) == "" ? "server error" : err.response.data,

                    })
                })
            }
            else if (url == '/initiate-budget') {
                this.setState({
                    loader: true,
                    message: "",

                })
                let mdaId = this.state.authorisedMdaId

                let formData = new FormData();
                let enteredBy = this.state.employeeId
                formData.append('budgetInformationFile', csvFile)
                formData.append('enteredBy', enteredBy)
                formData.append('mdaId', mdaId)

                axios.post(`${BASE_API_URL}/BudgetInformation/UploadBudgetInformation`, formData).then((response) => {
                    this.setState({
                        loader: false,
                        message: "",

                    })
                    notify(store, 'success', 'budget information file uploaded successfully');
                }).catch((err) => {
                    this.setState({
                        loader: false,
                        message: nullcheck(err.response) == "" ? "server error" : err.response.data,

                    })
                })
            }
        }

    }
    handleExportCsv = (table_id, separator = ",") => {
        let url = this.props.match.url

        if (url === "/funds-code") {
            table_id = "fundcode"
        } else if (url === "/program-code") {
            table_id = "programcode"
        } else if (url === "/functional-code") {
            table_id = "functionalcode"
        } else if (url === "/economic-code") {
            table_id = "economiccode"
        }
        else if (url == "/budget-items-setup") {
            table_id = "budgetitemssetup"
        }
        else if (url == '/initiate-budget') {
            table_id ="initatebudget"
        }
        else {
            return "";
        }
        // Select rows from table_id
        var rows = document.querySelectorAll("table#" + table_id + " tr");
        // Construct csv
        var csv = [];
        for (var i = 0; i < rows.length; i++) {
            var row = [],
                cols = rows[i].querySelectorAll("td, th");
            for (var j = 0; j < cols.length; j++) {
                // Clean innertext to remove multiple spaces and jumpline (break csv)
                var data = cols[j].innerText
                    .replace(/(\r\n|\n|\r)/gm, "")
                    .replace(/(\s\s)/gm, " ");
                // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
                data = data.replace(/"/g, '""');
                // Push escaped string
                row.push('"' + data + '"');
            }
            csv.push(row.join(separator));
        }
        var csv_string = csv.join("\n");
        // Download it
        var filename =
            "export_" + table_id + "_" + new Date().toLocaleDateString() + ".csv";
        var link = document.createElement("a");
        link.style.display = "none";
        link.setAttribute("target", "_blank");
        link.setAttribute(
            "href",
            "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
        );
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };






    render() {
        return (
            <React.Fragment>
                {this.state.loader === true ? <Preloader /> : ""}
                {this.state.open ? (
                    <div className="mb-3 ">
                        <div
                            className=" p-10 w-100 bg-card-header py-4 px-4 shadow-sm overflow-hidden bb-green cursor-pointer"
                            onClick={() => this.setState({ open: !this.state.open })}
                        >
                            <p className=" fz-17 font-medium font-avenir-black text-color">
                                Upload File
              </p>
                        </div>
                        <div className="flex p-10 w-100 bg-white py-4 pl-4 shadow-sm overflow-hidden cursor-pointer">
                            <div className="w-80 mr-10">
                                <div class="mt-3 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div class="p-10 sm:col-span-12">
                                        {this.state.message !== "" ? (
                                            // <ValidationMessageComponent
                                            //     message={this.state.message}
                                            // ></ValidationMessageComponent>
                                            <p></p>
                                        ) : (
                                                ""
                                            )}
                                        <label
                                            for="file"
                                            class="block p-10 text-base font-medium label-color"
                                        >
                                            Upload File
                    </label>
                                        <div class="m-t-5">
                                            <input


                                                type="file"
                                                name="file"
                                                id="file"
                                                class="shadow-sm focus:outline-none block w-full sm:text-sm"
                                                onChange={this.handleFile}
                                            />
                                        </div>

                                    </div>
                                    <div class="pt-5 w-100 sm:col-span-12">
                                        <div class="flex w-100">
                                            <button
                                                onClick={() => this.handleSubmit()}
                                                type="submit"
                                                class="mr-5 w-40 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                                            >
                                                {this.state.loading && (
                                                    <svg
                                                        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            class="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            stroke-width="4"
                                                        ></circle>
                                                        <path
                                                            class="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                        ></path>
                                                    </svg>
                                                )}
                        Submit
                      </button>
                                            <button onClick={() => this.handleExportCsv("")}

                                                class="mr-5 w-40 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                                            >
                                                {this.state.loading && (
                                                    <svg
                                                        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            class="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            stroke-width="4"
                                                        ></circle>
                                                        <path
                                                            class="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                        ></path>
                                                    </svg>
                                                )}
                        Download Format
                      </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <table id="budgetitemssetup" hidden>
                                <tr>
                                    <th>program code</th>
                                    <th>expenditure code</th>
                                    <th>item</th>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                            <table id="fundcode" hidden>
                                <tr>
                                    <th>Code</th>
                                    <th>Description</th>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                            <table id="programcode" hidden>
                                <tr>
                                    <th>Code</th>
                                    <th>Policy</th>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                            <table id="functionalcode" hidden>
                                <tr>
                                    <th>Code</th>
                                    <th>Description</th>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                            <table id="economiccode" hidden>
                                <tr>
                                    <th>Code</th>
                                    <th>Description</th>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                            <table id="initatebudget" hidden>
                                <tr>
                                    <th>Currency Name</th>
                                    <th>Programme Code</th>
                                    <th>Item</th>
                                    <th>JanVal</th>
                                    <th>FebVal</th>
                                    <th>MarVal</th>
                                    <th>AprVal</th>
                                    <th>MayVal</th>
                                    <th>JunVal</th>
                                    <th>JulVal</th>
                                    <th>AugVal</th>
                                    <th>SepVal</th>
                                    <th>OctVal</th>
                                    <th>NovVal</th>
                                    <th>DecVal</th>
                                </tr>
                                {this.state.budgetData.map((budgetInformation)=>{

                                    return(
                                        <tr>
                                            <td>Naira</td>
                                            <td>{budgetInformation.programmeCode}</td>
                                            <td>{budgetInformation.item}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                    )
                                })}

                            </table>


                        </div>
                    </div>
                ) : (
                        <div
                            className="bg-white w-100 py-4 px-4 pointer shadow-sm overflow-hidden cursor-pointer mb-3"
                            onClick={() => this.setState({ open: !this.state.open })}
                        >
                            <p className="fz-17 font-medium text-color">Upload File</p>
                        </div>
                    )}
            </React.Fragment>
        );
    }
}
