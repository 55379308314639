import * as yup from "yup"
const budgetCurrencyByMonthConservationValidationSchema=yup.object().shape({

    naira:yup.string().required('naira rate is required'),
    currencyId:yup.string().required('currency is required'),


});

export default budgetCurrencyByMonthConservationValidationSchema
