import * as yup from "yup"
const projectObjectivesValidationSchema = yup.object().shape({
    projectID: yup.string().required('project is required'),
    objectiveName: yup.string().required('objective name is required'),
    objectiveCode: yup.string().required('objective code is required'),



});
//     let data = { projectID, objectiveName, objectiveCode };
export default projectObjectivesValidationSchema
