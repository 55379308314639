// import React from 'react'
// import { Collapse, Form, Input, Button, Checkbox } from 'antd';
// import Tablez from '../layouts/Tablez';
// import BudgetDetailsSummary from '../layouts/BudgetDetailsSummary';
// import ItemSetUpTable from '../layouts/ItemSetUpTable';


// const { Panel } = Collapse;

// const BudgetHistory = ()=>{

//     const onFinish = (values) => {
//         console.log('Success:', values);
//       };
    
//       const onFinishFailed = (errorInfo) => {
//         console.log('Failed:', errorInfo);
//       };

  
// function callback(key) {
//     console.log(key);
//   }
//     return(
//         <div style={{width:'100%'}}>





// <Collapse defaultActiveKey={['1']} onChange={callback}>
//     <Panel header="Department Budget History" key="1">
//       <div className='flex-between'>
//       <Input value={'Select Period'}/>
//       <div className='flex'>
//           <Button>Excel Export</Button>
//           <Button>Print Document</Button>
//       </div>
//       </div>
//       <Tablez/>
//     </Panel>
   
   
//   </Collapse>







//         </div>
//     )
// }
// export default BudgetHistory

import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import { Nav } from "../Components/Nav";
import  DepartmentBudgetHistory  from "../layouts/DepartmentBudgetHistory";
import cookies from 'js-cookie'
import Greeting from '../common/Greeting';
import LocationDetails from '../layouts/LocationDetails';

export default class BudgetHistory extends Component {
  constructor(props) {

        super(props);
        this.state = {
            fullName: "",
            role: "",
            time: "",


        };
    }
    componentDidMount() {
        this.getAuthorisedUser();
    }

    getAuthorisedUser = () => {
        // const { cookies } = this.props;
        if (typeof cookies.get('userId') !== "undefined") {
            let authUser = cookies.get('userId');
            // let time = authUser.lastLoginTime.split("T")[1]
            this.setState({
                fullName: authUser.firstName + ' ' + authUser.lastName,
                role: authUser.role,
                // time: time
            })
        }

    }

  render() {
    return (
      <React.Fragment>
      
            <main
              class="flex-1 w-100 relative overflow-y-auto focus:outline-none"
              tabindex="0"
              style={{ outline: "none" }}
            >
               <div className="w-100 flex space-between flex-v-baseline">
        <Greeting />
        <LocationDetails />
      </div>
              <div class="py-10 max-w-full mx-auto px-2 sm:px-4 md:px-8">
                <div class="flex">
                  <div className="flex-1 flex">
                    <h1 class=" text-base font-medium text-color mr-10">
                      Ministry of Education
                    </h1>
                  </div>
                  {/* <div className="flex">
                    <p className="text-sm font-normal mr-3">

                      <br />
                      <span className="text-xs ml-5">
                                      Time of login: { this.state.time}
                      </span>
                    </p> */}
                    {/* <img src="/location-icon@2x.png" /> */}
                    {/* <div style={{ height: 40, width: 40 }}>
                      <img src="/icn.png" />
                    </div> */}
                  {/* </div> */}
                </div>
                <div className="py-6">
                  <DepartmentBudgetHistory {...this.props} />
                </div>
              </div>
            </main>
        
      </React.Fragment>
    );
  }
}
