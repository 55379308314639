import React, { Component, useState, useEffect, useRef } from "react";
// import { DataTypes } from "../data/Types";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";

import { TiArrowBack } from 'react-icons/ti';
import { AiOutlineFileExcel,AiOutlinePrinter } from 'react-icons/ai';
import axios from "axios";

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';

// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

function formatDefaultDate(dateString) {

  var newDate = new Date(dateString);
  newDate.setDate(newDate.getDate() + 1);

  return newDate.toISOString().split("T")[0];
}

export default class DetailFinanceC extends Component {
  constructor(props) {
    super(props);
      this.state = {
          item: "",
          allBudgetDepartment: [],
          programmeActivities: "",
          open: true,

          allBudgetInformationData: [],
          budgetInformation: "",
          loading: false,

      };
  }
    componentDidMount() {
        let url = this.props.match.url
        let mdaId = url.replace('/schedule-manager/finance-detail:', '');
        this.getBudgetInformation(mdaId);

  }
  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };
    getMda = (departmentBudgetId) => {
        let mdaName = "";
        let departmentBudget = this.state.allBudgetDepartment.filter(item => item.id == departmentBudgetId);
        if (departmentBudget.length !== 0)
            mdaName = departmentBudget[0].mdas.name
        return mdaName
    }

    getDepartmentBudget() {
        this.setState({
            loader: true,
            message: "",
        });
        axios
            .get(`${BASE_API_URL}/departmentbudget`)
            .then((response) => {
                console.log(response.data);
                this.setState({
                    allBudgetDepartment: response.data,
                    loader: false,
                    message: "",
                });
            })
            .catch((err) => {
                this.setState({
                    loader: false,
                    message: err.message,
                });
            });
    }
    getBudgetInformation = (mdaId) => {
    axios
        .get(`${BASE_API_URL}/budgetinformation/GetBudgetmentInformationByMdaId?mdaId=${mdaId}`)
      .then((response) => {
        console.log(response.data);
        this.setState({
          allBudgetInformationData: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    };
    handleExportCsv = (table_id, separator = ",") => {
        // Select rows from table_id
        var rows = document.querySelectorAll("table#" + table_id + " tr");
        // Construct csv
        var csv = [];
        for (var i = 0; i < rows.length; i++) {
            var row = [],
                cols = rows[i].querySelectorAll("td, th");
            for (var j = 0; j < cols.length; j++) {
                // Clean innertext to remove multiple spaces and jumpline (break csv)
                var data = cols[j].innerText
                    .replace(/(\r\n|\n|\r)/gm, "")
                    .replace(/(\s\s)/gm, " ");
                // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
                data = data.replace(/"/g, '""');
                // Push escaped string
                row.push('"' + data + '"');
            }
            csv.push(row.join(separator));
        }
        var csv_string = csv.join("\n");
        // Download it
        var filename =
            "export_" + table_id + "_" + new Date().toLocaleDateString() + ".csv";
        var link = document.createElement("a");
        link.style.display = "none";
        link.setAttribute("target", "_blank");
        link.setAttribute(
            "href",
            "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
        );
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    handlePrint = (table_id) => {
        var divToPrint = document.getElementById(table_id);
        let newWin = window.open("");
        newWin.document.write(divToPrint.outerHTML);
        newWin.print();
        newWin.close();
    };




  render() {

      var alltotal=0
      // const budgetInformations = this.state.allBudgetInformationData;
      var janVal = 0;
      var febVal = 0;
      var marVal = 0;
      var aprVal = 0;
      var mayVal = 0;
      var junVal = 0;
      var julVal = 0;
      var augVal = 0;
      var sepVal = 0;
      var octVal = 0;
      var novVal = 0;
      var decVal = 0;
    const budgetInformations = this.state.allBudgetInformationData;
    var allBudgetInformations = budgetInformations.map((budgetInformation) => {

        janVal += parseFloat(budgetInformation.jan_val);
        febVal += parseFloat(budgetInformation.feb_val);
        marVal += parseFloat(budgetInformation.mar_val);

        aprVal += parseFloat(budgetInformation.apr_val);
        mayVal += parseFloat(budgetInformation.may_val);
        junVal += parseFloat(budgetInformation.june_val);
        julVal += parseFloat(budgetInformation.july_val);
        augVal += parseFloat(budgetInformation.aug_val);
        sepVal += parseFloat(budgetInformation.sept_val);
        octVal += parseFloat(budgetInformation.oct_val);
        novVal += parseFloat(budgetInformation.nov_val);
        decVal += parseFloat(budgetInformation.dec_val);
        let total =
            budgetInformation.jan_val
            + budgetInformation.feb_val
            + budgetInformation.mar_val
            + budgetInformation.apr_val
            + budgetInformation.may_val
            + budgetInformation.june_val
            + budgetInformation.july_val
            + budgetInformation.aug_val
            + budgetInformation.sept_val
            + budgetInformation.oct_val
            + budgetInformation.nov_val
            + budgetInformation.dec_val
        alltotal+=total
        return (

        <tr>
          <td className="table-border-right p-2 text-base font-medium text-color">

                    {budgetInformation.departmentBudget.item}
          </td>
          <td className="table-border-right p-2 text-base text-color">
                    {budgetInformation.currency.title}
          </td>
                <td className="table-border-right p-2 text-base text-color">{budgetInformation.jan_val }</td>
                <td className="table-border-right p-2 text-base text-color">{budgetInformation.feb_val}</td>
                <td className="table-border-right4 p-2 text-base text-color">{budgetInformation.mar_val}</td>
                <td className="table-border-right p-2 text-base text-color">{budgetInformation.apr_val}</td>
                <td className="table-border-right p-2 text-base text-color">{budgetInformation.may_val}</td>
                <td className="table-border-right p-2 text-base text-color">{budgetInformation.june_val}</td>
                <td className="table-border-right p-2 text-base text-color">{budgetInformation.july_val}</td>
                <td className="table-border-right p-2 text-base text-color">{budgetInformation.aug_val}</td>
                <td className="table-border-right p-2 text-base text-color">{budgetInformation.sept_val}</td>
                <td className="table-border-right p-2 text-base text-color">{budgetInformation.oct_val}</td>
                <td className="table-border-right p-2 text-base text-color">{budgetInformation.nov_val}</td>
                <td className="table-border-right p-2 text-base text-color">{budgetInformation.dec_val}</td>
                <td className="table-border-right p-2 text-base text-color">{total}</td>
        </tr>
      );
    });
    return (
      <React.Fragment>
        <div
          className="w-100 flex-between bg-white py-4 px-4 shadow-sm overflow-hidden cursor-pointer"
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <p style={{ width:'50%', marginRight:"20px"}}  className="flex-align  mt-2 text-base text-color">
            <Link
              onClick={() => this.props.history.goBack()}
              // style={{border:"2px solid red", maxWidth:'40px'}}
              className="primary-green flex-align flex-between font-avenir-black"
            >
             <TiArrowBack style={{fontSize:"26px", color:'green'}}/>
              <p className=".ummary m-r-10" style={{color:'black'}}>
                Back to Summary
              </p>
            </Link>
            <span className="font-avenir-black m-r-10">|</span>
            <span className="font-medium font-avenir-black">
              Ministry of Finance (2021 Detailed Budget)
            </span>{" "}
            {/* <span className="font-normal">(2021 Detailed Budget)</span> */}
          </p>
              
          <div className='flex-align ' style={{ minWidth:"50%"}}>
                    <button style={{ marginRight:"10px"}} onClick={()=>this.handleExportCsv("table_info") }
              type="submit"
              className="mr-3 h-10 flex-align  w-100 py-2 pr-4   shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
            >
            <AiOutlineFileExcel style={{fontSize:"20px", marginRight:'5px'}}/>

              Excel Export
            </button>
                   <button  onClick={() => this.handlePrint("table_info")}
              type="submit"
              className="mr-3 h-10 w-100 flex-align py-2 pr-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
            >
            <AiOutlinePrinter style={{fontSize:"20px", marginRight:'5px'}}/>

              Print Document
            </button>
          </div>
        </div>
        <div class="flex w-100 flex-col">
          <table class="shadow w-100 overflow-hidden" id="table_info">
            <thead class="bg-card-header">
              <tr>
                <th
                  scope="col"
                  class="table-border-right px-6 py-3 text-left text-base font-medium font-avenir-black text-color tracking-wider"
                >
                  Budget Items (’000mn)
                </th>
                <th
                  scope="col"
                  class="table-border-right px-6 py-3 text-left text-base font-medium font-avenir-black text-color tracking-wider"
                >
                  Currency
                </th>
                <th
                  scope="col"
                  class="table-border-right px-6 py-3 text-left text-base font-medium font-avenir-black text-color tracking-wider"
                >
                  Jan
                </th>
                <th
                  scope="col"
                  class="table-border-right px-6 py-3 text-left text-base font-medium font-avenir-black text-color tracking-wider"
                >
                  Feb
                </th>
                <th
                  scope="col"
                  class="table-border-right px-6 py-3 text-left text-base font-medium font-avenir-black text-color tracking-wider"
                >
                  Mar
                </th>
                <th
                  scope="col"
                  class="table-border-right px-6 py-3 text-left text-base font-medium font-avenir-black text-color tracking-wider"
                >
                  Apr
                </th>
                <th
                  scope="col"
                  class="table-border-right px-6 py-3 text-left text-base font-medium font-avenir-black text-color tracking-wider"
                >
                  May
                </th>
                <th
                  scope="col"
                  class="table-border-right px-6 py-3 text-left text-base font-medium font-avenir-black text-color tracking-wider"
                >
                  Jun
                </th>
                <th
                  scope="col"
                  class="table-border-right px-6 py-3 text-left text-base font-medium font-avenir-black text-color tracking-wider"
                >
                  Jul
                </th>
                <th
                  scope="col"
                  class="table-border-right px-6 py-3 text-left text-base font-medium font-avenir-black text-color tracking-wider"
                >
                  Aug
                </th>
                <th
                  scope="col"
                  class="table-border-right px-6 py-3 text-left text-base font-medium font-avenir-black text-color tracking-wider"
                >
                  Sept
                </th>
                <th
                  scope="col"
                  class="table-border-right px-6 py-3 text-left text-base font-medium font-avenir-black text-color tracking-wider"
                >
                  Oct
                </th>
                <th
                  scope="col"
                  class="table-border-right px-6 py-3 text-left text-base font-medium font-avenir-black text-color tracking-wider"
                >
                  Nov
                </th>
                <th
                  scope="col"
                  class="table-border-right px-6 py-3 text-left text-base font-medium font-avenir-black text-color tracking-wider"
                >
                  Dec
                </th>
                <th
                  scope="col"
                  class="table-border-right px-6 py-3 text-left text-base font-medium font-avenir-black text-color tracking-wider"
                >
                  Total
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              {allBudgetInformations}

                        <tr>
                            <td
                                colSpan="2"
                                class="table-border-right p-2 text-base font-medium font-avenir-black text-color"
                            >
                                Total
                </td>

                            <td class="table-border-right p-2 text-base font-medium font-avenir-black text-color">
                                {janVal}
                            </td>
                            <td class="table-border-right p-2 text-base font-medium font-avenir-black text-color">
                                {febVal}
                            </td>
                            <td class="table-border-right4 p-2 text-base font-medium font-avenir-black text-color">
                                {marVal}
                            </td>
                            <td class="table-border-right p-2 text-base font-medium font-avenir-black text-color">
                                {aprVal}
                            </td>
                            <td class="table-border-right p-2 text-base font-medium font-avenir-black text-color">
                                {mayVal}
                            </td>
                            <td class="table-border-right p-2 text-base font-medium font-avenir-black text-color">
                                {junVal}
                            </td>
                            <td class="table-border-right p-2 text-base font-extrabold text-color">
                                {julVal}
                            </td>
                            <td class="table-border-right p-2 text-base font-medium font-avenir-black text-color">
                                {augVal}
                            </td>
                            <td class="table-border-right p-2 text-base font-medium font-avenir-black text-color">
                                {sepVal}
                            </td>
                            <td class="table-border-right p-2 text-base font-medium font-avenir-black text-color">
                                {octVal}
                            </td>
                            <td class="table-border-right p-2 text-base font-medium font-avenir-black text-color">
                                {novVal}
                            </td>
                            <td class="table-border-right p-2 text-base font-medium font-avenir-black text-color">
                                {decVal}
                            </td>

                            <td class="table-border-right p-2 text-base font-medium font-avenir-black text-color">
                                {alltotal}
                            </td>
                        </tr>
            </tbody>
          </table>
        </div>
        <p className="text-base font-normal text-color p-4"></p>



        {this.state.showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-7/12 my-6 mx-auto">
                {/*content*/}
                <div
                  className="flex bg-card-header py-4 px-4 shadow-sm overflow-hidden cursor-pointer"
                  onClick={() =>
                    this.setState({
                      showModal: !this.state.showModal,
                    })
                  }
                >
                  <p className="flex-1 mt-2 text-lg font-medium font-avenir-black text-color">
                    Approval Information
                  </p>
                </div>
                <div class="flex flex-col bg-white">
                  <table class="">
                    <thead class="bg-gray-table-header">
                      <tr>
                        <th
                          scope="col"
                          class="table-border-right table-border-bottom px-6 py-3 text-left text-base font-medium font-avenir-black  text-color tracking-wider"
                        >
                          Approval Role
                        </th>
                        <th
                          scope="col"
                          class="table-border-right table-border-bottom px-6 py-3 text-left text-base font-medium font-avenir-black  text-color tracking-wider"
                        >
                          Approved By
                        </th>
                        <th
                          scope="col"
                          class="table-border-right table-border-bottom px-6 py-3 text-left text-base font-medium font-avenir-black  text-color tracking-wider"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          class="table-border-right table-border-bottom px-6 py-3 text-left text-base font-medium font-avenir-black  text-color tracking-wider"
                        >
                          Date Initiated
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 table-border-bottom text-left text-base font-medium font-avenir-black  text-color tracking-wider"
                        >
                          Comment
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr>
                        <td class="table-border-right table-border-bottom px-4 py-2 text-base font-medium text-gray-900">
                          Approver 1
                        </td>
                        <td class="table-border-right table-border-bottom px-4 py-2 text-base text-color">
                          Ugochukwu Chukwunonso
                        </td>
                        <td class="table-border-right table-border-bottom px-4 py-2 text-base text-color">
                          Completed
                        </td>
                        <td class="table-border-right table-border-bottom px-4 py-2 text-base text-color">
                          04-Mar-2019
                        </td>
                        <td class="p-2 table-border-bottom text-base text-color"></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="flex p-2 mb-2">
                    <p className="flex-1 text-base font-normal text-color p-4">
                      Showing 1 to 2 of 2 entries
                    </p>
                    <button
                      type="button"
                      class="w-32 h-10 mt-5 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-bold text-color bg-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() =>
                        this.setState({
                          showModal: !this.state.showModal,
                        })
                      }
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </React.Fragment>
    );
  }
}
