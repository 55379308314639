import React, { useState,useEffect } from 'react';
import { Table,Button, Input, InputNumber, Popconfirm, Form, Typography,Select } from 'antd';
import axios from "axios";
import notification from "../../utility/notification";

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';
// const BASE_API_URL = 'http://localhost:5000/api/v1';

const originData = [];
const { Option } = Select;


// for (let i = 0; i < 100; i++) {
//   originData.push({
//     key: i.toString(),
//     name: `Edrward ${i}`,
//     age: 32,
//     address: `London Park no. ${i}`,
//   });
// }



const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [programme, setProgramme] = useState('');
  const [programmeName, setProgrammeName] = useState('');
  const [programmeId, setProgrammeId] = useState('');
  const [item, setItem] = useState('');
  const [show, setshow] = useState(false);
  const [itemName, setItemName] = useState('');
  const [itemId, setItemId] = useState('');
  const [currency, setCurrency] = useState('');
  const [currencyId, setCurrencyId] = useState('');
  const [currencyName, setCurrencyName] = useState('');
  const [janVal, setjanVal] = useState('');
  const [febVal, setfebVal] = useState('');
  const [marVal, setmarVal] = useState('');
  const [aprVal, setaprVal] = useState('');
  const [mayVal, setmayVal] = useState('');
  const [junVal, setjunVal] = useState('');
  const [augVal, setaugVal] = useState('');
  const [septVal, setseptVal] = useState('');
  const [octVal, setoctVal] = useState('');
  const [julVal, setjulVal] = useState('');
  const [novVal, setnovVal] = useState('');
  const [decVal, setdecVal] = useState('');
  const [totalCost, setTotalCost] = useState('');


  const [ budgetItemMonthlBudgetArray, setBudgetItemMonthlBudgetArray] = 
  
  useState({
          budgetItem:{},
          monthlyBudget:{},

});
const [holdArray, setHoldArray] = useState([]);


//   const [count, setCount] = useState(1);

  const isEditing = (record) => record?.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
    // let count = 1

    useEffect(()=>{
setData([])

    },[props.data])


    // useEffect(()=>{
    //   if (Object?.keys(budgetItemMonthlBudgetArray?.budgetItem)?.length !== 0)

    //   setHoldArray([...holdArray,budgetItemMonthlBudgetArray])
    //       },[budgetItemMonthlBudgetArray])

          console.log(holdArray)

    const toPost = ()=>{

        let postData =
        
        data.map((e)=>(
           
            {
                // key: idx+1,
            
                        id: e?.id,
                        dateCreated: e?.dateCreated,
                        enteredBy: e?.enteredBy,
                        dateUpdated: e?.dateUpdated,
                        updatedBy: e?.updatedBy,
                        isDelete: e?.isDelete,
                        dateDeleted: e?.dateDeleted,
                        project: e?.project,
                        budgetPlanId: e?.budgetPlanId,
                        programmeCode: e?.programmeCode?e?.programmeCode:'',
                        status: e?.status,
                         budgetDeptId:e.budgetDeptId,
                        projectLocation: e?.projectLocation?e?.projectLocation:'',
                        proposedBudget: +e.proposedBudget?e?.proposedBudget:'',
                        performance: e?.performance?e?.performance:'',
                        actuals: e?.actuals?e?.actuals:'',
                        mdaId: e?.mdaId
                      }
        ))
        // let poster = arrayHolder.map((a)=>())
        axios.post(`${BASE_API_URL}/BudgetPlanning`, postData 
        
                          ).then(()=>{
                            notification({
                                title: 'Successful',
                                message: 'Costs have been Allocated successfully',
                                type: 'success'
                          });

                          })
      }

      useEffect (()=>(

        form.setFieldsValue({total:(+janVal)+(+febVal)+(+marVal)+(+aprVal)+(+mayVal)+(+junVal)+(+julVal)+(+augVal)+(+septVal)+(+octVal)+(+novVal)+(+decVal)}),
        
        setTotalCost((+janVal)+(+febVal)+(+marVal)+(+aprVal)+(+mayVal)+(+junVal)+(+julVal)+(+augVal)+(+septVal)+(+octVal)+(+novVal)+(+decVal))

      ),[janVal,febVal,marVal,aprVal,mayVal,junVal,julVal,augVal,septVal,octVal,novVal,decVal])

      useEffect (()=>{

        
        ((props?.project?.proposedBudget)<totalCost)&&
        
        notification({
          title: 'Error Cannot continue',
          message: 'Total Cost must be lower than proposed budget',
          type: 'danger'
        })
        // form.setFieldsValue({total:(+janVal)+(+febVal)+(+marVal)+(+aprVal)+(+mayVal)+(+junVal)+(+julVal)+(+augVal)+(+septVal)+(+octVal)+(+novVal)+(+decVal)})
        // setTotalCost(janVal+febVal+marVal+aprVal+mayVal+junVal+julVal+augVal+septVal+octVal+novVal+decVal)

      },[totalCost])
      

useEffect(()=>{
  console.log(props?.project)
  setData([...data, props?.project])
setHoldArray([...data,props?.project])



  getProgramme()
  getCurrency()
// getcurrency


  // setData(props?.project)
//     let dat = props?.project?.map((e,idx)=>(
  // let dat =   {
  //   // key: idx+1,

  //   actuals: "",
  //   budgetPlanId:props.project.budgetPlanId,
  //   dateCreated: props.project.dateCreated,
  //   dateDeleted: props.project.dateCreated,
  //   dateUpdated: props.project.dateUpdated,
  //   economicCode:props.project.economicCode,
  //   enteredBy: props.project247.enteredBy,
  //   functionalCode: props.project.functionalCode,
  //   fundCode: props.project.fundCode,
  //   id: props.project.id,
  //   isDelete:props.project.isDelete,
  //   mdaId:props.project.mdaId,
  //   performance:props.project.performance ,
  //   programmeCode:props.project.programmeCode,
  //   project:props.project. project,
  //   projectLocation: props.project.projectLocation,
  //   proposedBudget:props.project.proposedBudget,
  //   status:props.project.status,
  //   updatedBy:props.project.updatedBy,
  //         }
      
  //   console.log('dat:',dat)
    // setData([dat])
},[props?.project])

const getProgramme = ()=>{
  axios.get(`${BASE_API_URL}/programme/getprogram`, 
        
        ).then((res)=>{
         
        setProgramme(res.data)

        })

}

const getItem =(programmeId, mdaId )=>{
  axios.get(`${BASE_API_URL}/departmentBudget/GetDepartmentBudgetByProgrammeIDAndMda?programmeId=${programmeId}&mdaId=${mdaId}`, 
        
        ).then((res)=>{
         
        setItem(res.data)

        })

}
const getCurrency =()=>{
  axios.get(`${BASE_API_URL}/currency/getcurrency`, 
        
        ).then((res)=>{
         
        setCurrency(res.data)

        })

}

const done =()=>{
  console.log(holdArray)
props.addFormToArrayToSubmit(holdArray)
setHoldArray([])
props.handleCancel()

}

const onFinish =(values)=>{
  // https://edogoverp.com/budget/api/v1/budgetInformation/addBudgetInformations
console.log(values)


let formload ={
  currencyId: values?.currency?.value,
departmentBudgetId: props?.project?.budgetDeptId,
janVal: values?.janVal,
febVal: values?.febVal,
marVal:  values?.marVal,
aprVal:  values?.aprVal,
mayVal:  values?.mayVal,
junVal:  values?.junVal,
julVal:  values?.julVal,
augVal:  values?.augVal,
sepVal:  values?.septVal,
octVal:  values?.octVal,
novVal:  values?.novVal,
decVal:  values?.decVal,
total:  values?.total,
currency:values?.currency,
item:values?.item,
programme:values?.programme,
enteredBy: 'string'
}
// props.addFormToArrayToSubmit(data,formload)

// setBudgetItemMonthlBudgetArray({budgetItem:props?.project,
//   monthlyBudget:formload})

console.log(formload)
// setshow(false)


props.closeEditablleTable()
}


console.log(data)
console.log(holdArray)
console.log(budgetItemMonthlBudgetArray)
  

  const columns = [
    {
      title: 'project',
      dataIndex: 'project',
      width: '25%',
      editable: true,
    },
    {
      title: 'programme Code',
      dataIndex: 'programmeCode',
      width: '15%',
      editable: true,
    },
    {
      title: 'project Location',
      dataIndex: 'projectLocation',
      width: '40%',
      editable: true,
    },
    {
        title: 'proposed Budget',
        dataIndex: 'proposedBudget',
        width: '40%',
        editable: true,
      },
      {
        title: 'actuals',
        dataIndex: 'actuals',
        width: '40%',
        editable: true,
      },
    // {
    //   title: 'operation',
    //   dataIndex: 'operation',
    //   render: (_, record) => {
    //     const editable = isEditing(record);
    //     return editable ? (
    //       <span>
    //         <Typography.Link
    //           onClick={() => save(record.key)}
    //           style={{
    //             marginRight: 8,
    //           }}
    //         >
    //           Save
    //         </Typography.Link>
    //         <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
    //           <a>Cancel</a>
    //         </Popconfirm>
    //       </span>
    //     ) : (
    //       <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
    //         Edit
    //       </Typography.Link>
    //     );
    //   },
    // },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'proposedBudget' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <div>
              
    <div className='p-10' style={{backgroundColor: "#66ff99",}}>{props?.project?.project}</div>

      <Form onFinish={onFinish} className='w-100' layout='vertical' style={{display:''}} form={form}>
      {/* <Form onFinish={onFinish} layout='vertical' style={{width:"100%",padding:"20%", flexDirection:"column", border:"2px solid blue"}} form={form} component={false}> */}
        <Table
        style={{width:"100%", border:""}}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
    {props.hideFinish && <Button onClick ={done}>Finish</Button>}

{/* {props.showForm&&
<div class="bg-white p-20">
  <div class="flex-between  w-100">
    <Form.Item
                           className='w-100 m-r-10'
                           label="Programme" name="programme"
                        //    rules={[{
                        //    required: true,
                        //    message: 'Please select who you are sending to!'},
                        //    ]}
                           >
               <Select
                                onChange={(text,index)=>{
                                  getItem(index.value,11)
                                  setProgrammeName(index?.children)
                                setProgrammeId(index?.value)
    
    
                                            }}
                          //  disabled = {fundType.length ==0? true:false}
    
                                    labelInValue
                                    style={{ width: "100%", border: "" }}
                                    placeholder="Please select"
                                            >
                                    {Array.isArray(programme) && programme?.map((obj) => (
                            <Option value={obj?.value} key={obj?.id}>{`${obj?.label}`}</Option>
                            ))}
                </Select>
                  </Form.Item>
    
                  <Form.Item
                           className='w-100 m-r-10'
                           label="Item" name="item"
                        //    rules={[{
                        //    required: true,
                        //    message: 'Please select who you are sending to!'},
                        //    ]}
                           >
               <Select
                                onChange={(text,index)=>{
                                setItemName(index?.children)
                                setItemId(index?.value)
    
    
                                            }}
                          //  disabled = {fundType.length ==0? true:false}
    
                                    labelInValue
                                    style={{ width: "100%", border: "" }}
                                    placeholder="Please select"
                                            >
                                    {Array.isArray(item) && item?.map((obj) => (
                            <Option value={obj?.value} key={obj?.id}>{`${obj?.label}`}</Option>
                            ))}
                </Select>
                  </Form.Item>
                  <Form.Item
                           className='w-100 m-r-10'
                           label="Currency" name="currency"
                        //    rules={[{
                        //    required: true,
                        //    message: 'Please select who you are sending to!'},
                        //    ]}
                           >
               <Select
                                onChange={(text,index)=>{
                                setCurrencyName(index?.children)
                                setCurrencyId(index?.value)
    
    
                                            }}
                          //  disabled = {fundType.length ==0? true:false}
    
                                    labelInValue
                                    style={{ width: "100%", border: "" }}
                                    placeholder="Please select"
                                            >
                                    {Array.isArray(currency) && currency?.map((obj) => (
                            <Option value={obj?.value} key={obj?.id}>{`${obj?.label}`}</Option>
                            ))}
                </Select>
                  </Form.Item>
    
  </div>
                <div class="flex-between  w-100">
                  <Form.Item
                            className='w-100 m-r-10'
                            label="January"
                            name="janVal"
                            // rules={[{
                            // required: true,
                            // message: 'Please select who you are sending to!'},
                            // ]}
                            >
                           
                               <InputNumber   onChange={(e)=>{setjanVal(e)}}/>
                   </Form.Item>
                   <Form.Item
                            className='w-100 m-r-10'
                            label="February"
                            name="febVal"
                            // rules={[{
                            // required: true,
                            // message: 'Please select who you are sending to!'},
                            // ]}
                            >
                               <InputNumber className='w-100 m-r-10'  onChange={(e)=>{setfebVal(e)}}/>
                   </Form.Item>
                   <Form.Item
                            className='w-100 m-r-10'
                            label="March"
                            name="marVal"
                            // rules={[{
                            // required: true,
                            // message: 'Please select who you are sending to!'},
                            // ]}
                            >
                           <InputNumber className='w-100 m-r-10' onChange={(e)=>{setmarVal(e)}}/>
                   </Form.Item>
                </div>
                 <div class="flex-between  w-100">
                   <Form.Item
                            className='w-100 m-r-10'
                            label="April"
                            name="aprVal"
                            // rules={[{
                            // required: true,
                            // message: 'Please select who you are sending to!'},
                            // ]}
                            >
                           <InputNumber  className='w-100 m-r-10' onChange={(e)=>{setaprVal(e)}}/>
                   </Form.Item>
                   <Form.Item
                            className='w-100 m-r-10'
                            label="May"
                            name="mayVal"
                            // rules={[{
                            // required: true,
                            // message: 'Please select who you are sending to!'},
                            // ]}
                            >
                           <InputNumber className='w-100 m-r-10'  onChange={(e)=>{setmayVal(e)}}/>
                   </Form.Item>
                   <Form.Item
                            className='w-100 m-r-10'
                            label="June"
                            name="junVal"
                            // rules={[{
                            // required: true,
                            // message: 'Please select who you are sending to!'},
                            // ]}
                            >
                           <InputNumber className='w-100 m-r-10'  onChange={(e)=>{setjunVal(e)}}/>
                   </Form.Item>
                 </div><div class="flex-between  w-100">
                   
                   <Form.Item
                            className='w-100 m-r-10'
                            label="July"
                            name="julVal"
                            // rules={[{
                            // required: true,
                            // message: 'Please select who you are sending to!'},
                            // ]}
                            >
                           <InputNumber className='w-100 m-r-10'  onChange={(e)=>{setjulVal(e)}}/>
                   </Form.Item>
                   <Form.Item
                            className='w-100 m-r-10'
                            label="August"
                            name="augVal"
                            // rules={[{
                            // required: true,
                            // message: 'Please select who you are sending to!'},
                            // ]}
                            >
                           <InputNumber  onChange={(e)=>{setaugVal(e)}}/>
                   </Form.Item>
                   <Form.Item
                            className='w-100 m-r-10'
                            label="September"
                            name="septVal"
                            // rules={[{
                            // required: true,
                            // message: 'Please select who you are sending to!'},
                            // ]}
                            >
                           <InputNumber  className='w-100 m-r-10' onChange={(e)=>{setseptVal(e)}}/>
                   </Form.Item>
                 </div>
                 <div class="flex-between w-100">
                   <Form.Item
                            className='w-100 m-r-10'
                            label="October"
                            name="octVal"
                            // rules={[{
                            // required: true,
                            // message: 'Please select who you are sending to!'},
                            // ]}
                            >
                           <InputNumber  className='w-100 m-r-10' onChange={(e)=>{setoctVal(e)}}/>
                   </Form.Item>
                   <Form.Item
                            className='w-100 m-r-10'
                            label="November"
                            name="novVal"
                            // rules={[{
                            // required: true,
                            // message: 'Please select who you are sending to!'},
                            // ]}
                            >
                           <InputNumber  className='w-100 m-r-10' onChange={(e)=>{setnovVal(e)}}/>
                   </Form.Item>
                   <Form.Item
                            className='w-100 m-r-10'
                            label="December"
                            name="decVal"
                            // rules={[{
                            // required: true,
                            // message: 'Please select who you are sending to!'},
                            // ]}
                            >
                           <InputNumber className='w-100 m-r-10'  onChange={(e)=>{setdecVal(e)}}/>
                   </Form.Item>
                 </div>

                 <div style={{width:'33.3%'}}>
                   <Form.Item
                            className='w-100 m-r-10'
                            label="Total"
                            name="total"
                            // rules={[{
                            // required: true,
                            // message: 'Please select who you are sending to!'},
                            // ]}
                            >
                                <InputNumber />
                   </Form.Item>
                 </div>
  
                 <Form.Item>
          <Button htmlType='submit'>Save</Button>
          </Form.Item>

</div>} */}


      </Form>
    </div>
  );
};

export default EditableTable