// import React from 'react'
// import { Collapse, Form, Input, Button, Checkbox } from 'antd';
// import Tablez from '../layouts/Tablez';
// import BudgetDetailsSummary from '../layouts/BudgetDetailsSummary';
// import ItemSetUpTable from '../layouts/ItemSetUpTable';
// import CodificationTable from '../layouts/CodificationTable';


// const { Panel } = Collapse;

// const BudgetCodification = ()=>{

//     const onFinish = (values) => {
//         console.log('Success:', values);
//       };
    
//       const onFinishFailed = (errorInfo) => {
//         console.log('Failed:', errorInfo);
//       };

  
// function callback(key) {
//     console.log(key);
//   }
//     return(
//         <div style={{width:'100%'}}>





// <div className='bold'>Budget Codificaton</div>
   
//       <div className='flex-between'>
//       <Input value={'Select Period'}/>
//       <div className='flex'>
//           <Button>Excel Export</Button>
//           <Button>Print Document</Button>
//       </div>
//       </div>
//       <CodificationTable/>
   
   
 







//         </div>
//     )
// }
// export default BudgetCodification

import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import { Nav } from "../Components/Nav";
import BudgetCodificationC from '../layouts/BudgetCodificationC'
// import  BudgetCodificationC  from "../Components/BudgetCodificationC";
import cookies from 'js-cookie'
import Greeting from '../common/Greeting';
import LocationDetails from '../layouts/LocationDetails';


export default class BudgetCodification extends Component {
  constructor(props) {
    super(props);
        this.state = {
            fullName: "",
            role: "",
            time: "",
            employeeId: 0,


        };
    }
    componentDidMount() {
        this.getAuthorisedUser();
    }

    getAuthorisedUser = () => {
        // const { cookies } = this.props;
        if (typeof cookies.get('userId') !== "undefined") {
            let authUser = cookies.get('userId');
            // let time = authUser.lastLoginTime.split("T")[1]
            this.setState({
                fullName: authUser.firstName + ' ' + authUser.lastName,
                role: authUser.role,
                // time: time,
                employeeId: authUser.id
            })
        }



    }


  render() {
    return (
      <React.Fragment>
      
            <main
              class=" w-100 relative overflow-y-auto focus:outline-none"
              tabindex="0"
              style={{ outline: "none" }}
            >
               <div className="w-100 flex space-between flex-v-baseline">
        <Greeting />
        <LocationDetails />
      </div>
              <div class="py-10 w-100 m-t-20 max-w-full mx-auto px-2 sm:px-4 md:px-8">
                <div class="flex-between w-100">
                  <div className="flex-between flex-align">
                    <h1 class=" text-base font-medium text-color mr-10">
                      Budget Codification
                    </h1>
                  </div>
                 
                </div>
                <div className="py-6 w-100">
                          <BudgetCodificationC employeeId={this.state.employeeId} {...this.props} />
                </div>
              </div>
            </main>
        
      </React.Fragment>
    );
  }
}
// 