import React from 'react'
import { Collapse } from 'antd';
import Tablez from '../layouts/Tablez';
import BudgetDetailsSummaryC from '../layouts/BudgetDetailsSummaryC';
import BudgetItemSetUp from './BudgetItemSetUp';
import BudgetDetailsC from '../layouts/BudgetDetailsC';
import ExpenseExpandedC from '../layouts/ExpenseExpandedC';
import ReimbursementExpendedC from '../layouts/ReimbursementExpendedC';
import CashAdvancedExpendedC from '../layouts/CashAdvancedExpendedC';
import Greeting from '../common/Greeting';
import LocationDetails from '../layouts/LocationDetails';


const { Panel } = Collapse;

const BudgetPlanning = ()=>{

    const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

function callback(key) {
    console.log(key);
  }
    return(
        <div style={{width:'100%'}}>
 <div className="w-100 flex space-between flex-v-baseline m-b-10">
        <Greeting />
        <LocationDetails />
      </div>


<div className="m-t-10"><BudgetDetailsC/></div>
<div className="m-t-10">
  <BudgetDetailsSummaryC  />
</div>
<div className="m-t-10">
  <ExpenseExpandedC/>
</div>
<div className="m-t-10">
  <ReimbursementExpendedC  />
</div>
<div className="m-t-10">
  <CashAdvancedExpendedC  />
</div>


{/* <Collapse defaultActiveKey={['1']} onChange={callback}>
    <Panel header="Budget Planning" key="1">
      <p><BudgetDetailsC/></p>
    </Panel>
    <Panel header="Budget Details Summary 2" key="2">
      <p><BudgetDetailsSummary/></p>
     
    </Panel>
    <Panel header="Expense Expended" key="3">
      <p><Tablez/></p>
    
    </Panel>
    <Panel header="Reimbursement Expended" key="4">
      <p><Tablez/></p>
    
    </Panel>
    <Panel header="Cash Advanced Expended" key="5">
      <p><Tablez/></p>
    
    </Panel>
  </Collapse>,
 */}

  {/* <BudgetItemSetUp/> */}







        </div>
    )
}
export default BudgetPlanning