import React, { Component } from "react";
import { Preloader } from "../../Utils/Preloader";
// import { store } from 'react-notifications-component';
import { nullcheck, notify } from '../../Utils/Helper'
// import ValidationMessageComponent from "../Components/ValidationMessage";
import queryString from 'query-string'
import cookies from 'js-cookie'

import { AiOutlineFileExcel,AiOutlinePrinter } from 'react-icons/ai';

import { BiPencil } from 'react-icons/bi';
import axios from "axios";
import { Button } from "antd";
const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';


// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const BASE_MODULE_URL = process.env.REACT_APP_BASE_MODULE_URL;
//const BASE_API_URL ='http://localhost:5000/api/v1';


export default class BudgetReviewC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: "",
            programmeActivities: "",
            open: true,
            geoCodeData: "",
            localGovernmentCouncilData: [],
            localGovernmentCouncil: "",
            activityData: null,
            activity: "",
            wardCouncil: "",
            allBudgetDepartments: [],
            allBudgetDepartment: [],
            budgetDepartmentId: "",
            approverInformationProcessId:"",
            budgetReviewInformation: [],
            reviewData: [],
            commentData: [],
            replyData: [],
            employeeData: [],
            userolesData: [],
            getMdaData:[],

            allBudgetInformationData: [],
            budgetInformation: [],
            loading: false,

            deleteId: "",
            edit: false,
            editId: "",
            submenu: false,
            showModal: false,
            total: 0,
            editComment: false,
            fullName: "",
            role: "",
            employeeId: 0,

            loader: false,
            status:"",
            message: "",
            comment: "",
            authorized: false,
            search: "",
            profilePicture: "",
            isGovernor: false,
            governorCanAprove:false,

        };
    }
    componentDidMount() {
        this.getAuthorisedUser();
        this.getBudgetInformation();

        this.getReviews();
        this.getComment();
        this.getEmployees();
        this.getUserRoles();
        this.getReply();
        this.getMdas();
        const parsed = queryString.parse(this.props.location.search);

        if (Object.keys(parsed).length != 0) {
            this.getBudgetReviewByParameter(parsed.mdaId)
        }
       //

    }



    getBudgetReviewByParameter(parameter) {

        document.getElementById('mda').selectedIndex = parameter;
        this.handleBudgetInformation(parameter)

    }
    getAuthorisedUser = () => {
        // const { cookies } = this.props;
        if (typeof cookies.get("userId") !== "undefined") {
            let authUser = cookies.get("userId");
            this.setState({
                fullName: authUser.firstName + " " + authUser.lastName,
                role: authUser.role,
                employeeId: authUser.id,
                profilePicture: `${BASE_MODULE_URL}/${authUser.picture}`,
                isGovernor: authUser.isGovernor

            });
            console.log(authUser)
            if (authUser.isGovernor==true) {
                this.getGovernorApproval()
            }
            this.getPendingReview(authUser.id)

        }

    };

    handleChange = (ev) => {
        this.setState({
            [ev.target.name]: ev.target.value,
        });
    };
    formatDefaultDate = (dateString) => {
        var newDate = new Date(dateString);

        var strArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var d = newDate.getDate();
        var m = strArray[newDate.getMonth()];
        var y = newDate.getFullYear();
        return '' + (d <= 9 ? '0' + d : d) + ',' + m + ' ' + y;
        //newDate.setDate(newDate.getDate() + 1);

        // return newDate.toISOString().split("T")[0];
    }
    subtractDate = (dateString) => {
        var newDate =new Date(dateString);

        var todayDate = new Date();

        var diff = todayDate - newDate;

        return this.timeConversion(diff)


    }
    subtractTwoDates = (firstDate,secondDate) => {
        var _firstDate = new Date(firstDate);
        var _secondDate = new Date(secondDate)

        //var todayDate = new Date();

        var diff = _firstDate - _secondDate;

        return this.timeConversion(diff)


    }
    timeConversion = (millisec) => {
        var seconds = (millisec / 1000).toFixed(1);

        var minutes = (millisec / (1000 * 60)).toFixed(1);

        var hours = (millisec / (1000 * 60 * 60)).toFixed(1);

        var days = (millisec / (1000 * 60 * 60 * 24)).toFixed(1);

        if (seconds < 60) {
            return seconds + " Sec";
        } else if (minutes < 60) {
            return minutes + " Min";
        } else if (hours < 24) {
            return hours + " Hrs";
        } else {
            return days + " Days"
        }
    }


    handleSubmit = (newbudgetReviewInformation) => {
        let optionId = `option${newbudgetReviewInformation.approvalProcessInformations.id}`;
        let commentId = `comment${newbudgetReviewInformation.approvalProcessInformations.id}`;
        let employeeId =this.state.employeeId.toString();
        let status = parseInt(document.getElementById(optionId).value);
        let comment =  document.getElementById(commentId).value;

        let approvalProcessInformationId = newbudgetReviewInformation.approvalProcessInformations.id
        let mdaId = newbudgetReviewInformation.mda.id.toString();
        let data = { status, comment, employeeId, approvalProcessInformationId, mdaId };

        this.setState({
            loader: true,
            message: "",
        });
        axios.post(`${BASE_API_URL}/ApprovalProcessInformation/AddBudgetReviewInformation`, {...data}).then((response) => {
            this.setState({
                loader: false,
                message: "",
            });
            this.getBudgetInformation();
            this.getPendingReview(employeeId);
            // notify(store, 'success', 'budget review added successfully');
            window.location.reload();
        }).catch((err) => {
            this.setState({
                loader: false,
                message: nullcheck(err.response) == "" ? "server error" : err.response.data,
            });
        })

    }
    handleEditSubmit = () => {
        let localGovernmentCouncil = this.state.localGovernmentCouncil;
        let wardCouncil = this.state.wardCouncil;
        let geoCode = this.state.geoCode;
        let id = this.state.editId;

        let data = { localGovernmentCouncil, wardCouncil, geoCode, id };

        // this.setState({ loading: true }, () =>
        //   this.props.updateAction(DataTypes.PUT_BUDGET_PERIOD, { data })
        // );
    };
    handleBudgetInformation = (mdaId) => {
        let newMdaId = mdaId.toString();
        this.setState({
            loader: true,
            message: "",

        });
        axios
            .get(`${BASE_API_URL}/budgetinformation/GetBudgetmentInformationByMdaId?mdaId=${newMdaId}`)
            .then((response) => {
                this.setState({
                    budgetInformation: response.data,
                    showModal: !this.state.showModal,
                    loader: false,
                    message: "",
                });

            }).catch((err) => {
                this.setState({
                    loader: false,
                    message: err.message,

                });
            })

        //this.setState({
        //    showModal: !this.state.showModal,

        //});
       // let allbudgetInformation = this.state.allBudgetInformationData;

        //if (allbudgetInformation.length === 0) {
        //    this.setState({
        //        budgetInformation: "",
        //    });
        //}
        //allbudgetInformation.map((newBudgetInformation) => {
        //    if (newBudgetInformation.departmentBudgetId === departmentBudgetId) {
        //        this.setState({
        //            budgetInformation: newBudgetInformation,
        //        });
        //    }
        //});

    };


    handleGetComment = (approvalProcessInformationId) => {

        let commentData = this.state.commentData;
        let comments = commentData.filter((commentData) => {
            return commentData.approvalProcessInformationId == approvalProcessInformationId
        })
        return comments;
    }
    handleGetReply = (commentId) => {
        let replyData = this.state.replyData;
        let replys = replyData.filter((reply) => {
            return reply.commentID == commentId
        })
        return replys;
    }
    handleGetEmployee = (employeeId) => {

        let employeeData = this.state.employeeData;
        //console.log(this.state.employeeData)
        let employee = employeeData.filter((employeeData) => {
            return employeeData.id == employeeId
        })
        //console.log({'employee':employee})
        return employee;
    }

    handleGetRole = (roleid) => {
        let userolesData = this.state.userolesData;
        let userroles = userolesData.filter((useroleData) => {
            return useroleData.role.id == roleid
        })

       return userroles.length==0?"":userroles[0].role.name;
    }
    handleGetMdaName = (mdaId) => {
        let mdas = this.state.getMdaData;

      let mdaInfo = mdas.filter((mda) => {
            return mda.id == mdaId
        })
        return mdaInfo.length == 0 ? "" : mdaInfo[0].name
    }
    handleResponseToggle = (budgetReview) => {

        let commentId = `comment${budgetReview.approvalProcessInformations.id.replace("-", "")}`;
        let submitCommentId = `submitComment${budgetReview.approvalProcessInformations.id}`;
        let respondCommentId = `respondComment${budgetReview.approvalProcessInformations.id}`;
        let cancelId = `cancel${budgetReview.approvalProcessInformations.id.replace("-", "")}`;

        document.getElementById(submitCommentId).style.display = "";
        document.getElementById(cancelId).style.display = "";
        document.getElementById(commentId).style.display = "";
        document.getElementById(respondCommentId).style.display = "none";

    }
    handleCancelCommentToggle = (budgetReview) => {
        let commentId = `comment${budgetReview.approvalProcessInformations.id.replace("-", "")}`;
        let submitCommentId = `submitComment${budgetReview.approvalProcessInformations.id}`;
        let respondCommentId = `respondComment${budgetReview.approvalProcessInformations.id}`;
        let cancelId = `cancel${budgetReview.approvalProcessInformations.id.replace("-", "")}`;



        document.getElementById(submitCommentId).style.display = "none";
        document.getElementById(commentId).style.display = "none";
        document.getElementById(cancelId).style.display = "none";
        document.getElementById(respondCommentId).style.display = "";


    }



    handleSubmitCommentToggle = (budgetReview, commentId) => {

        let enteredBy = this.state.employeeId.toString();
        let commentInfoId = `comment${budgetReview.approvalProcessInformations.id.replace("-", "")}`;
        let cancelId = `cancel${budgetReview.approvalProcessInformations.id.replace("-", "")}`;

        let text = document.getElementById(commentInfoId).value;
        if (text === "") {
            this.setState({
                message: 'comment is required'
            })
        } else {
            this.setState({
                loader: true,
                message: "",
            });
            let submitCommentId = `submitComment${budgetReview.approvalProcessInformations.id}`;
            let respondCommentId = `respondComment${budgetReview.approvalProcessInformations.id}`;

            document.getElementById(submitCommentId).style.display = "none";
            document.getElementById(commentInfoId).style.display = "none";
            document.getElementById(respondCommentId).style.display = "";
            document.getElementById(cancelId).style.display = "none";

            let data = { enteredBy, commentId, text };

            axios.post(`${BASE_API_URL}/reply`, { ...data }).then((response) => {
                this.setState({
                    loader: false,
                    message: "",
                });
                // notify(store, 'success', 'reply added successfully');
                document.getElementById(commentInfoId).value = '';
                this.getReply();

            }).catch((err) => {
                this.setState({
                    loader: false,
                    message: nullcheck(err.response) == "" ? "server error" : err.response.data,
                });
            })
        }


    }
    handleExportCsv = (table_id, separator = ",") => {
        // Select rows from table_id
        var rows = document.querySelectorAll("table#" + table_id + " tr");
        // Construct csv
        var csv = [];
        for (var i = 0; i < rows.length; i++) {
            var row = [],
                cols = rows[i].querySelectorAll("td, th");
            for (var j = 0; j < cols.length; j++) {
                // Clean innertext to remove multiple spaces and jumpline (break csv)
                var data = cols[j].innerText
                    .replace(/(\r\n|\n|\r)/gm, "")
                    .replace(/(\s\s)/gm, " ");
                // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
                data = data.replace(/"/g, '""');
                // Push escaped string
                row.push('"' + data + '"');
            }
            csv.push(row.join(separator));
        }
        var csv_string = csv.join("\n");
        // Download it
        var filename =
            "export_" + table_id + "_" + new Date().toLocaleDateString() + ".csv";
        var link = document.createElement("a");
        link.style.display = "none";
        link.setAttribute("target", "_blank");
        link.setAttribute(
            "href",
            "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
        );
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    handlePrint = (table_id) => {
        var divToPrint = document.getElementById(table_id);
        let newWin = window.open("");
        newWin.document.write(divToPrint.outerHTML);
        newWin.print();
        newWin.close();
    };
    getGovernorApproval = () => {
        this.setState({
            loader: true,
            message: "",

        });
        axios.get(`${BASE_API_URL}/approvalProcessInformation/GovernorApproved`)
            .then((response) => {
                this.setState({
                    governorCanAprove: response.data,
                    loader: false,
                    message: "",
                });
            }).catch((err) => {
                this.setState({
                    loader: false,
                    message: err.message,

                });
            });
        //governorCanAprove
    }
    submitGovernorApproval = () => {
        let comment = document.getElementById('commenttext').value;
        let status = parseInt(document.getElementById('govStatus').value);
        let enteredById = this.state.employeeId.toString();
        let data = { comment, status, enteredById };


        if (comment == "") {
            this.setState({
                message: "text is required"
            })
        } else {
            this.setState({
                loader: true,
            });
            axios.post(`${BASE_API_URL}/approvalProcessInformation/GovernorApprovalProcess`, { ...data }).then((response) => {
                this.setState({
                    loader: false,
                });
                // notify(store, 'success', 'Congratulations budget added successfully');
            }).catch((err) => {

                this.setState({
                    message: nullcheck(err) ==""? "" : err.response.data,
                    loader: false,
                })
            })

            console.log(data);
        }

    }
    getBudgetInformation = () => {
        this.setState({
            loader: true,
            message: "",

        });
        axios
            .get(`${BASE_API_URL}/budgetinformation/GetAllBudgetInformationData`)
            .then((response) => {

                this.setState({
                    allBudgetInformationData: response.data,
                    loader: false,
                    message: "",
                });
            })
            .catch((err) => {
                this.setState({
                    loader: false,
                    message:err.message,

                });
            });
    };
    getBudgetInformationByMda() {
        let mdaId = document.getElementById('mda').value;

        if (mdaId != "") {
            this.setState({
                loader: true,
            });
            axios
                .get(`${BASE_API_URL}/budgetinformation/GetAllBudgetInformationDataByMda?mdaId=${mdaId}`)
                .then((response) => {
                    console.log(response.data)
                    this.setState({
                        allBudgetInformationData: response.data,
                        loader: false,
                        message:""
                    });
                })
                .catch((err) => {
                    this.setState({
                        message: err.message,
                        loader: false,
                    });
                });
        }

    }
    getPendingReview(employeeId) {
        this.setState({
            loader: true,
            message: "",
        });

        axios
            .get(`${BASE_API_URL}/approvalProcessInformation/getApprovalProcessByPendingUser?employeeId=${employeeId}`)
            .then((response) => {
                console.log(response.data)
                this.setState({
                    loader: false,
                    message: "",
                    budgetReviewInformation:response.data
                });
                console.log(response)
            }).catch((err) => {
                this.setState({
                    loader: false,
                    message: err.message,
                });
            })
    }
    getReviews() {
        this.setState({
            loader: true,
            message: "",
        });
        axios.get(`${BASE_API_URL}/approvalProcessInformation/GetApprovalProcessInformation`).then((response) => {

            this.setState({
                loader: false,
                message: "",
                reviewData: response.data
            });
        }).catch((err) => {
            this.setState({
                loader: false,
                message: err.message,
            });
        });
    }
    getReviewsByMdaId(mdaId) {
        let newMda = mdaId.toString()
        this.setState({
            loader: true,
            message: "",
        });
        axios.get(`${BASE_API_URL}/approvalProcessInformation/GetApprovalProcessInformationByMdaId?mdaId=${newMda}`).then((response) => {
            console.log(response.data)
            this.setState({
                loader: false,
                message: "",
                reviewData: response.data
            });
        }).catch((err) => {
            this.setState({
                loader: false,
                message: err.message,
            });
        });
    }
    getComment() {
        this.setState({
            loader: true,
            message: "",
        });
        axios.get(`${BASE_API_URL}/comment`).then((response) => {
            console.log(response.data)
            this.setState({
                loader: false,
                message: "",
                commentData: response.data
            });
        }).catch((err) => {
            this.setState({
                loader: false,
                message: err.message,
            });
        });
    }
    getReply() {
        this.setState({
            loader: true,
            message: "",
        });
        axios.get(`${BASE_API_URL}/reply`).then((response) => {
            console.log(response.data)
            this.setState({
                loader: false,
                message: "",
                replyData: response.data
            });
        }).catch((err) => {
            this.setState({
                loader: false,
                message: err.message,
            });
        });
    }
    getEmployees() {
        this.setState({
            loader: true,
            message: "",
        });
        axios.get(`${BASE_API_URL}/employee`).then((response) => {
            console.log(response.data)
            this.setState({
                loader: false,
                message: "",
                employeeData: response.data
            });
        }).catch((err) => {
            this.setState({
                loader: false,
                message: err.message,
            });
        });
    }
    getUserRoles() {
        this.setState({
            loader: true,
            message: "",
        });
        axios.get(`${BASE_API_URL}/role/userroles`).then((response) => {

            this.setState({
                loader: false,
                message: "",
                userolesData: response.data
            });
        }).catch((err) => {
            this.setState({
                loader: false,
                message: err.message,
            });
        });
    }
    getMdas() {
        this.setState({
            loader: true,
            message: "",

        });
        axios
            .get(`${BASE_API_URL}/mda`)
            .then((response) => {


                this.setState({
                    getMdaData: response.data,
                    loader: false,
                    message: "",
                });
            })
            .catch((err) => {
                this.setState({
                    loader: false,
                    message: err.message,

                });
            });
    }

  render() {

      var allbudgettotal = 0;
      var totalPercentage = 0;
      var alltotalPercentage = 0;
      var totalPercentages = 0;
      let governorCanAprove = this.state.governorCanAprove;



      //const budgetInformations = this.state.allBudgetInformationData;

      const budgetInformations = this.state.allBudgetInformationData.filter(
          (budgetInformation) => {
              return budgetInformation.mdaName.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
          }

      );

      var allBudgetInformations = budgetInformations.map((budgetInformation) => {
          let total = budgetInformation.total

          allbudgettotal += total;
          totalPercentage += ((total - 0) / total) * 100;

          return (
              <tr className='border'>

                  <td
                      className="table-border-right p-2 text-sm font-medium underline "

                  >
                      {
                          governorCanAprove == false ?
                              (
                                  <>
                                      <div onClick={() => this.getReviewsByMdaId(budgetInformation.mdaId)}
                                          className="bg-pen"

                                      >
                            <BiPencil style={{fontSize:"29px", padding:"5px", color:"white", backgroundColor:"#43425d" }}/>


                                      </div>
                                   </>
                              ): null
                      }


                      <a className="text-green-600 cursor-pointer" onClick={() =>
                          this.handleBudgetInformation(budgetInformation.mdaId)
                      }> {budgetInformation.mdaName}</a>
                  </td>
                  <td className="table-border-right p-2 text-right text-sm text-color">
                      0
          </td>
                  <td className="table-border-right p-2 text-right text-sm text-color">
                      0
          </td>
                  <td className="table-border-right p-2 text-right text-sm text-color">
                      {total}
                  </td>
                  <td className="table-border-right p-2 text-right text-sm text-color">
                      {total}
                  </td>
                  <td className="table-border-right border p-2 text-right text-sm text-color">
                      {((total - 0) / total) * 100}
                  </td>
              </tr>
          );
      });

      let allReview = this.state.reviewData.map((budgetReview) => {


          let commentInfo = this.handleGetComment(budgetReview.approvalProcessInformations.id).length == 0 ? "" : this.handleGetComment(budgetReview.approvalProcessInformations.id)[0];
          var commentText = commentInfo == "" ? "" : commentInfo.text;
          var commentInfoId = commentInfo == "" ? "" : commentInfo.id;

          var getEmployee = this.handleGetEmployee(budgetReview.approvalProcessInformations.nextPersonID).length == 0 ? null : this.handleGetEmployee(budgetReview.approvalProcessInformations.nextPersonID)

          var _employeeFullName = getEmployee == null ? "" : getEmployee[0].firstName + ' ' + getEmployee[0].lastName;

          let employeeFullName = budgetReview.approvalProcessInformations.isFinance == true ? "Finance"
              : budgetReview.approvalProcessInformations.isGovernor == true ? "Governor"
                  : _employeeFullName

          let roleId = getEmployee == null ? "" : getEmployee[0].roleId;

          var _role = this.handleGetRole(roleId);
          let role = budgetReview.approvalProcessInformations.isFinance == true ? "Finance"
              : budgetReview.approvalProcessInformations.isGovernor == true ? "Governor"
                  : _role

          let getEmployeePicture = getEmployee == null ? "" : getEmployee[0].profilePicture;
          let profilePictureUrl = `${BASE_MODULE_URL}/${getEmployeePicture}`

          let commentId = `comment${budgetReview.approvalProcessInformations.id.replace("-","")}`;

          let cancelId = `cancel${budgetReview.approvalProcessInformations.id.replace("-","")}`;
          let submitCommentId = `submitComment${budgetReview.approvalProcessInformations.id}`;
          let respondCommentId = `respondComment${budgetReview.approvalProcessInformations.id}`;
          let replyInfo = this.handleGetReply(commentInfoId)

          let _dateInTray = budgetReview.approvalProcessInformations.dateCreated
          let _dateOutTray = budgetReview.approvalProcessInformations.dateOutOfTray;
          let dateInTray = typeof (_dateInTray) == "undefined" ? "" : _dateInTray;
          let dateOutTray = typeof (_dateOutTray) == "undefined" ? "" : _dateOutTray;
          if (_dateOutTray != null) {

              dateOutTray = this.subtractTwoDates(_dateOutTray,_dateInTray)
          } else {
              dateOutTray=  this.subtractDate(dateInTray)
          }

          let allReply = replyInfo.map((reply) => {
              return (
                  <div>
                      <hr />
                      <p class="p-6">

                          {reply.text}</p>
                  </div>
              )
          })

          return (
              <tr className='border p-20'>
                  <td class="p-3  w-100 flex text-sm text-color">
                      <img
                          class="h-10 w-10 mr-5 rounded-full"
                          src={profilePictureUrl  }
                          alt=""
                      />
                      <p>
                          <span className="font-avenir-heavy">
                              {employeeFullName}
                          </span>
                          <br />
                          <span className="text-xs opacity-30 absolute">
                              {role}
                          </span>
                      </p>
                  </td>
                  <td class="p-3 text-sm text-color">
                      <p>
                          {this.formatDefaultDate(dateInTray)} <br />
                          <span className="text-xs opacity-30 absolute">
                              {this.subtractDate(dateInTray)}
                          </span>{" "}
                          <br />
                          <span
                              className="text-xs opacity-30"
                              style={{ position: "relative", top: -12 }}
                          >

                          </span>
                      </p>
                  </td>
                  <td class="p-3 absolute text-sm text-color" style={{ width:"18%",}}>
                      <p>

                          {budgetReview.approvalProcessInformations.status == 0 ? "Pending" : budgetReview.approvalProcessInformations.status == 1 ? "Approved" : "Declined"}
                          <br />
                          <span className="text-xs opacity-30 absolute">
                              {dateOutTray}
                          </span>
                          <br />

                         
                      </p>
                  </td>
                  <td class="p-3 text-sm text-color">
                      <p className="p-r-5 bold">
                          {commentText}

                      </p>
                      {allReply}

                      <>
                          <textarea style={{ display: 'none', height:"80px", width:"100%" }}
                              id={commentId}
                              name=""
                              rows=""
                              class="mt-5 bg-white border border-gray-300 w-full h-40"


                          ></textarea>
                          <div className="flex w-100">
                              <button style={{ display: 'none', backgroundColor:"#43425d", color:"white" }}
                                  onClick={() => this.handleSubmitCommentToggle(budgetReview, commentInfoId)}
                                  id={submitCommentId}
                                  type="submit"

                                  class="m-r-5 m-t-5 p-5 justify-center w-36 py-2 px-4 border border-transparent rounded shadow-sm text-sm font-avenir-black text-white bg-commet-texta focus:outline-none"
                              >
                                  Submit
                        </button>
                              {commentInfoId != "" ? (
                                  <>
                                      <button

                                          onClick={() => this.handleCancelCommentToggle(budgetReview)}
                                          id={cancelId}
                                          type="submit"
                                          style={{ backgroundColor: "#dc2626", display: 'none',color:"white"  }}

                                          class="m-r-5 m-t-5 p-5 justify-center w-36 py-2 px-4 border border-transparent rounded shadow-sm text-sm font-avenir-black text-white bg-commet-texta focus:outline-none"
                                      >
                                          Cancel
                                    </button>
                                      </>
                              ): ""}

                          </div>

                      </>


                      {commentInfoId != "" ? (
                          <button
                              onClick={() => this.handleResponseToggle(budgetReview)}
                              id={respondCommentId}

                              type="submit"
                              style={{borderRadius:"20px",backgroundColor:"#dfeddf"}}
                              className="p-10 pointer h-12 w-60 flex-align py-4 pr-4 border border-transparent rounded-full text-sm text-color comment-button focus:outline-none"
                          >
                              {/* <img
                                  src="/pen.png"
                                  style={{ width: 18, height: 26, top: -3 }}
                                  className="ml-7 mr-5 relative"
                              /> */}
                              <BiPencil style={{fontSize:"20px", marginRight:"5px"}}/>
                              <span className="">Respond to Comment</span>
                          </button>

                      ) : ""}


                  </td>
              </tr>

          )

      })

      var alltotal = 0;
      // const budgetInformations = this.state.allBudgetInformationData;
      var janVal = 0;
      var febVal = 0;
      var marVal = 0;
      var aprVal = 0;
      var mayVal = 0;
      var junVal = 0;
      var julVal = 0;
      var augVal = 0;
      var sepVal = 0;
      var octVal = 0;
      var novVal = 0;
      var decVal = 0;


      let allMdaItem = this.state.budgetInformation.map((mdaitem) => {

          janVal += parseFloat(mdaitem.jan_val);
          febVal += parseFloat(mdaitem.feb_val);
          marVal += parseFloat(mdaitem.mar_val);

          aprVal += parseFloat(mdaitem.apr_val);
          mayVal += parseFloat(mdaitem.may_val);
          junVal += parseFloat(mdaitem.june_val);
          julVal += parseFloat(mdaitem.july_val);
          augVal += parseFloat(mdaitem.aug_val);
          sepVal += parseFloat(mdaitem.sept_val);
          octVal += parseFloat(mdaitem.oct_val);
          novVal += parseFloat(mdaitem.nov_val);
          decVal += parseFloat(mdaitem.dec_val);
          let total =
              mdaitem.jan_val
              + mdaitem.feb_val
              + mdaitem.mar_val
              + mdaitem.apr_val
              + mdaitem.may_val
              + mdaitem.june_val
              + mdaitem.july_val
              + mdaitem.aug_val
              + mdaitem.sept_val
              + mdaitem.oct_val
              + mdaitem.nov_val
              + mdaitem.dec_val
          alltotal += total
          return (
              <tr>
                  <td className="table-border-right p-2 text-sm font-medium text-gray-900">
                      {mdaitem.departmentBudget.item}
                  </td>
                  <td className="table-border-right p-2 text-right text-sm text-color">
                      {mdaitem.currency.title}
                  </td>
                  <td className="table-border-right p-2 text-right text-sm text-color">
                      {mdaitem.jan_val}
                  </td>
                  <td className="table-border-right p-2 text-right text-sm text-color">
                      {mdaitem.feb_val}
                  </td>
                  <td className="table-border-right p-2 text-right text-sm text-color">
                      {mdaitem.mar_val}
                  </td>
                  <td className="table-border-right p-2 text-right text-sm text-color">
                      {mdaitem.apr_val}
                  </td>
                  <td className="table-border-right p-2 text-right text-sm text-color">
                      {mdaitem.may_val}
                  </td>
                  <td className="table-border-right p-2 text-right text-sm text-color">
                      {mdaitem.june_val}
                  </td>
                  <td className="table-border-right p-2 text-right text-sm text-color">
                      {mdaitem.july_val}
                  </td>
                  <td className="table-border-right p-2 text-right text-sm text-color">
                      {mdaitem.aug_val}
                  </td>
                  <td className="table-border-right p-2 text-right text-sm text-color">
                      {mdaitem.sept_val}
                  </td>
                  <td className="table-border-right p-2 text-right text-sm text-color">
                      {mdaitem.oct_val}
                  </td>
                  <td className="table-border-right p-2 text-right text-sm text-color">
                      {mdaitem.nov_val}
                  </td>
                  <td className="table-border-right p-2 text-right text-sm text-color">
                      {mdaitem.dec_val}
                  </td>
                  <td className="table-border-right p-2 text-right text-sm text-color">
                      {total}
                  </td>
                  <td className="table-border-right p-2 text-right text-sm text-color"></td>
              </tr>

              )
      })

      let mdas = typeof this.state.getMdaData == "undefined" ? [] : this.state.getMdaData;


      let allMdaOption = mdas.map((mda) => {
          return (
              <option value={mda.id}>{mda.name}</option>
          )
      })




    return (
        <React.Fragment>
            {this.state.loader === true ? <Preloader /> : ""}
        <div
        // style={{border:'2px solid red'}}
          className="w-100 flex-between flex-align bg-white py-4 px-4 shadow-sm overflow-hidden cursor-pointer"
          onClick={() => this.setState({ open: !this.state.open })}
            >
                {governorCanAprove == true ? "" : (
                    <>
                        <p className="flex-1 mt-2 fz-17 font-medium text-color">

                            {/* <img src="/calendar.png" className="inline ml-3" /> */}
                            <select id="mda" className='border' style={{lineHeight:"200px", fontSize:'18px', padding:'5px'}} onChange={() => this.getBudgetInformationByMda()} class="relative pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm">
                                <option> Select MDA</option>
                                {allMdaOption}
                            </select>
                        </p>
                        </>

                    )}


          <div>
            <div className="flex-align"><button
            onClick={() => this.handleExportCsv("allBudgetInformation")}
            type="submit"
            className="m-r-5 p-5 h-10 inline-flex py-2 pr-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
            >
            <div className='flex-align'>
            <AiOutlineFileExcel style={{fontSize:"20px", marginRight:'5px'}}/>
            <p>
            Excel Export
            </p>
            </div>
            
            </button>
            <button
            onClick={() => this.handlePrint("allBudgetInformation")}
            type="submit"
            className="m-r-5 p-5 h-10 inline-flex py-2 pr-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
            >
            <div className='flex-align'>
            <AiOutlinePrinter style={{fontSize:"20px", marginRight:'5px'}}/>
            <p>
            Print Document
            </p>
            </div>
            </button>
            <input
            placeholder="Search"
            className="border p-5 m-r-5 border-solid px-2 h-10 w-50"
            style={{
            backgroundColor: "transparent",
            borderColor: "#707070",
            }}
            
            name='search'
            value={ this.state.search}
            onChange={this.handleChange}
            /></div>
          </div>
        </div>
        <div class="flex flex-col">
          <table
            id="allBudgetInformation"
            class="min-w-full shadow overflow-hidden"
            style={{ width: "100%" }}
          >
            <thead class="bg-gray-table-header">
              <tr >
                <th
                  scope="col"
                  class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                  style={{color:"#000000",textShadow:"1px 0 #000000",width: "40%"}}
                >
                  Department
                </th>
                <th
                  scope="col"
                  class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                  style={{color:"#000000",textShadow:"1px 0 #000000",width: "12%"}}
                >
                  Previous
                </th>
                <th
                  scope="col"
                  class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                  style={{color:"#000000",textShadow:"1px 0 #000000",width: "12%"}}
                >
                  Pre Actuals
                </th>
                <th
                  scope="col"
                  class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                  style={{color:"#000000",textShadow:"1px 0 #000000",width: "12%"}}
                >
                  Current
                </th>
                <th
                  scope="col"
                  class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                  style={{color:"#000000",textShadow:"1px 0 #000000",width: "12%"}}
                >
                  % on Total
                </th>
                <th
                  scope="col"
                  class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                  style={{ width: "12%" }}
                >
                  % Growth
                </th>
              </tr>
            </thead>
            <tbody class="bg-white border divide-y divide-gray-200">
              {allBudgetInformations}

              <tr>

                <td class="table-border-right p-2 text-sm font-extrabold text-gray-900"  style={{color:"#000000",textShadow:"1px 0 #000000"}}>
                  Total
                </td>
                <td class="table-border-right p-2 text-right text-sm font-extrabold text-color"  style={{color:"#000000",textShadow:"1px 0 #000000",width: "12%"}}>
                  0
                </td>
                <td class="table-border-right p-2 text-right text-sm font-extrabold text-color"  style={{color:"#000000",textShadow:"1px 0 #000000",width: "12%"}}>
                  0
                </td>
                <td class="table-border-right p-2 text-right text-sm font-extrabold text-color"  style={{color:"#000000",textShadow:"1px 0 #000000",width: "12%"}}>
                                {allbudgettotal}
                </td>
                <td class="table-border-right p-2 text-right text-sm font-extrabold text-color"  style={{color:"#000000",textShadow:"1px 0 #000000",width: "12%"}}>
                                {allbudgettotal}
                </td>
                <td class="table-border-right p-2 text-right text-sm font-extrabold text-color"  style={{color:"#000000",textShadow:"1px 0 #000000",width: "12%"}}>
                                {((allbudgettotal - 0) / allbudgettotal) * 100}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="text-sm font-normal text-color mt-4">

        </p>

            {
                this.state.budgetReviewInformation.map((newbudgetReviewInformation) => {
                    let optionId = `option${newbudgetReviewInformation.approvalProcessInformations.id}`;
                    let commentId = `comment${newbudgetReviewInformation.approvalProcessInformations.id}`;
                    return (
                        <div className="mt-10">
                            <div className="flex">
                                <div className="bg-white shadow-sm w-8/12">
                                    <div
                                        className="flex py-4 px-4"
                                        style={{ borderBottom: "1px solid #D5D5D6" }}
                                    >
                                        <div className="flex-1">
                                            <div class="mt-1 relative">
                                                <select name="status"
                                                        id={optionId}
                                                        name="status"
                                                        aria-haspopup="listbox"
                                                        aria-expanded="true"
                                                        aria-labelledby="listbox-label"
                                                        class="relative border shadow-sm w-28 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm"
                                                >

                                                    <option value="1">Approve</option>
                                                    <option value="2">Decline</option>



                                                </select>

                                            </div>
                                        </div>

                                        <div className="flex">
                                            <p className="text-color font-medium text-sm mr-3 text-right">
                                                <span className="font-avenir-black">{this.state.fullName}</span>
                                                <br />
                                                <span className="font-normal text-xs ml-16">{this.state.role}</span>
                                            </p>
                                            <img class="h-8 w-8 rounded-full" src={this.state.profilePicture} alt="" />
                                        </div>
                                    </div>
                                    <textarea
                                        id={commentId}
                                        name="commenttext"

                                        class="shadow-sm block w-full sm:text-sm"
                                        style={{ height: "13rem", border: "none" }}
                                    ></textarea>
                                </div>
                            </div>
                            <button
                                onClick={() => this.handleSubmit(newbudgetReviewInformation) }
                                type="submit"
                                class="mr-5 w-8/12 mt-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white primary-bg-green focus:outline-none"
                            >
                                Submit for Review and Approval ({newbudgetReviewInformation.mda.name})
                            </button>
                        </div>
                    )
                })
            }

        <div class="m-t-20  flex-col  w-8/12">


                {this.state.governorCanAprove == false ? (

                    <>
                        <p className="text-lg p-10 bg-white bold font-avenir-black text-color">
                            Approval Officer Comments
                        {this.state.message !== "" ? (
                                // <ValidationMessageComponent
                                //     message={this.state.message}
                                // ></ValidationMessageComponent>
                               <p>gggg </p>
                            ) : (
                                    ""
                                )}
                        </p>
                        <table
                            id="allBudgetInformation"
                            class="shadow overflow-hidden m-t-5"
                            style={{ width: "100%" }}
                        >
                            <thead class="bg-gray-table-header">
                                <tr>
                                    <th
                                        scope="col"
                                        class="px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                         style={{color:"#000000",textShadow:"1px 0 #000000",width: "25%"}}
                                    >
                                        Assigned Officers
                </th>
                                    <th
                                        scope="col"
                                        class="px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                        style={{color:"#000000",textShadow:"1px 0 #000000",width: "18%"}}
                                    >
                                        In-Tray
                </th>
                                    <th
                                    
                                        scope="col"
                                        class="px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                         style={{color:"#000000",textShadow:"1px 0 #000000",width: "22%",}}
                                    >
                                        Out-Tray
                </th>
                                    <th
                                        scope="col"
                                        class="px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                         style={{color:"#000000",textShadow:"1px 0 #000000",width: "35%"}}
                                    >
                                        Comment
                </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                {allReview}
                            </tbody>
                        </table>
                    </>

                ): null}

        </div>




        {this.state.showModal ? (
                <>
            <div className="w-100 overlay justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div style={{width:'80%'}} className="relative w-60 mx-auto">
                {/*content*/}
                <div className="mb-5 flex">
                  <div className="flex-1" />
                  <div
                    className="cursor-pointer"
                    style={{
                      backgroundColor: "#F74F11",
                      height: 60,
                      width: 60,
                    }}
                    onClick={() =>
                      this.setState({
                        showModal: false,
                      })
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="#fff"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                </div>
                <div className="flex-between w-100 bg-white py-4 px-4 shadow-sm overflow-hidden cursor-pointer">
                  <p className="flex-1 mt-2 fz-17 font-medium text-color">
                    <span className="font-avenir-black">
                      Ministry of Finance{" "}
                    </span>
                    <span>(2021 Detailed Budget)</span>
                  </p>
                  <div>
                    <button
                      onClick={() => this.handleExportCsv("budgetItem")}
                      type="submit"
                      className="m-r-10 h-10 inline-flex py-2 pr-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                    >
                      <img
                        src="/upload-excel.png"
                        style={{ width: 18, height: 18 }}
                        className="mx-3"
                      />
                      Excel Export
                    </button>
                    <button
                      onClick={() => this.handlePrint("budgetItem")}
                      type="submit"
                      className="mr-3 h-10 inline-flex py-2 pr-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                    >
                      <img
                        src="/upload-excel.png"
                        style={{ width: 18, height: 18 }}
                        className="mx-3"
                      />
                      Print Document
                    </button>
                  </div>
                </div>
                <div class="flex flex-col">
                  <table
                    id="budgetItem"
                    class="min-w-full shadow overflow-hidden"
                    style={{ width: "100%" }}
                  >
                    <thead style={{backgroundColor:"grey", color:'white'}} class="bg-gray-table-header">
                      <tr>
                        <th
                          scope="col"
                          class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                          style={{ width: "20%" }}
                        >
                          Budget Items (’000mn)
                        </th>
                        <th
                          scope="col"
                          class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                          style={{ width: "8%" }}
                        >
                          Currency
                        </th>
                        <th
                          scope="col"
                          class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                          style={{ width: "5%" }}
                        >
                          Jan
                        </th>
                        <th
                          scope="col"
                          class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                          style={{ width: "5%" }}
                        >
                          Feb
                        </th>
                        <th
                          scope="col"
                          class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                          style={{ width: "5%" }}
                        >
                          Mar
                        </th>
                        <th
                          scope="col"
                          class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                          style={{ width: "5%" }}
                        >
                          Apr
                        </th>
                        <th
                          scope="col"
                          class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                          style={{ width: "5%" }}
                        >
                          May
                        </th>
                        <th
                          scope="col"
                          class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                          style={{ width: "5%" }}
                        >
                          Jun
                        </th>
                        <th
                          scope="col"
                          class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                          style={{ width: "5%" }}
                        >
                          Jul
                        </th>
                        <th
                          scope="col"
                          class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                          style={{ width: "5%" }}
                        >
                          Aug
                        </th>
                        <th
                          scope="col"
                          class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                          style={{ width: "5%" }}
                        >
                          Sep
                        </th>
                        <th
                          scope="col"
                          class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                          style={{ width: "5%" }}
                        >
                          Oct
                        </th>
                        <th
                          scope="col"
                          class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                          style={{ width: "5%" }}
                        >
                          Nov
                        </th>
                        <th
                          scope="col"
                          class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                          style={{ width: "5%" }}
                        >
                          Dec
                        </th>
                        <th
                          scope="col"
                          class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                          style={{ width: "5%" }}
                        >
                          Total
                        </th>
                        <th
                          scope="col"
                          class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                          style={{ width: "7%" }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                         {allMdaItem}
                         <tr>
                                            <td
                                                colSpan="2"
                                                class="table-border-right p-2 text-sm font-extrabold text-gray-900"
                                            >
                                                Total
                        </td>
                                            <td class="table-border-right p-2 text-right text-sm font-extrabold text-color">
                                                {janVal}
                                            </td>
                                            <td class="table-border-right p-2 text-right text-sm font-extrabold text-color">
                                                {febVal}
                                            </td>
                                            <td class="table-border-right p-2 text-right text-sm font-extrabold text-color">
                                                {marVal}
                                            </td>
                                            <td class="table-border-right p-2 text-right text-sm font-extrabold text-color">
                                                {aprVal}
                                            </td>
                                            <td class="table-border-right p-2 text-right text-sm font-extrabold text-color">
                                                {mayVal}
                                            </td>
                                            <td class="table-border-right p-2 text-right text-sm font-extrabold text-color">
                                                {junVal}
                                            </td>
                                            <td class="table-border-right p-2 text-right text-sm font-extrabold text-color">
                                                {julVal}
                                            </td>
                                            <td class="table-border-right p-2 text-right text-sm font-extrabold text-color">
                                                {augVal}
                                            </td>
                                            <td class="table-border-right p-2 text-right text-sm font-extrabold text-color">
                                                {sepVal}
                                            </td>
                                            <td class="table-border-right p-2 text-right text-sm font-extrabold text-color">
                                                {octVal}
                                            </td>
                                            <td class="table-border-right p-2 text-right text-sm font-extrabold text-color">
                                                {novVal}
                                            </td>
                                            <td class="table-border-right p-2 text-right text-sm font-extrabold text-color">
                                                {decVal}
                                            </td>
                                            <td class="table-border-right p-2 text-right text-sm font-extrabold text-color">
                                                {alltotal}
                                            </td>
                                            <td class="table-border-right p-2 text-right text-sm font-extrabold text-color"></td>
                                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
            ) : null}

            {this.state.governorCanAprove ? (
                <>
                    <div className="mt-10">

                        <div className="flex">
                            <div className="bg-white shadow-sm w-6/12">

                                <div
                                    className="flex py-4 px-4"
                                    style={{ borderBottom: "1px solid #D5D5D6" }}
                                >
                                    <div className="flex-1">
                                        <div class="mt-1 relative">
                                            <select name="status"
                                                id="govStatus"
                                                name="status"
                                                aria-haspopup="listbox"
                                                aria-expanded="true"
                                                aria-labelledby="listbox-label"
                                                class="relative border shadow-sm w-28 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm"
                                            >

                                                <option value="1">Approve</option>
                                                <option value="2">Decline</option>



                                            </select>


                                        </div>
                                    </div>

                                    <div className="flex">
                                        <p className="text-color font-medium text-sm mr-3 text-right">
                                            <span className="font-avenir-black">{this.state.fullName}</span>
                                            <br />
                                            <span className="font-normal text-xs ml-16">{this.state.role}</span>
                                        </p>
                                        <img class="h-8 w-8 rounded-full" src={this.props.profilePicture} alt="" />
                                    </div>
                                </div>
                                <textarea

                                    name="commenttext"
                                    id="commenttext"
                                    rows=""
                                    class="shadow-sm block w-full sm:text-sm"
                                    style={{ height: "13rem", border: "none" }}
                                ></textarea>
                            </div>
                        </div>
                        <button
                            onClick={()=>this.submitGovernorApproval()}
                            type="submit"

                            class="mr-5 w-6/12 mt-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white primary-bg-green focus:outline-none"
                        >
                            Submit Budget
                          </button>
                    </div>

                </>
            )


                : null}

      </React.Fragment>
    );
  }
}
