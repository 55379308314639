import React, { Component } from "react";
import budgetCodificationSchema from "../../Validation/BudgetCodificationValidation";
// import ValidationMessageComponent from "./ValidationMessage";
// import { store } from 'react-notifications-component';
import notification from "../../utility/notification";
import { Table,Modal, Input, InputNumber,message, Popconfirm, Form, Typography,Menu,Dropdown,Button,Checkbox } from 'antd';
import { nullcheck, notify } from '../../Utils/Helper'
import { Preloader } from "../../Utils/Preloader";
import Select from "react-select";
import axios from "axios";
import { AiOutlineFileExcel,AiOutlinePrinter } from 'react-icons/ai';

// import { Input } from 'antd';

// import { EconomicCode } from "../Pages/EconomicCode";

//const BASE_API_URL = "http://localhost:5002/api/v1";
// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';



export default class BudgetCodificationC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: "",
            allBudgetCodification: [],
            geoCode: "",
            geoCodeData: [],
            economicCode: "",
            functionalCode: "",
            programmeCode: "",
            fundsCode: "",
            economicSegment: "",
            economicSegmentData: [],
            functionalSegment: "",
            functionalSegmentData: [],
            subFunction: "",
            subFunctionData: [],
            functionalItem: "",
            functionalItemData: [],

            programmeData: [],
            programme: "",
            programmeSegment: "",
            programmeSegmentData: "",
            project: "",
            projectData: [],
            fundsSegment: "",
            economicTypeData: [],
            economicType: "",
            objective: "",
            objectiveData: null,

            functionalType: "",
            programmeType: "",
            fundsType: "",
            economicSubType: "",
            economicSubTypeData: [],

            fundsSubType: "",
            economicSubSubType: "",
            economicSubSubTypeData: [],
            fundSegmentData: [],
            fundSegment: "",
            fundTypeData: [],
            fundType: "",
            fundItem: "",
            fundItemData: [],

            programmeActivities: "",
            open: true,

            localGovernmentCouncilData: [],
            localGovernmentCouncil: "",
            activityData: null,
            activity: "",
            wardCouncil: "",

            allBudgetInformationData: [],
            budgetInformation: "",
            loading: false,
            deleteId: "",
            edit: false,
            editId: "",
            submenu: false,
            showModal: false,
            total: 0,
            loader: false,
            message: "",
            showNotification: false,
            notificationSuccess: false,
            notificationMessage: "Successfully saved!",
            search: ''
        };
    }
    handleClear = () => {
        this.setState({
            geoCode: "",

            economicSubSubTypeData: [],
            economicSubSubType: "",
            economicSubTypeData: [],
            economicSubType: "",
            economicTypeData: [],
            economicType: "",
            subFunction: "",
            subFunctionData: [],
            functionalItem: "",
            functionalItemData: [],
            project: "",
            projectData: [],
            objective: "",
            objectiveData: [],
            activity: "",
            activityData: [],
            fundType: "",
            fundTypeData: [],
            fundItem: "",
            fundItemData: [],
            getMdaData:[]
            
        });
    };
    handleClose = () => {
        this.setState({
            geoCode: "",

            economicSubSubTypeData: [],
            economicSubSubType: "",
            economicSubTypeData: [],
            economicSubType: "",
            economicTypeData: [],
            economicType: "",
            subFunction: "",
            subFunctionData: [],
            functionalItem: "",
            functionalItemData: [],
            project: "",
            projectData: [],
            objective: "",
            objectiveData: [],
            activity: "",
            activityData: [],
            fundType: "",
            fundTypeData: [],
            fundItem: "",
            fundItemData: [],
            getMdaData: [],
            economicCode: "",
            functionalCode: "",
            programmeCode: "",
            fundsCode: "",
            showModal: false

        });
    };
    componentDidMount() {

       
        this.getBudgetCodification();
        this.getBudgetInformation();
        this.getEconomicSegment();
        this.getfunctionalSegment();
        this.getProgrammes();
        this.getFundSegment();
        this.getGeoCodes();
        this.getMdas();
    }
    handleChange = (ev) => {
        this.setState({
            [ev.target.name]: ev.target.value,
        });
    };

    handleSelectedEconomicSegmentChange = (selectedEconomicSegment) => {
        this.setState(
            { selectedEconomicSegment, economicSegment: selectedEconomicSegment },
            () => console.log(`Option selected:`, selectedEconomicSegment)
        );
        this.getEconomicTypeBySegment(selectedEconomicSegment.value);
    };
    handleSelectedEconomicTypeChange = (selectedEconomicType) => {
        this.setState(
            { selectedEconomicType, economicType: selectedEconomicType },
            () => console.log(`Option selected:`, selectedEconomicType)
        );
        this.getEconomicSubTypeByEconomicType(selectedEconomicType.value);
    };
    handleSelectedEconomicSubChange = (selectedEconomicSub) => {
        this.setState(
            { selectedEconomicSub, economicSubType: selectedEconomicSub },
            () => console.log(`Option selected:`, selectedEconomicSub)
        );
        this.getEconomicSubSubType(selectedEconomicSub.value);
    };
    handleSelectedEconomicSubSubChange = (selectedEconomicSubSub) => {
        this.setState(
            { selectedEconomicSubSub, economicSubSubType: selectedEconomicSubSub },
            () => console.log(`Option selected:`, selectedEconomicSubSub)
        );
    };
    handleSelectedGeoCodeChange = (selectedGeCode) => {
        this.setState({ selectedGeCode, geoCode: selectedGeCode }, () =>
            console.log(`Option selected:`, selectedGeCode)
        );
    };

    handleSelectedFunctionalSegmentChange = (selectedFunctionalSegment) => {
        this.setState(
            {
                selectedFunctionalSegment,
                functionalSegment: selectedFunctionalSegment,
            },
            () => console.log(`Option selected:`, selectedFunctionalSegment)
        );
        let id = selectedFunctionalSegment.value;
        this.getSubFunction(id);
    };
    handleSelectedSubFunctionChange = (selectedSubFunction) => {
        this.setState(
            { selectedSubFunction, subFunction: selectedSubFunction },
            () => console.log(`Option selected:`, selectedSubFunction)
        );

        this.getFunctionalItemsBySubFunctions(selectedSubFunction.value);
    };

    handleSelectedProgrammeChange = (selectedProgramme) => {
        var id = selectedProgramme.value;
        this.setState(
            { selectedProgramme, programmeSegment: selectedProgramme },
            () => console.log(`Option selected:`, selectedProgramme)
        );
        this.getProjects(id);
    };
    handleSelectedProjectChange = (selectedProject) => {
        var id = selectedProject.value;
        this.setState({ selectedProject, project: selectedProject }, () =>
            console.log(`Option selected:`, selectedProject)
        );
        this.getObjectives(id);
    };
    handleSelectedObjectiveChange = (selectedObjective) => {
        var id = selectedObjective.value;
        this.setState({ selectedObjective, objective: selectedObjective }, () =>
            console.log(`Option selected:`, selectedObjective)
        );
        this.getActivities(id);
    };
    handleSelectedActivityChange = (selectedActivity) => {
        //var id = selectedProject.value;
        this.setState({ selectedActivity, activity: selectedActivity }, () =>
            console.log(`Option selected:`, selectedActivity)
        );
    };

    handleSelectedFunctionalSegmentChange = (selectedFunctionalSegment) => {
        this.setState(
            {
                selectedFunctionalSegment,
                functionalSegment: selectedFunctionalSegment,
            },
            () => console.log(`Option selected:`, selectedFunctionalSegment)
        );
        let id = selectedFunctionalSegment.value;
        this.getSubFunction(id);
    };
    //handleSelectedSubFunctionChange = (selectedSubFunction) => {
    //  this.setState(
    //      { selectedSubFunction, subFunction: selectedSubFunction },
    //      () => console.log(`Option selected:`, selectedSubFunction)
    //  );
    //};

    handleSelectedFundSegmentChange = (selectedFundSegment) => {
        this.setState(
            { selectedFundSegment, fundSegment: selectedFundSegment },
            () => console.log(`Option selected:`, selectedFundSegment)
        );
        var id = selectedFundSegment.value;
        this.getFundTypes(id);
    };
    handleSelectedFundTypeChange = (selectedFundType) => {
        this.setState({ selectedFundType, fundType: selectedFundType }, () =>
            console.log(`Option selected:`, selectedFundType)
        );
        var id = selectedFundType.value;
        this.getFundSubTypes(id);
    };
    handleSelectedSubFundTypeChange = (selectedSubFund) => {
        this.setState({ selectedSubFund, fundItem: selectedSubFund }, () =>
            console.log(`Option selected:`, selectedSubFund)
        );
    };
    handleSubmit = () => {
        let geoCodeId = this.state.geoCode == "" ? "" : this.state.geoCode.value;
        let economicSubSubTypeId =
            this.state.economicSubSubType == ""
                ? ""
                : this.state.economicSubSubType.value;
        let functionalItemId =
            this.state.functionalItem == "" ? "" : this.state.functionalItem.value;
        let activityId = this.state.activity == "" ? "" : this.state.activity.value;
        let fundItemId = this.state.fundItem == "" ? "" : this.state.fundItem.value;
        let departmentBudgetId = this.state.budgetInformation.departmentBudgetId;
        //alert(this.props.employeeId)
        let enteredBy = this?.props?.employeeId?.toString();

        let data = {
            geoCodeId,
            economicSubSubTypeId,
            functionalItemId,
            activityId,
            fundItemId,
            departmentBudgetId,
            enteredBy
        };

        this.setState({
            loader: true,
            message: "",
            showModal: false,
        });
        budgetCodificationSchema
            .validate(data)
            .then((message) => {
                axios
                    .post(`${BASE_API_URL}/budgetcodification`, { ...data })
                    .then((response) => {
                        this.setState({
                            loader: false,
                            message: "",
                            showModal: false,
                            editId: "",
                            edit:false
                        });
                        this.getBudgetCodification();
                        this.getBudgetInformation();
                        this.getEconomicSegment();
                        this.getfunctionalSegment();
                        this.getProgrammes();
                        this.getFundSegment();
                        this.getGeoCodes();
                    })
                    .catch((err) => {
                        this.setState({
                            loader: false,
                            message:  nullcheck(err.response) == "" ? "server error" : err.response.data,
                            showModal: true,
                        });

                        notification({
                            title: 'ERROR MESSAGE',
                            message: `
                            ${nullcheck(err.response) == "" ? "Something went wrong please check network" : err.response.data}`,
                            type: 'danger',
                            // container:'top-center'
                            // animationIn: ['animate__animated', 'animate__slideIn'],
                          });
                        
                    });
                // notify(store, 'success', 'budget codification updated successfully');
            })
            .catch((err) => {
                this.setState({
                    loader: false,
                    message: err.errors,
                });
            });

      
    };

    handleChange = (ev) => {
        this.setState({ [ev.target.name]: ev.target.value });
    };

    handleDelete = (id) => {
        // this.setState({ deleteId: id }, () =>
        //   this.props.removeAction(DataTypes.DELETE_BUDGET_PERIOD, { id })
        // );
    };

    handleEdit = (data) => {
        this.setState({
            localGovernmentCouncil: data.localGovernmentCouncil,
            wardCouncil: data.wardCouncil,
            geoCode: data.geoCode,
            edit: true,
            editId: data.id,
        });
    };

    handleEditSubmit = () => {
        let geoCodeId = this.state.geoCode == "" ? "" : this.state.geoCode.value;
        let economicSubSubTypeId =
            this.state.economicSubSubType == ""
                ? ""
                : this.state.economicSubSubType.value;
        let functionalItemId =
            this.state.functionalItem == "" ? "" : this.state.functionalItem.value;
        let activityId = this.state.activity == "" ? "" : this.state.activity.value;
        let fundItemId = this.state.fundItem == "" ? "" : this.state.fundItem.value;
        let departmentBudgetId = this.state.budgetInformation.departmentBudgetId;

        let data = {
            geoCodeId,
            economicSubSubTypeId,
            functionalItemId,
            activityId,
            fundItemId,
            departmentBudgetId,
        };
        console.log(departmentBudgetId);
        console.log(this.state.budgetInformation);

        // this.setState({ loading: true }, () =>
        //   this.props.updateAction(DataTypes.PUT_BUDGET_PERIOD, { data })
        // );
    };

    getEconomicCode = (departmentBudgetId) => {
        console.log(departmentBudgetId);
        var getBudgetCodification = this.getBudgetCodificationByDepartmentId(
            departmentBudgetId
        );
        console.log(getBudgetCodification);
        let code = "";
        if (getBudgetCodification.length == 0) return code;
        if (typeof getBudgetCodification[0] == "undefined") return code;
        let economicSubSubType = getBudgetCodification[0].economicSubSubType;

        if (typeof economicSubSubType == "undefined") return code;
        let economicSubType = economicSubSubType.economicSubType;
        let economicType = economicSubType.economicType;
        let economicSegment = economicType.economicSegment;

        code += `${economicSegment.code}${economicType.code}${economicSubType.code}${economicSubSubType.code}`;
        return code;
    };
    getFunctionalCode = (departmentBudgetId) => {
        var getBudgetCodification = this.getBudgetCodificationByDepartmentId(
            departmentBudgetId
        );
        let code = "";
        if (getBudgetCodification.length == 0) return code;
        if (typeof getBudgetCodification[0] == "undefined") return code;
        let functionalItem = getBudgetCodification[0].functionalItem;
        if (typeof getBudgetCodification[0] == "undefined") return code;
        let subFunction = functionalItem.subFunction;
        let functionalSegment = subFunction.functionalSegment;
        code += `${functionalSegment.code}${subFunction.code}${functionalItem.code}`;
        return code;
    };
    getProgrammeCode = (departmentBudgetId) => {
        var getBudgetCodification = this.getBudgetCodificationByDepartmentId(
            departmentBudgetId
        );
        let code = "";
        if (getBudgetCodification.length == 0) return code;
        if (typeof getBudgetCodification[0] == "undefined") return code;
        let activity = getBudgetCodification[0].activity;
        if (typeof activity == "undefined") return code;
        let objective = activity.objective;
        let project = objective.project;
        let programme = project.programme;
        code += `${programme.programmeCode}${project.projectCode}${objective.objectiveCode}${activity.activityCode}`;
        return code;
    };
    getFundsCode = (departmentBudgetId) => {
        var getBudgetCodification = this.getBudgetCodificationByDepartmentId(
            departmentBudgetId
        );
        let code = "";
        if (getBudgetCodification.length == 0) return code;
        if (typeof getBudgetCodification[0] == "undefined") return code;
        let fundItem = getBudgetCodification[0].fundItem;
        if (typeof fundItem == "undefined") return code;
        let fundType = fundItem.fundType;
        let fundSegment = fundType.fundSegment;

        code += `${fundSegment.fundSegmentCode}${fundType.fundTypeCode}${fundItem.fundCode}`;
        return code;
    };
    getGeoCode = (departmentBudgetId) => {
        var getBudgetCodification = this.getBudgetCodificationByDepartmentId(
            departmentBudgetId
        );
        let code = "";
        if (getBudgetCodification.length == 0) return code;
        if (typeof getBudgetCodification[0] == "undefined") return code;
        let geoCode = getBudgetCodification[0].geoCode;
        if (typeof geoCode == "undefined") return code;

        code += `${geoCode.geoCodeName}`;
        return code;
    };

    setBudgetInformation = (departmentBudgetId) => {
        let getBudgetCodification = this.getBudgetCodificationByDepartmentId(
            departmentBudgetId
        );

        if (
            getBudgetCodification.length > 0 &&
            typeof getBudgetCodification[0] != "undefined"
        ) {
            let economicSubSubType = getBudgetCodification[0].economicSubSubType;

            let functionalItem = getBudgetCodification[0].functionalItem;
            let subFunction = functionalItem.subFunction;
            let functionalSegment = subFunction.functionalSegment;

            let fundItem = getBudgetCodification[0].fundItem;
            let fundType = fundItem.fundType;
            let fundSegment = fundType.fundSegment;

            let activity = getBudgetCodification[0].activity;
            let objective = activity.objective;
            let project = objective.project;
            let programme = project.programme;

            let geoCode = getBudgetCodification[0].geoCode;

            let economicSubType = economicSubSubType.economicSubType;
            let economicType = economicSubType.economicType;
            let economicSegment = economicType.economicSegment;

            let selectedEconomicType = {
                value: economicType.id,
                label: economicType.economicTypeName,
            };
            let selectedEconomicSegment = {
                value: economicSegment.id,
                label: economicSegment.economicSegmentName,
            };
            let selectedEconomicSubType = {
                value: economicSubType.id,
                label: economicSubType.economicSubTypeName,
            };
            let selectedEconomicSubSubType = {
                value: economicSubSubType.id,
                label: economicSubSubType.economicSubSubTypeName,
            };

            let selectedfundType = {
                value: fundType.id,
                label: fundType.fundTypeName,
            };
            let selectedfundSegment = {
                value: fundSegment.id,
                label: fundSegment.fundSegmentName,
            };
            let selectedfundItem = { value: fundItem.id, label: fundItem.fundName };

            let selectedfunctionalSegment = {
                value: functionalSegment.id,
                label: functionalSegment.functionalSegmentName,
            };
            let selectedsubFunction = {
                value: subFunction.id,
                label: subFunction.subFunctionName,
            };
            let selectedfunctionalItem = {
                value: functionalItem.id,
                label: functionalItem.functionalItemName,
            };

            let selectedactivity = {
                value: activity.id,
                label: activity.activityName,
            };
            let selectedobjective = {
                value: objective.id,
                label: objective.objectiveName,
            };
            let selectedproject = {
                value: project.id,
                label: project.projectDescription,
            };
            let selectedprogramme = {
                value: programme.id,
                label: programme.programmeName,
            };
            let selectedGeoCode = { value: geoCode.id, label: geoCode.geoCodeName };

            this.setState({
                economicSegment: selectedEconomicSegment,
                economicType: selectedEconomicType,
                economicSubType: selectedEconomicSubType,
                economicSubSubType: selectedEconomicSubSubType,

                fundType: selectedfundType,
                fundSegment: selectedfundSegment,
                fundItem: selectedfundItem,

                functionalSegment: selectedfunctionalSegment,
                subFunction: selectedsubFunction,
                functionalItem: selectedfunctionalItem,
                activity: selectedactivity,
                objective: selectedobjective,
                project: selectedproject,
                programmeSegment: selectedprogramme,
                economicCode: this.getEconomicCode(departmentBudgetId),
                functionalCode: this.getFunctionalCode(departmentBudgetId),
                programmeCode: this.getProgrammeCode(departmentBudgetId),
                fundsCode: this.getFundsCode(departmentBudgetId),
                geoCode: selectedGeoCode,
                editId: getBudgetCodification.id,
                edit: false,
            });
        }
    };

    getBudgetCodification = () => {
        this.setState({
            loader: true,
        });
        axios
            .get(`${BASE_API_URL}/budgetcodification`)
            .then((response) => {
               
                this.setState({
                    allBudgetCodification: response.data,
                    loader: false,
                });
            })
            .catch((err) => {
                this.setState({
                    message: nullcheck(err.response) == "" ? "server error" : err.response.data,
                    loader: false,
                });
            });
    };
    getBudgetInformation = () => {
        this.setState({
            loader: true,
        });
        axios
            .get(`${BASE_API_URL}/budgetinformation/getbudgetinformation`)
            .then((response) => {
                
                this.setState({
                    allBudgetInformationData: response.data,
                    loader: false,
                });
            })
            .catch((err) => {
                this.setState({
                    message: nullcheck(err.response) == "" ? "server error" : err.response.data,
                    loader: false,
                });
            });
    };
    getBudgetInformationByMda() {
        let mdaId = document.getElementById('mda').value;
       
        if (mdaId != "") {
            this.setState({
                loader: true,
            });
            axios
                .get(`${BASE_API_URL}/budgetinformation/getbudgetinformationmda?mdaId=${mdaId}`)
                .then((response) => {

                    this.setState({
                        allBudgetInformationData: response.data,
                        loader: false,
                    });
                })
                .catch((err) => {
                    this.setState({
                        message:nullcheck(err.response) == "" ? "server error" : err.response.data,
                        loader: false,
                    });
                });
        }
    
    }
    getEconomicSegment = () => {
        axios
            .get(`${BASE_API_URL}/economicsegment/geteconomicsegment`)
            .then((response) => {
                let economicSegment =
                    response.data.length === 0 ? "" : response.data[0];

                this.setState({
                    economicSegmentData: response.data,
                    economicSegment: economicSegment,
                });
            })
            .catch((err) => { });
    };

    getEconomicTypeBySegment = (economicSegmentId) => {
        axios
            .get(
                `${BASE_API_URL}/economictype/geteconomictypebyeconomicsegmentId?economicSegmentId=${economicSegmentId}`
            )
            .then((response) => {
                let firstEconomicType =
                    response.data.length === 0 ? "" : response.data[0];
                
                this.setState({
                    economicTypeData: response.data,
                    economicType: firstEconomicType,
                });
            })
            .catch((err) => {
               
            });
    };
    getEconomicSubTypeByEconomicType = (economicTypeId) => {
        axios
            .get(
                `${BASE_API_URL}/economicSubType/GetEconomicSubTypeByEconomicTypeId?economicTypeId=${economicTypeId}`
            )
            .then((response) => {
                let firstEconomicSubType =
                    response.data.length === 0 ? "" : response.data[0];
                
                this.setState({
                    economicSubTypeData: response.data,
                    economicSubType: firstEconomicSubType,
                });
            })
            .catch((err) => {
                
            });
    };
    getEconomicSubSubType = (economicSubTypeId) => {
        axios
            .get(
                `${BASE_API_URL}/economicsubsubtype/GetEconomicSubSubTypeByEconomicSubType?economicSubTypeId=${economicSubTypeId}`
            )
            .then((response) => {
                let economicSubSubType =
                    response.data.length === 0 ? "" : response.data[0];
                this.setState({
                    economicSubSubTypeData: response.data,
                    economicSubSubType: economicSubSubType,
                });
            })
            .catch((err) => { });
    };
    getfunctionalSegment = () => {
        axios
            .get(`${BASE_API_URL}/functionalSegment/getFunctionalSegment`)
            .then((response) => {
                let functionalSegment =
                    response.data.length === 0 ? "" : response.data[0];
               
                this.setState({
                    functionalSegmentData: response.data,
                    functionalSegment: functionalSegment,
                });
            })
            .catch((err) => {
                
            });
    };
    getSubFunction = (functionSegmentId) => {
        axios
            .get(
                `${BASE_API_URL}/subFunction/GetSubFunctionByFunctionalSegment?functionalSegmentID=${functionSegmentId}`
            )
            .then((response) => {
                this.setState({
                    subFunctionData: response.data,
                    subFunction: response.data.length === 0 ? "" : response.data[0],
                });
            })
            .catch((err) => {
               
            });
    };
    getFunctionalItemsBySubFunctions = (SubFunctionID) => {
        axios
            .get(
                `${BASE_API_URL}/functionalitem/GetFunctionalItemBySubEconomicId?subEconomicId=${SubFunctionID}`
            )
            .then((response) => {
               
                this.setState({
                    functionalItemData: response.data,
                    functionalItem: response.data.length === 0 ? "" : response.data[0],
                });
            })
            .catch((err) => {
               
            });
    };

    getProgrammes = () => {
        axios
            .get(`${BASE_API_URL}/programme/getprogram`)
            .then((response) => {
                
                this.setState({
                    programmeSegmentData: response.data,
                });
            })
            .catch((err) => {
               
            });
    };
    getProjects = (programmeID) => {
        axios
            .get(
                `${BASE_API_URL}/project/getprojectbyprogrammeid?programmeID=${programmeID}`
            )
            .then((response) => {
               

                this.setState({
                    projectData: response.data,
                    objectiveData: null,
                    project: response.data.length === 0 ? "" : response.data[0],
                });
            })
            .catch((err) => {
                
            });
    };
    getObjectives = (projectId) => {
        axios
            .get(
                `${BASE_API_URL}/objective/getobjectivesbyprojectid?projectid=${projectId}`
            )
            .then((response) => {
                var firstElement = "";
                if (response.data.length !== 0) {
                    firstElement = response.data.length === 0 ? "" : response.data[0];
                }

                this.setState({
                    objectiveData: response.data,
                    objective: firstElement,
                });
            })
            .catch((err) => {
                
            });
    };
    getActivities = (objectiveId) => {
        axios
            .get(
                `${BASE_API_URL}/activity/GetActivityByObjectiveId?objectiveId=${objectiveId}`
            )
            .then((response) => {
                
                this.setState({
                    activityData: response.data,
                    activity: response.data.length === 0 ? "" : response.data[0],
                });
            })
            .catch((err) => {
               
            });
    };

    getfunctionalSegment = () => {
        axios
            .get(`${BASE_API_URL}/functionalSegment/getfunctionalSegment`)
            .then((response) => {
                
                this.setState({
                    functionalSegmentData: response.data,
                });
            })
            .catch((err) => {
                
            });
    };
    getSubFunction = (functionSegmentId) => {
        axios
            .get(
                `${BASE_API_URL}/subFunction/GetSubFunctionByFunctionalSegment?functionalSegmentID=${functionSegmentId}`
            )
            .then((response) => {
                this.setState({
                    subFunctionData: response.data,
                    subFunction: response.data.length === 0 ? "" : response.data[0],
                });
            })
            .catch((err) => {
               
            });
    };
    getFundSegment = () => {
        axios
            .get(`${BASE_API_URL}/fundsegment/getfundsegment`)
            .then((response) => {
               
                this.setState({
                    fundSegmentData: response.data,
                    fundSegment: response.data.length === 0 ? "" : response.data[0],
                });
            })
            .catch((err) => {
               
            });
    };
    getFundTypes = (segmentId) => {
        axios
            .get(
                `${BASE_API_URL}/fundtype/getfundtypebyfundsegmentid?fundSegmentId=${segmentId}`
            )
            .then((response) => {
                this.setState({
                    fundTypeData: response.data,
                    fundType: response.data.length === 0 ? "" : response.data[0],
                });
            })
            .catch((err) => {
               
            });
    };
    getFundSubTypes = (fundTypeId) => {
        axios
            .get(
                `${BASE_API_URL}/FundItem/GetFundItemByFundType?FundTypeId=${fundTypeId}`
            )
            .then((response) => {
                this.setState({
                    fundItemData: response.data,
                    fundItem: response.data.length === 0 ? "" : response.data[0],
                });
            })
            .catch((err) => {
               
            });
    };
    getGeoCodes = () => {
        axios
            .get(`${BASE_API_URL}/geocode/getgeocode`)
            .then((response) => {
                this.setState({
                    geoCodeData: response.data,
                    geoCode: response.data.length === 0 ? "" : response.data[0],
                });
            })
            .catch((err) => {
               
            });
    };

    getMdas () {
        this.setState({
            loader: true,
            message: "",

        });
        axios
            .get(`${BASE_API_URL}/mda`)
            .then((response) => {
                
                console.log("--mdas--")
                console.log(response.data)
                this.setState({
                    getMdaData: response.data, 
                    loader: false,
                    message: "",
                });
            })
            .catch((err) => {
                this.setState({
                    loader: false,
                    message: nullcheck(err.response) == "" ? "server error" : err.response.data,

                });
            });
    }
    
    getBudgetCodificationByDepartmentId(departmentBudgetId) {
        var allBudgetCodifications = this.state.allBudgetCodification;
        let budgetCodification = [];
        for (let allBudgetCodification of allBudgetCodifications) {
            if (allBudgetCodification.departmentBudgetId === departmentBudgetId)
                budgetCodification.push(allBudgetCodification);
        }

        return budgetCodification;
    }
    handleExportCsv = (table_id, separator = ",") => {
        // Select rows from table_id
        var rows = document.querySelectorAll("table#" + table_id + " tr");
        // Construct csv
        var csv = [];
        for (var i = 0; i < rows.length; i++) {
            var row = [],
                cols = rows[i].querySelectorAll("td, th");
            for (var j = 0; j < cols.length; j++) {
                // Clean innertext to remove multiple spaces and jumpline (break csv)
                var data = cols[j].innerText
                    .replace(/(\r\n|\n|\r)/gm, "")
                    .replace(/(\s\s)/gm, " ");
                // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
                data = data.replace(/"/g, '""');
                // Push escaped string
                row.push('"' + data + '"');
            }
            csv.push(row.join(separator));
        }
        var csv_string = csv.join("\n");
        // Download it
        var filename =
            "export_" + table_id + "_" + new Date().toLocaleDateString() + ".csv";
        var link = document.createElement("a");
        link.style.display = "none";
        link.setAttribute("target", "_blank");
        link.setAttribute(
            "href",
            "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
        );
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    handlePrint = (table_id) => {
        var divToPrint = document.getElementById(table_id);
        let newWin = window.open("");
        newWin.document.write(divToPrint.outerHTML);
        newWin.print();
        newWin.close();
    };

    render() {
        let mdas = typeof this.state.getMdaData == "undefined" ? [] : this.state.getMdaData;
       
        console.log(mdas)
        let allMdaOption = mdas.map((mda) => {
            return (
                <option value={mda.id}>{mda.name}</option>
                )
        })

        var total = 0;
       
        let searchBudgetInformations = this.state.allBudgetInformationData.filter((newBudgetInformation) => {
          
            return newBudgetInformation.categoryName.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
        })

        var allBudgetInformations = searchBudgetInformations.map((budgetInformation) => {
            total += budgetInformation.total;
            return (
                <tr>
                    <td className="table-border-right p-2 text-sm font-medium text-gray-900">
                        {budgetInformation.categoryName}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.total}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {this.getEconomicCode(budgetInformation.departmentBudgetId)}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {this.getFunctionalCode(budgetInformation.departmentBudgetId)}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {this.getProgrammeCode(budgetInformation.departmentBudgetId)}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {this.getFundsCode(budgetInformation.departmentBudgetId)}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {this.getGeoCode(budgetInformation.departmentBudgetId)}
                    </td>

                    <td
                        colSpan="3"
                        className="table-border-right p-2 text-sm text-color"
                    ></td>
                    <td className="table-border-right text-right p-2 text-sm text-color">
                        <button
                            type="submit"
                            className="mr-5 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                            onClick={(e) => {
                                e.stopPropagation();
                                this.setBudgetInformation(budgetInformation.departmentBudgetId);
                                this.setState({
                                    showModal: !this.state.showModal,
                                    budgetInformation: budgetInformation,
                                });
                            }}
                        >
                            Assign Code
            </button>
                    </td>
                </tr>
            );
        });

        return (
            <React.Fragment>
                {this.state.loader === true ? <Preloader /> : ""}
                <div class="m-b-5 w-100 relative z-20 pb-1">

                    <select className='p-5 border' id="mda" style={{lineHeight:"200px", fontSize:'18px', padding:'5px'}} onChange={() => this.getBudgetInformationByMda() } class="relative pl-3 pr-10 py-2 text-left cursor-default focus:outline-none text-sm">
                        <option> Select MDA</option>
                        {allMdaOption}
                    </select>
                    {this.state.submenu && (
                        <div
                            class="absolute z-30 mt-1 shadow-lg"
                        // style={{
                        //   backgroundColor: "#F3F8F3",
                        //   borderColor: "#B2D4B2",
                        // }}
                        >
                            <ul
                                tabindex="-1"
                                role="listbox"
                                aria-labelledby="listbox-label"
                                aria-activedescendant="listbox-item-3"
                                class="max-h-60 rounded-md py-1 text-sm overflow-auto focus:outline-none text-sm"
                            >
                                <li
                                    id="listbox-option-0"
                                    role="option"
                                    class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 focus:outline-none "
                                    onClick={() =>
                                        this.setState({ submenu: !this.state.submenu })
                                    }
                                >
                                    <span class="font-normal block truncate">Approve</span>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
                <div
                    className="w-100 flex-between flex-align bg-white py-4 px-4 shadow-sm overflow-hidden cursor-pointer"
                    onClick={() => this.setState({ open: !this.state.open })}
                >
                    <p className="flex-1 bold  mt-2 text-sm font-medium font-avenir-black text-color">
                        Budget Period
            {/* <img src="/calendar.png" className="inline ml-3" /> */}
                    </p>
                    <div className='flex-between '
                        style={{width:"90%" }}
                    
                    >
                        <button 
                        style={{backgroundColor:"#1d811c", color:"white",minWidth:'90px', padding:"10px" }}
                            onClick={() => this.handleExportCsv("budgeCodificationTable")}
                            type="submit"
                            className="m-r-5 bold border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                        >
                            {/* <img
                                src="/upload-excel.png"
                                style={{ width: 18, height: 18 }}
                                className="mx-3"
                            /> */}
                            <div className='flex-align'>
                                <AiOutlineFileExcel style={{fontSize:"20px", marginRight:'5px'}}/>
                            <p className="bold">
                            Excel Export
                            </p>
                            </div>
            </button>
                        <button
                        style={{backgroundColor:"#1d811c", color:"white", padding:'10px'}}

                            onClick={() => this.handlePrint("budgeCodificationTable")}
                            type="submit"
                            className="m-r-5 bold-text border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                        >
                            {/* <img
                                src="/upload-excel.png"
                                style={{ width: 18, height: 18 }}
                                className="mx-3"
                            /> */}
                            <div className='flex-align'>
                                <AiOutlinePrinter style={{fontSize:"20px", marginRight:'5px'}}/>
                            <p className="bold">
                            Print Document
                            </p>
                            </div>
            
            </button>
                        <input
                            placeholder="Search"
                            className="border border-solid px-2 h-10 p-5 "
                            style={{
                                backgroundColor: "transparent",
                                borderColor: "#707070",
                                width:"50%",

                                minWidth:'90px'
                            }}
                            
                            id="search"
                            name='search'
                            onChange={this.handleChange}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </div>
                </div>
                <div class="flex w-100 flex-col">
                    <div class="shadow w-100 overflow-y-scroll">
                        <table className="min-w-full w-100" id="budgeCodificationTable">
                            <thead class="bg-gray-table-header w-100">
                                <tr>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        Budget Items
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        Proposed Appropriaton
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        Economic Code
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        Functional Code
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        Programme Code
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        Funds Code
                  </th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        Geo Code
                  </th>
                                    <th
                                        scope="col"
                                        colSpan="3"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    ></th>
                                    <th
                                        scope="col"
                                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                    >
                                        Action
                  </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                {allBudgetInformations}

                                <tr>
                                    <td class="table-border-right p-2 text-sm font-extrabold text-gray-900">
                                        Total
                  </td>
                                    <td class="table-border-right p-2 text-sm font-extrabold text-color">
                                        {total}
                                    </td>
                                    <td class="table-border-right p-2 text-sm font-extrabold text-color"></td>
                                    <td class="table-border-right p-2 text-sm font-extrabold text-color"></td>
                                    <td class="table-border-right p-2 text-sm font-extrabold text-color"></td>
                                    <td class="table-border-right p-2 text-sm font-extrabold text-color"></td>
                                    <td class="table-border-right p-2 text-sm font-extrabold text-color"></td>
                                    <td
                                        colSpan="3"
                                        class="table-border-right p-2 text-sm font-extrabold text-color"
                                    ></td>
                                    <td class="table-border-right p-2 text-sm font-extrabold text-color"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <p className="text-sm font-normal text-color p-4"></p>

                {this.state.showModal ? (
                    //console.log()
                    <>
                        <div className=" overlay w-100 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-60 w-7/12 my-6 mx-auto" style={{width:""}}>
                                {/*content*/}
                                <div className="flex bg-card-header py-4 px-4 shadow-sm overflow-hidden cursor-pointer">
                                    <p className="flex-1 mt-2 text-sm font-bold font-avenir-black text-color">
                                        Budget Item Codification
                  </p>
                                    <div
                                        style={{
                                            width: 38,
                                            height: 38,
                                            backgroundColor: "#FFB831",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <img
                                            src="/share-icon.png"
                                            style={{
                                                width: 15,
                                                position: "absolute",
                                                top: 28,
                                                right: 28,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="bg-white py-4 px-4 shadow-sm overflow-hidden">
                                    {this.state.message !== "" ? (
                                        // <ValidationMessageComponent
                                        //     message={this.state.message}
                                        // ></ValidationMessageComponent>
                                        <p>pp</p>
                                    ) : (
                                            ""
                                        )}
                                    <div class="m-t-3 flex-between grid-cols-1  grid-cols-12">
                                        <div class="col-span-3">
                                            <label
                                                for="item"
                                                class="block text-sm font-medium text-gray-700"
                                            >
                                                Item
                      </label>
                                            <div class="mt-1">
                                                <Input
                                                    readOnly={true}
                                                    type="text"
                                                    name="item"
                                                    id="item"
                                                    value={this.state.budgetInformation.categoryName}
                                                    class="shadow-sm focus:outline-none block w-full text-sm"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div class="col-span-3">
                                            <label
                                                for="geoCode"
                                                class="block text-sm font-medium text-gray-700"
                                            >
                                                Geo Code
                      </label>
                                            <div class="m-t-5">
                                                <Select
                                                    name="geoCode"
                                                    id="geoCode"
                                                    value={this.state.geoCode}
                                                    options={this.state.geoCodeData}
                                                    class="shadow-sm focus:outline-none block w-full text-sm"
                                                    onChange={this.handleSelectedGeoCodeChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="m-t-10">
                                        <div className="flex-between" style={{marginBottom:'-5px'}}>
                                            <div class="col-span-3">
                                                <label
                                                    for="economicCode"
                                                    class="block text-sm font-bold text-gray-700"
                                                >
                                                    Economic Code
                                                                  </label>
                                                <div class="mt-1">
                                                    <input
                                                        readOnly={true}
                                                        type="text"
                                                        name="economicCode"
                                                        id="economicCode"
                                                        value={this.state.economicCode}
                                                        class="shadow-sm focus:outline-none block w-full text-sm"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-span-3">
                                                <label
                                                    for="functionalCode"
                                                    class="block text-sm font-bold text-gray-700"
                                                >
                                                    Functional Code
                                                                  </label>
                                                <div class="mt-1">
                                                    <input
                                                        readOnly={true}
                                                        type="text"
                                                        name="functionalCode"
                                                        id="functionalCode"
                                                        value={this.state.functionalCode}
                                                        class="shadow-sm focus:outline-none block w-full text-sm"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-span-3">
                                                <label
                                                    for="programmeCode"
                                                    class="block text-sm font-bold text-gray-700"
                                                >
                                                    Programme Code
                                                                  </label>
                                                <div class="mt-1">
                                                    <input
                                                        type="text"
                                                        readOnly={true}
                                                        name="programmeCode"
                                                        id="programmeCode"
                                                        value={this.state.programmeCode}
                                                        class="shadow-sm focus:outline-none block w-full text-sm"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-span-3">
                                                <label
                                                    for="fundsCode"
                                                    class="block text-sm font-bold text-gray-700"
                                                >
                                                    Funds Code
                                                                  </label>
                                                <div class="mt-1">
                                                    <input
                                                        type="text"
                                                        readOnly={true}
                                                        name="fundsCode"
                                                        id="fundsCode"
                                                        value={this.state.fundsCode}
                                                        class="shadow-sm focus:outline-none block w-full text-sm"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-between">
                                            <div class="col-span-3">
                                                <label
                                                    for="economicSegment"
                                                    class="block text-sm font-medium text-gray-700"
                                                >
                                                    Economic Segment
                                                                  </label>
                                                <div class="mt-1">
                                                    <Select
                                                        name="economicSegment"
                                            
                                                        id="economicSegment"
                                                        value={this.state.economicSegment}
                                                        options={this.state.economicSegmentData}
                                                        class="shadow-sm focus:outline-none block w-full text-sm"
                                                        onChange={this.handleSelectedEconomicSegmentChange}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-span-3">
                                                <label
                                                    for="functionalSegment"
                                                    class="block text-sm font-medium text-gray-700"
                                                >
                                                    Functional Segment
                                                                  </label>
                                                <div class="mt-1">
                                                    <Select
                                                        name="functionalSegment"
                                                        id="functionalSegment"
                                                        value={this.state.functionalSegment}
                                                        options={this.state.functionalSegmentData}
                                                        class="shadow-sm focus:outline-none block w-full text-sm"
                                                        onChange={this.handleSelectedFunctionalSegmentChange}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-span-3">
                                                <label
                                                    for="programmeSegment"
                                                    class="block text-sm font-medium text-gray-700"
                                                >
                                                    Programme Segment
                                                                  </label>
                                                <div class="mt-1">
                                                    <Select
                                                        name="programmeSegment"
                                                        id="programmeSegment"
                                                        value={this.state.programmeSegment}
                                                        options={this.state.programmeSegmentData}
                                                        class="shadow-sm focus:outline-none block w-full text-sm"
                                                        onChange={this.handleSelectedProgrammeChange}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-span-3">
                                                <label
                                                    for="fundsSegment"
                                                    class="block text-sm font-medium text-gray-700"
                                                >
                                                    Funds Segment
                                                                  </label>
                                                <div class="mt-1">
                                                    <Select
                                                        name="fundsSegment"
                                                        id="fundsSegment"
                                                        value={this.state.fundSegment}
                                                        options={this.state.fundSegmentData}
                                                        class="shadow-sm focus:outline-none block w-full text-sm"
                                                        onChange={this.handleSelectedFundSegmentChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-between">
                                            <div class="col-span-3">
                                                <label
                                                    for="economicType"
                                                    class="block text-sm font-medium text-gray-700"
                                                >
                                                    Economic Type
                                                                  </label>
                                                <div class="mt-1">
                                                    <Select
                                                        name="economicType"
                                                        id="economicType"
                                                        value={this.state.economicType}
                                                        options={this.state.economicTypeData}
                                                        class="shadow-sm focus:outline-none block w-full text-sm"
                                                        onChange={this.handleSelectedEconomicTypeChange}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-span-3">
                                                <label
                                                    for="functionalType"
                                                    class="block text-sm font-medium text-gray-700"
                                                >
                                                    Sub Function
                                                                  </label>
                                                <div class="mt-1">
                                                    <Select
                                                        name="functionalType"
                                                        id="functionalType"
                                                        value={this.state.subFunction}
                                                        options={this.state.subFunctionData}
                                                        class="shadow-sm focus:outline-none block w-full text-sm"
                                                        onChange={this.handleSelectedSubFunctionChange}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-span-3">
                                                <label
                                                    for="programmeType"
                                                    class="block text-sm font-medium text-gray-700"
                                                >
                                                    Programme Type
                                                                  </label>
                                                <div class="mt-1">
                                                    <Select
                                                        name="programmeType"
                                                        id="programmeType"
                                                        value={this.state.project}
                                                        options={this.state.projectData}
                                                        class="shadow-sm focus:outline-none block w-full text-sm"
                                                        onChange={this.handleSelectedProjectChange}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-span-3">
                                                <label
                                                    for="fundsType"
                                                    class="block text-sm font-medium text-gray-700"
                                                >
                                                    Funds Type
                                                                  </label>
                                                <div class="mt-1">
                                                    <Select
                                                        name="fundsType"
                                                        id="fundsType"
                                                        value={this.state.fundType}
                                                        options={this.state.fundTypeData}
                                                        class="shadow-sm focus:outline-none block w-full text-sm"
                                                        onChange={this.handleSelectedFundTypeChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-between">
                                            <div class="col-span-3">
                                                <label
                                                    for="economicSubType"
                                                    class="block text-sm font-medium text-gray-700"
                                                >
                                                    Economic Sub-Types
                                                                  </label>
                                                <div class="mt-1">
                                                    <Select
                                                        name="economicSubType"
                                                        id="economicSubType"
                                                        value={this.state.economicSubType}
                                                        options={this.state.economicSubTypeData}
                                                        class="shadow-sm focus:outline-none block w-full text-sm"
                                                        onChange={this.handleSelectedEconomicSubChange}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-span-3">
                                                <label
                                                    for="economicTypes"
                                                    class="block text-sm font-medium text-gray-700"
                                                >
                                                    Functional Item
                                                                  </label>
                                                <div class="mt-1">
                                                    <Select
                                                        name="economicTypes"
                                                        id="economicTypes"
                                                        value={this.state.functionalItem}
                                                        options={this.state.functionalItemData}
                                                        class="shadow-sm focus:outline-none block w-full text-sm"
                                                    // onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-span-3">
                                                <label
                                                    for="objective"
                                                    class="block text-sm font-medium text-gray-700"
                                                >
                                                    Programme Objective
                                                                  </label>
                                                <div class="mt-1">
                                                    <Select
                                                        name="objective"
                                                        id="objective"
                                                        value={this.state.objective}
                                                        options={this.state.objectiveData}
                                                        class="shadow-sm focus:outline-none block w-full text-sm"
                                                        onChange={this.handleSelectedObjectiveChange}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-span-3">
                                                <label
                                                    for="fundsSubType"
                                                    class="block text-sm font-medium text-gray-700"
                                                >
                                                    Fund Sub-Type
                                                                  </label>
                                                <div class="mt-1">
                                                    <Select
                                                        name="fundsSubType"
                                                        id="fundsSubType"
                                                        value={this.state.fundItem}
                                                        options={this.state.fundItemData}
                                                        class="shadow-sm focus:outline-none block w-full text-sm"
                                                        onChange={this.handleSelectedSubFundTypeChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex-between">
                                            <div class="col-span-3">
                                                <label
                                                    for="economicSubSubTypes"
                                                    class="block text-sm font-medium text-gray-700"
                                                >
                                                    Economic Sub-Sub-Types
                                                                  </label>
                                                <div class="mt-1">
                                                    <Select
                                                        name="economicSubSubTypes"
                                                        id="economicSubSubTypes"
                                                        value={this.state.economicSubSubType}
                                                        options={this.state.economicSubSubTypeData}
                                                        class="shadow-sm focus:outline-none block w-full text-sm"
                                                        onChange={this.handleSelectedEconomicSubSubChange}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-span-3" />
                                            <div class="col-span-3">
                                                <label
                                                    for="programmeActivities"
                                                    class="block text-sm font-medium text-gray-700"
                                                >
                                                    Programme Activities
                                                                  </label>
                                                <div class="mt-1">
                                                    <Select
                                                        name="programmeActivities"
                                                        id="programmeActivities"
                                                        value={this.state.activity}
                                                        options={this.state.activityData}
                                                        class="shadow-sm focus:outline-none block w-full text-sm"
                                                        onChange={this.handleSelectedActivityChange}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-span-3" />

                                        </div>
                                        

                                        <div class="pt-5 col-span-12 grid place-content-end">
                                            <div class="flex">
                                                <button
                                                    onClick={
                                                        this.state.edit
                                                            ? this.handleEditSubmit
                                                            : this.handleSubmit
                                                    }
                                                    type="submit"
                                                    class="mr-5 w-32 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                                                >
                                                    Save Record
                        </button>
                                                <button
                                                    onClick={this.handleClear}
                                                    type="button"
                                                    class="mr-5 w-32 bg-clear-entry py-2 px-4 border border-gray-300 shadow-sm text-sm font-bold text-color bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    Clear Entry
                        </button>
                                                <button
                                                    type="button"
                                                    class="w-32 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-bold text-color bg-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    onClick={() =>this.handleClose()}
                                                >
                                                    Close
                        </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
            </React.Fragment>
        );
    }
}
// 