import * as yup from "yup"
const programTypeValidationSchema = yup.object().shape({
    programmeName: yup.string().required('programme name is required'),
    programmeCode: yup.string().required('programme code is required'),
  


});
// let data = { programmeName, programmeCode };
export default programTypeValidationSchema
