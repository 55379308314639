import React from 'react';

import PortalRoutes from '../routes/PortalRoutes';
import Sidebar from './common/Sidebar';
import Header from './common/Header';
import Footer from './common/Footer';

import menuList from '../config/sidebarMenu';

function UserPortal(props) {
  document.title = 'Budget'
  const { history } = props;

  return (
    <div className="container">
      <Sidebar history={history} menuList={menuList()} />
      <div className="portal">
        <Header />
        <div className="content main-content">
          <PortalRoutes />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default UserPortal;
