import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Nav } from "../Components/Nav";
import  ProgramTypeSetup  from "../layouts/ProgramTypeSetup";
import  FunctionalCodeSegment  from "../layouts/FunctionalCodeSegment";
import  SubFunctions  from "../layouts/SubFunctions";
import { decodeToken } from "../../utility/auth";
import FunctionalItems from "../layouts/FunctionalItems";
// import { UploadFile } from "../Components/UploadFile";
import { Table,Modal, Input, InputNumber,message, Popconfirm, Form, Typography,Menu,Dropdown,Button,Checkbox } from 'antd';
import { BsPencilSquare } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';

import axios from "axios";
const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';
// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export default class FunctionalCode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fullName: "",
            role: "",
            time: "",
            employeeId:0,
            employeeData:[],
        };
    }
    componentDidMount() {
        this.getAuthorisedUser();
        this.getEmployees();
    }
    getEmployees = () => {
        this.setState({
            loader: true,
            message: "",
        });
        axios
            .get(`${BASE_API_URL}/employee`)
            .then((response) => {
                this.setState({
                    loader: false,
                    message: "",
                    employeeData: response.data,
                });
            })
            .catch((err) => {
                this.setState({
                    loader: false,
                    message: err.message,
                });
            });
    };

    getAuthorisedUser = () => {
      let fn = localStorage.getItem('fn');
  let ln = localStorage.getItem('ln');
  let ui = localStorage.getItem('ui');
  let mi = localStorage.getItem('mi');

  // r && setRole(decodeToken('r').r);
  if (fn && ln) {
    fn = decodeToken('fn').fn;
    ln = decodeToken('ln').ln;
    ui = decodeToken('ui').ui;
    mi = decodeToken('mi').mi;
  }
        // const { cookies } = this.props;
        // if (typeof cookies.get('userId') !== "undefined") {
        //     let authUser = cookies.get('userId');
        //     let time = authUser.lastLoginTime.split("T")[1]
            this.setState({
                fullName: fn + ' ' + ln,
                // role: authUser.role,
                // time: time,
                employeeId: ui,
            })
        // }
        // const { cookies } = this.props;
        // if (typeof cookies.get('userId') !== "undefined") {
        //     let authUser = cookies.get('userId');
        //     let time = authUser.lastLoginTime.split("T")[1]
        //     this.setState({
        //         fullName: authUser.firstName + ' ' + authUser.lastName,
        //         role: authUser.role,
        //         time: time,
        //         employeeId:authUser.id
        //     })
        // }

    }

  render() {
    return (
      <React.Fragment>
      
            <main
              class="flex-1 relative overflow-y-auto focus:outline-none"
              tabindex="0"
              style={{ outline: "none" }}
            >
              <div class="py-10 max-w-full mx-auto px-2 sm:px-4 md:px-8">
                <div class="flex">
                  <h1 class="flex-1 text-base font-medium text-color">
                    Budget Settings
                  </h1>
                  <div className="flex">
                    <p className="text-sm font-normal mr-3">

                      <br />
                      {/* <span className="text-xs ml-5">
                                      Time of login: { this.state.time}
                      </span> */}
                    </p>
                    {/* <div style={{ height: 40, width: 40 }}>
                      <img src="/icn.png" />
                    </div> */}
                  </div>
                </div>
                <div className="py-6">
                          <FunctionalCodeSegment employees={this.state.employeeData} employeeId={this.state.employeeId} {...this.props} />
                          <div className="m-t-10">
                              <SubFunctions employees={this.state.employeeData} employeeId={this.state.employeeId} {...this.props} />
                          </div>
                        <div className="m-t-10"> 
                        <FunctionalItems employees={this.state.employeeData} employeeId={this.state.employeeId} {...this.props} />
                         </div>
                          {/* <UploadFile employeeId={this.state.employeeId} {...this.props} /> */}

                </div>
              </div>
            </main>
       
      </React.Fragment>
    );
  }
}
