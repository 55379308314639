import React, { useState,useEffect } from 'react';
import { Table,Button, Input, InputNumber, Popconfirm, Form, Typography } from 'antd';
import axios from "axios";
import notification from '../../utility/notification';

import { AiOutlineClose } from 'react-icons/ai';
import { setDisplayName } from 'recompose';
// const BASE_API_URL = 'http://localhost:5000/api/v1';

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';

const originData = [];

// for (let i = 0; i < 100; i++) {
//   originData.push({
//     key: i.toString(),
//     name: `Edrward ${i}`,
//     age: 32,
//     address: `London Park no. ${i}`,
//   });
// }

const { TextArea } = Input;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : (inputType === 'textArea')? <TextArea rows={4} />: <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const CommentTableReq = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [displayEdit, setDisplayEdit] = useState(true);
  const [disabledd, setDisabledd] = useState(false);
  const [approversComments, setapproversComments] = useState('');
  const [showCommentz, setshowCommentz] = useState(false);
  const [sendersCommentz, setSendersComments] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  

  
console.log(props)
  const isEditing = (record) => record.key === editingKey;
  console.log(props?.projectParams)

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
      setDisabledd(true)

      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
    // let count = 1
    const toReview = ()=>{
        setDisplayEdit(!displayEdit)
    }
    const toApprove = ()=>{
        let postData =
          
        {
            id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            dateCreated: '2022-02-14T15:33:43.390Z',
            enteredBy: props.userIdz+'',
            dateUpdated: '2022-02-14T15:33:43.390Z',
            updatedBy: props.userIdz+'',
            isDelete: true,
            dateDeleted: '2022-02-14T15:33:43.390Z',
            text: approversComments,
            batchId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            mdaId: 11,
            approverId: 0
          }
    
        // let poster = arrayHolder.map((a)=>())
        // axios.post(`${BASE_API_URL}/ApprovalProcess/AddApprovalPlanProcess`, postData, {params:{status}})
        // .then((res)=>{
        //     console.log(res)
        //     notification({
        //         title: 'Successful',
        //         message: 'Allocated Mda budget has been sent successfully',
        //         type: 'success'
        //   });
        // })
    }
    const sendComment = ()=>{

       
        console.log(props.userIdz)
        let postData =   {
            dateCreated: props.project.dateCreated,
            enteredBy: props.userIdz+'',
            dateUpdated: '2022-02-12T14:07:40.545Z',
            updatedBy: props.userIdz+"",
            isDelete: false,
            dateDeleted: '2022-02-12T14:07:40.545Z',
            text: approversComments,
            approvalPlanId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            budgetPlanId:props.projectParams.batchId,
            approverId: 0
          }
        // let poster = arrayHolder.map((a)=>())
        axios.post(`${BASE_API_URL}/CommentPlan`, postData)
      
    }

    

    const toPost = ()=>{
      setIsLoading(!isLoading)

        let postData =
        
        data.map((e)=>(

           
            {
                // key: idx+1,
                id: e.id, 
                ids: e?.id,

            actuals: e.actuals,
            approvalStatus: e.approvalStatus,
            budgetDeptId: e.budgetDeptId,
            geoCode: e.geoCode,
            programmeName: e.programmeName,
            stage: e.stage,
            title: e.title,

            budgetPlanId: e.budgetPlanId,
            dateCreated: e.dateCreated,
            enteredBy: e.enteredBy,
            dateUpdated: e.dateUpdated,
            updatedBy: props.userIdz+"",
            isDelete: e.isDelete,
            dateDeleted: e.dateDeleted,
            project: e.project,
            economicCode: e.economicCode,
            programmeCode: e.programmeCode,
            status: e.status,
            projectLocation: e.projectLocation,
            proposedBudget: +e.proposedBudget,
            fundCode: e.fundCode,
            functionalCode: e.functionalCode +'',
            performance: e.performance,
            actuals: e.actual,
            mdaId: e.mdaId

            
                        // id: e?.id,
                        // dateCreated: e?.dateCreated,
                        // enteredBy: props.userIdz+"",
                        // dateUpdated: e?.dateUpdated,
                        // updatedBy: props.userIdz+"",
                        // isDelete: e?.isDelete,
                        // dateDeleted: e?.dateDeleted,
                        // project: e?.project,
                        // budgetPlanId: e?.budgetPlanId,
                        // programmeCode: e?.programmeCode,
                        // economicCode: e?.economicCode,
                        // status: e?.status,
                        // projectLocation: e?.projectLocation,
                        // proposedBudget: +e?.proposedBudget,
                        // performance: e?.performance,
                        // actuals: e?.actuals,
                        // mdaId: e?.mdaId
                      }
        ))
        // let poster = arrayHolder.map((a)=>())
     if (sendersCommentz) { axios.put(`${BASE_API_URL}/BudgetPlanning/UpdateBudgetPlannings`,{
            id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            dateCreated: '2022-02-13T18:26:10.084Z',
            enteredBy: 'string',
            dateUpdated: '2022-02-13T18:26:10.085Z',
            updatedBy: 'string',
            isDelete: false,
            dateDeleted: '2022-02-13T18:26:10.085Z',
            budgetPlannings:postData,
            comments:{
              
                    pId: 0,
                    dateCreated: props.commentsz[0]?.dateCreated ,
                    enteredBy:  props?.userIdz,
                    dateUpdated: props.commentsz[0]?.dateUpdated ,
                    updatedBy: props?.userIdz,
                    isDelete: props.commentsz[0]?.isDelete ,
                    dateDeleted: props.commentsz[0]?.dateDeleted ,
                    text:  sendersCommentz,
                    approvalPlanId: props.commentsz[0]?.approvalPlanId ,
                    budgetPlanId: props.commentsz[0]?.budgetPlanId ,
                    actionStatus: props.commentsz[0]?.actionStatus ,
                    approverId: props.commentsz[0]?.approverId 
            }
        }).then((res)=>{
                console.log(res)
      setIsLoading(!isLoading)
               
                notification({
                    title: 'Successful',
                    message: 'Allocated Mda budget has been sent successfully',
                    type: 'success'
              });
              props.closeModal()
              props.handleCancel()
        }).catch((err)=>{
          // notification({
          //   title: 'ERROR MESSAGE',
          //   message: `${ err?.response?.data}`,
          //   type: 'danger',
          //   // container:'top-center'
          //   // animationIn: ['animate__animated', 'animate__slideIn'],
          // });
        })
      }
      else{
        
        notification({
          title: 'Message',
          message: 'Please input Comments',
          type: 'danger'
    });
      }
      }

useEffect(()=>{
    console.log(props.project)
    let dat = props?.project?.map((e,idx)=>(
        {
    key: idx+1,

pId: e.pId,

            id: e.id,           
            actuals: e.actuals,
            approvalStatus: e.approvalStatus,
            budgetDeptId: e.budgetDeptId,
            geoCode: e.geoCode,
            programmeName: e.programmeName,
            stage: e.stage,
            title: e.title,

            budgetPlanId: e.budgetPlanId,
            dateCreated: e.dateCreated,
            enteredBy: e.enteredBy,
            dateUpdated: e.dateUpdated,
            updatedBy: props.userIdz+"",
            isDelete: e.isDelete,
            dateDeleted: e.dateDeleted,
            project: e.project,
            economicCode: e.economicCode?e.economicCode:'',
            programmeCode: e.programmeCode?e.programmeCode:'',
            status: e.status,
            projectLocation: e.projectLocation?e.projectLocation:'',
            proposedBudget: +e.proposedBudget?e.proposedBudget:0,
            fundCode: +e.fundCode?e.fundCode:0,
            functionalCode: +e.functionalCode?e.functionalCode:0,
            performance: e.performance?e.performance:'',
            actuals: e.actuals?e.actuals:'',
            mdaId: e.mdaId



          }
      ))
    console.log('dat:',dat)
    setData(dat)
},[])
console.log(data)
  

  
  // const columns1 =[
  //   {
  //     title: 'project',
  //     dataIndex: 'project',
  //     width: '25%',
  //     editable: true,
  //   },
  //   {
  //     title: 'programmeCode',
  //     dataIndex: 'programmeCode',
  //     width: '15%',
  //     editable: true,
  //   },
  //   {
  //     title: 'projectLocation',
  //     dataIndex: 'projectLocation',
  //     width: '40%',
  //     editable: true,
  //   },
  //   {
  //       title: 'proposedBudget',
  //       dataIndex: 'proposedBudget',
  //       width: '40%',
  //       editable: true,
  //     },
  //     {
  //       title: 'actuals',
  //       dataIndex: 'actuals',
  //       width: '40%',
  //       editable: true,
  //     },
  //   {
  //     title: 'operation',
  //     dataIndex: 'operation',
  //     render: (_, record) => {
  //       const editable = isEditing(record);
  //       return editable ? (
  //         <span>
  //           <Typography.Link
  //             onClick={() => save(record.key)}
  //             style={{
  //               marginRight: 8,
  //             }}
  //           >
  //             Save
  //           </Typography.Link>
  //           <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
  //             <a>Cancel</a>
  //           </Popconfirm>
  //         </span>
  //       ) : (
  //         <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
  //           {displayEdit &&<p>
  //               Edit
  //           </p>}
  //         </Typography.Link>
  //       );
  //     },
  //   },
  // ];
  const columns1 =[
    {
      title: 'Economic Code',
      dataIndex: 'economicCode',
      // width: '25%',
      editable: true,
    },
    {
      title: 'Functional Code',
      dataIndex: 'functionalCode',
      // width: '25%',
      editable: true,
    },
    {
      title: 'Programme Code',
      dataIndex: 'programmeCode',
      // width: '25%',
      editable: true,
    },
    {
      title: 'Fund Code',
      dataIndex: 'fundCode',
      // width: '25%',
      editable: true,
    },
    {
      title: 'Project/Line Item',
      dataIndex: 'project',
      // width: '25%',
      editable: true,
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   // width: '40%',
    //   editable: true,
    // },
    {
      title: 'Project Location',
      dataIndex: 'projectLocation',
      // width: '40%',
      editable: true,
    },
    {
        title: 'proposedBudget',
        dataIndex: 'proposedBudget',
        // width: '40%',
        editable: true,
      },
      // {
      //   title: 'actuals',
      //   dataIndex: 'actuals',
      //   width: '40%',
      //   editable: true,
      // },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            {displayEdit &&<p>
                Edit
            </p>}
          </Typography.Link>
        );
      },
    },
  ];


  const columns2 = [
    {
      title: 'project',
      dataIndex: 'project',
      width: '25%',
      editable: true,
    },
    {
      title: 'programmeCode',
      dataIndex: 'programmeCode',
      width: '10%',
      editable: true,
    },
    {
      title: 'project Location',
      dataIndex: 'projectLocation',
      width: '20%',
      editable: true,
    },
    {
        title: 'proposedBudget',
        dataIndex: 'proposedBudget',
        width: '10%',
        editable: true,
      },
    //   {
    //     title: 'actuals',
    //     dataIndex: 'actuals',
    //     width: '10%',
    //     editable: true,
    //   },
      {
        title: 'Comment',
        dataIndex: 'comment',
        width: '40%',
        editable: true,
      },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            {displayEdit &&<p>
                Edit
            </p>}
          </Typography.Link>
        );
      },
    },
  ];




//   const columns = displayEdit?columns2:columns1
  const columns = columns1




  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: (col.dataIndex === 'proposedBudget') ? 'number' : (col.dataIndex === 'comment') ?'textArea':'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form style={{width:'100%'}}  form={form} component={false}>
        <div style={{display:"flex", width:'100%'}}>
        <div style={{ width:'100%'}}>
          <Table
          style={{ width:'100%'}}
      components={{
      body: {
      cell: EditableCell,
      },
      }}
      bordered
      dataSource={data}
      columns={mergedColumns}
      rowClassName="editable-row"
      pagination={{
      onChange: cancel,
      }}
      />

<Button onClick={()=>{setshowCommentz(!showCommentz)}}>Add your Comments</Button>
      
     {displayEdit &&  <div className=' p-20 w-100 flex' style={{ justifyContent:'center'}}>
     { showCommentz && <div style={{width:"60%", padding:"20px", backgroundColor:"white"}}>
                <p className='bold m-b-5'>Your Comments</p>
                <TextArea  value={sendersCommentz} onChange={(e)=>{setSendersComments(e.target.value)}} style={{ border:"2px solid blue"}}  rows={2}/>
            </div>}

            <div style={{width:"60%", padding:"20px", backgroundColor:"white"}}>
              {
                 props.commentsz.map((e)=>(
                  // e?.updatedBy !==  props?.userIdz+''?<p style={{border:"2px solid red", width:"50%"}} className='bold m-b-5'>Comments From Approver</p>:""
                  e?.updatedBy !==  props?.userIdz+''?
                  <div style={{ width:"100%", display:'flex', justifyContent:'flex-start'}}>
                  <div style={{ width:"50%",}}>
                   <div className='m-b-5 bold' style={{color:"blue", fontSize:'11px'}}>{e.name}</div>
                   <p>{e.text}</p>
                  </div>
                  
                </div>:
                <div style={{ width:"100%", display:'flex', justifyContent:'flex-end'}}>
                <div style={{border:"", width:"50%",}}>
                <div className='m-b-5 bold' style={{color:"blue", fontSize:'11px'}}>{e.name}</div>

                <p>{e.text}</p>

                </div>
                
              </div>
                ))
                
                }
                {/* <p className='bold m-b-5'>Comments From Approver</p>
                <TextArea readOnly value={props.commentsz[0]?.text} onChange={(e)=>{setapproversComments(e.target.value)}} style={{ border:"2px solid blue"}}  rows={2}/>
           
                <p className='bold m-b-5'>Comments From User</p>
                <TextArea readOnly value={props.commentsz[0]?.text} onChange={(e)=>{setapproversComments(e.target.value)}} style={{ border:"2px solid blue"}}  rows={2}/> */}
            </div>

       </div>}

       <div className="w-100 flex" style={{justifyContent:'center'}}>
           <Button loading={isLoading} style={{width:"60%", margin:"auto"}} block  onClick = {toPost}>{'Send'}</Button>
       </div>
      {/* <div style={{marginLeft:"20px", display:"flex", justifyContent:"space-between"}}>
      <Button  onClick = {toReview}>{displayEdit ? 'Revert':'Review'}</Button>
     { !displayEdit?<Popconfirm
      placement="leftBottom"
      title={
      <div>
      <p className='bold'> Satisfied & Sure to Approve?</p>
      <p>Once Approved, it cannot be reversed.</p>
      </div>
      } onConfirm={toApprove}>
      <Button disabled={disabledd}>Approve</Button>
      
      </Popconfirm>
      :
      <Button onClick={sendComment}>Send for Review</Button>
      }
      </div> */}
      </div>

     
        </div>
      
     
    </Form>
  );
};

export default CommentTableReq