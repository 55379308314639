import React, { Component, useState, useEffect, useRef } from "react";
// import { DataTypes } from "../data/Types";
import Select from "react-select";
import Cookies from 'js-cookie'


const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';

function formatDefaultDate(dateString) {
  console.log(dateString);
  var newDate = new Date(dateString);
  newDate.setDate(newDate.getDate() + 1);

//   return newDate.toISOString().split("T")[0];
}

export default class ExpenseExpended extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      geoCodeData: "",
      localGovernmentCouncilData: [],
      localGovernmentCouncil: "",
      wardCouncil: "",
      geoCode: "",
      loading: false,
      deleteId: "",
      edit: false,
      editId: "",
      submenu: false,
    };
  }

  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  handleSubmit = () => {
    let localGovernmentCouncil = this.state.localGovernmentCouncil;
    let wardCouncil = this.state.wardCouncil;
    let geoCode = this.state.geoCode;

    let data = { localGovernmentCouncil, wardCouncil, geoCode };

    // this.setState({ loading: true }, () =>
    //   this.props.createAction(DataTypes.POST_BUDGET_PERIOD, { data })
    // );
  };

  handleDelete = (id) => {
    // this.setState({ deleteId: id }, () =>
    //   this.props.removeAction(DataTypes.DELETE_BUDGET_PERIOD, { id })
    // );
  };

  handleEdit = (data) => {
    this.setState({
      localGovernmentCouncil: data.localGovernmentCouncil,
      wardCouncil: data.wardCouncil,
      geoCode: data.geoCode,
      edit: true,
      editId: data.id,
    });
  };

  handleEditSubmit = () => {
    let localGovernmentCouncil = this.state.localGovernmentCouncil;
    let wardCouncil = this.state.wardCouncil;
    let geoCode = this.state.geoCode;
    let id = this.state.editId;

    let data = { localGovernmentCouncil, wardCouncil, geoCode, id };

    // this.setState({ loading: true }, () =>
    //   this.props.updateAction(DataTypes.PUT_BUDGET_PERIOD, { data })
    // );
  };

  render() {
    return (
      <React.Fragment>
        {this.state.open ? (
          <React.Fragment>
            <div
              className="flex-between w-100 flex-align  bg-card-header py-4 px-4 shadow-sm overflow-hidden bb-green cursor-pointer"
              onClick={() => this.setState({ open: !this.state.open })}
            >
              <p className="flex-1 text-sm font-medium font-avenir-black text-color">
                Expense Expended (N’mn)
              </p>
              <div
                className="mt-3"
                style={{ backgroundColor: "#1C811C", width: 26, height: 4 }}
              />
            </div>
            <div class="flex flex-col w-100">
              <div class="w-100 shadow overflow-y-scroll">
                <table className="w-100 min-w-full">
                  <thead class="w-100 bg-gray-table-header">
                    <tr>
                      <th
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Category
                      </th>
                      <th
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Budget Item
                      </th>
                      <th
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Jan
                      </th>
                      <th
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Feb
                      </th>
                      <th
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Mar
                      </th>
                      <th
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Apr
                      </th>
                      <th
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        May
                      </th>
                      <th
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Jun
                      </th>
                      <th
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Jul
                      </th>
                      <th
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Aug
                      </th>
                      <th
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Sept
                      </th>
                      <th
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Oct
                      </th>
                      <th
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Nov
                      </th>
                      <th
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Dec
                      </th>
                      <th
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr>
                      <td class="table-border-right px-4 py-2 text-sm font-medium text-gray-900">
                        Inventories
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm text-color">
                        Exam/Termly and Terminal Examinations
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm text-color">
                        30
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm text-color">
                        30
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm text-color">
                        30
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm text-color">
                        30
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm text-color">
                        30
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm text-color">
                        30
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm text-color">
                        30
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm text-color">
                        30
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm text-color">
                        30
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm text-color">
                        30
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm text-color">
                        30
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm text-color">
                        30
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm text-color">
                        360
                      </td>
                    </tr>

                    <tr>
                      <td
                        colSpan="2"
                        class="table-border-right px-4 py-2 text-right text-sm font-medium font-avenir-black  text-gray-900"
                      >
                        Total
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm font-medium font-avenir-black  text-color">
                        128
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm font-medium font-avenir-black  text-color">
                        128
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm font-medium font-avenir-black  text-color">
                        128
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm font-medium font-avenir-black  text-color">
                        128
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm font-medium font-avenir-black  text-color">
                        128
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm font-medium font-avenir-black  text-color">
                        128
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm font-medium font-avenir-black  text-color">
                        128
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm font-medium font-avenir-black  text-color">
                        128
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm font-medium font-avenir-black  text-color">
                        128
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm font-medium font-avenir-black  text-color">
                        128
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm font-medium font-avenir-black  text-color">
                        128
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm font-medium font-avenir-black  text-color">
                        128
                      </td>
                      <td class="table-border-right px-4 py-2 text-sm font-medium font-avenir-black  text-color">
                        1,536
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <p className="text-sm font-normal text-color p-4">
              Showing 1 to 2 of 2 entries
            </p>
          </React.Fragment>
        ) : (
          <div
            className="bg-white pointer py-4 px-4 shadow-sm overflow-hidden cursor-pointer mb-3"
            onClick={() => this.setState({ open: !this.state.open })}
          >
            <p className="text-sm font-medium text-color colorW">Expense Expended</p>
          </div>
        )}
      </React.Fragment>
    );
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}
}
