/* eslint-disable max-len */
import React, { Component } from 'react';

import qs from 'query-string';
import Cookies from 'js-cookie'

import EdsgLogo from '../../assets/images/edo-logo-250.png';
import { getLocation } from '../../api-services/otherApis';

import { get } from '../../api-services/fetch';
import { encodeToken } from '../../utility/auth';
import notification from '../../utility/notification';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: 'Authenticating...'
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const query = qs.parse(location.search);

    // this.location();
    encodeToken({ api: query.base }, 'api');
    this.handleAuthentication(query.emp);
  }

  location = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          console.log(pos);
          const res = await getLocation(pos);
          console.log(res);

          if (res.status === 'OK') {
            if (res.results[0]) {
              const { results } = res;
              const neighborhood = results.filter((item) => item.types.includes('administrative_area_level_2'));
              console.log(neighborhood[0].formatted_address);
              encodeToken({ gl: neighborhood[0].formatted_address }, 'gl');
            }
          } else {
            console.log(res.error_message);
          }
        }, (error) => {
          console.log(error);
        }
      );
    } else {
      notification({
        title: 'Geo-Location not Supported',
        message: 'Your browser does not support Geo-Location',
        type: 'danger'
      });
    }
  }

  handleAuthentication = async (emp) => {
    const res = await get({ endpoint: 'login', param: emp, auth: false });

    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        this.setState({ message: 'Granting you access...' });
        const { history } = this.props;
        data.token && encodeToken({ t: data.token }, 't');
        data.data.firstName && encodeToken({ fn: data.data.firstName }, 'fn');
        data.data.userId && encodeToken({ ui: data.data.userId }, 'ui');
        data.data.mdaId && encodeToken({ mi: data.data.mdaId }, 'mi');
        data.data.lastName && encodeToken({ ln: data.data.lastName }, 'ln');
        data.data.lastLoginTime && encodeToken({ lo: data.data.lastLoginTime }, 'lo');
        data.data.authenticationToken && encodeToken({ at: data.data.authenticationToken }, 'at');
        data.data.isGovernor && encodeToken({ ig: data.data.isGovernor }, 'ig');
        data.data.isPa && encodeToken({ ip: data.data.isPa }, 'ip');
        data.data.profilePicture && encodeToken({ pp: data.data.profilePicture }, 'pp');
        data.data.roles && encodeToken({ r: data.data.roles[0].name }, 'r');
        data.data.homeLink && encodeToken({ hl: data.data.homeLink }, 'hl');
        
        encodeToken({ il: true }, 'il'); // isLoggedin

        Cookies.set('userId', data.data.userId)
        Cookies.set('mdaId', data.data.mdaId)
        Cookies.set('isApprover', data.data.approverInfo.isApprover)
      
        Cookies.set('isExecutive', data.data.approverInfo.isExecutive)
        Cookies.set('isUser', data.data.approverInfo.isUser)

        if (data.data.isGovernor || data.data.isPa) {
          setTimeout(() => history.push('/budget'), 2000);
        } else {
          setTimeout(() => history.push('/budget/requests'), 2000);
        }
        notification({
          title: '',
          message: 'Welcome to Budget Management',
          type: 'success'
        });
      } else {
        this.setState({ message: 'Access Denied!' });
        notification({
          title: 'Authorization Error',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      this.setState({ message: 'Network Error!' });
      notification({
        title: 'Network Error',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  }

  render() {
    const { message } = this.state;

    return (
      <div className="w-100 p-t-40">
        <div className="loading-inner-box">
          <img src={EdsgLogo} alt="Edo State Logo" />
          {message}
        </div>
      </div>
    );
  }
}

export default Home;


// /* eslint-disable max-len */
// import React, { Component } from 'react';

// import qs from 'query-string';
// import Cookies from 'js-cookie'
// import axios from 'axios'
// import EdsgLogo from '../../assets/images/edo-logo-250.png';
// import { getLocation } from '../../api-services/otherApis';

// import { get } from '../../api-services/fetch';
// import { encodeToken } from '../../utility/auth';
// import notification from '../../utility/notification';
// const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';

// class Home extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       message: 'Authenticating...'
//     };
//   }

//   componentDidMount() {
//     const { location } = this.props;
//     const query = qs.parse(location.search);

//     // this.location();
//     encodeToken({ api: query.base }, 'api');
//     this.handleAuthentication(query.emp);
//   }

//   location = async () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         async (position) => {
//           const pos = {
//             lat: position.coords.latitude,
//             lng: position.coords.longitude
//           };

//           console.log(pos);
//           const res = await getLocation(pos);
//           console.log(res);

//           if (res.status === 'OK') {
//             if (res.results[0]) {
//               const { results } = res;
//               const neighborhood = results.filter((item) => item.types.includes('administrative_area_level_2'));
//               console.log(neighborhood[0].formatted_address);
//               encodeToken({ gl: neighborhood[0].formatted_address }, 'gl');
//             }
//           } else {
//             console.log(res.error_message);
//           }
//         }, (error) => {
//           console.log(error);
//         }
//       );
//     } else {
//       notification({
//         title: 'Geo-Location not Supported',
//         message: 'Your browser does not support Geo-Location',
//         type: 'danger'
//       });
//     }
//   }

//   handleAuthentication = async (emp) => {

//     axios
//     .post(`${BASE_API_URL}/auth/authorization?urltoken=${emp}`, {
//       headers: {
//         "Access-Control-Allow-Credentials": true,
//         crossorigin: true,
//         "Access-Control-Allow-Methods": "POST",
//         "Access-Control-Allow-Origin": "*",
//       },
//     }).then((res) => {
//     console.log(res);
//     if (res && res.status === 200) {
//       const { data } = res;
//       if (res && res.status === 200) {
//         this.setState({ message: 'Granting you access...' });
//         const { history } = this.props;
//         data?.token && encodeToken({ t: data?.token }, 't');
//         data?.firstName && encodeToken({ fn: data?.firstName }, 'fn');
//         data?.userId && encodeToken({ ui: data?.userId }, 'ui');
//         data?.lastName && encodeToken({ ln: data?.lastName }, 'ln');
//         data?.lastLoginTime && encodeToken({ lo: data?.lastLoginTime }, 'lo');
//         data?.authenticationToken && encodeToken({ at: data?.authenticationToken }, 'at');
//         // data.isGovernor && encodeToken({ ig: data.data.isGovernor }, 'ig');
//         // data.data.isPa && encodeToken({ ip: data.data.isPa }, 'ip');
//         data?.picture && encodeToken({ pp: data?.picture }, 'pp');
//         // data.data.roles && encodeToken({ r: data.data.roles[0].name }, 'r');
//         data.homeLink && encodeToken({ hl: data.homeLink }, 'hl');
//         encodeToken({ il: true }, 'il'); // isLoggedin

//         // Cookies.set('userId', data.userId)
//         Cookies.set('userId', data.id)

//         // if (data.data.isGovernor || data.data.isPa) {
//           setTimeout(() => history.push('/budget'), 2000);
//         // } else {
//           // setTimeout(() => history.push('/schedule-manager/requests'), 2000);
//         // }
//         notification({
//           title: '',
//           message: 'Welcome to Budget Management',
//           type: 'success'
//         });
//       } else {
//         this.setState({ message: 'Access Denied!' });
//         notification({
//           title: 'Authorization Error',
//           message: "data.message" ,
//           type: 'danger'
//         });
//       }
//     } else {
//       this.setState({ message: 'Network Error!' });
//       notification({
//         title: 'Network Error',
//         message: 'Something has gone wrong. Please, try again.',
//         type: 'danger'
//       });
//     }
      
//     })
//     // // const res = await get({ endpoint: 'login', param: emp, auth: false });


   
//   }

//   render() {
//     const { message } = this.state;

//     return (
//       <div className="w-100 p-t-40">
//         <div className="loading-inner-box">
//           <img src={EdsgLogo} alt="Edo State Logo" />
//           {message}
//         </div>
//       </div>
//     );
//   }
// }

// export default Home;
