import React from 'react';

const ValidationMessageComponent = (props) => {
    return (
        (props.message==""?"":
        <span className="block text-base font-medium  content-center"
              style={{textAlign: "center", color: "red", fontSize: 15, fontStyle: "italic"}}>
                           <li>
                           <span>{props.message}</span>
                           </li>
            </span>
       )
    );
};
export default ValidationMessageComponent;