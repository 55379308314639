import * as yup from "yup"
const budgetOrderSchema = yup.object().shape({
    priority: yup.number().required('priority is required'),
    roleid: yup.string().required('role is required'),
    employeeID: yup.string().required('employee is required'),
});

//    let data = { priority, approvalRole, employeeID };

export default budgetOrderSchema
