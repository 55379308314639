import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Nav } from "../Components/Nav";
// import  from "../Components/DetailFinanceC";
import DetailFinanceC from '../layouts/DetailFinanceC'

import cookies from 'js-cookie'

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';


export default class DetailFinance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fullName: "",
            role: "",
            // time: "",


        };
    }
    componentDidMount() {
        this.getAuthorisedUser();
    }

    getAuthorisedUser = () => {
        // const { cookies } = this.props;
        if (typeof cookies.get('userId') !== "undefined") {
            let authUser = cookies.get('userId');
            // let time = authUser.lastLoginTime.split("T")[1]
            this.setState({
                fullName: authUser.firstName + ' ' + authUser.lastName,
                role: authUser.role,
                // time: time
            })
        }

    }

  render() {
    return (
      <React.Fragment>
      
            <main
              class="flex-1 w-100 relative overflow-y-auto focus:outline-none"
              tabindex="0"
              style={{ outline: "none" }}
            >
              <div class="py-10 max-w-full mx-auto px-2 sm:px-4 md:px-8">
                <div class="flex">
                  <div className="flex-1 flex">
                    <h1 class=" text-2xl font-medium text-color mr-10">
                      Budget Review
                    </h1>
                  </div>
                  <div className="flex">
                    <p className="text-base font-normal mr-3">

                      <br />
                      <span className="text-xs ml-5">
                                      {/* Time of login: { this.state.time} */}
                      </span>
                    </p>
                    {/* <img src="/location-icon@2x.png" /> */}
                    {/* <div style={{ height: 40, width: 40 }}>
                      <img src="/icn.png" />
                    </div> */}
                  </div>
                </div>
                <div className="py-6">
                  <DetailFinanceC {...this.props} />
                </div>
              </div>
            </main>
         
       
      </React.Fragment>
    );
  }
}
