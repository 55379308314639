import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Nav } from "../Components/Nav";
// import { VarianceReportC } from "../Components/VarianceReportC";
import VarianceReportC from '../layouts/VarianceReportC'
import Greeting from '../common/Greeting';
import LocationDetails from '../layouts/LocationDetails';


export default class VarianceReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        
            <main
              class="flex-betwen w-100 relative overflow-y-auto focus:outline-none"
              tabindex="0"
              style={{ outline: "none" }}
            >
              <div className="w-100 flex space-between flex-v-baseline">
        <Greeting />
        <LocationDetails />
      </div>
              <div class=" m-t-20 py-10  w-100 max-w-full mx-auto px-2 sm:px-4 md:px-8">
                <div class="flex-betwen w-100">
                  <div className="flex-1 flex">
                    <h1 class="flex text-base font-medium text-color mr-10">
                      <span className="mr-3">
                        Budget Department Item Details Report
                      </span>

                    </h1>
                  </div>
                 
                </div>
                <div className="p-10">
                  <VarianceReportC {...this.props} />
                </div>
              </div>
            </main>
       
      </React.Fragment>
    );
  }
}
