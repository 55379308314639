import React,{Component} from "react";
import axios from "axios";

import Select from 'react-select';

import cookies from 'js-cookie'
const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';

// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
class Employees extends Component{
    constructor(props) {
        super(props);
        this.state={
            employees:[],
            selectedOption:null
        };
    }

    handleChange = selectedOption => {
        console.log(selectedOption)
        this.setState(
            { selectedOption },
            () => console.log(`Option selected:`, this.state.selectedOption)
        );
    };
    componentDidMount() {
        axios.get(`${BASE_API_URL}/employee/getemployeefullname`).then((response)=>{

            this.setState({
                employees: response.data
            })
        })

    }
    render() {
        const { selectedOption } = this.state;
        // const { message } = this.props;
        // console.log(message)
    return (
        <Select
            name="employeeID"
            id="employeeID"
            value={selectedOption}
            onChange={this.handleChange}
            options={this.state.employees}
        />
    )}
}
export default Employees
