import React, { Component } from "react";

import Select from "react-select";
import axios from "axios";
// import { store } from 'react-notifications-component';
import { formatDefaultDate, nullcheck, notify,formatNaira } from '../../Utils/Helper'
import {Preloader} from "../../Utils/Preloader";
import { AiOutlineFileExcel,AiOutlinePrinter, AiOutlineSearch } from 'react-icons/ai';
import {Link} from "react-router-dom";
// import SummaryReportC froms '../layouts/SummaryReportC'
import cookies from 'js-cookie'
const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';

// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;


export default class SummaryReportC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      mdas:[],
      budgetPeriods:[],
      budgetPeriod:"",
      allBudgetInformationData:[],
      mda:"",
      loading: false,
      deleteId: "",
      edit: false,
      editId: "",
      submenu: false,
      showModal: false,
      total: 0,
      message:"",
      loader:"",
      search:"",
    };
  }
  componentDidMount() {
    this.getMdas();
    this.getBudgetPeriods();
    this.getBudgetInformation();
  }
  handleSearch=()=>{
    let budgetPeriodId=this.state.budgetPeriod;
    let mdaId=this.state.mda;
    this.setState({
      loader: true,
      message: "",
    });
    if(mdaId=="" && budgetPeriodId==""){

      this.getBudgetInformation();
    }else if(mdaId==""){

      this.getBudgetInformationByBudgetPeriod(budgetPeriodId);
    }else if(budgetPeriodId==""){
      this.getBudgetInformationByMda(mdaId);
    }else{
      this.getBudgetInformationByMdaAndBudgetPeriod(mdaId,budgetPeriodId)
    }


  }
  handlePrint = (table_id) => {
    var divToPrint = document.getElementById(table_id);
    let newWin = window.open("");
    newWin.document.write(divToPrint?.outerHTML);
    newWin.print();
    newWin.close();
  };

  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };
  getMdas=()=>{
    this.setState({
      loader: true,
      message: "",
    });
    axios.get(`${BASE_API_URL}/mda/GetAllMda`).then((response)=>{
      this.setState({
        mdas:response.data,

        loader: false,
        message: "",
      })
    }).catch((err)=>{
      this.setState({
        loader: false,

        message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      })
    })
  }
  getBudgetPeriods=()=>{
    this.setState({
      loader: true,
      message: "",
    });
    axios.get(`${BASE_API_URL}/BudgetPeriod/GetBudgetPeriod`).then((response)=>{
      this.setState({
        budgetPeriods:response.data,

        loader: false,
        message: "",
      })
    }).catch((err)=>{
      this.setState({
        loader: false,

        message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      })
    })
  }
  getBudgetInformation = () => {
    this.setState({
      loader: true,
      message: "",
    });
    axios
        .get(`${BASE_API_URL}/budgetinformation/GetBudgetInformationsAllYear`)
        .then((response) => {

          this.setState({
            allBudgetInformationData: response.data,
            loader: false,
            message: "",
          });
        })
        .catch((err) => {
          this.setState({
            loader: false,

            message: nullcheck(err.response) == "" ? "server error" : err.response.data,
          })
        });
  };
  getBudgetInformationByMda = (mdaId) => {
    this.setState({
      loader: true,
      message: "",
    });
    axios
        .get(`${BASE_API_URL}/budgetinformation/GetBudgetInfoMda?mdaId=${mdaId}`)
        .then((response) => {

          this.setState({
            allBudgetInformationData: response.data,
            loader: false,
            message: "",
          });
        })
        .catch((err) => {
          this.setState({
            loader: false,

            message: nullcheck(err.response) == "" ? "server error" : err.response.data,
          })
        });
  };
  getBudgetInformationByBudgetPeriod = (budgetPeriod) => {
    this.setState({
      loader: true,
      message: "",
    });
    axios
        .get(`${BASE_API_URL}/budgetinformation/GetBudgetInfoBudgetPeriod?budgetPeriodId=${budgetPeriod.toString()}`)
        .then((response) => {

          this.setState({
            allBudgetInformationData: response.data,
            loader: false,
            message: "",
          });
        })
        .catch((err) => {
          this.setState({
            loader: false,

            message: nullcheck(err.response) == "" ? "server error" : err.response.data,
          })
        });
  };
  getBudgetInformationByMdaAndBudgetPeriod=(mdaId,budgetPeriodId)=>{
    axios.get(`${BASE_API_URL}/budgetinformation/GetBudgetInformationMdaAndBudgetPeriod?mdaId=${mdaId}&budgetPeriodId=${budgetPeriodId}`)
        .then((response) => {
          this.setState({
            allBudgetInformationData: response.data,
            loader: false,
            message: "",
          });
        }).catch((err) => {

      this.setState({
        loader: false,
        message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      })
    })
  }



  render() {


    const budgetInformations = this.state.allBudgetInformationData.filter(
        (budgetInformation) => {
          return budgetInformation.mdaName.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
        }

    );

    var allBudgetInformations = budgetInformations.map((budgetInformation) => {
      let total = budgetInformation.total
      let totalPercentage = ((total - 0) / total) * 100;

      //let url = `/finance-detail:${budgetInformation.departmentBudget.mdaId}`;
      //total += budgetInformation.total;


      return(
          <tr>
            <td className="table-border-right p-2 text-right text-sm font-extrabold text-color">
              {budgetInformation.mdaName}
            </td>

            <td className="table-border-right p-2 text-right text-sm font-extrabold text-color">
              0
            </td>
            <td className="table-border-right p-2 text-right text-sm font-extrabold text-color">
              0
            </td>
            <td className="table-border-right p-2 text-right text-sm font-extrabold text-color">
              {formatNaira(total)}
            </td>
            <td className="table-border-right p-2 text-right text-sm  text-color">
            {totalPercentage}
            </td>
            <td className="table-border-right p-2 text-right text-sm  text-color">
              {((total - 0) / total) * 100}
            </td>

          </tr>
      )
    })

      let mdas=this.state.mdas.map((mda)=>{
         return(
             <option value={mda.value}>{mda.label}</option>
         )
      })
    let budgetPeriods=this.state.budgetPeriods.map((budgetPeriod)=>{
         return(
             <option value={budgetPeriod.value}>{budgetPeriod.label}</option>
         )
      })
    return (
        <React.Fragment>
          {this.state.loader === true ? <Preloader /> : ""}
          <div class="w-100 p-10 flex-btween bg-white shadow-sm ">
            <table className="w-100 " >
              {/* <tr>
                <td className="text-base font-black px-3 py-3  text-sm font-medium font-avenir-black font-extrabold tracking-wider" style={{color:"#000000",textShadow:"1px 0 #000000"}}>MDA/Agency</td>
                <td className="text-base font-black px-3 py-3  text-sm font-medium font-avenir-black font-extrabold tracking-wider" style={{color:"#000000",textShadow:"1px 0 #000000"}}>Budget Period</td>
                <td className="text-base font-black px-3 py-3  text-sm font-medium font-avenir-black font-extrabold tracking-wider" style={{color:"#000000",textShadow:"1px 0 #000000"}}></td>
                <td className="text-base font-black px-3 py-3  text-sm font-medium font-avenir-black font-extrabold tracking-wider" style={{color:"#000000",textShadow:"1px 0 #000000"}}></td>
                <td></td>

              </tr> */}
              {/* <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr> */}
              <tr>
                <div className="flex-align" >
                    <td className="" >
                <div className="">
                <p>MDA/Agency</p>
                
                
                <select className='border m-r-5 w-100' style={{lineHeight:"200px", fontSize:'15px', padding:'5px'}}  onChange={this.handleChange} id="mda" name="mda" class="shadow-sm focus:outline-none block w-full sm:text-sm">
                <option className='border m-r-5 w-100' value="">All</option>
                {mdas}
                </select>
                </div>
                </td>
                <td>
                <div className="m-l-10">
                <p>Budget Period</p>
                <select className='border m-r-5 w-100' style={{lineHeight:"200px", fontSize:'15px', padding:'5px'}} onChange={this.handleChange} name="budgetPeriod" id="budgetPeriod">
                <option value="">All</option>
                {budgetPeriods}
                </select>
                </div>
                </td>
                <div className="m-l-5 flex-align " style={{marginTop:"20px"}}>
                <div style={{ marginRight:"5px"}} className=" cursor-pointer" onClick={this.handleSearch}> <AiOutlineSearch style={{fontSize:'32px',  backgroundColor: '#1d811c', padding:'3px', color:'white'}}/></div>
                    <div style={{ marginRight:"5px"}} className=" cursor-pointer" onClick={()=>this.handlePrint("table_info")}><AiOutlinePrinter style={{fontSize:'32px',  backgroundColor: '#1d811c', padding:'3px', color:'white'}}/></div>
                {/* <div className="inline-block focus:outline-none cursor-pointer" onClick={()=>this.handleSearch()}> <img className="h-10 w-10 mr-5" src="/searchicons.png" /></div> */}
                {/* <div className="inline-block focus:outline-none cursor-pointer" onClick={()=>this.handlePrint("tableinfo")} ><img className="h-10 w-10 mr-5" src="/print.png"/></div> */}
                
                    {/* <div className="inline-block ..." onClick={this.handleSearch}> <img className="h-10 w-10 mr-5" src="/searchicons.png"/></div> */}
                    {/* <div className="inline-block ..."><img className="h-10 w-10 mr-5" src="/print.png" onClick={()=>this.handlePrint('summaryReport')}/></div> */}

                  </div>
                </div>
                <td>
                 
                </td>
                <td>



                </td>
                <td>
                  <div
                      className="flex  py-4 px-4 shadow-sm overflow-hidden cursor-pointer"

                  >


                    <input
                        placeholder="Search"
                        className="border border-solid p-5 h-9 w-100"
                        style={{
                          backgroundColor: "transparent",
                          borderColor: "#707070",
                        }}
                        id="search"
                        name='search'
                        onChange={this.handleChange}
                    />
                  </div>
                </td>


              </tr>
            </table>
          </div>


          <div class="w-100 flex flex-col">
            <table class=" w-100 min-w-full shadow overflow-hidden" id="summaryReport">
              <thead class=" w-100 bg-gray-table-header">
              <tr>


                <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  Department
                </th>
                <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  Previous
                </th>
                <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  Pre Actual
                </th>
                <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  Current
                </th>
                <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  % on Total
                </th>
                <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  % on Growth
                </th>

              </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
              {allBudgetInformations}


              </tbody>
            </table>
          </div>
          <p className="text-sm font-normal text-color mt-4">

          </p>


        </React.Fragment>
    );
  }
}
