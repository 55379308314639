import React, { Component, useState, useEffect, useRef } from "react";
// import { Preloader } from "../Utils/Preloader";
// import ValidationMessageComponent from "../Components/ValidationMessage";
import cookies from 'js-cookie'
import axios from "axios";
// const BASE_API_URL = process.env.REACT_APP_BASE_API_UR?L;
const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';


function formatDefaultDate(dateString) {
  console.log(dateString);
  var newDate = new Date(dateString);
  newDate.setDate(newDate.getDate() + 1);

  return newDate.toISOString().split("T")[0];
}

export default class BudgetDetailsC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
        geoCodeData: "",
        allBudgetInformationData: [],
      localGovernmentCouncilData: [],
      localGovernmentCouncil: "",
      wardCouncil: "",
      geoCode: "",
      loading: false,
      deleteId: "",
      edit: false,
      editId: "",
        submenu: false,
        fuillName: "",
        employeeId: "",
        role: "",
        time: "",
        ministry: "",
        mdaId: 0,
        authorized: false,
        auhtorizedMdaId: 0
    };
    }
    componentDidMount() {

       //this.getBudgetInformation();
        this.getAuthorisedUser();
    }


  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };
    getAuthorisedUser = () => {
       

        if (typeof cookies.get('userId') !== "undefined") {

            let authUser = cookies.get('userId');
            console.log(authUser)
            // let time = authUser.lastLoginTime.split("T")[1]
            this.setState({
                fullName: authUser.firstName + ' ' + authUser.lastName,
                // role: authUser.role,
                // time: time,
                //ministry: authUser.mda.name,
                employeeId: authUser.id,
                //mdaId: authUser.mdaId

            })
            this.checkUserCanInitiate(authUser);
        }

    }
    //checkUserCanInitiate
  handleSubmit = () => {
    //let localGovernmentCouncil = this.state.localGovernmentCouncil;
    //let wardCouncil = this.state.wardCouncil;
    //let geoCode = this.state.geoCode;
    //let data = { localGovernmentCouncil, wardCouncil, geoCode };
    // this.setState({ loading: true }, () =>
    //   this.props.createAction(DataTypes.POST_BUDGET_PERIOD, { data })
    // );
  };

  handleDelete = (id) => {
    // this.setState({ deleteId: id }, () =>
    //   this.props.removeAction(DataTypes.DELETE_BUDGET_PERIOD, { id })
    // );
  };

  handleEdit = (data) => {
    this.setState({
      localGovernmentCouncil: data.localGovernmentCouncil,
      wardCouncil: data.wardCouncil,
      geoCode: data.geoCode,
      edit: true,
      editId: data.id,
    });
  };

  handleEditSubmit = () => {
    let localGovernmentCouncil = this.state.localGovernmentCouncil;
    let wardCouncil = this.state.wardCouncil;
    let geoCode = this.state.geoCode;
    let id = this.state.editId;

    let data = { localGovernmentCouncil, wardCouncil, geoCode, id };

    // this.setState({ loading: true }, () =>
    //   this.props.updateAction(DataTypes.PUT_BUDGET_PERIOD, { data })
    // );
    };
    getBudgetInformation = (mdaId) => {
        // this.setState({
        //     loader: true,
        //     message: "",

        // });
        axios
            .get(`${BASE_API_URL}/budgetinformation/GetBudgetmentInformationByMdaId?mdaId=${mdaId}`)
            .then((response) => {
                this.setState({
                    loader: false,
                    message: "",
                    allBudgetInformationData: response.data,
                });
                //let departmentBudgetId = response.data.map(a => a.departmentBudgetId);
                //this.checkIfBudgetHaveAlreadyBeenAddded(departmentBudgetId)
                //this.getBudgetApprovalRole(departmentBudgetId)

            })
            .catch((err) => {
                this.setState({
                    loader: false,
                    message: "",

                });
            });
    };
    checkUserCanInitiate = (id) => {
        axios.get(`${BASE_API_URL}/userprevilege/getuserprevilegebyemployeeId?employeeId=${id}`).then((response) => {
            console.log(response.data)
            if (response.data.length != 0) {
                this.getBudgetInformation(response.data[0].mdaId);
                this.setState({
                    auhtorizedMdaId: response.data[0].mdaId
                })
            }

            else {

                this.setState({
                    allBudgetInformationData: response.data,
                    authorized: false
                });

            }

        }).catch((err) => {
            console.log(err.message)
        })
    }

    render() {
        const budgetInformations = this.state.allBudgetInformationData;


        var alltotal = 0;
        // const budgetInformations = this.state.allBudgetInformationData;
        var janVal = 0;
        var febVal = 0;
        var marVal = 0;
        var aprVal = 0;
        var mayVal = 0;
        var junVal = 0;
        var julVal = 0;
        var augVal = 0;
        var sepVal = 0;
        var octVal = 0;
        var novVal = 0;
        var decVal = 0;
        let allData = budgetInformations.map((budgetInformation) => {
            //total += budgetInformation.total;
            janVal += parseFloat(budgetInformation.jan_val);
            febVal += parseFloat(budgetInformation.feb_val);
            marVal += parseFloat(budgetInformation.mar_val);

            aprVal += parseFloat(budgetInformation.apr_val);
            mayVal += parseFloat(budgetInformation.may_val);
            junVal += parseFloat(budgetInformation.june_val);
            julVal += parseFloat(budgetInformation.july_val);
            augVal += parseFloat(budgetInformation.aug_val);
            sepVal += parseFloat(budgetInformation.sept_val);
            octVal += parseFloat(budgetInformation.oct_val);
            novVal += parseFloat(budgetInformation.nov_val);
            decVal += parseFloat(budgetInformation.dec_val);
          let total =
                budgetInformation.jan_val
                + budgetInformation.feb_val
                + budgetInformation.mar_val
                + budgetInformation.apr_val
                + budgetInformation.may_val
                + budgetInformation.june_val
                + budgetInformation.july_val
                + budgetInformation.aug_val
                + budgetInformation.sept_val
                + budgetInformation.oct_val
                + budgetInformation.nov_val
                + budgetInformation.dec_val
            alltotal += total;
            return (
                <tr>
                    <td className="colorW p-10 text-sm font-medium text-gray-900">
                        {budgetInformation.departmentBudget.programmes == null ? "" : budgetInformation.departmentBudget.programmes.programmeName}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.departmentBudget.item}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.currency.title}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.jan_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.feb_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.mar_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.apr_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.may_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.june_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.july_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.aug_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.sept_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.oct_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.nov_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.dec_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {total}
                    </td>
                </tr>
            );
        });
    return (
      <React.Fragment>
        {this.state.open ? (
          <React.Fragment>
            <div
            // style={{backgroundColor:"d5ffd5"}}
              className=" w-100 flex-between flex-align pointer p-10 bg-card-header py-4 px-4 shadow-sm overflow-hidden bb-green cursor-pointer"
              onClick={() => this.setState({ open: !this.state.open })}
            >
              <p 
            className=" text-sm no-margin font-medium font-avenir-black text-color">
                Budget Details (N’mn)
              </p>
              <div
                className="mt-3"
                style={{ backgroundColor: "#1C811C", width: 26, height: 4 }}
              />
            </div>
            <div class="flex w-100">
              <div className="w-100 shadow overflow-y-scroll">
                <table className="w-100 min-w-full">
                                <thead className= "w-100 p-10 bg-black-table-header font-black">
                                    <tr>
                                        <th style={{ fontSize: '16px', color: "black", fontStyle: "bold", fontWeight: 700 }}
                        scope="col"
                                            class=" p-10 table-border-right px-3 py-3 text-left  font-avenir-black tracking-wider"
                      >
                                           Category
                      </th>
                                        <th style={{ fontSize: '16px', color: "black", fontStyle: "bold" }}
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Item
                      </th>
                                        <th style={{ fontSize: '16px', color: "black", fontStyle: "bold" }}
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Currency
                      </th>
                                        <th style={{ fontSize: '16px', color: "black", fontStyle: "bold" }}
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Jan
                      </th>
                                        <th style={{ fontSize: '16px', color: "black", fontStyle: "bold" }}
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Feb
                      </th>
                                        <th style={{ fontSize: '16px', color: "black", fontStyle: "bold" }}
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Mar
                      </th>
                                        <th style={{ fontSize: '16px', color: "black", fontStyle: "bold" }}
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Apr
                      </th>
                                        <th style={{ fontSize: '16px', color: "black", fontStyle: "bold" }}
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        May
                      </th>
                                        <th style={{ fontSize: '16px', color: "black", fontStyle: "bold" }}
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Jun
                      </th>
                                        <th style={{ fontSize: '16px', color: "black", fontStyle: "bold" }}
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Jul
                      </th>
                                        <th style={{ fontSize: '16px', color: "black", fontStyle: "bold" }}
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Aug
                      </th>
                                        <th style={{ fontSize: '16px', color: "black", fontStyle: "bold" }}
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Sept
                      </th>
                                        <th style={{ fontSize: '16px', color: "black", fontStyle: "bold" }}
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Oct
                      </th>
                                        <th style={{ fontSize: '16px', color: "black", fontStyle: "bold" }}
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Nov
                      </th>
                                        <th style={{ fontSize: '16px', color: "black", fontStyle: "bold" }}
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Dec
                      </th>
                                        <th style={{ fontSize: '16px', color: "black", fontStyle: "bold" }}
                        scope="col"
                        class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                                    {allData}

                    <tr>
                      <td
                        colSpan="3"
                        class="table-border-right p-2 text-sm font-medium font-avenir-black  text-gray-900"
                      >

                      </td>
                      <td class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                            { janVal}
                      </td>
                      <td class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                            {febVal}
                      </td>
                      <td class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                            {marVal}
                      </td>
                      <td class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                            {aprVal}
                      </td>
                      <td class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                            {mayVal}
                      </td>
                      <td class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                            {junVal}
                      </td>
                      <td class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                            {julVal}
                      </td>
                      <td class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                            {augVal}
                      </td>
                      <td class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                            {sepVal}
                      </td>
                      <td class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                            {octVal}
                      </td>
                      <td class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                            {novVal}
                      </td>
                      <td class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                            {decVal}
                      </td>
                      <td class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                            {alltotal}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <p className="text-sm font-normal text-color p-4">

            </p>

          </React.Fragment>
        ) : (
          <div
            className="bg-white pointer py-4 px-4 shadow-sm overflow-hidden cursor-pointer mb-3"
            onClick={() => this.setState({ open: !this.state.open })}
          >
            <p className="w-100 pointer colorW  text-sm font-medium text-color">Budget Details</p>
          </div>
        )}
      </React.Fragment>
    );
  }



}
