
import React, { useEffect, useState,   } from "react";
import "../../assets/css/Dashboard.css";
// import AutoComplete from "../inputs/AutoComplete";
import {Input,Select, Modal,} from 'antd';

// import { IoMdClose } from "react-icons/io";
import axios from "axios";
import {
  
  formatDate,

  
} from "../../utility/dateTime";
import Greeting from "../common/Greeting";
import LocationDetails from "../layouts/LocationDetails";
// import EditableTable from "../layouts/EditableTable";

import { TiPencil } from "react-icons/ti";
import "../../assets/css/calendar.css";
// import SelectInputColored from "../inputs/SelectInputColored";
// import DeleteIcon from "@material-ui/icons/Delete";
import { decodeToken } from "../../utility/auth";
import {

  CloseOutlined,
  
} from '@ant-design/icons';
import CommentTableReq from "../layouts/commentTableReq";
import ProgrammeTable from "../layouts/ProgrammeTable";

const BudgetReq = ({ history }) => {
  // const { Option } = Select;



// const [name, setName] = useState('')
// const [amount, setAmount] = useState('')
// const [status, setStatus] = useState('')
const [arrayHolder, setArrayHolder] = useState([])
// const [id, setId] = useState(1)
// const [paramid, setParamId] = useState(1)
// const [paramHolder, setParamHoler] = useState([])
// const [approverName, setApproverName] = useState([])
// const [approver, setApprover] = useState([]);

// const [mdaName, setMdaName] = useState([])


// const [title, setTile] = useState('')


// const [commentsBudget, setCommentsBudget] = useState([]);
  // const [commentsBudgetA, setCommentsBudgetA] = useState([]);
    
  // const [isLoading, setIsLoading] = useState(false);
  // const [details, setDetails] = useState([]);
  // const [requestCounts, setRequestCounts] = useState({});
  // const [meetingCounts, setMeetingCounts] = useState({});
  // const [errorMsg, setErrorMsg] = useState("No Request Yet");
  // const [Mda, setMda] = useState([]);
  const [mdaId, setMdaId] = useState([]);
  // const [employees, setEmployees] = useState([]);
  // const [employeesId, setEmployeesId] = useState([]);
  // const [ApproverId, setApproverId] = useState([]);
  // const [mailDisplay, setMailDisplay] = useState(true);
  // const [mdaDisplay, setMdaDisplay] = useState(false);
  // const [category, setCategory] = useState([]);
  // const [searchText, setSearchText] = useState("");
//   const [status, setStatus] = useState("actioned");
  // const [ourUrl, setOurUrl] = useState("");
  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState('');
  const [refresher, setRefresher] = useState(0);
  // const [summary, setSummaary] = useState('');
  // const [batchId, setBatchId] = useState('');
const [commentsBudget, setCommentsBudget] = useState([]);

  const [envelopeHolder, setEnvelopeHolder] = useState([]);
//   let [initiateStatus, setInitiateStatus] = useState([
//     {name:'Ungoing', id:1},
//     {name:'New', id:2}

// ]);
  
// const [initiateStatusId, setInitiateStatusId] = useState([]);

  // const [showCalendar, setShowCalendar] = useState(false);
  // const [calendarFor, setCalendarFor] = useState("");
  // const [calendar, setCalendar] = useState(null);
  // const [requestCalendar, setRequestCalendar] = useState(null);
  // const [meetingCalendar, setMeetingCalendar] = useState(null);
  // const [showQueryForm, setShowQueryForm] = useState(false);
  // const [showQueryThread, setShowQueryThread] = useState(false);
  // const token =
    // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySUQiOiIxIiwiRmlyc3ROYW1lIjoiRWRvIEdvdnYiLCJMYXN0TmFtZSI6IkFkbWluIiwiZXhwIjoxNjIxNzg3NTQ1LCJpc3MiOiJzZXJ2ZXIiLCJhdWQiOiJjbGllbnQifQ.JpkTA-250nWIkIxrOyaLZcbw33fJNXuV7z1tPE9zz0w";
  // const wrapperRef = useRef(null);
  // const focusRef = useRef();
  // const [current, setCurrent] = useState("");
  // const [currentId, setCurrentId] = useState("");
  // console.log(details);
  // console.log(errorMsg);

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';
// const BASE_API_URL = 'http://localhost:5000/api/v1';

let fn;
let ln;
let ui;
 let mi;
useEffect (()=>{
//   getMdas()
//   getEmployees()
  // const r = localStorage.getItem('r');
  fn = localStorage.getItem('fn');
  ln = localStorage.getItem('ln');
  ui = localStorage.getItem('ui');
  mi = localStorage.getItem('mi');

  // r && setRole(decodeToken('r').r);
  if (fn && ln) {
    fn = decodeToken('fn').fn;
    ln = decodeToken('ln').ln;
    ui = decodeToken('ui').ui;
    mi = decodeToken('mi').mi;
    setUsername(`${fn} ${ln}`);
  // getPending(ui)

  // alert(mi)

    console.log(ui)
    setUserId(ui)
  toGetEnvelope(ui)
  setMdaId(mi)

  toGetBudgetPlan(+mi)
  toCallComments(+mi)
  

  }

},[refresher])

const refresh = ()=>{
  toGetEnvelope(ui)
  toGetBudgetPlan(+mi)
  toCallComments(+mi)
  // alert('go go')

}



  const removeItem = (id)=>{
      console.log(id)
const temp = arrayHolder.filter(item =>(
    item.idz !== id
))

setArrayHolder(temp)
  }

  const changeRefresher =()=>{
    setRefresher(refresher+1)
  }
  

  const toGetBudgetPlan = (id)=>{
    axios.get(`${BASE_API_URL}/BudgetPlanning/GetByMdaId`, {params:{mda:id}}).then(
      
      (response)=>{
  console.log(response.data)
  // setEnvelopeHolder(response.data)
  
  //       let summary = response.data?.map(((res)=>(
  // <p onClick={callBatchId(res.batchId)}>{res.batchId}</p>
  //       )))
  //     // setEmployees( response.data)
  //        setSummaary(summary)
     
   })
  }

  const toCallComments = (id)=>{
    axios.get(`${BASE_API_URL}/CommentPlan/GetCommentsSummaryByMdaId`, {params:{mdaId:id}}).then(
      
      (response)=>{
  console.log(response.data)
  setCommentsBudget(response.data)
  
  //       let summary = response.data?.map(((res)=>(
  // <p onClick={callBatchId(res.batchId)}>{res.batchId}</p>
  //       )))
  //     // setEmployees( response.data)
  //        setSummaary(summary)
     
   })
  }

 
  
  const toGetEnvelope = (userId)=>{
    axios.get(`${BASE_API_URL}/BudgetPlanning/GetEnvelopeByUserId`, {params:{userId}}).then(
      
      (response)=>{
  console.log(response.data)
  setEnvelopeHolder(response.data)
  
  //       let summary = response.data?.map(((res)=>(
  // <p onClick={callBatchId(res.batchId)}>{res.batchId}</p>
  //       )))
  //     // setEmployees( response.data)
  //        setSummaary(summary)
     
   })
  }
   
     return (
    <div className="w-100">
     
      <div className="w-100 m-b-50 flex space-between flex-v-baseline">
        <Greeting />
        <LocationDetails />
      </div>

      <h3 className='bold' style={{fontSize:'30px',textAlign:'cener'}}>Budget Initiators Review</h3>
      
     
         { envelopeHolder.length !== 0 && <div className="w-100">
           
           <p className='bold' style={{fontSize:'20px',}}>Initiator's Cost Allocation</p>
         
         
           <table className="table-view m-t-10">
          <thead className="table-header">
          <tr className="table-row">
          <th className="th" style={{ padding: "20px 10px" }}>
         <p>Document Title</p>
         </th>
        
         <th className="th" style={{ padding: "20px 10px" }}>
         <p>Status</p>
         </th>
         <th className="th" style={{ padding: "20px 10px" }}>
         <p>Amount</p>
         </th>
         <th className="th" style={{ padding: "20px 10px" }}>
         <p>Actions</p>
         </th>
         </tr>
         </thead>
         <tbody className="table-body">
         {envelopeHolder.length !== 0 &&
         envelopeHolder.map((item) => (
         <ListItem2
         refresh ={refresh}
         key={item.id}
         detail={item}
         removeItem={removeItem}
         // currentId={currentId}
         // handleDetails={handleDetails}
         />
         ))}
         </tbody>
         </table>
         </div>
}



{ commentsBudget.length !== 0 && <div className="w-100">
           
           <p className='bold m-t-20' style={{fontSize:'20px',}}>Initiator's Correspondence</p>
         
         
           <table className="table-view m-t-10">
          <thead className="table-header">
          <tr className="table-row">
          <th className="th" style={{ padding: "20px 10px" }}>
         <p>Document Title</p>
         </th>
        
         <th className="th" style={{ padding: "20px 10px" }}>
         <p>Reviewed By</p>
         </th>
         <th className="th" style={{ padding: "20px 10px" }}>
         <p>Reviewed Date</p>
         </th>
         <th className="th" style={{ padding: "20px 10px" }}>
         <p>Actions</p>
         </th>
         </tr>
         </thead>
         <tbody className="table-body">
        <p>{commentsBudget.tilte}</p>

         {commentsBudget.length !== 0 &&
         commentsBudget.map((item) => (
         <ListItem3
         detail={item}
         userId={userId}
         changeRefresher={changeRefresher}
         // currentId={currentId}
         // handleDetails={handleDetails}
         />
         ))}
         </tbody>
         </table>
         </div>
}


    </div>
  );
};

const ListItem2 = ({ detail, refresh, handleDetails, current, removeItem }) => {
  const [itemDetails, setItemDetails] = useState(detail || {});
  const [showDetails, setShowDetails] = useState(false);

  const handleCancel = ()=>{
    setShowDetails(!showDetails)
  }

//   const updateInfo = (newItem) => {
//     setItemDetails(newItem || {});
//   };

  return (
    <>
      <tr
        className={`${showDetails && "active"}  ${
          itemDetails.id
        } table-row pointer`}
        role="presentation"
      >
        <td className="td p-r" style={{ padding: "20px 10px" }}>
          {itemDetails.budgetProjectInitiatives[0].title}
        
        </td>
       
        <td className="td" style={{ padding: "20px 10px" }}>
          <div className={`status ${'pending'}-status`}>
            {(detail.status)||'Pending'}
          </div>
        </td>
        <td className="td" style={{ padding: "20px 10px" }}>
          {(detail.totalAmount)}
        </td>
        <td
          style={{
            display: "flex",
            right: "0",
            justifyContent: "flex-end",
            padding: "20px 10px",
          }}
        >
                        
<TiPencil onClick={() => setShowDetails(!showDetails)} style={{backgroundColor: "#66ff99", fontSize:"30px", padding:"5px"}}/>
         
        </td>
      </tr>

     {/* <tr className="table-row pointer"  style={{ border:"2px solid red"}}> */}
         
           {/* { showDetails &&
             detail.budgetProjectInitiatives.map((e)=>(
               <tr className="w-100" style={{border:'2px solid red'}}>
                <td>{ e.projectInitiative}</td>
                <td>{ e.status}</td>
                <td>{}</td>
                <td
                 style={{
                  display: "flex",
                  right: "0",
                  justifyContent: "flex-end",
                  padding: "20px 10px",
                }}
                ><TiPencil  style={{backgroundColor: "#66ff99", fontSize:"30px", padding:"5px"}}/>
</td>
               </tr>
             ))
           } */}

      
       {/* <td style={{width:'100%', border:"2px solid red", margin:"0 auto"}}>
         <EditableTable  project = {detail.budgetProjectInitiatives}/>
       </td> */}
     {/* </tr> */}

       <Modal
                    width="70%"
                   
                    footer={false}
                    // okText={<p style={{ maxWidth: "100px" }}>Boss</p>}
                    closeIcon={<CloseOutlined style={{ fontSize: "25px", backgroundColor: "red", color: "white", padding: "10px", marginLeft:'60px' }} />}
                    visible={showDetails} onCancel={handleCancel}>
                    <div style={{marginTop:"-23px",}}>
                    <ProgrammeTable refresh ={refresh} handleCancel ={handleCancel} project = {detail.budgetProjectInitiatives}/>

                    </div>
                </Modal> 
     
     
    </>
  );
};



const ListItem3 = ({ detail, handleDetails, changeRefresher, current,userId, removeItem  }) => {
  const [itemDetails, setItemDetails] = useState(detail || {});
  const [showDetails, setShowDetails] = useState(false);
  const [comentTable, setCommentTable] = useState(false);

  const closeModal = (close)=>{
    console.log(close)
    handleCancel()
    setCommentTable(close)
    changeRefresher()
  }

  const handleCancel = ()=>{
    setShowDetails(!showDetails)
  }

//   const updateInfo = (newItem) => {
//     setItemDetails(newItem || {});
//   };

  return (
    <>
      <tr
        className={`${showDetails && "active"}  ${
          itemDetails.id
        } table-row pointer`}
        role="presentation"
      >
        <td className="td p-r" style={{ padding: "20px 10px" }}>
          {itemDetails.title}
        
        </td>
       
        <td className="td" style={{ padding: "20px 10px" }}>
          <div>
            {(detail.reviewedBy)}
          </div>
        </td>
        <td className="td" style={{ padding: "20px 10px" }}>
        {formatDate(detail.reviewedDate)}

        </td>
        <td
          style={{
            display: "flex",
            right: "0",
            justifyContent: "flex-end",
            padding: "20px 10px",
          }}
        >
                        
<TiPencil onClick={() => setShowDetails(!showDetails)} style={{backgroundColor: "#66ff99", fontSize:"30px", padding:"5px"}}/>
         
        </td>
      </tr>


      <Modal
                    width="70%"
                   
                    footer={false}
                    // okText={<p style={{ maxWidth: "100px" }}>Boss</p>}
                    closeIcon={<CloseOutlined style={{ fontSize: "25px", backgroundColor: "red", color: "white", padding: "10px", marginLeft:'60px' }} />}
                    visible={showDetails} onCancel={handleCancel}>
                    <div style={{marginTop:"-23px",}}>
          <CommentTableReq closeModal={closeModal} userIdz = {userId} project = {detail.budgetPlanning} commentsz={detail.comments} projectParams = {detail}/>

                    {/* <EditableTable  project = {detail.budgetProjectInitiatives}/> */}

                    </div>
                </Modal>
     
     
    </>
  );
};

export default BudgetReq;
