import React, { Component, useState } from "react";
// import { DataTypes } from "../data/Types";

// import { store } from 'react-notifications-component'
import { nullcheck, notify,formatNaira } from '../../Utils/Helper'
import axios from "axios";
import { Preloader } from "../../Utils/Preloader";

import { BsPencilSquare } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';
import budgetCurrencyByMonthConservationValidationSchema from "../../Validation/BudgetCurrencyByMonthConversationValidation";
// import ValidationMessageComponent from "./ValidationMessage";
import Select from "react-select";

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';

// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export default class BudgetCurrencyConversationPerMonth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      budgetCurrencies: [],
      budgetPeriods: [],
      months:[],
      allCurrencyData: [],
      currency:"",
      month:"",
      id: "",
      editMode: false,
      loading: false,
      title: "",
      nairaRate: "0",
      budgetPeriodID: "",
      budgetPeriodValue: "",
      budgetPeriodText: "",
      open: false,
      message: "",


      showNotification: false,
      notificationSuccess: false,
      notificationMessage: "Successfully saved!",
      search: "",
      deleteModal: false,
      deleteId: "",
    };
  }

  componentDidMount() {
    this.refreshCurrency();

    this.getBudgetPeriod();
    this.getMonths();

  }
  handleSelect = (event) => {
    this.setState({
      budgetPeriodValue: event.target.value,
      budgetPeriodText: event.target.innerHTML,
    });
    //alert(event.target.value);
  };
  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };
  handleNairaRate = (ev) => {
    const re = /^[0-9\b]+$/;
    if (ev.target.value === "" || re.test(ev.target.value)) {
      let targetvalue = ev.target.value == "" ? "0" : ev.target.value;
      this.setState({
        [ev.target.name]: targetvalue,
      });


    } else {
      this.setState({
        [ev.target.name]: "0",
      });
    }
  }
  addCurrency() {

    let currency=this.state.currency;
    let currencyId=currency==""?"":currency.value;
    let naira = parseInt(this.state.nairaRate);
    let month=this.state.month.value;

    let enteredBy = this.props.employeeId.toString()

    let data = { currencyId, naira, enteredBy,month };
    console.log(data)
    budgetCurrencyByMonthConservationValidationSchema
        .validate(data)
        .then((message) => {
          this.setState({
            loader: true,
            message: "",
          });
          axios
              .post(`${BASE_API_URL}/currencybymonth`, { ...data })
              .then((response) => {
                this.refreshCurrency();

                this.setState({
                  loader: false,
                  message:"",
                  title: "",
                  nairaRate: "",
                  currency:"",
                  budgetPeriodID: "",
                });
                // notify(store, 'success', 'currency was added successfully');
              })
              .catch((err) => {
                this.setState({
                  loader: false,
                  message: nullcheck(err.response) == "" ? "server error" : err.response.data,
                  //showNotification: true,
                  //notificationMessage: err.message,
                });
              });
        })
        .catch((err) => {
          this.setState({
            loader: false,
            message: err.errors,
          });
        });
  }

  handleEdit(data) {
    //budgetCurrency.currency.budgetPeriod
    //budgetCurrency.currency.title
    let budgetPeriodID={value: data.currency.budgetPeriod.id,label:data.currency.budgetPeriod.title};

    let currency={value:data.currency.id,label:data.currency.title}
    let month={value:data.month,label:this.getMonth(data.month)}

    this.setState({
      id: data.id,
      currency: currency,
      month:month,
      budgetPeriodID:budgetPeriodID,
      nairaRate: data.naira,
      editMode: true,

    });
    //document.getElementById("budgetPeriodID").value = data.budgetPeriodID;
  }
  handleEditSubmit() {
    let id=this.state.id;
    let currency=this.state.currency;
    let currencyId=currency==""?"":currency.value;
    let naira = parseInt(this.state.nairaRate);
    let month=this.state.month.value;

    let enteredBy = this.props.employeeId.toString()

    let data = { id,currencyId, naira, enteredBy,month };

    budgetCurrencyByMonthConservationValidationSchema
        .validate(data)
        .then((message) => {
          this.setState({
            loader: true,
            message: "",
          });

          axios
              .put(`${BASE_API_URL}/currencyBymonth`, { ...data })
              .then((response) => {
                this.setState({
                  editMode: false,
                  id: "",
                  title: "",
                  nairaRate: "0",
                  budgetPeriodId: "",
                  loader: false,
                  message: "",
                });
                this.refreshCurrency();
                // notify(store, 'success', 'currency was updated successfully');
              })
              .catch((err) => {
                this.setState({
                  loader: false,
                  message: nullcheck(err.response) == "" ? "server error" : err.response.data,
                });
              });
        })
        .catch((err) => {
          this.setState({
            loader: false,
            message: err.errors,
          });
        });
  }
  getCurrency = (budgetPeriodId) => {
    this.setState({
      loader: true,
      message: "",
    });
    axios
        .get(`${BASE_API_URL}/currency/GetCurrencyByBudgetPeriod?budgetPeriodId=${budgetPeriodId}`)
        .then((response) => {
          console.log(response.data);
          this.setState({
            allCurrencyData: response.data,
            currency:response.data.length==0?"":response.data[0],
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            loader: false,
            message: nullcheck(err.response) == "" ? "server error" : err.response.data,
          });
        });
  };

  refreshCurrency() {
    this.setState({
      loader: true,
      message: "",
    });
    axios
        .get(`${BASE_API_URL}/currencyBymonth`)
        .then((response) => {
          this.setState({
            budgetCurrencies: response.data,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            loader: false,
            message: nullcheck(err.response) == "" ? "server error" : err.response.data,
          });
        });
  }
  getBudgetPeriod() {
    this.setState({
      loader: true,
      message: "",
    });
    axios
        .get(`${BASE_API_URL}/BudgetPeriod/GetBudgetPeriod`)
        .then((response) => {
          this.setState({
            budgetPeriods: response.data,
            budgetPeriodID:
                response.data.length === 0 ? "" : response.data[0],
            loader: false,
            message:""
          });
        })
        .catch((err) => {
          this.setState({
            loader: false,
            message: nullcheck(err.response) == "" ? "server error" : err.response.data,
          });
        });
  }
  handleDelete = () => {
    let id = this.state.deleteId
    this.setState({
      loader: true,
      message: "",
      deleteModal: false
    });
    axios
        .delete(`${BASE_API_URL}/currencyBymonth?id=${id}`)
        .then((response) => {
          this.setState({
            loader: false,
            message: "",
            deleteModal: false,
            deleteId: "",
            id:"",
          });
        //   notify(store, 'success', 'currency was deleted successfully');
          this.refreshCurrency();
        })
        .catch((err) => {
          this.setState({
            loader: false,
            message: nullcheck(err.response) == "" ? "server error" : err.response.data,
            deleteModal: false,
            deleteId: ""
          });
        });

  };
  handleCancel=()=>{
    this.setState({

      deleteModal: false,
      deleteId: "",

      id:"",
      title: "",
      nairaRate: "0",
      month:"",
      budgetPeriodId: "",
      loader: false,
      message: "",
    });
  }


  handleSelectedCurrencyChange = (selectedCurrency) => {
    this.setState({ selectedCurrency, currency: selectedCurrency }, () =>
        console.log(`Option selected:`, selectedCurrency)
    );
  };
  handleSelectedBudgetPeriodChange = (selectedBudgetPeriod) => {
    this.setState({ selectedBudgetPeriod, budgetPeriodID: selectedBudgetPeriod }, () =>
        console.log(`Option selected:`, selectedBudgetPeriod)
    );
    this.getCurrency(selectedBudgetPeriod.value)
  };
  handleSelectedMonthChange = (selectedMonth) => {
    this.setState({ selectedMonth, month: selectedMonth }, () =>
        console.log(`Option selected:`, selectedMonth)
    );
  };

  getMonths = () => {
    let months = [
      {value: 1, label: "January"},
      {value: 2, label: "February"},
      {value: 3, label: "March"},
      {value: 4, label: "April"},
      {value: 5, label: "May"},
      {value: 6, label: "June"},
      {value: 7, label: "July"},
      {value: 8, label: "August"},
      {value: 9, label: "September"},
      {value: 10, label: "October"},
      {value: 11, label: "November"},
      {value: 12, label: "December"},
    ];
    this.setState({
      months: months,
      month: months[0],
    })

  }
  getMonth=(value)=>{
    var monthInfo= this.state.months.filter((month)=>{
      return month.value==value;
    })
    return monthInfo.length==0?"":monthInfo[0].label
  }
  render() {

    let searchBudgetCurrency = this.state.budgetCurrencies.filter((newBudgetCurrency) => {

      return newBudgetCurrency.currency.title.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
    })


    let budgetCurrencies = searchBudgetCurrency.map((budgetCurrency) => {

      return (
          <tr
              key={budgetCurrency.id}
              style={{
                borderBottom: "1px solid #C7C7C7",
              }}
          >
            <td className="px-6 py-4 whitespace-nowrap">
              <div
                  className="bg-pen"
                  onClick={() => this.handleEdit(budgetCurrency)}
              >
                {/* <svg
                    width="18"
                    height="18"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#fff"
                >
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  />
                </svg> */}
                            <BsPencilSquare style={{backgroundColor:"#43425D", color:"white", fontSize:"26px", padding:"5px"}}/>

              </div>
            </td>
            <td className="px-6 py-4  text-sm font-avenir-medium table-color">
              {budgetCurrency.currency.title}
            </td>
            <td className="px-6 py-4  text-sm font-avenir-medium table-color">
              {formatNaira(budgetCurrency.naira)}
            </td>
            <td className="px-6 py-4  text-sm font-avenir-medium table-color">
              {budgetCurrency.currency.budgetPeriod.title}
            </td>
            <td className="px-6 py-4  text-sm font-avenir-medium table-color">
              {this.getMonth(budgetCurrency.month)}
            </td>
            <td className="px-6 py-4  text-sm font-avenir-medium">
              {true ? (
                  <a

                      className="cursor-pointer"
                      onClick={() =>
                          this.setState({
                            deleteModal: !this.state.deleteModal,
                            deleteId: budgetCurrency.id

                          })
                      }
                  >
                            <AiOutlineDelete style={{backgroundColor:"#43425D", color:"white", fontSize:"26px", padding:"5px"}}/>

                    {/* <img src="/delete-icon.png" className="ml-5 h-5 w-5" /> */}
                  </a>
              ) : (
                  <svg
                      className="animate-spin ml-5 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                  >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="#43425D"
                        stroke-width="4"
                    ></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
              )}
            </td>
          </tr>
      );
    });
    return (
        <React.Fragment>
          {this.state.loader === true ? <Preloader /> : ""}
          {this.state.deleteModal && (
              <>
                <div className=" p-10 overlay justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                  <div className="relative w-3/12 my-6 mx-auto bg-white p-4">
                    {/*content*/}
                    <div className=" py-4 px-4 shadow-sm overflow-hidden cursor-pointer">
                      <p className="flex-1 p-10 mt-2 text-sm font-medium font-avenir-black text-color text-center">
                        Are you sure you want to
                        delete?
                      </p>
                    </div>
                    <div class="flex justify-center p-10">
                      <button onClick={() => this.handleDelete()}
                              type="submit"
                              class="mr-5 w-40 pointer inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"

                      >

                        Yes
                      </button>
                      <button
                          type="button"
                          class="w-40 bg-white py-2 pointer px-4 border border-gray-300 shadow-sm text-sm font-medium text-color bg-gray-200 hover:bg-gray-50 focus:outline-none"
                          onClick={() =>
                              this.setState({
                                deleteModal: !this.state.deleteModal,
                              })
                          }
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
          )}

          {this.state.budgetPeriods ? (
              <React.Fragment>
                {this.state.open ? (
                    <div className="mb-3">
                      <div
                          className="bg-card-header pointer m-b-5 py-4 px-4 shadow-sm overflow-hidden bb-green cursor-pointer"
                          onClick={() => this.setState({ open: !this.state.open })}
                      >
                        <div className="flex-between  flex-align">
                          <p className="flex-1  text-sm font-medium font-avenir-black text-color">
                            Budget Currency Conversation Settings Per Month
                          </p>
                          <input
                              placeholder="Search"
                              className="border border-solid p-5 px-2 h-8 w-50 mt-2"
                              style={{
                                backgroundColor: "transparent",
                                borderColor: "#707070",
                              }}
                              name='search'
                              onChange={this.handleChange}
                              onClick={(e) => e.stopPropagation()}
                          />
                        </div>
                      </div>
                      <div className="w-100 flex bg-white py-4 pl-4 shadow-sm cursor-pointer">
                        <div className="w-40 mr-10">
                          <div  className="w-100 p-10 m-t-3 m-r-5 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="sm:col-span-12">
                              {this.state.message !== "" ? (
                                //   <ValidationMessageComponent
                                //       message={this.state.message}
                                //   ></ValidationMessageComponent>
                                <p>yyyyyyy</p>
                              ) : (
                                  ""
                              )}
                              <label
                                  htmlFor="activeBudgetPeriod"
                                  className="block  text-sm font-medium label-color"
                              >
                                Active Budget Period
                              </label>
                              <div className="mt-1">
                                <Select
                                    name="budgetPeriodID"
                                    id="budgetPeriodID"
                                    options={this.state.budgetPeriods}
                                    value={this.state.budgetPeriodID}
                                    onChange={this.handleSelectedBudgetPeriodChange}
                                    className="shadow-sm focus:outline-none block w-full sm:text-sm"
                                >

                                </Select>
                              </div>
                            </div>
                            <div className="sm:col-span-12">
                              <label
                                  htmlFor="currency"
                                  className="block  text-sm font-medium label-color"
                              >
                                Currency
                              </label>
                              <div className="mt-1">
                                <Select
                                    name="currency"
                                    id="currency"
                                    onChange={this.handleSelectedCurrencyChange}
                                    options={this.state.allCurrencyData}
                                    value={this.state.currency}

                                    className="shadow-sm focus:outline-none block w-full sm:text-sm"
                                >

                                </Select>
                              </div>
                            </div>
                            <div className="sm:col-span-12">
                              <label
                                  htmlFor="currency"
                                  className="block  text-sm font-medium label-color"
                              >
                                Month
                              </label>
                              <div className="mt-1">
                                <Select
                                    name="month"
                                    id="month"
                                    onChange={this.handleSelectedMonthChange}
                                    value={this.state.month}
                                    options={this.state.months}
                                    className="shadow-sm focus:outline-none block w-full sm:text-sm"
                                >

                                </Select>
                              </div>
                            </div>
                            <div className="sm:col-span-12">
                              <label
                                  htmlFor="nairaEquivalent"
                                  className="block  text-sm font-medium label-color"
                              >
                                Naira Equivalent
                              </label>
                              <div className="mt-1">
                                <input
                                    type="text"
                                    name="nairaRate"
                                    id="nairaRate"
                                    value={this.state.nairaRate}
                                    onChange={this.handleNairaRate}
                                    className="shadow-sm focus:outline-none block w-full sm:text-sm"
                                />
                              </div>
                            </div>
                            <div className="pt-5 sm:col-span-12">
                              <div className="flex">
                                <button
                                    type="submit"
                                    className="mr-5 w-40 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                                    onClick={
                                      this.state.editMode
                                          ? this.handleEditSubmit.bind(this)
                                          : this.addCurrency.bind(this)
                                    }
                                >
                                  {this.state.loading && (
                                      <svg
                                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                      >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            stroke-width="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                      </svg>
                                  )}
                                  Save Record
                                </button>
                                <button
                                    type="button"
                                    onClick={this.handleCancel}
                                    className="w-40 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-color bg-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" w-100 w-4/6 md:flex-grow" style={{ marginTop: -9 }}>
                          <div className="w-100 p-10 flex flex-col" >
                            <div className="w-100 -my-2 overflow-y-scroll sm:-mx-6 lg:-mx-8">
                              <div className="w-100 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <table className="w-100 min-w-full">
                                  <thead className="bg-table-header">
                                  <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                                    >
                                      <svg
                                          version="1.0"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 1278.000000 1280.000000"
                                          preserveAspectRatio="xMidYMid meet"
                                      >
                                        <g
                                            transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                                            fill="#fff"
                                            stroke="none"
                                        >
                                          <path
                                              d="M5565 12793 c-437 -20 -955 -111 -1410 -249 -859 -261 -1717 -753
                                          -2352 -1348 -51 -47 -95 -86 -97 -86 -3 0 -122 149 -266 330 -144 182 -282
                                          347 -307 368 -24 20 -68 60 -98 88 -74 70 -128 94 -212 94 -114 0 -208 -61
                                          -264 -169 -33 -64 -36 -151 -43 -1161 -4 -481 -11 -1322 -17 -1869 -5 -546 -8
                                          -995 -6 -997 4 -5 -24 -16 1912 766 270 109 738 298 1040 420 303 122 569 233
                                          591 246 53 30 90 74 122 143 33 72 33 152 -2 262 -29 95 -66 146 -130 184 -36
                                          21 -584 207 -858 292 -49 15 -88 30 -88 33 0 14 236 191 377 283 309 200 595
                                          339 927 449 394 131 768 200 1181 217 512 22 983 -45 1478 -210 372 -123 711
                                          -285 1020 -485 54 -35 100 -64 102 -64 3 0 16 18 30 40 l26 41 79 -56 79 -56
                                          34 53 c231 364 837 1361 831 1367 -13 12 -246 160 -334 213 -431 259 -963 489
                                          -1460 632 -642 185 -1242 258 -1885 229z"
                                          />
                                          <path
                                              d="M9310 10957 c0 -8 -16 -125 -35 -259 -36 -246 -187 -1284 -295 -2033
                                          -132 -913 -161 -1115 -181 -1249 -30 -199 -29 -257 6 -329 34 -68 78 -110 152
                                          -144 105 -48 201 -40 295 25 24 17 198 172 387 346 190 173 346 314 347 313 2
                                          -1 10 -47 18 -102 98 -641 32 -1341 -189 -1985 -292 -851 -810 -1542 -1558
                                          -2078 -146 -105 -276 -186 -464 -290 l-152 -84 19 -39 c11 -21 20 -42 20 -46
                                          0 -5 -34 -25 -76 -46 -41 -21 -74 -41 -72 -45 6 -15 731 -1357 750 -1389 l19
                                          -33 62 32 c391 197 862 515 1227 826 792 678 1366 1481 1720 2407 269 705 400
                                          1415 400 2175 0 429 -36 796 -117 1190 -20 96 -38 187 -40 201 l-5 26 264 42
                                          c521 83 548 89 623 125 39 18 90 39 115 46 109 30 184 99 215 196 34 107 -4
                                          239 -88 308 -36 29 -3302 1884 -3349 1902 -12 4 -18 1 -18 -9z"
                                          />
                                          <path
                                              d="M4 7453 c-4 -49 -4 -214 0 -368 22 -980 241 -1847 696 -2755 431
                                          -860 1128 -1639 1965 -2197 435 -289 894 -512 1450 -704 91 -32 139 -53 137
                                          -61 -2 -7 -73 -195 -159 -418 -173 -450 -183 -480 -183 -564 0 -76 26 -131 88
                                          -186 26 -23 72 -66 103 -96 96 -92 206 -124 311 -89 58 19 3339 1905 3335
                                          1918 -6 19 -2978 2350 -3024 2372 -102 49 -253 26 -329 -50 -14 -14 -47 -36
                                          -73 -49 -54 -28 -116 -92 -137 -142 -28 -68 -18 -148 77 -580 50 -229 88 -418
                                          85 -421 -7 -7 -353 163 -481 237 -626 361 -1171 883 -1561 1497 -363 571 -573
                                          1185 -660 1928 -14 113 -18 231 -18 476 l-1 326 -149 7 c-82 3 -445 6 -807 6
                                          l-657 0 -8 -87z"
                                          />
                                        </g>
                                      </svg>
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                                    >
                                      Currency
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left  text-sm font-avenir-mediumt text-white tracking-wider"
                                    >
                                      Naira Rate
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                                    >
                                      Budget Year
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                                    >
                                      Month
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                                    >
                                      Action
                                    </th>
                                  </tr>
                                  </thead>
                                  <tbody className="bg-white">
                                  {budgetCurrencies}
                                  </tbody>
                                </table>
                                {true && (
                                    <p
                                        className=" text-sm font-normal text-color py-4"
                                        hidden
                                    >
                                      Showing 1 to 3 of 3 entries
                                    </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.showNotification && (
                          <div class="mt-20 fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
                            <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                              <div class="p-4">
                                <div class="flex items-start">
                                  <div class="flex-shrink-0">
                                    {this.state.notificationSuccess ? (
                                        <svg
                                            class="h-6 w-6 text-green-400"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                          <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              stroke-width="2"
                                              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                          />
                                        </svg>
                                    ) : (
                                        <svg
                                            class="h-6 w-6 text-green-400"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                          <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              stroke-width="2"
                                              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                          />
                                        </svg>
                                    )}
                                  </div>
                                  <div class="ml-3 w-0 flex-1 pt-0.5">
                                    <p class="text-sm font-medium text-gray-900">
                                      {this.state.notificationMessage}
                                    </p>
                                  </div>
                                  <div class="ml-4 flex-shrink-0 flex">
                                    <button
                                        class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={() =>
                                            this.setState({
                                              showNotification: !this.state
                                                  .showNotification,
                                            })
                                        }
                                    >
                                      <span class="sr-only">Close</span>
                                      <svg
                                          class="h-5 w-5"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                          fill="currentColor"
                                          aria-hidden="true"
                                      >
                                        <path
                                            fill-rule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      )}
                    </div>
                ) : (
                    <div
                        className="bg-white pointer py-4 px-4 shadow-sm overflow-hidden cursor-pointer mb-3"
                        onClick={() => this.setState({ open: !this.state.open })}
                    >
                      <p className=" text-sm font-medium text-color">
                        Budget Currency Conversation Settings Per Month
                      </p>
                    </div>
                )}
              </React.Fragment>
          ) : (
              ""
          )}
        </React.Fragment>
    );
  }
}
