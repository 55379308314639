import React, { Component } from "react";

import Select from "react-select";
import axios from "axios";
// import { store } from 'react-notifications-component';
import { formatDefaultDate, nullcheck, notify,formatNaira } from '../../Utils/Helper'
import {Preloader} from "../../Utils/Preloader";
import { AiOutlineFileExcel,AiOutlinePrinter, AiOutlineSearch } from 'react-icons/ai';

// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
import Cookies from 'js-cookie'

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';

export default class VarianceReportC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      mdas:[],
      budgetPeriods:[],
      budgetPeriod:"",
      allBudgetInformationData:[],

      mda:"",
      loading: false,
      deleteId: "",
      edit: false,
      editId: "",
      submenu: false,
      showModal: false,
      total: 0,
      message:"",
      loader:"",
      search:"",
    };
  }
  componentDidMount() {
    this.getMdas();
    this.getBudgetPeriods();
    this.getBudgetInformation();
  }
  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };
  getMdas=()=>{
    this.setState({
      loader: true,
      message: "",
    });
    axios.get(`${BASE_API_URL}/mda/GetAllMda`).then((response)=>{
      this.setState({
        mdas:response.data,
        loader: false,
        message: "",
      })
    }).catch((err)=>{
      this.setState({
        loader: false,

        message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      })
    })
  }
  getBudgetPeriods=()=>{
    this.setState({
      loader: true,
      message: "",
    });
    axios.get(`${BASE_API_URL}/BudgetPeriod/GetBudgetPeriod`).then((response)=>{
      this.setState({
        budgetPeriods:response.data,
        loader: false,
        message: "",
      })
    }).catch((err)=>{
      this.setState({
        loader: false,

        message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      })
    })
  }

  getBudgetInformation = () => {

    axios
        .get(`${BASE_API_URL}/budgetinformation/GetBudgetmentInformation`)

        .then((response) => {
          this.setState({
            loader: false,
            allBudgetInformationData: response.data,
            message: "",
          });
          console.log(response.data)
          //let departmentBudgetId = response.data.map(a => a.departmentBudgetId);
          //this.checkIfBudgetHaveAlreadyBeenAddded(departmentBudgetId)
          //this.getBudgetApprovalRole(departmentBudgetId)
          ;
        })
        .catch((err) => {
          console.log(err)
        });
  };
  getBudgetInformationByMdaId = (mdaId) => {
    this.setState({
      loader: true,
      message: "",
    });
    axios
        .get(`${BASE_API_URL}/budgetinformation/GetBudgetInformationByMdaId?mdaId=${mdaId.toString()}`)

        .then((response) => {
          this.setState({
            loader: false,
            allBudgetInformationData: response.data,
            message: "",
          });

          ;
        })
        .catch((err) => {
         this.setState({
           loader: false,
           message: "",
         })
        });
  };
  getBudgetInformationByBudgetPeriod = (budgetPeriodId) => {
    this.setState({
      loader: true,
      message: "",
    });
    axios
        .get(`${BASE_API_URL}/budgetInformation/getBudgetInformationByBudgetPeriod?budgetPeriodId=${budgetPeriodId}`)

        .then((response) => {
          this.setState({
            loader: false,
            allBudgetInformationData: response.data,
            message: "",
          });

        })
        .catch((err) => {
          this.setState({
            loader: false,
            message: "",
          })
        });
  };
  getBudgetInformationByBudgetPeriodAndMda = (mdaId,budgetPeriodId) => {
    this.setState({
      loader: true,
      message: "",
    });
    axios
        .get(`${BASE_API_URL}/budgetInformation/GetBudgetInformationByMdaIdAndBudgetPeriod?mdaId=${mdaId}&budgetPeriodId=${budgetPeriodId}`)

        .then((response) => {
          this.setState({
            loader: false,
            allBudgetInformationData: response.data,
            message: "",
          });

        })
        .catch((err) => {
          this.setState({
            loader: false,
            message: "",
          })
        });
  };
  handleExportCsv = (table_id, separator = ",") => {
    // Select rows from table_id
    var rows = document.querySelectorAll("table#" + table_id + " tr");
    // Construct csv
    var csv = [];
    for (var i = 0; i < rows.length; i++) {
      var row = [],
          cols = rows[i].querySelectorAll("td, th");
      for (var j = 0; j < cols.length; j++) {
        // Clean innertext to remove multiple spaces and jumpline (break csv)
        var data = cols[j].innerText
            .replace(/(\r\n|\n|\r)/gm, "")
            .replace(/(\s\s)/gm, " ");
        // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
        data = data.replace(/"/g, '""');
        // Push escaped string
        row.push('"' + data + '"');
      }
      csv.push(row.join(separator));
    }
    var csv_string = csv.join("\n");
    // Download it
    var filename =
        "export_" + table_id + "_" + new Date().toLocaleDateString() + ".csv";
    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  handlePrint = (table_id) => {
    var divToPrint = document.getElementById(table_id);
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  };

  handleSearch = () => {

    let mdaId = this.state.mda;
    let budgetPeriodId = this.state.budgetPeriod;

    if (mdaId == "" && budgetPeriodId == "") {
      this.getBudgetInformation();
    } else if (mdaId == "") {

      this.getBudgetInformationByBudgetPeriod(budgetPeriodId)
    } else if (budgetPeriodId == "") {
      this.getBudgetInformationByMdaId(mdaId)

    } else {
      this.getBudgetInformationByBudgetPeriodAndMda(mdaId,budgetPeriodId);
    }


  }




  render() {
    let mdas=this.state.mdas.map((mda)=>{
      return(
          <option value={mda.value}>{mda.label}</option>
      )
    })
    let budgetPeriods=this.state.budgetPeriods.map((budgetPeriod)=>{
      return(
          <option value={budgetPeriod.value}>{budgetPeriod.label}</option>
      )
    })
    var alltotal = 0;

    var janVal = 0;
    var febVal = 0;
    var marVal = 0;
    var aprVal = 0;
    var mayVal = 0;
    var junVal = 0;
    var julVal = 0;
    var augVal = 0;
    var sepVal = 0;
    var octVal = 0;
    var novVal = 0;
    var decVal = 0;

    var total = 0;

    const budgetInformations = this.state.allBudgetInformationData.filter(
        (budgetInformation) => {
          return budgetInformation.departmentBudget.programmes.programmeName.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
        }

    );
    var allBudgetInformations = budgetInformations.map((budgetInformation) => {
      janVal += parseFloat(budgetInformation.jan_val);
      febVal += parseFloat(budgetInformation.feb_val);
      marVal += parseFloat(budgetInformation.mar_val);

      aprVal += parseFloat(budgetInformation.apr_val);
      mayVal += parseFloat(budgetInformation.may_val);
      junVal += parseFloat(budgetInformation.june_val);
      julVal += parseFloat(budgetInformation.july_val);
      augVal += parseFloat(budgetInformation.aug_val);
      sepVal += parseFloat(budgetInformation.sept_val);
      octVal += parseFloat(budgetInformation.oct_val);
      novVal += parseFloat(budgetInformation.nov_val);
      decVal += parseFloat(budgetInformation.dec_val);
      let total =
          budgetInformation.jan_val
          + budgetInformation.feb_val
          + budgetInformation.mar_val
          + budgetInformation.apr_val
          + budgetInformation.may_val
          + budgetInformation.june_val
          + budgetInformation.july_val
          + budgetInformation.aug_val
          + budgetInformation.sept_val
          + budgetInformation.oct_val
          + budgetInformation.nov_val
          + budgetInformation.dec_val
      alltotal+=total
      total += budgetInformation.total;
      return (
          <tr>
            <td  className="table-border-right p-2 text-sm font-medium text-gray-900" >
              {budgetInformation.departmentBudget.programmes == null ? "" : budgetInformation.departmentBudget.programmes.programmeName}
            </td>
            <td className="table-border-right p-2 text-sm text-color">
              {budgetInformation.departmentBudget.item}
            </td>
            <td className="table-border-right p-2 text-sm text-color">
              {budgetInformation.currency.title}
            </td>
            <td className="table-border-right p-2 text-sm text-color">
              {budgetInformation.jan_val}
            </td>
            <td className="table-border-right p-2 text-sm text-color">
              {budgetInformation.feb_val}
            </td>
            <td className="table-border-right p-2 text-sm text-color">
              {budgetInformation.mar_val}
            </td>
            <td className="table-border-right p-2 text-sm text-color">
              {budgetInformation.apr_val}
            </td>
            <td className="table-border-right p-2 text-sm text-color">
              {budgetInformation.may_val}
            </td>
            <td className="table-border-right p-2 text-sm text-color">
              {budgetInformation.june_val}
            </td>
            <td className="table-border-right p-2 text-sm text-color">
              {budgetInformation.july_val}
            </td>
            <td className="table-border-right p-2 text-sm text-color">
              {budgetInformation.aug_val}
            </td>
            <td className="table-border-right p-2 text-sm text-color">
              {budgetInformation.sept_val}
            </td>
            <td className="table-border-right p-2 text-sm text-color">
              {budgetInformation.oct_val}
            </td>
            <td className="table-border-right p-2 text-sm text-color">
              {budgetInformation.nov_val}
            </td>
            <td className="table-border-right p-2 text-sm text-color">
              {budgetInformation.dec_val}
            </td>

          </tr>
      );
    });
    return (
        <React.Fragment>
          {this.state.loader === true ? <Preloader /> : ""}
          <div class="w-100 flex-between p-10 flex bg-white shadow-sm ">
            <table className="w-100 flex-between min-w-full shadow overflow-hidden"   >
              <tr className="w-100 flex-between flex-align bg-white shadow-sm " >
               

              {/* </tr> */}
              {/* <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr> */}
             <div className=" flex-align flex-between m-r-5">
                    <td>
                <div >
                <p>Budget Agency</p>
                
                <select className='border m-r-5' style={{lineHeight:"200px", fontSize:'15px', padding:'5px'}} onChange={this.handleChange} id="mda" name="mda"
                className="shadow-sm focus:outline-none block w-full sm:text-sm">
                <option value="">All</option>
                {mdas}
                </select>
                </div>
                </td>
                <td>
                <div className="">
                <p>Budget Period</p>
                
                <select className='border m-r-5' style={{lineHeight:"200px", fontSize:'15px', padding:'5px'}} onChange={this.handleChange} name="budgetPeriod" id="budgetPeriod" className="shadow-sm focus:outline-none block w-full sm:text-sm">
                <option value="">All</option>
                {budgetPeriods}
                </select>
                </div>
                </td>
                <td>
                <div className="m-l-5">
                <div style={{ marginLeft:""}} className=" cursor-pointer" onClick={this.handleSearch}> <AiOutlineSearch style={{fontSize:'32px',  backgroundColor: '#1d811c', padding:'3px', color:'white'}}/></div>
                    <div style={{ marginLeft:""}} className=" cursor-pointer" onClick={()=>this.handlePrint("table_info")}><AiOutlinePrinter style={{fontSize:'32px',  backgroundColor: '#1d811c', padding:'3px', color:'white'}}/></div>
                {/* <div className="inline-block focus:outline-none cursor-pointer" onClick={()=>this.handleSearch()}> <img className="h-10 w-10 mr-5" src="/searchicons.png" /></div> */}
                {/* <div className="inline-block focus:outline-none cursor-pointer" onClick={()=>this.handlePrint("tableinfo")} ><img className="h-10 w-10 mr-5" src="/print.png"/></div> */}
                
                </div>
                </td>
            </div>

                <td className="w-60 right-0"  >

                  <div  className="right-0 flex-align">
                    <button
                        onClick={()=>this.handleExportCsv("tableinfo")}
                        type="submit"
                        className="m-r-5 h-10 inline-flex py-2 pr-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                    >
                      {/* <img
                          src="/upload-excel.png"
                          style={{ width: 18, height: 18 }}
                          className="mx-3"
                      /> */}
                      Excel Export
                    </button>
                    <button onClick={()=>this.handlePrint("tableinfo")}

                        type="submit"
                        className="m-r-5 h-10 inline-flex py-2 pr-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                    >
                      {/* <img
                          src="/upload-excel.png"
                          style={{ width: 18, height: 18 }}
                          className="mx-3"
                      /> */}
                      Print Document
                    </button>
                    <input
                        placeholder="Search"
                        name="search"
                        id="search"
                        className="border border-solid p-5 h-10 w-70 ml-10 "
                        style={{
                          backgroundColor: "transparent",
                          borderColor: "#707070",
                          width:'50%',
                        }}

                        name='search'
                        value={ this.state.search}
                        onChange={this.handleChange}
                    />
                  </div>

                </td>

              </tr>
            </table>
          </div>


          <div class="flex w-100 flex-col">
            <table class="min-w-full w-100 shadow overflow-hidden" id="tableinfo">
              <thead class="bg-gray-table-header w-100">
              <tr>


                <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                 Category
                </th>
                <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                 Budget Item
                </th>
                <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  Currency
                </th>
                <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                 Jan
                </th>
                <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  Feb
                </th>
                <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  Mar
                </th>
                <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  Apr
                </th>  <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  May
                </th>
                <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  Jun
                </th>
                <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  Jul
                </th>
                <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  Aug
                </th>
                <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  Sep
                </th>  <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  Oct
                </th>
                <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  Nov
                </th>
                <th
                    scope="col"
                    class="table-border-right px-3 py-3 text-right text-sm font-medium font-avenir-black font-extrabold tracking-wider"
                    style={{color:"#000000",textShadow:"1px 0 #000000"}}
                >
                  Dec
                </th>

              </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">

              {allBudgetInformations}

              </tbody>
            </table>
          </div>
          <p className="text-sm font-normal text-color mt-4">

          </p>


        </React.Fragment>
    );
  }
}
