import React, { Component } from "react";
import Select from "react-select";
import { store } from 'react-notifications-component';
import { nullcheck, notify } from '../../Utils/Helper'
import notification from "../../utility/notification";
 
import { AiOutlineDelete } from 'react-icons/ai';
import { BsPencilSquare } from 'react-icons/bs';
import {  Input } from 'antd';

import axios from "axios";
import { Preloader } from "../../Utils/Preloader";
import ValidationMessageComponent from "./ValidationMessage";
import fundItemValidationSchema from "../../Validation/FundItemValidation";

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';
// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
function formatDefaultDate(dateString) {
 
  var newDate = new Date(dateString);
  newDate.setDate(newDate.getDate() + 1);

  return newDate.toISOString().split("T")[0];
}

export default class FundsItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      fundsSegmentData: [],
      fundsSegment: "",
      fundType: "",
      fundTypeData: [],
      fundItem: "",
      fundItemData: [],
      code: "",
      loading: false,
      deleteId: "",
      edit: false,
      editId: "",
      id: "",
      message: "",
      showNotification: false,
      notificationSuccess: false,
      notificationMessage: "Successfully saved!",
        loader: false,
        deleteModal: false,
     search:""
    };
  }
  componentDidMount() {
    //this.getFundTypes();
    this.getFundSegments();
    this.getFundItems();
  }

  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
    };
    handleSelectChange = (ev) => {
      // console.log(allfundType)
        this.setState({
            [ev.target.name]: ev.target.value,
        });
        let fundTypeData = this.state.fundTypeData;
       
        let fundTypeInfo = fundTypeData.filter((fundType) => {
            return fundType.value == ev.target.value;
        })
        this.setState({
            code: fundTypeInfo.length == 0 ? "" : fundTypeInfo[0].code
        })
        // let option = { value: data.fundType.id, label: data.fundType.fundTypeName };
        //var value=
    };

    
    getEmployee=(id)=>{
        const employeesData=this.props.employees;
        const employeeInfo=employeesData.filter((employee)=>{

            return employee.id==id;
        })

        return employeeInfo.length==0?"":employeeInfo[0].firstName+ ' '+employeeInfo[0].lastName

    }
  handleSelectedFundSegmentChange = (selectedFundSegment) => {
    this.setState(
      { selectedFundSegment, fundsSegment: selectedFundSegment },
      () => console.log(`Option selected:`, selectedFundSegment)
    );
    var id = selectedFundSegment.value;
    this.getFundTypes(id);
    };

    handleCodeChange = (ev) => {
        if (ev?.target?.value != "") {
            let prev = this?.state?.code?.substr(0, 3)
            if (ev.target?.value?.length < 3) {
                this.setState({
          [ev.target.name]: ev.target.value,
          // [ev?.target?.name]: prev,
                });

            } else {
                this.setState({
                    [ev?.target?.name]: ev?.target?.value,
                });
            }

        }

    };

  handleSubmit = () => {
    // let f = this.state.fundsSegment.value;
      let fundTypeId = this.state.fundType === "" ? "" : this.state.fundType.value;
      
       let fundName = this.state?.fundItem;
      let fundCode = this.state?.code?.slice(3);
      let enteredBy=this.props?.employeeId?.toString()
      let updatedBy=this.props?.employeeId?.toString()

      let data = { fundTypeId, fundName, fundCode, updatedBy,updatedBy };

    fundItemValidationSchema
      .validate(data)
      .then((message) => {
        this.setState({
          loader: true,
          message: "",
        });
        axios
          .post(`${BASE_API_URL}/funditem`, { ...data })
          .then((response) => {
            this.setState({
              code: "",
              fundItem: "",
              fundType:'',
              fundTypeData:[],
              loader: false,
              message: "",
            });
              // notify(store, 'success', 'fund item added successfully');
              notification({
                title: 'Successful',
                message: 'fund item added successfully',
                type: 'success'
          });
            this.getFundItems();
          })
          .catch((err) => {
            this.setState({
              loader: false,
                message: nullcheck(err.response) == "" ? "server error" : err.response.data,
            });
          });
      })
      .catch((err) => {
        this.setState({
          loader: false,
          message: err.errors,
        });
      });
  };

    handleDelete = () => {

        let id = this.state.deleteId
    this.setState({
      loader: true,
        message: "",
        deleteModal: false,
        deleteId: '',
    });
    axios
      .delete(`${BASE_API_URL}/funditem?id=${id}`)
      .then((response) => {
        this.setState({
          code: "",
          fundItem: "",
          fundType: "",
          loader: false,
          message: "",
          edit: false,
            editId: "",
            deleteModal: false,
            deleteId: '',
        });
          // notify(store, 'success', 'fund item deleted successfully');
          notification({
            title: 'Successful',
            message: 'fund item deleted successfully',
            type: 'success'
      });
        this.getFundItems();
      })
      .catch((err) => {
        this.setState({
          loader: false,
            message: nullcheck(err.response) == "" ? "server error" : err.response.data,
            deleteModal: false,
            deleteId: '',
        });
      });
  };

  handleEdit = (data) => {
    let option = { value: data.fundType.id, label: data.fundType.fundTypeName };
    let fundSegments = data.fundType.fundSegment;
    let selectedFundSegments = {
      value: fundSegments.id,
      label: fundSegments.fundSegmentName,
    };

    // this.getFundTypes(id);
    this.setState({
      fundsSegment: selectedFundSegments,
      fundTypeData: [option],
      fundItem: data.fundName,
        code: data.fundType.fundSegment.fundSegmentCode+""+ data.fundType.fundTypeCode+""+ data.fundCode,
      edit: true,
      fundType: data.fundTypeID,
      id: data.id,
    });
    };

    handleCancel = () => {
       
        this.setState({
            code: "",
            fundItem: "",
            fundType: "",
            loader: false,
            message: "",
            edit: false,
            editId: "",
            deleteModal: false,
            deleteId: '',
        });

    }

  handleEditSubmit = () => {
    let fundTypeId = this.state.fundType;
    let fundName = this.state.fundItem;
    let fundCode = this.state.code.slice(3);
      let id = this.state.id;
      let updatedBy=this.props.employeeId.toString()
      // let updatedBy=this.props.employeeId.toString()

      let data = { fundCode, fundName, fundTypeId, id, updatedBy };

    fundItemValidationSchema
      .validate(data)
      .then((message) => {
        this.setState({
          loader: true,
          message: "",
        });
        axios
          .put(`${BASE_API_URL}/funditem`, { ...data })
          .then((response) => {
            this.setState({
              code: "",
              fundItem: "",
              fundType: "",
              loader: false,
              message: "",
              edit: false,
              editId: "",
            });
              // notify(store, 'success', 'fund item updated successfully');
              notification({
                title: 'Successful',
                message: 'fund item updated successfully',
                type: 'success'
          });
            this.getFundItems();
          })
          .catch((err) => {
            this.setState({
              loader: false,
                message: nullcheck(err.response) == "" ? "server error" : err.response.data,
            });
          });
      })
      .catch((err) => {
        this.setState({
          loader: false,
          message: err.errors,
        });
      });
  };
  getFundTypes = (segmentId) => {
    this.setState({
      loader: true,
      message: "",
    });
    axios
      .get(
        `${BASE_API_URL}/fundtype/getfundtypebyfundsegmentid?fundSegmentId=${segmentId}`
      )
      .then((response) => {
        this.setState({
          fundTypeData: response.data,
            fundType: response.data.length === 0 ? "" : response.data[0],
            code: response.data.length === 0 ? "" : response.data[0].code,
          loader: false,
          message: "",
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
            message: nullcheck(err.response) == "" ? "server error" : err.response.data,
        });
      });
  };
  getFundSegments = () => {
    this.setState({
      loader: true,
      message: "",
    });
    axios
      .get(`${BASE_API_URL}/fundsegment/getfundsegment`)
      .then((response) => {
        this.setState({
          fundsSegmentData: response.data,
          loader: false,
          message: "",
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
            message: nullcheck(err.response) == "" ? "server error" : err.response.data,
        });
      });
  };
  getFundItems = () => {
    this.setState({
      loader: true,
      message: "",
    });
    axios
      .get(`${BASE_API_URL}/funditem`)
      .then((response) => {
        console.log(response.data);
        this.setState({
          fundItemData: response.data,
          loader: false,
          message: "",
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
            message: nullcheck(err.response) == "" ? "server error" : err.response.data,
        });
      });
  };
    render() {

        let fundItems = this.state.fundItemData.filter((fundItem) => {

            return (fundItem.fundName.toLowerCase()).indexOf(this.state.search.toLowerCase()) !== -1;
        })

        let allfundItemData = fundItems.map((fundItem) => {
      return (
        <tr
          style={{
            borderBottom: "1px solid #C7C7C7",
          }}
        >
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="bg-pen" onClick={() => this.handleEdit(fundItem)}>
<BsPencilSquare style={{backgroundColor:"#43425D", color:"white", fontSize:"26px", padding:"5px"}}/>
            {/* <AiOutlineDelete style={{backgroundColor:"#43425D", color:"white", fontSize:"26px", padding:"5px"}}/> */}
              {/* <svg
                width="18"
                height="18"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#fff"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg> */}
            </div>
          </td>
          <td className="px-6 py-4  text-sm font-avenir-medium table-color">
            {fundItem?.fundType?.fundSegment?.fundSegmentName}
          </td>
          <td className="px-6 py-4  text-sm font-avenir-mediumtable-color">
            {fundItem?.fundType?.fundTypeName}
          </td>
          <td className="px-6 py-4  text-sm font-avenir-medium table-color">
            {fundItem?.fundName}
          </td>
          <td className="px-6 py-4  text-sm font-avenir-medium table-color">
                  {fundItem?.fundType?.fundSegment?.fundSegmentCode+''+fundItem?.fundType?.fundTypeCode+''+fundItem?.fundCode}
          </td>
          <td className="px-6 py-4  text-sm font-avenir-medium table-color">
            {this.getEmployee(fundItem.updatedBy)}
          </td>
          <td className="px-6 py-4  text-sm font-avenir-medium">
                  <a

                      className="cursor-pointer"
                      onClick={() =>
                          this.setState({
                              deleteModal: !this.state.deleteModal,
                              deleteId: fundItem.id

                          })
                      }
                  >

<AiOutlineDelete style={{backgroundColor:"#43425D", color:"white", fontSize:"26px", padding:"5px"}}/>

                  </a>
          </td>
        </tr>
      );
    });

    let allFundType = this.state.fundTypeData.map((fundTypeData) => {
      return (
        <option key={fundTypeData.value} value={fundTypeData.value}>
          {fundTypeData.label}
        </option>
      );
    });
    return (
      <React.Fragment>
            {this.state.loader === true ? <Preloader /> : ""}
            {this.state.deleteModal && (
                <>
                    <div className="overlay justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-3/12 my-6 mx-auto bg-white p-4">
                            {/*content*/}
                            <div className=" py-4 px-4 shadow-sm overflow-hidden cursor-pointer">
                                <p className="flex-1 mt-2 text-sm font-medium font-avenir-black text-color text-center">
                                    Are you sure you want to
                                    delete?
                                                    </p>
                            </div>
                            <div class="flex justify-center">
                                <button onClick={() => this.handleDelete()}
                                    type="submit"
                                    class="pointer mr-5 w-40 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"

                                >

                                    Yes
                                                    </button>
                                <button
                                    type="button"
                                    class="pointer w-40 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-color bg-gray-200 hover:bg-gray-50 focus:outline-none"
                                    onClick={() =>
                                        this.setState({
                                            deleteModal: !this.state.deleteModal,
                                        })
                                    }
                                >
                                    No
                                                    </button>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            )}
        {this.state.open ? (
          <div className="mb-3">
            <div
              className="bg-card-header py-4 px-4 shadow-sm overflow-hidden bb-green cursor-pointer"
              onClick={() => {
                this.getFundSegments();
                this.getFundItems();
            this.setState({ open: !this.state.open })}}
            >
                        <div class="flex">

                            <div class="flex-1">
                                <p className=" text-sm font-medium font-avenir-black text-color">
                                    Funds Items
              </p>
                            </div>

                            <Input
                                placeholder="Search"
                                className="border border-solid px-2 h-8 w-50 mt-2"
                                style={{
                                    backgroundColor: "transparent",
                                    borderColor: "#707070",
                                    maxWidth:'300px'
                                }}
                                name='search'
                                onClick={(e) => e.stopPropagation()}
                                onChange={this.handleChange}
                            />
                        </div>
            </div>
            <div className="w-100  p-10 flex bg-white py-4 pl-4 shadow-sm cursor-pointer">
                            <div style={{border:"", width:"30%"}} className="  w-80 m-r-10">
                                <div class="mt-3grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div class="sm:col-span-12">
                    {this.state.message !== "" ? (
                      <ValidationMessageComponent
                        message={this.state.message}
                      ></ValidationMessageComponent>
                    ) : (
                      ""
                    )}
                    <label
                      for="fundsSegment"
                      class="block  text-sm font-medium label-color"
                    >
                      Funds Segment
                    </label>
                    <div class="mt-1">
                      <Select
                        name="fundsSegment"
                        id="fundsSegment"
                        value={this.state.fundsSegment}
                        options={this.state.fundsSegmentData}
                        class="shadow-sm focus:outline-none block w-full sm:text-sm"
                        onChange={this.handleSelectedFundSegmentChange}
                      />
                    </div>
                  </div>
                  <div class="sm:col-span-12">
                    <label
                      for="fundType"
                      class="block  text-sm font-medium label-color"
                    >
                      Funds Type
                    </label>
                    <div class="mt-1">
                       <select
                        name="fundType"
                        id="fundType"
                        value={this.state.fundType}
                        style={{minWidth:"350px"}}
                        class="border p-10 focus:outline-none block w-full sm:text-sm"
                                            onChange={this.handleChange}
                        options={this.state.fundTypeData}

                      >
                        {allFundType}
                      </select> 
                       {/* <Select
                        name="fundsSegment"
                        id="fundsSegment"
                        value={this.state.fundType}
                        options={this.state.fundTypeData}
                        class="shadow-sm focus:outline-none block w-full sm:text-sm"
                        onChange={this.handleSelectChange}
                      /> */}
                    </div>
                  </div>
                  <div class="col-span-12">
                    <label
                      for="fundItem"
                      class="block  text-sm font-medium label-color"
                    >
                      Fund Item
                    </label>
                    <div class="mt-1">
                      <Input
                        type="text"
                        name="fundItem"
                        id="fundItem"
                        value={this.state.fundItem}
                        class="shadow-sm border focus:outline-none block w-full sm:text-sm"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div class="col-span-12">
                    <label
                      for="code"
                      class="block  text-sm font-medium label-color"
                    >
                      Code
                    </label>
                    <div class="mt-1">
                      <Input
                        type="text"
                        name="code"
                        id="code"
                        value={this.state.code}
                        class="shadow-sm border focus:outline-none block w-full sm:text-sm"
                                            onChange={this.handleCodeChange}
                      />
                    </div>
                  </div>
                  <div class="pt-5 sm:col-span-12">
                    <div class="flex">
                      <button
                        type="submit"
                        class="mr-5 w-40 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                        onClick={
                          this.state.edit
                            ? this.handleEditSubmit
                            : this.handleSubmit
                        }
                      >
                        {this.state.loading && (
                          <svg
                            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              class="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              stroke-width="4"
                            ></circle>
                            <path
                              class="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        )}
                        Save Record
                      </button>
                          <button onClick={this.handleCancel }
                        type="button"
                        class="w-40 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-color bg-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div  className="w-100 md:flex-grow" >
                                <div class="w-100 flex " >
                                    <div class="w-100 -my-2 overflow-y-scroll" >
                  <table class="min-w-full w-100">
                        <thead class="bg-table-header">
                          <tr>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                            >
                              <svg
                                version="1.0"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 1278.000000 1280.000000"
                                preserveAspectRatio="xMidYMid meet"
                              >
                                <g
                                  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                                  fill="#fff"
                                  stroke="none"
                                >
                                  <path
                                    d="M5565 12793 c-437 -20 -955 -111 -1410 -249 -859 -261 -1717 -753
                                        -2352 -1348 -51 -47 -95 -86 -97 -86 -3 0 -122 149 -266 330 -144 182 -282
                                        347 -307 368 -24 20 -68 60 -98 88 -74 70 -128 94 -212 94 -114 0 -208 -61
                                        -264 -169 -33 -64 -36 -151 -43 -1161 -4 -481 -11 -1322 -17 -1869 -5 -546 -8
                                        -995 -6 -997 4 -5 -24 -16 1912 766 270 109 738 298 1040 420 303 122 569 233
                                        591 246 53 30 90 74 122 143 33 72 33 152 -2 262 -29 95 -66 146 -130 184 -36
                                        21 -584 207 -858 292 -49 15 -88 30 -88 33 0 14 236 191 377 283 309 200 595
                                        339 927 449 394 131 768 200 1181 217 512 22 983 -45 1478 -210 372 -123 711
                                        -285 1020 -485 54 -35 100 -64 102 -64 3 0 16 18 30 40 l26 41 79 -56 79 -56
                                        34 53 c231 364 837 1361 831 1367 -13 12 -246 160 -334 213 -431 259 -963 489
                                        -1460 632 -642 185 -1242 258 -1885 229z"
                                  />
                                  <path
                                    d="M9310 10957 c0 -8 -16 -125 -35 -259 -36 -246 -187 -1284 -295 -2033
                                        -132 -913 -161 -1115 -181 -1249 -30 -199 -29 -257 6 -329 34 -68 78 -110 152
                                        -144 105 -48 201 -40 295 25 24 17 198 172 387 346 190 173 346 314 347 313 2
                                        -1 10 -47 18 -102 98 -641 32 -1341 -189 -1985 -292 -851 -810 -1542 -1558
                                        -2078 -146 -105 -276 -186 -464 -290 l-152 -84 19 -39 c11 -21 20 -42 20 -46
                                        0 -5 -34 -25 -76 -46 -41 -21 -74 -41 -72 -45 6 -15 731 -1357 750 -1389 l19
                                        -33 62 32 c391 197 862 515 1227 826 792 678 1366 1481 1720 2407 269 705 400
                                        1415 400 2175 0 429 -36 796 -117 1190 -20 96 -38 187 -40 201 l-5 26 264 42
                                        c521 83 548 89 623 125 39 18 90 39 115 46 109 30 184 99 215 196 34 107 -4
                                        239 -88 308 -36 29 -3302 1884 -3349 1902 -12 4 -18 1 -18 -9z"
                                  />
                                  <path
                                    d="M4 7453 c-4 -49 -4 -214 0 -368 22 -980 241 -1847 696 -2755 431
                                        -860 1128 -1639 1965 -2197 435 -289 894 -512 1450 -704 91 -32 139 -53 137
                                        -61 -2 -7 -73 -195 -159 -418 -173 -450 -183 -480 -183 -564 0 -76 26 -131 88
                                        -186 26 -23 72 -66 103 -96 96 -92 206 -124 311 -89 58 19 3339 1905 3335
                                        1918 -6 19 -2978 2350 -3024 2372 -102 49 -253 26 -329 -50 -14 -14 -47 -36
                                        -73 -49 -54 -28 -116 -92 -137 -142 -28 -68 -18 -148 77 -580 50 -229 88 -418
                                        85 -421 -7 -7 -353 163 -481 237 -626 361 -1171 883 -1561 1497 -363 571 -573
                                        1185 -660 1928 -14 113 -18 231 -18 476 l-1 326 -149 7 c-82 3 -445 6 -807 6
                                        l-657 0 -8 -87z"
                                  />
                                </g>
                              </svg>
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                            >
                              Segment
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                            >
                              Type
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                            >
                              Item
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                            >
                              Code
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                            >
                              Updated By
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody class="bg-white">{allfundItemData}</tbody>
                      </table>
                      {true && (
                        <p className=" text-sm font-normal text-color py-4"></p>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="bg-white py-4 px-4 shadow-sm overflow-hidden cursor-pointer mb-3"
            // onClick={() => this.setState({ open: !this.state.open })}
            onClick={() => {
              this.getFundSegments();
              this.getFundItems();
          this.setState({ open: !this.state.open })}}
          >
            <p className=" text-sm font-medium text-color">Funds Item</p>
          </div>
        )}
      </React.Fragment>
    );
  }

  componentDidUpdate(prevProps) {}
}
