import * as yup from "yup"
const budgetCodificationSchema = yup.object().shape({
    geoCodeId: yup.string().required('geocode is required'),
    economicSubSubTypeId: yup.string().required('economic-sub-sub-type is required'),
    functionalItemId: yup.string().required('functional item is required'),
    activityId: yup.string().required('activity is required'),
    fundItemId: yup.string().required('fund Item is required')

});

//let geoCodeId = this.state.geoCode;
//let economicSubSubTypeId = this.state.economicSubSubType == "" ? "" : this.state.economicSubSubType.value;
//let functionalItemId = this.state.functionalItem == "" ? "" : this.state.functionalItem;
//let activitiesId = this.state.activity == "" ? "" : this.state.activity;
//let fundItemId = this.state.fundItem == "" ? "" : this.state.fundItem;
export default budgetCodificationSchema
