import { decodeToken } from './auth';

export const toggleScroll = () => {
  const overlays = document.querySelectorAll('.overlay');

  if (overlays.length > 0) {
    document.body.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
  }
};

export const convertImgToBase64 = (inputFile) => {
  if (inputFile === undefined) return '';
  const file = new FileReader();

  return new Promise((resolve, reject) => {
    file.onerror = () => {
      file.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    file.onload = () => {
      resolve(file.result);
    };
    file.readAsDataURL(inputFile);
  });
};

export const formatFileUrl = (path) => {
  if (!path) return null;
  return `${decodeToken('api').api}/${path}`;
};

export const formatCurrency = (value) => {
  if (value) {
    let val = value;
    val = val ? parseFloat(val).toFixed(2) : 0.00;
    return val === 0 ? '₦0.00' : `₦${Number(val).toLocaleString('en-US')}`;
  }
  return '₦0.00';
};

export const formatStatus = (status) => {
  switch (status) {
  case 'pending': return 'Pending';
  case 'declined': return 'Declined';
  case 'approved': return 'Approved';
  case 'booked': return 'Booked';
  case 'cancelled': return 'Cancelled';
  case 'assigned': return 'Assigned';

  default: return status;
  }
};
