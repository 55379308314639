import React, { Component, useState, useEffect, useRef } from "react";
import { Preloader } from "../../Utils/Preloader";
// import ValidationMessageComponent from "../../Components/ValidationMessage";
import axios from "axios";
import { Collapse, Button } from 'antd';

import { store } from 'react-notifications-component';
import { nullcheck, notify } from '../../Utils/Helper'
import cookies from 'js-cookie'

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1'

// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const BASE_MODULE_URL = process.env.REACT_APP_BASE_MODULE_URL
//const BASE_API_URL = "http://localhost:5000/api/v1";

function formatDefaultDate(dateString) {
    console.log(dateString);
    var newDate = new Date(dateString);
    newDate.setDate(newDate.getDate() + 1);

    // return newDate.toISOString().split("T")[0];
}

//getBudgetInformation
export default class InitiateBudgetDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            geoCodeData: "",

            budgetInformation: "",
            localGovernmentCouncilData: [],
            localGovernmentCouncil: "",
            wardCouncil: "",
            geoCode: "",
            loading: false,
            deleteId: "",
            edit: false,
            editId: "",
            isalreadyadded: this.props.isalreadyadded,
            submenu: false,
            showApprovalModal: false,
            approvalData:[],

            fullName: "",
            hello: false,
            role: "",
            time: "",
            ministry: "",
            mdaId:0,
            text: "",
            userid: "",
            message: "",
            loader: "",
            profilePicture: ""

        };
    }
    componentDidMount() {

console.log(this.props.informations)
        this.getBudgetInformation();
        this.getAuthorisedUser();
    }
    //componentDidUpdate() {
    //    this.getBudgetInformation();

    //}
    getAuthorisedUser = () => {
        // const { cookies } = this.props;
console.log(this.props?.informations[0]?.employees)

        console.log(this.props)

        if (typeof cookies.get('userId') !== "undefined") {

            let authUser = cookies.get('userId');
            console.log(authUser)
            // let time = authUser.lastLoginTime.split("T")[1]
            this.setState({
                fullName: this.props?.informations[0]?.employees.firstName + ' ' + this.props?.informations[0]?.employees.lastName,
                role: authUser.role,
                // time: time,
                //ministry: authUser.mda.name,
                userid: authUser,
                //mdaId:authUser.mdaId
                // profilePicture: `${BASE_MODULE_URL}/${authUser.picture}`
            })
        }

    }
    handleChange = (ev) => {
        this.setState({
            [ev.target.name]: ev.target.value,
        });
    };

    handleSubmit = () => {
        this.setState({
            loader: true,
            message: "",
        });
        let budgetInformation = this.props.allBudgetInformationData
        console.log(budgetInformation)

        let departmentBudgetID = budgetInformation.map(a => a.departmentBudgetID);
        let text = this.state.text;
        let _enteredBy = this.state.userid;
        let enteredBy = _enteredBy.toString();

        let data = { departmentBudgetID, text, enteredBy };

        axios.post(`${BASE_API_URL}/ApprovalProcess/AddApprovalProcess`, { ...data }).then((response) => {
            this.setState({
                loader: false,
                messsage: "",
                isalreadyadded:true,
                total:0
            })
            notify(store, 'success', 'budget initiated successfully');
            window.location.reload();
        }).catch((err) => {

            this.setState({
                loader: false,
                message: nullcheck(err.response) == "" ? "server error" : err.response.data,
            });
        })

    };

    handleDelete = (id) => {
        // this.setState({ deleteId: id }, () =>
        //   this.props.removeAction(DataTypes.DELETE_BUDGET_PERIOD, { id })
        // );
    };

    handleEdit = (data) => {
        this.setState({
            localGovernmentCouncil: data.localGovernmentCouncil,
            wardCouncil: data.wardCouncil,
            geoCode: data.geoCode,
            edit: true,
            editId: data.id,
        });
    };

    handleEditSubmit = () => {
        let localGovernmentCouncil = this.state.localGovernmentCouncil;
        let wardCouncil = this.state.wardCouncil;
        let geoCode = this.state.geoCode;
        let id = this.state.editId;

        let data = { localGovernmentCouncil, wardCouncil, geoCode, id };
    };

    getBudgetInformation = () => {

        let departmentBudgetId = this.props.allBudgetInformationData.map(a => a.departmentBudgetId);

        this.getBudgetApprovalRole(departmentBudgetId)

    };

    getBudgetApprovalRole = (departmentid) => {
        axios.post(`${BASE_API_URL}/approvalprocess/getapprovalroles`, departmentid).then((response) => {
            console.log(response.data)
            this.setState({
                approvalData: response.data == ""? []: response.data
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    render() {

        const allApprovalRoles = this.state.approvalData;

        var no=0
        let getAllApprovalRoles = allApprovalRoles.map((approvalRoles) => {
            no += 1;
            return (
                <tr>
                    <td class="table-border-right table-border-bottom px-4 py-2 text-sm font-medium text-gray-900">
                        Approver {no }
                            </td>
                    <td class="table-border-right table-border-bottom px-4 py-2 text-sm text-color">
                        {approvalRoles.previousPerson }
                            </td>
                    <td class="table-border-right table-border-bottom px-4 py-2 text-sm text-color">
                        {approvalRoles.status}
                            </td>
                    <td class="table-border-right table-border-bottom px-4 py-2 text-sm text-color">
                        {approvalRoles.dateCreated}
                            </td>
                    <td class="p-2 table-border-bottom text-sm text-color"></td>
                </tr>
            );
        });

        const budgetInformations = this.props.allBudgetInformationData;

        let allData = budgetInformations.map((budgetInformation) => {
            let total =
                budgetInformation.jan_val
                + budgetInformation.feb_val
                + budgetInformation.mar_val
                + budgetInformation.apr_val
                + budgetInformation.may_val
                + budgetInformation.june_val
                + budgetInformation.july_val
                + budgetInformation.aug_val
                + budgetInformation.sept_val
                + budgetInformation.oct_val
                + budgetInformation.nov_val
                + budgetInformation.dec_val

            return (
                <tr>
                    <td className="w-100 table-border-right p-2 text-sm font-medium text-gray-900">
                        {budgetInformation.departmentBudget.programmes == null ? "" : budgetInformation.departmentBudget.programmes.programmeName}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.departmentBudget.item}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.currency.title}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.jan_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.feb_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.mar_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.apr_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.may_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.june_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.july_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.aug_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.sept_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.oct_val  }
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.nov_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {budgetInformation.dec_val}
                    </td>
                    <td className="table-border-right p-2 text-sm text-color">
                        {total}
                    </td>
                </tr>
            );
        });

        return (
            <React.Fragment>
                   {this.state.loader === true ? <Preloader /> : ""}
                {this.state.open ? (
                    <React.Fragment>
                        <div
                            className="w-100 flex-between pointer flex-align bg-card-header py-4 px-4 shadow-sm overflow-hidden bb-green cursor-pointer"
                            onClick={() => this.setState({ open: !this.state.open })}
                        >
                            <p className="flex-1 mt-2 text-sm font-medium font-avenir-black text-color">
                                Budget Details (N000’mn)
              </p>
                            <div className="flex-align" style = {{width:'30%'}}>
                                <p className="text-sm no-margin mt-2 font-medium text-color m-r-10">
                                    <span className="font-avenir-black bold">Status</span>:{" "}
                                    <span className="font-normal"> {this.props.isalreadyadded==true?"Submitted":"Draft"}  </span>
                                </p>
                                <button
                                    type="submit"
                                    className="m-r-5 h-10 w-48 inline-flex py-2 px-6 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                            showApprovalModal: !this.state.showApprovalModal,
                                        });
                                    }}
                                >
                                    View Approved Roles
                </button>
                            </div>
                        </div>
                        <div class="w-100 flex flex-col">
                            <div class="w-100 shadow overflow-y-scroll">
                                <table className="w-100 table-border-bottom min-w-full">
                                    <thead class="w-100 bg-gray-table-header">
                                        <tr>
                                            <th
                                                scope="col"
                                                class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                            >
                                                Category
                      </th>
                                            <th
                                                scope="col"
                                                class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                            >
                                                Item
                      </th>
                                            <th
                                                scope="col"
                                                class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                            >
                                                Currency
                      </th>
                                            <th
                                                scope="col"
                                                class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                            >
                                                Jan
                      </th>
                                            <th
                                                scope="col"
                                                class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                            >
                                                Feb
                      </th>
                                            <th
                                                scope="col"
                                                class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                            >
                                                Mar
                      </th>
                                            <th
                                                scope="col"
                                                class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                            >
                                                Apr
                      </th>
                                            <th
                                                scope="col"
                                                class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                            >
                                                May
                      </th>
                                            <th
                                                scope="col"
                                                class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                            >
                                                Jun
                      </th>
                                            <th
                                                scope="col"
                                                class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                            >
                                                Jul
                      </th>
                                            <th
                                                scope="col"
                                                class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                            >
                                                Aug
                      </th>
                                            <th
                                                scope="col"
                                                class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                            >
                                                Sept
                      </th>
                                            <th
                                                scope="col"
                                                class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                            >
                                                Oct
                      </th>
                                            <th
                                                scope="col"
                                                class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                            >
                                                Nov
                      </th>
                                            <th
                                                scope="col"
                                                class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                            >
                                                Dec
                      </th>
                                            <th
                                                scope="col"
                                                class="table-border-right px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                            >
                                                Total
                      </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {allData}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <p className="text-sm font-normal text-color p-4"></p>
                        {/* {this.props.authorized == true ? ( */}
                        {this.state.hello ? (
                            <>
                                {this.props.isalreadyadded === false ? (



                                    <>
                                        <div className="flex w-100">
                                            <div className="bg-white w-60 shadow-sm m-t-20">
                                                <div
                                                    className="w-100 flex py-4 px-4"
                                                    style={{ borderBottom: "1px solid #D5D5D6" }}
                                                >
                                                    <div className="flex-1">
                                                        <div class="mt-1 relative">


                                                            {this.state.submenu && (
                                                                <div
                                                                    class="absolute mt-1 w-36 shadow-lg"
                                                                    style={{
                                                                        backgroundColor: "#F3F8F3",
                                                                        borderColor: "#B2D4B2",
                                                                    }}
                                                                >
                                                                    <ul
                                                                        tabindex="-1"
                                                                        role="listbox"
                                                                        aria-labelledby="listbox-label"
                                                                        aria-activedescendant="listbox-item-3"
                                                                        class="max-h-60 rounded-md py-1 text-sm overflow-auto focus:outline-none sm:text-sm"
                                                                    >
                                                                        <li
                                                                            id="listbox-option-0"
                                                                            role="option"
                                                                            class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 focus:outline-none "
                                                                            onClick={() =>
                                                                                this.setState({ submenu: !this.state.submenu })
                                                                            }
                                                                        >
                                                                            <span class="font-normal block truncate">
                                                                                Approve
                              </span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="flex ">
                                                        <p className="text-color  font-medium text-sm mr-3 text-right">
                                                            <span className="font-avenir-black">
                                                                {this.state.fullName}
                                                            </span>
                                                            <br />
                                                            <span className="font-normal text-xs ml-16">
                                                                {this.state.role}
                                                            </span>
                                                        </p>
                                                        <img
                                                            class="h-8 w-8 rounded-full"
                                                            src={this.state.profilePicture }
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                                <textarea
                                                    value={this.state.text}
                                                    onChange={this.handleChange}
                                                    id="text"
                                                    name="text"
                                                    rows=""
                                                    class="shadow-sm block w-full sm:text-sm"
                                                    style={{ height: "13rem", border: "none" }}
                                                ></textarea>
                                            </div>
                                        </div>
                                        <button onClick={() => this.handleSubmit()}

                                            type="submit"
                                            className="m-r-5 w-6/12 m-t-10 pointer inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white primary-bg-green focus:outline-none"
                                        >
                                            Submit for Review and Approval
            </button>
                                    </>
                                ) : null}

                            </>
                        ): null}



                        {this.state.showApprovalModal ? (
                            <>
                                <div className="overlay">
                                    <div className="relative w-60 w-7/12 my-6 mx-auto">
                                        {/*content*/}
                                        <div
                                            className="flex  bg-card-header py-4 px-4 shadow-sm overflow-hidden cursor-pointer"
                                            onClick={() =>
                                                this.setState({
                                                    showApprovalModal: !this.state.showApprovalModal,
                                                })
                                            }
                                        >
                                            <p className="flex-1 mt-2 text font-medium font-avenir-black text-color">
                                                Approval Information
                      </p>
                                        </div>
                                        <div class="w-100 bg-white">
                                            <table class="w-100">
                                                <thead class="w-100 sbg-gray-table-header">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            class="table-border-right table-border-bottom px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                                        >
                                                            Approval Role
                            </th>
                                                        <th
                                                            scope="col"
                                                            class="table-border-right table-border-bottom px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                                        >
                                                            Approved By
                            </th>
                                                        <th
                                                            scope="col"
                                                            class="table-border-right table-border-bottom px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                                        >
                                                            Status
                            </th>
                                                        <th
                                                            scope="col"
                                                            class="table-border-right table-border-bottom px-3 py-3 text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                                        >
                                                            Date Initiated
                            </th>
                                                        <th
                                                            scope="col"
                                                            class="px-3 py-3 table-border-bottom text-left text-sm font-medium font-avenir-black  text-color tracking-wider"
                                                        >
                                                            Comment
                            </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="bg-white divide-y divide-gray-200">
                                                    {getAllApprovalRoles }
                                                </tbody>
                                            </table>
                                            <div className="flex p-2 mb-2" style={{marginLeft:'auto', alignItems:'flex-end', maxWidth:"90px"}}>
                                                <p className="flex-1 text-sm font-normal text-color p-4">

                        </p>
                                                <Button
                                                    type="button"
                                                    class="w-32 h-10 mt-5 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-bold text-color bg-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    onClick={() =>
                                                        this.setState({
                                                            showApprovalModal: !this.state.showApprovalModal,
                                                        })
                                                    }
                                                >
                                                    Close
                        </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            </>
                        ) : null}
                    </React.Fragment>
                ) : (
                        <div
                            className="w-100 bg-white py-4 px-4 pointer shadow-sm overflow-hidden cursor-pointer mb-3"
                            onClick={() => this.setState({ open: !this.state.open })}
                        >
                            <p className="text-sm font-medium text-color">
                                Budget Details (N000’mn)
            </p>
                        </div>
                    )}
            </React.Fragment>
        );
    }
}
