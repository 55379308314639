export function nullcheck(obj) {
    if (typeof (obj) === "undefined") {
        return "";
    }
    return obj
}

export function notify(store, type,message) {
    store.addNotification({
        title: type == "success" ? "Sucesss" : "Error",
        message,
        type,
        // insert: "top",
        container: "top-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true
        }
    });

}

export function formatDefaultDate(dateString) {

    var newDate = new Date(dateString);
    newDate.setDate(newDate.getDate() + 1);
    let newDateString = newDate.toISOString().split("T")[0];
    let dateSplited = newDateString.split('-')
    return dateSplited[2] + '-' + dateSplited[1] + '-' + dateSplited[0];
}
export function formatDefaultDateForInput(dateString) {

    var newDate = new Date(dateString);
    newDate.setDate(newDate.getDate() + 1);
    let newDateString = newDate.toISOString().split("T")[0];
    //let dateSplited = newDateString.split('-')
    return newDateString;
}

export function formatNaira(num) {
    var p = num.toFixed(2).split(".");
    return "" + p[0].split("").reverse().reduce(function (acc, num, i, orig) {
        return num == "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
    }, "") + "." + p[1];
}
