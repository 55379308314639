import React, { Component, useState } from "react";
import { store } from 'react-notifications-component';
import { nullcheck, notify } from '../../Utils/Helper'

import Select from "react-select";
import { BsPencilSquare,BsTrash  } from 'react-icons/bs';
// import { ImBin2from,  } from 'react-icons/im';

import axios from "axios";
import { Preloader } from "../../Utils/Preloader";
import { Input} from "antd"
import notification from '../../utility/notification';
// import ValidationMessageComponent from "./ValidationMessage";
import departmentBudgetSchema from "../../Validation/DepartmentBudgetValidation";
import cookies from 'js-cookie'

// const BASE_API_URL = 'http://localhost:5000/api/v1';
const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';
// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}
export default class DepartmentBudgetItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      allBudgetDepartment: [],
      budgetDepartment: "",
      programSegment: "",
      allProgramme: [],
      expenditureTypes: "",
      expenditureTypesData: [],
      item: "",
      budgetCurrencies: [],
      budgetPeriods: [],
        id: "",
      deleteId:"",
      editMode: false,
      loading: false,
      title: "",
      nairaRate: "",
      budgetPeriodID: "",
      budgetPeriodValue: "",
      budgetPeriodText: "",
      message: "",
      showNotification: false,
      notificationSuccess: false,
      notificationMessage: "Successfully saved!",
        loader: false,

        fuillName: "",
        employeeId: "",
        role: "",
        time: "",
        ministry: "",
        mdaId: 0,
        authorized: false,
        authorisedMdaId: 0,
        deleteModal: false,
    };
  }

  componentDidMount() {
    this.getProgramme();
    this.getBudgetPeriod();
   
      this.getEconomicSegment();
      this.getAuthorisedUser();
  }
  getAuthorisedUser = () => {
        // const { cookies } = this.props;

        if (typeof cookies.get('userId') !== "undefined") {
          console.log(cookies.get('userId'))

            let authUser = cookies.get('userId');
            console.log(authUser)
            // let time = authUser.lastLoginTime.split("T")[1]
            this.setState({
                fullName: authUser.firstName + ' ' + authUser.lastName,
                role: authUser.role,
                // time: time,
                ///ministry: authUser.mda.name,
                employeeId: authUser,
                ///mdaId: authUser.mdaId

            })
            this.checkUserCanInitiate(authUser);
        }

    }

  handleSelectedExpenditureTypeChange = (selectedExpenditure) => {
    this.setState(
      { selectedExpenditure, expenditureTypes: selectedExpenditure },
      () => console.log(`Option selected:`, selectedExpenditure)
    );
  };
  handleSelectedProgrammeChange = (selectedProgramSegment) => {
    this.setState(
      { selectedProgramSegment, programSegment: selectedProgramSegment },
      () => console.log(`Option selected:`, selectedProgramSegment)
    );
  };
  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

    handleDelete = () => {
        let id = this.state.deleteId
        let mdaId = this.state.authorisedMdaId
        this.setState({
            loader: true,
            message: "",
            deleteModal: false,
            deleteId: '',
        });
        axios
            .delete(`${BASE_API_URL}/departmentbudget?id=${id}`)
            .then((response) => {
                this.setState({
                    editMode: false,
                    id: "",
                    item: "",
                    loader: false,
                    message: "",
                });
                // notify(store, 'success', 'department budget deleted successfully');
                notification({
                  title: 'MESSAGE',
                  message: `
                  ${'department budget deleted successfully'}`,
                  type: 'success',
                  // container:'top-center'
                  // animationIn: ['animate__animated', 'animate__slideIn'],
                });
                this.getDepartmentBudget(mdaId);
            })
            .catch((err) => {
                this.setState({
                    loader: false,
                    message: nullcheck(err.response) == "" ? "server error" : err.response.data,
                });

                // notification({
                //   title: 'ERROR MESSAGE',
                //   message: `
                //   ${nullcheck(err.response) == "" ? "server error" : err.response.data}`,
                //   type: 'danger',
                //   // container:'top-center'
                //   // animationIn: ['animate__animated', 'animate__slideIn'],
                // });
            });
        // this.setState({ deleteId: id }, () =>
        //   this.props.removeAction(DataTypes, { id })
        // );
    };
  handleSubmit = () => {
    let programmeId = this?.state?.programSegment?.value;
    let economicSegmentId = this?.state.expenditureTypes?.value;
      let item = this?.state?.item;
      let mdaId = this?.state?.authorisedMdaId
      let _enteredBy = this?.state?.employeeId
      
      let enteredBy = _enteredBy?.toString();

      let data = { programmeId, economicSegmentId, item, mdaId, enteredBy };
    departmentBudgetSchema
      .validate(data)
      .then((message) => {
        this.setState({
          loader: true,
          message: "",
        });
        axios
          .post(`${BASE_API_URL}/DepartmentBudget`, { ...data })
          .then((response) => {
            this.setState({
              item: "",
              expenditureTypes:'',
              loader: false,
              message: "",
            });
              // notify(store, 'success', 'department budget added successfully');
              notification({
                title: 'MESSAGE',
                message: `
                ${'department budget added successfully'}`,
                type: 'success',
                // container:'top-center'
                // animationIn: ['animate__animated', 'animate__slideIn'],
              });
            this.getDepartmentBudget(mdaId);
            this.getFundSegments();
          })
          .catch((err) => {
            this.setState({
              loader: false,
                message: nullcheck(err.response) == "" ? "server error" : err.response.data,
            });
            // notification({
            //   title: 'ERROR MESSAGE',
            //   message: `
            //   ${nullcheck(err.response) == "" ? "server error" : err.response.data}`,
            //   type: 'danger',
            //   // container:'top-center'
            //   // animationIn: ['animate__animated', 'animate__slideIn'],
            // });
          });
         
      })
      .catch((err) => {
        this.setState({
          loader: false,
          message: err.errors,
        });
        notification({
          title: 'ERROR MESSAGE',
          message: `
          ${err.errors }`,
          type: 'danger',
          // container:'top-center'
          // animationIn: ['animate__animated', 'animate__slideIn'],
        });
      });
     
  };

  handleEdit(data) {
    let selectedValue = {
      value: data.economicSegments.id,
      label: data.economicSegments.economicSegmentName,
    };
    let selectedProgram = {
      value: data.programmes.id,
      label: data.programmes.programmeName,
    };
    console.log(selectedProgram);
    //let expenditureType = parseInt(this.state.expenditureTypes.value);
    this.setState({
      id: data.id,
      programSegment: selectedProgram,
      expenditureTypes: selectedValue,
      item: data.item,
      editMode: true,
      title: data.title,
    });

    //document.getElementById("budgetPeriodID").value = data.budgetPeriodID;
  }
  handleEditSubmit() {
    let programmeId = this.state.programSegment.value;
      let economicSegmentId = this.state.expenditureTypes.value;
      let mdaId = this.state.authorisedMdaId
    let item = this.state.item;
      let id = this.state.id;
      let _enteredBy = this.state?.employeeId
      let updatedBy = _enteredBy?.toString();

      let data = { programmeId, economicSegmentId, item, id, updatedBy };
    console.log(data);
    departmentBudgetSchema
      .validate(data)
      .then((message) => {
        this.setState({
          loader: true,
          message: "",
        });
        axios
          .put(`${BASE_API_URL}/DepartmentBudget`, { ...data })
          .then((response) => {

            this.setState({
              editMode: false,
              id: "",
              item: "",
              loader: false,
              message: "",
            });
            
              // notify(store, 'success', 'department budget updated successfully');
              // notification({
              //     title: 'MESSAGE',
              //     message: `
              //     ${response?.data}`,
              //     type: 'success',
              //     // container:'top-center'
              //     // animationIn: ['animate__animated', 'animate__slideIn'],
              //   });
              this.getDepartmentBudget(mdaId);
            this.getFundSegments();
          console.log(response.data)

          })
          .catch((err) => {

            this.setState({
              loader: false,
                // message: nullcheck(err.response) == "" ? "server error" : err.response.data,
            });
            // notification({
            //   title: 'ERROR MESSAGE',
            //   message: `
            //   ${err?.response?.data}`,
            //   type: 'danger',
            //   // container:'top-center'
            //   // animationIn: ['animate__animated', 'animate__slideIn'],
            // });
          console.log(err.response?.data);

          });
         
      })
      .catch((err) => {
        this.setState({
          loader: false,
          message: err.errors,
        });
        notification({
          title: 'ERROR MESSAGE',
          message: err.errors, 
          type: 'danger',
          // container:'top-center'
          // animationIn: ['animate__animated', 'animate__slideIn'],
        });
      });
      
  }

  getBudgetPeriod() {
    this.setState({
      loader: true,
      message: "",
    });
    axios
      .get(`${BASE_API_URL}/BudgetPeriod`)
      .then((response) => {
        this.setState({
          budgetPeriods: response.data,
          loader: false,
          message: "",
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
            message: nullcheck(err.response) == "" ? "server error" : err.response.data,
        });
        notification({
          title: 'ERROR MESSAGE',
          message: `
          ${nullcheck(err.errors) == "" ? "server error" : err.response.data}`,
          type: 'danger',
          // container:'top-center'
          // animationIn: ['animate__animated', 'animate__slideIn'],
        });
      });
  }
  getProgramme() {
    this.setState({
      loader: true,
      message: "",
    });
    axios
      .get(`${BASE_API_URL}/programme/getprogram`)
      .then((response) => {
        this.setState({
          allProgramme: response.data,
          loader: false,
          message: "",
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
            message: nullcheck(err.response) == "" ? "server error" : err.response.data,
        });
        notification({
          title: 'ERROR MESSAGE',
          message: `
          ${nullcheck(err.errors) == "" ? "server error" : err.response.data}`,
          type: 'danger',
          // container:'top-center'
          // animationIn: ['animate__animated', 'animate__slideIn'],
        });
      });
  }
  getDepartmentBudget(mdaId) {
    this.setState({
      loader: true,
      message: "",
    });
      
    axios
        .get(`${BASE_API_URL}/departmentbudget/GetDepartmentBudgetByMdaId?mdaId=${mdaId}`)
      .then((response) => {
       
        this.setState({
          allBudgetDepartment: response.data,
          loader: false,
          message: "",
        });
      })
        .catch((err) => {
            console.log(err);
        this.setState({
          loader: false,
          message: nullcheck(err.response) == "" ? "server error" : err.response.data,
        });
        notification({
          title: 'ERROR MESSAGE',
          message: `
          ${nullcheck(err.errors) == "" ? "server error" : err.response.data}`,
          type: 'danger',
          // container:'top-center'
          // animationIn: ['animate__animated', 'animate__slideIn'],
        });
      });
  }
  getEconomicSegment = () => {
    this.setState({
      loader: true,
      message: "",
    });
    axios
      .get(`${BASE_API_URL}/economicsegment/geteconomicsegment`)
      .then((response) => {
        let economicSegment =
          response.data.length === 0 ? "" : response.data[0];
        console.log(economicSegment);
        this.setState({
          expenditureTypesData: response.data,
          expenditureType: economicSegment,
          loader: false,
          message: "",
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
          message: nullcheck(err.response) == "" ? "server error" : err.response.data,
        });
        notification({
          title: 'ERROR MESSAGE',
          message: `
          ${nullcheck(err.errors) == "" ? "server error" : err.response.data}`,
          type: 'danger',
          // container:'top-center'
          // animationIn: ['animate__animated', 'animate__slideIn'],
        });
      });
    };

    checkUserCanInitiate = (id) => {
      console.log(id)
        this.setState({
            loader: true,
            message: "",
        });
        axios.get(`${BASE_API_URL}/userprevilege/getuserprevilegebyemployeeId?employeeId=${id}`).then((response) => {
            this.setState({
                loader: false,
                message: "",
            });

            if (response.data.length != 0) {
                
                this.setState({
                    authorized: true,
                    authorisedMdaId: response.data[0].mdaId

                });
                this.getDepartmentBudget(response.data[0].mdaId)
            }

            else {

                this.setState({
                    
                    authorized: false
                });
                this.props.history.push("/schedule-manager/");
            }


           
        }).catch((err) => {
            this.setState({
                loader: false,
                message: nullcheck(err.response) == "" ? "server error" : err.response.data,
            });
            notification({
              title: 'ERROR MESSAGE',
              message: `
              ${nullcheck(err.errors) == "" ? "server error" : err.response.data}`,
              type: 'danger',
              // container:'top-center'
              // animationIn: ['animate__animated', 'animate__slideIn'],
            });
        })
    }


  render() {
    let budgetPeriodsSelect = this.state.budgetPeriods.map((budgetPeriod) => {
      return (
        <option key={budgetPeriod.id} value={budgetPeriod.id}>
          {budgetPeriod.title}
        </option>
      );
    });
      let allBudgetDepartment = this.state?.allBudgetDepartment?.length==0?"":this.state?.allBudgetDepartment.map(
      (budgetDepartment) => {
        return (
          <tr
            key={budgetDepartment.id}
            style={{
              borderBottom: "1px solid #C7C7C7",
            }}
          >
            <td className="px-6 py-4 whitespace-nowrap">
              <div
                className="bg-pen"
                onClick={() => this.handleEdit(budgetDepartment)}
              >
                 <BsPencilSquare style={{color:'#43425D',fontSize:'20px'}}/>

              </div>
            </td>

            <td className="px-6 py-4 text-sm font-medium table-color">
                    {budgetDepartment?.programmes?.programmeName}
            </td>
            <td className="px-6 py-4 text-sm table-color">
              {budgetDepartment?.item}
                    {/* {budgetDepartment.programmes.programmeName} */}
            </td>
            <td className="px-6 py-4 text-sm table-color">
              {budgetDepartment.economicSegments?.economicSegmentName}
            </td>

            <td className="px-6 py-4 text-sm font-medium">
                    <a

                        className="cursor-pointer"
                        onClick={() =>
                            this.setState({
                                deleteModal: !this.state.deleteModal,
                                deleteId: budgetDepartment?.id

                            })
                        }
                    >
                 <BsTrash style={{color:'#43425D',fontSize:'20px'}}/>

                        {/* <img
                            // src="/delete-icon.png"
                            className="ml-5 h-5 w-5"
                        /> */}
                    </a>
            </td>
          </tr>
        );
      }
    );
    return (
      <React.Fragment>
            {this.state.loader === true ? <Preloader /> : ""}
            {this.state.deleteModal && (
                <>
                    <div className="overlay justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-3/12 my-6 mx-auto bg-white p-4">
                            {/*content*/}
                            <div className=" py-4 px-4 shadow-sm overflow-hidden cursor-pointer">
                                <p className="flex-1 mt-2 text-sm font-medium font-avenir-black text-color text-center">
                                    Are you sure you want to
                                    delete?
                                                    </p>
                            </div>
                            <div class="flex justify-center">
                                <button onClick={() => this.handleDelete()}
                                    type="submit"
                                    class="mr-5 w-40 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"

                                >

                                    Yes
                                                    </button>
                                <button
                                    type="button"
                                    class="w-40 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-color bg-gray-200 hover:bg-gray-50 focus:outline-none"
                                    onClick={() =>
                                        this.setState({
                                            deleteModal: !this.state.deleteModal,
                                        })
                                    }
                                >
                                    No
                                                    </button>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            )}
            {this.state.open? (
                <>
                    {this.state.authorized === true ? (
                        <>
                            <div className="mb-3">
                                <div
                                    className="flex p-10 bg-card-header py-4 pl-4 shadow-sm overflow-hidden bb-green m-b-10 cursor-pointer"
                                    onClick={() => this.setState({ open: !this.state.open })}
                                >
                                    <p className="flex-1 Upload Budget in Excel text-sm font-avenir-black text-color">
                                        Department Budget Items Setting
              </p>

                                </div>
                                <div className="flex w-100 bg-white py-4 pl-4 shadow-sm overflow-hidden cursor-pointer m-b-10">
                                    <div className="w-40 p-10">
                                        <div className="mt-3 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                            <div className="sm:col-span-12">
                                                {this.state.message !== "" ? (
                                                    // <ValidationMessageComponent
                                                    //     message={this.state.message}
                                                    // ></ValidationMessageComponent>
                                                    <p>ddd</p>
                                                ) : (
                                                        ""
                                                    )}
                                                <label
                                                    htmlFor="programSegment"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Program
                    </label>
                                                <div className="mt-1">
                                                    <Select
                                                        type="text"
                                                        name="programme"
                                                        id="programme"
                                                        value={this.state.programSegment}
                                                        onChange={this.handleSelectedProgrammeChange}
                                                        options={this.state.allProgramme}
                                                    />
                                                </div>
                                            </div>
                                            <div className="sm:col-span-12">
                                                <label
                                                    htmlFor="expenditureTypes"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Expenditure Types
                    </label>
                                                <div className="mt-1">
                                                    <Select
                                                        name="expenditureTypes"
                                                        id="expenditureTypes"
                                                        value={this.state?.expenditureTypes}
                                                        class="shadow-sm focus:outline-none block w-full sm:text-sm"
                                                        onChange={this.handleSelectedExpenditureTypeChange}
                                                        options={this.state?.expenditureTypesData}
                                                    />
                                                </div>
                                            </div>
                                            <div className="sm:col-span-12">
                                                <label
                                                    htmlFor="item"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Item
                    </label>
                                                <div className="m-t-5">
                                                    <Input
                                                        type="text"
                                                        name="item"
                                                        id="item"
                                                        value={this.state?.item}
                                                        onChange={this.handleChange}
                                                        className="shadow-sm focus:outline-none block w-full sm:text-sm"
                                                    />
                                                </div>
                                            </div>
                                            <div className="m-t-5 sm:col-span-12">
                                                <div className="flex">
                                                    <button
                                                        type="submit"
                                                        className="mr-5 w-40 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                                                        onClick={
                                                            this.state.editMode
                                                                ? this.handleEditSubmit.bind(this)
                                                                : this.handleSubmit
                                                        }
                                                    >
                                                        {this.state.loading && (
                                                            <svg
                                                                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <circle
                                                                    className="opacity-25"
                                                                    cx="12"
                                                                    cy="12"
                                                                    r="10"
                                                                    stroke="currentColor"
                                                                    stroke-width="4"
                                                                ></circle>
                                                                <path
                                                                    className="opacity-75"
                                                                    fill="currentColor"
                                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                ></path>
                                                            </svg>
                                                        )}
                        Save Record
                      </button>
                                                    <button
                                                        type="button"
                                                        className="w-40 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-color bg-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    >
                                                        Cancel
                      </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow w-100" style={{ marginTop: -9 }}>
                                        <div className="w-100 -my-2 sm:-mx-6 lg:-mx-8">
                                            <div className="w-100 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                <div className="w-100 flex flex-col ">
                                                    <div className="w-100 shadow overflow-y-scroll">
                                                        <table className=" w-100 min-w-full">
                                                            <thead className="w-100 bg-table-header">
                                                                <tr>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 py-3 text-left text-smfont-semibold text-white tracking-wider"
                                                                    >
                                                                        <svg
                                                                            version="1.0"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 1278.000000 1280.000000"
                                                                            preserveAspectRatio="xMidYMid meet"
                                                                        >
                                                                            <g
                                                                                transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                                                                                fill="#fff"
                                                                                stroke="none"
                                                                            >
                                                                                <path
                                                                                    d="M5565 12793 c-437 -20 -955 -111 -1410 -249 -859 -261 -1717 -753
                                        -2352 -1348 -51 -47 -95 -86 -97 -86 -3 0 -122 149 -266 330 -144 182 -282
                                        347 -307 368 -24 20 -68 60 -98 88 -74 70 -128 94 -212 94 -114 0 -208 -61
                                        -264 -169 -33 -64 -36 -151 -43 -1161 -4 -481 -11 -1322 -17 -1869 -5 -546 -8
                                        -995 -6 -997 4 -5 -24 -16 1912 766 270 109 738 298 1040 420 303 122 569 233
                                        591 246 53 30 90 74 122 143 33 72 33 152 -2 262 -29 95 -66 146 -130 184 -36
                                        21 -584 207 -858 292 -49 15 -88 30 -88 33 0 14 236 191 377 283 309 200 595
                                        339 927 449 394 131 768 200 1181 217 512 22 983 -45 1478 -210 372 -123 711
                                        -285 1020 -485 54 -35 100 -64 102 -64 3 0 16 18 30 40 l26 41 79 -56 79 -56
                                        34 53 c231 364 837 1361 831 1367 -13 12 -246 160 -334 213 -431 259 -963 489
                                        -1460 632 -642 185 -1242 258 -1885 229z"
                                                                                />
                                                                                <path
                                                                                    d="M9310 10957 c0 -8 -16 -125 -35 -259 -36 -246 -187 -1284 -295 -2033
                                        -132 -913 -161 -1115 -181 -1249 -30 -199 -29 -257 6 -329 34 -68 78 -110 152
                                        -144 105 -48 201 -40 295 25 24 17 198 172 387 346 190 173 346 314 347 313 2
                                        -1 10 -47 18 -102 98 -641 32 -1341 -189 -1985 -292 -851 -810 -1542 -1558
                                        -2078 -146 -105 -276 -186 -464 -290 l-152 -84 19 -39 c11 -21 20 -42 20 -46
                                        0 -5 -34 -25 -76 -46 -41 -21 -74 -41 -72 -45 6 -15 731 -1357 750 -1389 l19
                                        -33 62 32 c391 197 862 515 1227 826 792 678 1366 1481 1720 2407 269 705 400
                                        1415 400 2175 0 429 -36 796 -117 1190 -20 96 -38 187 -40 201 l-5 26 264 42
                                        c521 83 548 89 623 125 39 18 90 39 115 46 109 30 184 99 215 196 34 107 -4
                                        239 -88 308 -36 29 -3302 1884 -3349 1902 -12 4 -18 1 -18 -9z"
                                                                                />
                                                                                <path
                                                                                    d="M4 7453 c-4 -49 -4 -214 0 -368 22 -980 241 -1847 696 -2755 431
                                        -860 1128 -1639 1965 -2197 435 -289 894 -512 1450 -704 91 -32 139 -53 137
                                        -61 -2 -7 -73 -195 -159 -418 -173 -450 -183 -480 -183 -564 0 -76 26 -131 88
                                        -186 26 -23 72 -66 103 -96 96 -92 206 -124 311 -89 58 19 3339 1905 3335
                                        1918 -6 19 -2978 2350 -3024 2372 -102 49 -253 26 -329 -50 -14 -14 -47 -36
                                        -73 -49 -54 -28 -116 -92 -137 -142 -28 -68 -18 -148 77 -580 50 -229 88 -418
                                        85 -421 -7 -7 -353 163 -481 237 -626 361 -1171 883 -1561 1497 -363 571 -573
                                        1185 -660 1928 -14 113 -18 231 -18 476 l-1 326 -149 7 c-82 3 -445 6 -807 6
                                        l-657 0 -8 -87z"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 py-3 text-left text-smfont-semibold text-white tracking-wider"
                                                                    >
                                                                        Program Segment
                              </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 py-3 text-left text-smfont-semibold text-white tracking-wider"
                                                                    >
                                                                        Item
                              </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 py-3 text-left text-smfont-semibold text-white tracking-wider"
                                                                    >
                                                                        Expenditure Types
                              </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 py-3 text-left text-smfont-semibold text-white tracking-wider"
                                                                    >
                                                                        Action
                              </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="bg-white">
                                                                {allBudgetDepartment}
                                                            </tbody>
                                                        </table>
                                                        {true && (
                                                            <p
                                                                className="text-smfont-normal text-color py-4"
                                                                hidden
                                                            >
                                                                Showing 1 to 3 of 3 entries
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>


                    ): null }
       
                </>
        ) : (
          <div
            className="bg-white pointer py-4 px-4 shadow-sm overflow-hidden cursor-pointer m-b-10"
            onClick={() => this.setState({ open: !this.state.open })}
          >
            <p className="text-sm font-medium text-color">
              Department Budget Items Setting
            </p>
          </div>
        )}
      </React.Fragment>
    );
  }
}
