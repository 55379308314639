import React, { useState,useEffect } from 'react';
import { Table,Button, Input, InputNumber,Modal, Popconfirm, Form, Typography } from 'antd';
import axios from "axios";
import notification from "../../utility/notification";
import EditableTable from './EditableTable';
import ProjectForm from './ProjectForm';
import {

    CloseOutlined,
    SearchOutlined,
  } from '@ant-design/icons';
import PreviewTable from './PreviewTable';

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';
// const BASE_API_URL = 'http://localhost:5000/api/v1';

const originData = [];

// for (let i = 0; i < 100; i++) {
//   originData.push({
//     key: i.toString(),
//     name: `Edrward ${i}`,
//     age: 32,
//     address: `London Park no. ${i}`,
//   });
// }



const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restss
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restss}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ProgrammeTable = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [project, setProject] = useState('');

  const [arrayToSubmit, setArrayToSubmit] = useState([]);
  const [monthlyBudgetForm, setmonthlyBudgetForm] = useState({});

  
  
  
  const handleCancel = ()=>{
    setShowDetails(!showDetails)
  }
  const handleCancel2 = ()=>{
    setShowPreview(!showPreview)
  }
//   const [count, setCount] = useState(1);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
    // let count = 1

    const createArrayToSubmit = (e)=>{
      console.log(e)

      // if ((Object?.keys(e?.monthlyBudget)?.length !== 0)&&(Object?.keys(e?.budgetItem)?.length !== 0))
      setArrayToSubmit([...arrayToSubmit,e])
      let m = data.filter((e)=>(
        e.id !== project.id
      ))
      setData(m)

      // // setmonthlyBudgetForm(e.monthlyBudget)

    }

    console.log(arrayToSubmit)
    const toPreview = ()=>{
setShowPreview(!showPreview)
    }

    const postMonthlyAllocation = ()=>{

    }

    const toReset = ()=>{
      let dat = props?.project?.map((e,idx)=>(
        {
    key: idx+1,

            id: e.id,
            budgetPlanId: e.batchId,
            dateCreated: e.dateCreated,
            enteredBy: e.enteredBy,
            dateUpdated: e.dateUpdated,
            updatedBy: e.updatedBy,
            isDelete: e.isDelete,
            dateDeleted: e.dateDeleted,
            project: e.projectInitiative,
            programmeCode: e.programmeCode?e.programmeCode:'',
            programmeId: e.programmeId?e.programmeId:'',
            status: e.status,
            projectLocation: e.projectLocation?e.projectLocation:'',
            proposedBudget: +e.proposedBudget?e.proposedBudget:0,
            performance: e.performance?e.performance:'',
            actuals: e.actuals?e.actuals:'',
            mdaId: e.mdaId
          }
      ))
    console.log('dat:',dat)
    setData(dat)
    }

    const toPost = ()=>{
      console.log(arrayToSubmit)
      let prePostDate = []

      let k = arrayToSubmit.map((e)=>(
prePostDate =[...prePostDate, ...e]
      ))
      console.log(prePostDate)

      let postData = arrayToSubmit.map((e)=>(
         
          {
              // key: idx+1,
          
                      id: e?.id,
                      dateCreated: e?.dateCreated,
                      enteredBy: e?.enteredBy,
                      dateUpdated: e?.dateUpdated,
                      updatedBy: e?.updatedBy,
                      isDelete: e?.isDelete,
                      dateDeleted: e?.dateDeleted,
                      project: e?.project,
                      budgetPlanId: e?.budgetPlanId,
                      programmeCode: e?.programmeCode?e?.programmeCode:'',
                      status: e?.status,
                       budgetDeptId:e.budgetDeptId,
                      projectLocation: e?.projectLocation?e?.projectLocation:'',
                      proposedBudget: +e.proposedBudget?e?.proposedBudget:'',
                      performance: e?.performance?e?.performance:'',
                      actuals: e?.actuals?e?.actuals:'',
                      mdaId: e?.mdaId
                    }
      ))
      console.log(postData)

      // let poster = arrayHolder.map((a)=>())
      axios.post(`${BASE_API_URL}/BudgetPlanning`, prePostDate 
      
                        ).then(()=>{

                          postMonthlyAllocation(prePostDate)
                          notification({
                              title: 'Successful',
                              message: 'Costs have been successfully allocated ',
                              type: 'success'
                        });

                        }).catch((error) => {
                          if (error.response) {
                              
                            // Request made and server responded
                            console.log(error.response.data);
                            console.log(error.response.title);
                            console.log(error.response.headers);
                            // setLoading(false)
                            notification({
                              title: 'ERROR MESSAGE',
                              message: `
                              ${error.response.data}`,
                              type: 'danger',
                              // container:'top-center'
                              // animationIn: ['animate__animated', 'animate__slideIn'],
                            });
                            // setErrorMessage(error.response.data.title)
                            // setError(true)
                          } else if (error.request) {
                            // setLoading(false)
                      
                            // The request was made but no response was received
                            console.log(error.request);
                          } else {
                            // setLoading(false)
                      
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                          }
                      
                        });
      props.refresh()
      props.handleCancel()
      
                  
    }
      console.log(arrayToSubmit)

useEffect(()=>{
    console.log(props.project)
    let dat = props?.project?.map((e,idx)=>(
        {
    key: idx+1,

            id: e.id,
            budgetPlanId: e.batchId,
            dateCreated: e.dateCreated,
            enteredBy: e.enteredBy,
            dateUpdated: e.dateUpdated,
            updatedBy: e.updatedBy,
            isDelete: e.isDelete,
            dateDeleted: e.dateDeleted,
            project: e.projectInitiative,
            programmeCode: e.programmeCode?e.programmeCode:'',
            programmeId: e.programmeId?e.programmeId:'',
            status: e.status,
            projectLocation: e.projectLocation?e.projectLocation:'',
            proposedBudget: +e.proposedBudget?e.proposedBudget:0,
            performance: e.performance?e.performance:'',
            actuals: e.actuals?e.actuals:'',
            mdaId: e.mdaId
          }
      ))
    console.log('dat:',dat)
    setData(dat)
},[])
console.log(data)
  

  const columns = [
    {
      title: 'programme',
      dataIndex: 'project',
      width: '25%',
      editable: true,
    },
    // {
    //   title: 'programme Code',
    //   dataIndex: 'programmeCode',
    //   width: '15%',
    //   editable: true,
    // },
    // {
    //   title: 'project Location',
    //   dataIndex: 'projectLocation',
    //   width: '40%',
    //   editable: true,
    // },
    // {
    //     title: 'Status',
    //     dataIndex: 'proposedBudget',
    //     width: '40%',
    //     editable: true,
    //   },
      
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          
          <Typography.Link onClick={()=>{
            console.log(record)
              setShowDetails(!showDetails)
              setProject(record)
              }}>
            Edit
          </Typography.Link>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'proposedBudget' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form style={{width:"100%",padding:"20%", flexDirection:"column", }} form={form} component={false}>
      <Table
      style={{width:"100%",}}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
      <div className="flex-between">
        {/* {(arrayToSubmit.length > 0 && arrayToSubmit.length <= data.length) && <Button onClick = {toPost}>Send</Button>}
        {(arrayToSubmit.length > 0 && arrayToSubmit.length <= data.length) && <Button onClick = {toPreview}>Preview</Button>} */}
         {data.length < 1 && <Button onClick = {toPost}>Send</Button>}
          <Button onClick = {toReset}>Reset</Button>
       <Button onClick = {toPreview}>Preview</Button>
      </div>




      <Modal
                    width="80%"
                   
                    footer={false}
                    // okText={<p style={{ maxWidth: "100px" }}>Boss</p>}
                    closeIcon={<CloseOutlined style={{ fontSize: "25px", backgroundColor: "red", color: "white", padding: "10px", marginLeft:'60px' }} />}
                    visible={showDetails} onCancel={handleCancel}>
                    <div style={{marginTop:"-23px",}}>
                    
                     <ProjectForm 
                    project = {project}
                    addToArrayToSubmit = {createArrayToSubmit}

                    handleCancel={handleCancel}
                    // project = {detail.budgetProjectInitiatives}
                    />

                    </div>
        </Modal> 

        <Modal
                    width="80%"
                   
                    footer={false}
                    // okText={<p style={{ maxWidth: "100px" }}>Boss</p>}
                    closeIcon={<CloseOutlined style={{ fontSize: "25px", backgroundColor: "red", color: "white", padding: "10px", marginLeft:'60px' }} />}
                    visible={showPreview} onCancel={handleCancel2}>
                    <div style={{marginTop:"-23px",}}>
                    
                    {arrayToSubmit.map((e)=>(
                      <PreviewTable 
                    project = {e}

                    // addToArrayToSubmit = {createArrayToSubmit}
                    // project = {detail.budgetProjectInitiatives}
                    />

                    ))
                    
                    
                    }
                    </div>
        </Modal> 
    </Form>
  );
};

export default ProgrammeTable