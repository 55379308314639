import React, { useState,useEffect } from 'react';
import { Table,Button, Input, InputNumber, Popconfirm, Form, Typography,Select } from 'antd';
import axios from "axios";
import EditableTable from './EditableTable';
import notification from "../../utility/notification";
import { decodeToken } from "../../utility/auth";

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';
// const BASE_API_URL = 'http://localhost:5000/api/v1';

const originData = [];
const { Option } = Select;








const ProjectForm = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [hideFinish, setHideFinish] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [employees, setEmployees] = useState([]);
  const [approverId, setApproverId] = useState();
  const [approver, setApprover] = useState([]);
  const [showEditableTable, setshowEditableTable] = useState(true);

  const [economicSegment, setEconomicSegment] = useState([]);
  const [economicSegmentId, setEconomicSegmentId] = useState();

  const [economicSubType, setEconomicSubType] = useState([]);
  const [economicSubTypeId, setEconomicSubTypeId] = useState();

  const [economicType, setEconomicType] = useState([]);
  const [economicTypeId, setEconomicTypeId] = useState();

  const [economicItem, setEconomicItem] = useState([]);
  const [economicItemId, setEconomicItemId] = useState();

  const [fundSegment, setFundSegment] = useState([]);
  const [fundSegmentId, setFundSegmentId] = useState();

  const [functionalSegment, setFunctionalSegment] = useState([]);
  const [functionalSegmentId, setFunctionalSegmentId] = useState();

  const [subFunction, setSubFunction] = useState([]);
  const [subFunctionId, setSubFunctionId] = useState();

  const [fundType, setFundType] = useState([]);
  const [fundTypeId, setFundTypeId] = useState();

//   const [economicSegmentId, setEconomicSegmentId] = useState([]);
  const [subEconomicId, setSubEconomicId] = useState();
  const [subEconomic, setSubEconomic] = useState([]);

  const [subType, setSubType] = useState([]);
  const [subTypeId, setSubTypeId] = useState();


  const [functionalItem, setFunctionalItem] = useState([]);
  const [functionalItemId, setFunctionalItemId] = useState();

  const [economicSubSubType, setEconomicSubSubType] = useState([]);
  const [economicSubSubTypeId, setEconomicSubSubTypeId] = useState();

  

  const [fundSubType, setFundSubType] = useState([]);
  const [fundSubTypeId, setFundSubTypeId] = useState();

  const [project, setProject] = useState([]);
  const [projectID, setProjectID] = useState();

  const [programmeCost, setProgrammeCost] = useState();
  

  const [ programme, setProgramme] = useState([]);
  const [ programmeID, setProgrammeID] = useState();

  const [ geoLocation,   setGeolocation] = useState();

  const [ geoCode, setGeoCode] = useState([]);
  const [ performance, setPerformance] = useState([]);
  const [ proposedBudget, setProposedBudget] = useState([]);
  const [ projectLocation, setProjectLocation] = useState([]);
  const [ userId, setUserId] = useState([]);
  const [ mdaId, setmdaId] = useState();
  const [ payload, setPayload] = useState();
  const [ programmeCode, setProgrammeCode] = useState([]);
  const [ budgetItem, setbudgetItem] = useState([]);
  const [ monthlyBudget, setmonthlyBudget] = useState({});

  const [ showForm, setshowForm] = useState(false);

  
  const [ formForArrayToSubmit, setFormForArrayToSubmit] = 
  
  useState({
          budgetItem:{},
          monthlyBudget:{},

});





  console.log(formForArrayToSubmit)
//   const [ programmeID, setProgrammeID] = useState();
  

//   const [fundTypeId, setFundTypeId] = useState([]);
//   const [fundSegmentId, setFundSegmentId] = useState([]);
//   const [functionalSegmentId, setFunctionalSegmentId] = useState([]);
//   const [economicTypeId, setEconomicTypeId] = useState([]);
  
  




  







//   const [count, setCount] = useState(1);

 
    // let count = 1
    let mi
    useEffect(()=>{

        onReset()
        const r = localStorage.getItem('r');
        let fn = localStorage.getItem('fn');
        let ln = localStorage.getItem('ln');
        let ui = localStorage.getItem('ui');
         mi = localStorage.getItem('mi');
      
        // r && setRole(decodeToken('r').r);
        if (fn && ln) {
          fn = decodeToken('fn').fn;
          ln = decodeToken('ln').ln;
          ui = decodeToken('ui').ui;
          mi = decodeToken('mi').mi;
        //   setUsername(`${fn} ${ln}`);
        // getPending(ui)
      
          console.log(ui)
          console.log(mi)
          setUserId(ui)
          setmdaId(+mi)
        }

        
        console.log(props.project.programmeId)
        // console.log(Cookies.get('signature'))
        let project = {
            key:props.project.programmeId,
            label:props.project.project,
            value:props.project.programmeId
        }

        setProgrammeID(props.project.programmeId)
        gethrlp()

           
            // form.setFieldsValue({project:project})
               
      
      
        
        
        },[props?.project])

      
        form.setFieldsValue({programmeCode:programmeCode})

       const toSetCost = (e)=>{
        if(!e){
                notification({
                        title: 'No Programme Cost',
                        message: 'You have not created monthly cost allocation for this item',
                        type: 'danger'
                  });
                setHideFinish(false)

                
        }
        else{
                setHideFinish(true)
        form.setFieldsValue({proposedBudget:e})

               }
       }
      

       

    const gethrlp = ()=>{
        // axios.get(`${BASE_API_URL}/EconomicSegment/GetEconomicSegment`, 
        
        // ).then((res)=>{
         
        // setEconomicSegment(res.data)

        // })

        // axios.get(`${BASE_API_URL}/EconomicSubType/GetEconomicSubType`, 
        
        // ).then((res)=>{
         
        // // setEconomicSubType(res.data)


        // })
        // axios.get(`${BASE_API_URL}/FunctionalItem/GetFunctionalItem`, 
        
        // ).then((res)=>{
         
        // // setFunctionalItem(res.data)


        // })

        // axios.get(`${BASE_API_URL}/GeoCode/GetGeoCode`, 
        
        // ).then((res)=>{
         
        // setGeoCode(res.data)


        // })

        
        

        // axios.get(`${BASE_API_URL}/EconomicType/GetEconomicType`, 
        
        // ).then((res)=>{
         
        // // setEconomicType(res.data)
        

        // })

        // axios.get(`${BASE_API_URL}/EconomicItem/GetEconomicItem`, 
        
        // ).then((res)=>{
        
        // setEconomicItem(res.data)


        // })

        // axios.get(`${BASE_API_URL}/FundSegment/GetFundSegment`, 
        
        // ).then((res)=>{
         
        // setFundSegment(res.data)


        // })

        // axios.get(`${BASE_API_URL}/FunctionalSegment/GetFunctionalSegment`, 
        
        // ).then((res)=>{
          
        // setFunctionalSegment(res.data)
        // setFunctionalSegmentId(res?.data[0]?.value)
        // console.log(res?.data[0]?.value)

        // // getSubFunctionByFunctionalSegment(res?.data[0]?.value)
        // // form.setFieldsValue({subFunction:subFunction[0]?.label})



        // })

        

        // axios.get(`${BASE_API_URL}/SubFunction/GetSubFunction`, 
        
        // ).then((res)=>{
         
        // // setSubFunction(res.data)


        // })

        // axios.get(`${BASE_API_URL}/FundType/GetFundType`, 
        
        // ).then((res)=>{
         

        // // setFundType(res.data)


        // })

        
        // axios.get(`${BASE_API_URL}/project/GetProjectByProgrammeID?programmeID=${props.project.programmeId}`, 
        
        // ).then((res)=>{
          

        // setProject(res.data)


        // })

        axios.get(`${BASE_API_URL}/DepartmentBudget/GetDepartmentBudgetPlanByProgrammeIDAndMda?programmeID=${props.project.programmeId}&mdaId=${mi}`, 
        
        ).then((res)=>{
          

        setProject(res.data)


        })


        
        
    }

    const getEconomicSubSubTypeByEconomicSubType = (id)=>{
        axios.get(`${BASE_API_URL}/EconomicSubSubType/GetEconomicSubSubTypeByEconomicSubType?economicSubTypeId=${id}`, 
        
        ).then((res)=>{
          
        setEconomicSubSubType(res.data)


        })


    }
    const GetProjectByProgrammeID = ()=>{
        axios.get(`${BASE_API_URL}/project/GetProjectByProgrammeID?ProgrammeID=${programmeID}`, 
        
        ).then((res)=>{
         

        setProject(res.data)


        })


    }
    
    const getEconomicSubSubTypeBySubType = ()=>{
        axios.get(`${BASE_API_URL}/EconomicSubSubType/GetEconomicSubSubTypeBySubType?subTypeId=${subTypeId}`, 
        
        ).then((res)=>{
         

        // setFundType(res.data)


        })


    }

    const getEconomicSubTypeByEconomicTypeId = (id)=>{
        axios.get(`${BASE_API_URL}/EconomicSubType/GetEconomicSubTypeByEconomicTypeId?EconomicTypeID=${id}`, 
        
        ).then((res)=>{
         
        setEconomicSubType(res.data)


        })


    }
    const getEconomicTypeByEconomicSegmentId = (id)=>{
        axios.get(`${BASE_API_URL}/EconomicType/GetEconomicTypeByEconomicSegmentId?economicSegmentId=${id}`, 
        
        ).then((res)=>{
          
        setEconomicType(res.data)


        })


    }
     const getFunctionalItemBySubEconomicId = (id)=>{
        axios.get(`${BASE_API_URL}/FunctionalItem/GetFunctionalItemBySubEconomicId?subEconomicId=${id}`, 
        
        ).then((res)=>{
         

        setFunctionalItem(res.data)


        })


    }
    const getFundItemByFundType = (id)=>{
        axios.get(`${BASE_API_URL}/FundItem/GetFundItemByFundType?FundTypeId=${id}`, 
        
        ).then((res)=>{
         

        // setFundType(res.data)


        })


    }

    const getFundTypeByFundSegmentID = (id)=>{
        axios.get(`${BASE_API_URL}/FundType/GetFundTypeByFundSegmentID?fundSegmentId=${id}`, 
        
        ).then((res)=>{
        

        setFundType(res.data)



        })


    }
    const getSubFunctionByFunctionalSegment = (id)=>{
        axios.get(`${BASE_API_URL}/SubFunction/GetSubFunctionByFunctionalSegment?functionalSegmentID=${id}`, 
        
        ).then((res)=>{
          
        setSubFunction(res.data)
        // setFundType(res.data)


        })


    }
   
    const onReset = () => {
        form.resetFields();
        setData([])
      };
   
    const toPost = ()=>{

        let postData =
        
        data.map((e)=>(
            // {
            //     id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            //     dateCreated: '2022-02-11T09:59:24.439Z',
            //     enteredBy: 'string',
            //     dateUpdated: '2022-02-11T09:59:24.439Z',
            //     updatedBy: 'string',
            //     isDelete: false,
            //     dateDeleted: '2022-02-11T09:59:24.439Z',
            //     budgetPlanId:' 3fa85f64-5717-4562-b3fc-2c963f66afa6',
            //     project: 'string',
            //     programmeCode: 'string',
            //     status: 'string',
            //     projectLocation: 'string',
            //     proposedBudget: 5,
            //     performance: 'string',
            //     actuals: 'string',
            //     mdaId: 11
            //   }
            {
            //     id: props.project.id,
            //     dateCreated: '2022-03-13T13:28:14.285Z',
            //     enteredBy: userId,
            //     dateUpdated: '2022-03-13T13:28:14.286Z',
            //     updatedBy: userId,
            //     isDelete: false,
            //     dateDeleted: '2022-03-13T13:28:14.286Z',
            //     budgetPlanId: props.project.id,
            //     project: value.project,
            //     programmeCode: 'string',
            //     fundCode: 'string',
            //     functionalCode: 'string',
            //     economicCode: 'string',
            //     geoCode: 'string',
            //     status: 'string',
            //     projectLocation: 'string',
            //     proposedBudget: 0,
            //     performance: 'string',
            //     actuals: 'string',
            //     mdaId: 0
              }
        ))
        // let poster = arrayHolder.map((a)=>())
        axios.post(`${BASE_API_URL}/BudgetPlanning`, postData 
        
                          ).then(()=>{
                            notification({
                                title: 'Successful',
                                message: 'Costs have been Allocated successfully',
                                type: 'success'
                          });

                          })
      }

      const addFormToArrayToSubmit = (e)=>{
        console.log(e)
        props.addToArrayToSubmit(e)
        // addToArrayToSubmit(e)
        // console.log(a)
        // setshowForm(false)
        // setmonthlyBudget(e)
        
        // setFormForArrayToSubmit(e)
  
      }

      

      const onFinish = (value)=>{
          console.log(value)
         let payloadz = {
            id: props.project.id,
            dateCreated: '2022-03-14T23:31:06.332Z',
            enteredBy: '2022-03-14T23:31:06.332Z',
            dateUpdated: '2022-03-14T23:31:06.332Z',
            updatedBy: ''+userId,
            isDelete: false,
            dateDeleted: '2022-03-14T23:31:06.332Z',
            budgetPlanId: props.project.budgetPlanId,
            project: value.project.label,
            programmeCode: value.programmeCode,
            fundCode: '',
            functionalCode: '',
            economicCode: '',
            budgetDeptId:value.project.value,
            // geoCode: value.geoCode.value,
            status: 'string',
            projectLocation: value.projectLocation,
            proposedBudget: +value.proposedBudget,
            performance: value.performance,
            actuals:'',
            mdaId: mdaId,
            programmeName:props.project.project
          }

          setPayload(payloadz)
        //   setshowEditableTable(true)
          setbudgetItem(payloadz)
        //   setFormForArrayToSubmit()
        // props.addToArrayToSubmit(payloadz)
          console.log(payloadz)
          setshowForm(true)

      }

      const closeEditablleTable =(e)=>{
        setshowEditableTable(true)
        setshowForm(false)

        //       onReset()

      }

      const handleCancel =(e)=>{
        props.handleCancel()
        // setshowForm(false)

        //       onReset()

      }

 
  return (
    <div>
              
    <div className='p-10' style={{backgroundColor: "#66ff99",}}>{props.project.project}</div>

      <Form onFinish={onFinish} className='w-100' layout='vertical' style={{display:''}} form={form}>
      
      <div className='w-100 p-10' style={{display:'flex', backgroundColor:"white", justifyContent:"space-between"}}>
          <div className='w-100 m-r-10'>
               
         
              <Form.Item
                       className='w-100 m-r-10'
                       label="Project" 
                       name="project"
                    //    rules={[{
                    //    required: true,
                    //    message: 'Please select who you are sending to!'},
                    //    ]}
                       >
           <Select
                            onChange={(text,index)=>{
                            setApprover(index?.children)
                            setProgrammeCode(index?.code)
                            toSetCost(index?.cost)
                                        }}
                                labelInValue
                                
                                style={{ width: "100%", border: "" }}
                                placeholder="Please select"
                                        >
                                {Array.isArray(project) && project?.map((obj) => (
                        <Option value={obj?.value} key={obj?.id} cost={obj.cost} code={obj.programmeCode}>{`${obj?.label}`}</Option>
                        ))}
            </Select>
              </Form.Item> 

              <Form.Item
                        className='w-100 m-r-10'
                        label="Programme Code" 
                        name="programmeCode"
                        // rules={[{
                        // required: true,
                        // message: 'Please select who you are sending to!'},
                        // ]}
                        >
           <Input readOnly onChange={(e)=>{setProgrammeCode(e.target.value)}}/>
               </Form.Item> 
              {/* <Form.Item
                       className='w-100 m-r-10'
                       label="Programme Segment" 
                       name="programmeSegment"
                    //    rules={[{
                    //    required: true,
                    //    message: 'Please select who you are sending to!'},
                    //    ]}
                       >
           <Select
                            onChange={(text,index)=>{
                            setApprover(index?.children)
                            setApproverId(index?.value)
                                        }}
                                labelInValue
                                style={{ width: "100%", border: "" }}
                                placeholder="Please select"
                                        >
                                {Array.isArray(employees) && employees?.map((obj) => (
                        <Option value={obj?.value} key={obj?.id}>{`${obj?.label}`}</Option>
                        ))}
            </Select>
              </Form.Item> */}
              
              </div>
              <div className='w-100 m-r-10'>
               
         
               {/* <Form.Item
                        className='w-100 m-r-10'
                        label="Geo Code" 
                        name="geoCode"
                        // rules={[{
                        // required: true,
                        // message: 'Please select who you are sending to!'},
                        // ]}
                        >
            <Select
                             onChange={(text,index)=>{
                             setApprover(index?.children)
                             setApproverId(index?.value)
                                         }}
                                 labelInValue
                                 
                                 style={{ width: "100%", border: "" }}
                                 placeholder="Please select"
                                         >
                                 {Array.isArray(geoCode) && geoCode?.map((obj) => (
                         <Option value={obj?.value} key={obj?.id}>{`${obj?.label}`}</Option>
                         ))}
             </Select>
               </Form.Item>  */}

               
               <Form.Item
                        className='w-100 m-r-10'
                        label="Project Location" 
                        name="projectLocation"
                        // rules={[{
                        // required: true,
                        // message: 'Please select who you are sending to!'},
                        // ]}
                        >
           <Input onChange={()=>{setProjectLocation()}}/>
               </Form.Item> 

               <Form.Item
                        className='w-100 m-r-10'
                        label="Performance" 
                        name="performance"
                        // rules={[{
                        // required: true,
                        // message: 'Please select who you are sending to!'},
                        // ]}
                        >
           <Input onChange={()=>{setPerformance()}}/>
               </Form.Item> 

               <Form.Item
                        className='w-100 m-r-10'
                        label="proposed Budget" 
                        name="proposedBudget"
                        // rules={[{
                        // required: true,
                        // message: 'Please select who you are sending to!'},
                        // ]}
                        >
           <Input readOnly onChange={()=>{setProposedBudget()}}/>
               </Form.Item> 


               {/* <Form.Item
                        className='w-100 m-r-10'
                        label="Programme Segment" 
                        name="programmeSegment"
                        // rules={[{
                        // required: true,
                        // message: 'Please select who you are sending to!'},
                        // ]}
                        >
            <Select
                             onChange={(text,index)=>{
                             setApprover(index?.children)
                             setApproverId(index?.value)
                                         }}
                                 labelInValue
                                 style={{ width: "100%", border: "" }}
                                 placeholder="Please select"
                                         >
                                 {Array.isArray(employees) && employees?.map((obj) => (
                         <Option value={obj?.value} key={obj?.id}>{`${obj?.label}`}</Option>
                         ))}
             </Select>
               </Form.Item> */}
               
               </div>
          
              {/* <div className='w-100 m-r-10'>
              <Form.Item
                       className='w-100 m-r-10'
                       label="Functional Segment" 
                       name="functionalSegment"
                    //    rules={[{
                    //    required: true,
                    //    message: 'Please select who you are sending to!'},
                    //    ]}
                       >
           <Select
                            onChange={(text,index)=>{
                                getSubFunctionByFunctionalSegment(index.value)

                            // setApproverId(index?.value)
                                        }}
                                labelInValue
                                style={{ width: "100%", border: "" }}
                                placeholder="Please select"
                                        >
                                {Array.isArray(functionalSegment) && functionalSegment?.map((obj) => (
                        <Option value={obj?.value} key={obj?.value}>{`${obj?.label}`}</Option>
                        ))}
            </Select>
              </Form.Item> 
              {<Form.Item
                       className='w-100 m-r-10'
                       label="Sub Function" 
                       name="subFunction"
                    //    rules={[{
                    //    required: true,
                    //    message: 'Please select who you are sending to!'},
                    //    ]}
                       >
           <Select
                            onChange={(text,index)=>{
                            setApprover(index?.children)
                            getFunctionalItemBySubEconomicId(index?.value)
                                        }}
                                labelInValue
                       disabled = {subFunction.length ==0? true:false}

                                style={{ width: "100%", border: "" }}
                                placeholder="Please select"
                                        >
                                {Array.isArray(subFunction) && subFunction?.map((obj) => (
                        <Option value={obj?.value} key={obj?.id}>{`${obj?.label}`}</Option>
                        ))}
            </Select>
              </Form.Item> }
              <Form.Item
                       className='w-100 m-r-10'
                       label="Functional Item" 
                       name="functionalItem"
                    //    rules={[{
                    //    required: true,
                    //    message: 'Please select who you are sending to!'},
                    //    ]}
                       >
           <Select
                            onChange={(text,index)=>{
                            setApprover(index?.children)
                            setApproverId(index?.value)
                                        }}
                       disabled = {functionalItem.length ==0? true:false}

                                labelInValue
                                style={{ width: "100%", border: "" }}
                                placeholder="Please select"
                                        >
                                {Array.isArray(functionalItem) && functionalItem?.map((obj) => (
                        <Option value={obj?.value} key={obj?.id}>{`${obj?.label}`}</Option>
                        ))}
            </Select>
              </Form.Item>
              
              </div>
           */}
              {/* <div className='w-100 m-r-10'>
              <Form.Item
                       className='w-100 m-r-10'
                       label="Economic Segment" name="economicSegment"
                    //    rules={[{
                    //    required: true,
                    //    message: 'Please select who you are sending to!'},
                    //    ]}
                       >
           <Select
                            onChange={(text,index)=>{
                            setApprover(index?.children)
                            getEconomicTypeByEconomicSegmentId(index?.value)
                                        }}
                                labelInValue
                                style={{ width: "100%", border: "" }}
                                placeholder="Please select"
                                        >
                                {Array.isArray(economicSegment) && economicSegment?.map((obj) => (
                        <Option value={obj?.value} key={obj?.id}>{`${obj?.label}`}</Option>
                        ))}
            </Select>
              </Form.Item> 
              <Form.Item
                       className='w-100 m-r-10'
                       label="Economic Type" name="economicType"
                    //    rules={[{
                    //    required: true,
                    //    message: 'Please select who you are sending to!'},
                    //    ]}
                       >
           <Select
                            onChange={(text,index)=>{
                            setApprover(index?.children)
                            getEconomicSubTypeByEconomicTypeId(index?.value)
                                        }}
                                labelInValue
                       disabled = {economicType.length ==0? true:false}

                                style={{ width: "100%", border: "" }}
                                placeholder="Please select"
                                        >
                                {Array.isArray(economicType) && economicType?.map((obj) => (
                        <Option value={obj?.value} key={obj?.id}>{`${obj?.label}`}</Option>
                        ))}
            </Select>
              </Form.Item> 
              <Form.Item
                       className='w-100 m-r-10'
                       label="Economic Sub-type" name="economicSubType"
                    //    rules={[{
                    //    required: true,
                    //    message: 'Please select who you are sending to!'},
                    //    ]}
                       >
           <Select
                            onChange={(text,index)=>{
                            setApprover(index?.children)
                            getEconomicSubSubTypeByEconomicSubType(index?.value)
                                        }}
                                labelInValue
                       disabled = {economicSubType.length ==0? true:false}

                                style={{ width: "100%", border: "" }}
                                placeholder="Please select"
                                        >
                                {Array.isArray(economicSubType) && economicSubType?.map((obj) => (
                        <Option value={obj?.value} key={obj?.id}>{`${obj?.label}`}</Option>
                        ))}
            </Select>
              </Form.Item> 
              <Form.Item
                       className='w-100 m-r-10'
                       label="Economic Sub-sub-Type" name="subSubType"
                    //    rules={[{
                    //    required: true,
                    //    message: 'Please select who you are sending to!'},
                    //    ]}
                       >
           <Select
                            onChange={(text,index)=>{
                            setApprover(index?.children)
                            // getEconomicSubSubTypeByEconomicSubType(index?.value)
                                        }}
                                labelInValue
                       disabled = {economicSubSubType.length ==0? true:false}

                                style={{ width: "100%", border: "" }}
                                placeholder="Please select"
                                        >
                                {Array.isArray(economicSubSubType) && economicSubSubType?.map((obj) => (
                        <Option value={obj?.value} key={obj?.id}>{`${obj?.label}`}</Option>
                        ))}
            </Select>
              </Form.Item>
              
              </div> */}
          
              {/* <div className='w-100 m-r-10'>
              <Form.Item
                       className='w-100 m-r-10'
                       label="Fund Segment" name="fundSegment"
                    //    rules={[{
                    //    required: true,
                    //    message: 'Please select who you are sending to!'},
                    //    ]}
                       >
           <Select
                            onChange={(text,index)=>{
                            setApprover(index?.children)
                            getFundTypeByFundSegmentID(index?.value)
                                        }}

                                labelInValue
                                style={{ width: "100%", border: "" }}
                                placeholder="Please select"
                                        >
                                {Array.isArray(fundSegment) && fundSegment?.map((obj) => (
                        <Option value={obj?.value} key={obj?.id}>{`${obj?.label}`}</Option>
                        ))}
            </Select>
              </Form.Item> 
              <Form.Item
                       className='w-100 m-r-10'
                       label="Funds Type" name="fundsType"
                    //    rules={[{
                    //    required: true,
                    //    message: 'Please select who you are sending to!'},
                    //    ]}
                       >
           <Select
                            onChange={(text,index)=>{
                            setApprover(index?.children)
                            getFundItemByFundType(index?.value)
                                        }}
                       disabled = {fundType.length ==0? true:false}

                                labelInValue
                                style={{ width: "100%", border: "" }}
                                placeholder="Please select"
                                        >
                                {Array.isArray(fundType) && fundType?.map((obj) => (
                        <Option value={obj?.value} key={obj?.id}>{`${obj?.label}`}</Option>
                        ))}
            </Select>
              </Form.Item> 
              <Form.Item
                       className='w-100 m-r-10'
                       label="Fund Sub-type" name="fundSubType"
                    //    rules={[{
                    //    required: true,
                    //    message: 'Please select who you are sending to!'},
                    //    ]}
                       >
           <Select
                            onChange={(text,index)=>{
                            setApprover(index?.children)
                            setApproverId(index?.value)
                                        }}
                       disabled = {fundSubType.length ==0? true:false}

                                labelInValue
                                style={{ width: "100%", border: "" }}
                                placeholder="Please select"
                                        >
                                {Array.isArray(fundSubType) && fundSubType?.map((obj) => (
                        <Option value={obj?.value} key={obj?.id}>{`${obj?.label}`}</Option>
                        ))}
            </Select>
              </Form.Item>

              
              </div> */}
      </div>

      {hideFinish&&<Button block htmlType='submit'>Add to Table</Button>}

      </Form>

     { showEditableTable && <EditableTable  
                    project = {payload}
                    data = {data}
                    addFormToArrayToSubmit = {addFormToArrayToSubmit}
                    closeEditablleTable = {closeEditablleTable}
                    showForm={showForm}
                    handleCancel={handleCancel}
                    hideFinish = {hideFinish}

                    
                    // project = {detail.budgetProjectInitiatives}
                    />}
    </div>
  );
};

export default ProjectForm