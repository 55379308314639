import React from 'react'
import { Collapse, Form, Input, Button, Checkbox } from 'antd';
import Tablez from '../layouts/Tablez';
import BudgetDetailsSummary from '../layouts/BudgetDetailsSummary';
import ItemSetUpTable from '../layouts/ItemSetUpTable';


const { Panel } = Collapse;

const BudgetItemSetUp = ()=>{

    const onFinish = (values) => {
        console.log('Success:', values);
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

function callback(key) {
    console.log(key);
  }
    return(
        <div style={{width:'100%'}}>





<Collapse defaultActiveKey={['1']} onChange={callback}>
    <Panel header="Department Budget Items Setting" key="1">
      <div className='flex'>
      <div style={{width:'40%'}}>>
          <Form
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout = 'vertical'
      >
      <Form.Item
      label="Program"
      name="program"
      rules={[{ required: true, message: 'Please input your username!' }]}
      >
      <Input />
      </Form.Item>
      
      <Form.Item
      label="Expenditure Type"
      name="expType"
      rules={[{ required: true, message: 'Please input your password!' }]}
      >
      <Input.Password />
      </Form.Item>
      
      <Form.Item
      label="Item"
      name="item"
      rules={[{ required: true, message: 'Please input your username!' }]}
      >
      <Input />
      </Form.Item>
      
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
      <Button type="primary" htmlType="submit">
      Submit
      </Button>
      </Form.Item>
      </Form>
      </div>
          <p style={{width:'60%'}}> <ItemSetUpTable/></p>
      </div>
    </Panel>
    <Panel header="Upload Document" key="2">
      <p><BudgetDetailsSummary/></p>
      <p>{text}</p>
    </Panel>
   
  </Collapse>







        </div>
    )
}
export default BudgetItemSetUp