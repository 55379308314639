import * as yup from "yup"
const projectActivitiesValidationSchema = yup.object().shape({
    objectiveId: yup.string().required('objective is required'),
    activityName: yup.string().required('activity name is required'),
    activityCode: yup.string().required('activity code is required'),



});
// let data = { objectiveId, activityName, activityCode };
export default projectActivitiesValidationSchema