import * as yup from "yup"
const fundTypeValidationSchema = yup.object().shape({
    fundSegmentID: yup.string().required('fund segment is required'),
    fundTypeName: yup.string().required('fund type name is required'),
    fundTypeCode: yup.string().required('fund type code is required'),


});
//  let data = { fundSegmentID, fundTypeName, fundTypeCode };
export default fundTypeValidationSchema
