import React, { Component } from "react";
import { Preloader } from "../../Utils/Preloader";
// import ValidationMessageComponent from "../../Components/ValidationMessage";
import axios from "axios";
import {Select} from "antd";
import cookies from 'js-cookie'
const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';

const {Option} = Select
// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

function formatDefaultDate(dateString) {
  console.log(dateString);
  var newDate = new Date(dateString);
  newDate.setDate(newDate.getDate() + 1);

  return newDate.toISOString().split("T")[0];
}

export default class DepartmentBudgetHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      geoCodeData: "",
        localGovernmentCouncilData: [],
        allBudgetInformationData: [],

        allBudgetPeriodData:[],
      localGovernmentCouncil: "",
      wardCouncil: "",
      geoCode: "",
      loading: false,
      deleteId: "",
      edit: false,
      editId: "",
        submenu: false,
       laoder:false

    };
  }

    componentDidMount() {
        this.getBudgetPeriod();
        this.getBudgetInformation();
    }

  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

    getBudgetPeriod() {
        this.setState({
            loader: true,
            message: "",

        });
        axios.get(`${BASE_API_URL}/budgetperiod`).then((response) => {
            this.setState({
                loader: false,
                message: "",
                allBudgetPeriodData:response.data
            });
        }).catch((err) => {
            this.setState({
                loader: true,
                message: err.message

            });
        })
    }
    getBudgetInformationByBudgetPeriod=()=> {
        let budgetPeriodId = document.getElementById('budgetPeriod').value
        if (budgetPeriodId != "") {
            this.setState({
                loader: true,
                message: "",

            });
            axios.get(`${BASE_API_URL}/budgetInformation/getBudgetInformationByBudgetPeriod?budgetPeriodId=${budgetPeriodId}`).then((response) => {
                this.setState({
                    loader: false,
                    message: "",
                    allBudgetInformationData: response.data
                });

            }).catch((err) => {

                this.setState({
                    loader: false,
                    message: err.message

                });
            })
        }
        //alert(budgetPeriodId)

    }
    getBudgetInformation = () => {

        axios
            .get(`${BASE_API_URL}/budgetinformation/getallbudgetinformation`)

            .then((response) => {
                this.setState({
                    loader: false,
                    allBudgetInformationData: response.data,
                    message: "",
                });
                console.log(response.data)
                //let departmentBudgetId = response.data.map(a => a.departmentBudgetId);
                //this.checkIfBudgetHaveAlreadyBeenAddded(departmentBudgetId)
                //this.getBudgetApprovalRole(departmentBudgetId)
;
            })
            .catch((err) => {
                console.log(err)
            });
    };
    handleExportCsv = (table_id, separator = ",") => {
        // Select rows from table_id
        var rows = document.querySelectorAll("table#" + table_id + " tr");
        // Construct csv
        var csv = [];
        for (var i = 0; i < rows.length; i++) {
            var row = [],
                cols = rows[i].querySelectorAll("td, th");
            for (var j = 0; j < cols.length; j++) {
                // Clean innertext to remove multiple spaces and jumpline (break csv)
                var data = cols[j].innerText
                    .replace(/(\r\n|\n|\r)/gm, "")
                    .replace(/(\s\s)/gm, " ");
                // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
                data = data.replace(/"/g, '""');
                // Push escaped string
                row.push('"' + data + '"');
            }
            csv.push(row.join(separator));
        }
        var csv_string = csv.join("\n");
        // Download it
        var filename =
            "export_" + table_id + "_" + new Date().toLocaleDateString() + ".csv";
        var link = document.createElement("a");
        link.style.display = "none";
        link.setAttribute("target", "_blank");
        link.setAttribute(
            "href",
            "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
        );
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    handlePrint = (table_id) => {
        var divToPrint = document.getElementById(table_id);
        let newWin = window.open("");
        newWin.document.write(divToPrint.outerHTML);
        newWin.print();
        newWin.close();
    };

    render() {
        let budgetPeriods = this.state.allBudgetPeriodData;

        let options = budgetPeriods.map((budgetPeriod) => {

            return (
                <option style={{sidth:"200px"}} value={budgetPeriod.id}>{budgetPeriod.title }</option>
                )
        })
        const budgetInformations = this.state.allBudgetInformationData;
        var alltotal = 0;
        // const budgetInformations = this.state.allBudgetInformationData;
        var janVal = 0;
        var febVal = 0;
        var marVal = 0;
        var aprVal = 0;
        var mayVal = 0;
        var junVal = 0;
        var julVal = 0;
        var augVal = 0;
        var sepVal = 0;
        var octVal = 0;
        var novVal = 0;
        var decVal = 0;
        let allData = budgetInformations.map((budgetInformation) => {

            janVal += parseFloat(budgetInformation.jan_val);
            febVal += parseFloat(budgetInformation.feb_val);
            marVal += parseFloat(budgetInformation.mar_val);

            aprVal += parseFloat(budgetInformation.apr_val);
            mayVal += parseFloat(budgetInformation.may_val);
            junVal += parseFloat(budgetInformation.june_val);
            julVal += parseFloat(budgetInformation.july_val);
            augVal += parseFloat(budgetInformation.aug_val);
            sepVal += parseFloat(budgetInformation.sept_val);
            octVal += parseFloat(budgetInformation.oct_val);
            novVal += parseFloat(budgetInformation.nov_val);
            decVal += parseFloat(budgetInformation.dec_val);
            let total =
                budgetInformation.jan_val
                + budgetInformation.feb_val
                + budgetInformation.mar_val
                + budgetInformation.apr_val
                + budgetInformation.may_val
                + budgetInformation.june_val
                + budgetInformation.july_val
                + budgetInformation.aug_val
                + budgetInformation.sept_val
                + budgetInformation.oct_val
                + budgetInformation.nov_val
                + budgetInformation.dec_val
            alltotal+=total
            return (
                <tr>
                    <td className=" border table-border-right p-2 text-sm font-medium text-gray-900" >
                        {budgetInformation.departmentBudget.programmes == null ? "" : budgetInformation.departmentBudget.programmes.programmeName}
                    </td>
                    <td className="border table-border-right p-2 text-sm text-color">
                        {budgetInformation.departmentBudget.item}
                    </td>
                    <td className="border table-border-right p-2 text-sm text-color">
                        {budgetInformation.currency.title}
                    </td>
                    <td className="border table-border-right p-2 text-sm text-color">
                        {budgetInformation.jan_val}
                    </td>
                    <td className="border table-border-right p-2 text-sm text-color">
                        {budgetInformation.feb_val}
                    </td>
                    <td className="border table-border-right p-2 text-sm text-color">
                        {budgetInformation.mar_val}
                    </td>
                    <td className="border table-border-right p-2 text-sm text-color">
                        {budgetInformation.apr_val}
                    </td>
                    <td className="border table-border-right p-2 text-sm text-color">
                        {budgetInformation.may_val}
                    </td>
                    <td className="border table-border-right p-2 text-sm text-color">
                        {budgetInformation.june_val}
                    </td>
                    <td className="border table-border-right p-2 text-sm text-color">
                        {budgetInformation.july_val}
                    </td>
                    <td className="border table-border-right p-2 text-sm text-color">
                        {budgetInformation.aug_val}
                    </td>
                    <td className="border table-border-right p-2 text-sm text-color">
                        {budgetInformation.sept_val}
                    </td>
                    <td className="border table-border-right p-2 text-sm text-color">
                        {budgetInformation.oct_val}
                    </td>
                    <td className="border table-border-right p-2 text-sm text-color">
                        {budgetInformation.nov_val}
                    </td>
                    <td className="border table-border-right p-2 text-sm text-color">
                        {budgetInformation.dec_val}
                    </td>
                    <td className="border table-border-right p-2 text-sm text-color">
                        {total}
                    </td>
                </tr>
            );
        });

        return (

        <React.Fragment>
            {this.state.loader === true ? <Preloader /> : ""}
        {this.state.open ? (
          <React.Fragment>
            <div
              className="flex bg-white py-4 px-4 shadow-sm overflow-hidden cursor-pointer"
              onClick={() => this.setState({ open: !this.state.open })}
            >
              <p className="flex-1 w-100 mt-2 pointer text-sm font-medium font-avenir-black text-color">
                Department Budget History
              </p>
            </div>
            <div className="flex w-100 bg-gray-table-header py-4 px-4 shadow-sm overflow-hidden cursor-pointer">
                        <div class="w-100 flex-1 mt-1 relative z-20">

                            <select className="border p-5" style={{fontSize:'15px', padding:"5px"}} id="budgetPeriod" onChange={() => this.getBudgetInformationByBudgetPeriod() } class="block truncate text-sm text-color relative pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm">
                                <option style={{width:'20px'}} value="">Select Period</option>
                                {options}
                            </select>


                {this.state.submenu && (
                  <div
                    class="absolute z-30 mt-1 shadow-lg"
                    // style={{
                    //   backgroundColor: "#F3F8F3",
                    //   borderColor: "#B2D4B2",
                    // }}
                  >
                    <ul
                      tabindex="-1"
                      role="listbox"
                      aria-labelledby="listbox-label"
                      aria-activedescendant="listbox-item-3"
                      class="max-h-60 rounded-md py-1 text-sm overflow-auto focus:outline-none sm:text-sm"
                    >
                      <li
                        id="listbox-option-0"
                        role="option"
                        class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 focus:outline-none "
                        onClick={() =>
                          this.setState({ submenu: !this.state.submenu })
                        }
                      >
                        <span class="font-normal block truncate">Approve</span>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className='flex-between' style={{width:'23%'}}>
                                <button onClick={() => {
                                    this.handleExportCsv("table_info");
                                }}

                                    type="submit"
                                    className="m-r-5 h-10 inline-flex py-2 pr-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                                >
                                    {/* <img
                                        src="/upload-excel.png"
                                        style={{ width: 20, height: 20 }}
                                        className="mx-3"
                                    /> */}
                  Excel Export
                </button>
                                <button onClick={() => {
                                    this.handlePrint("table_info");
                                }}
                  type="submit"
                  className="mr-3 h-10 inline-flex py-2 pr-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                >
                  {/* <img
                    src="/upload-excel.png"
                    style={{ width: 20, height: 20 }}
                    className="mx-3"
                  /> */}
                  Print Document
                </button>
              </div>
            </div>
            <div class="w-100 flex flex-col">
              <div class="w-100 shadow overflow-y-scroll">
                <table style={{border:'none'}} className="w-100 min-w-full" id="table_info">
                  <thead class="w-100 bg-card-header">
                    <tr>
                      <th
                        scope="col"
                        class="table-border-right px-6 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                        style={{color:"#000000",textShadow:"1px 0 #000000"}}
                      >
                        Category
                      </th>
                      <th
                        scope="col"
                        style={{color:"#000000",textShadow:"1px 0 #000000"}}
                        class="table-border-right px-6 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                      >
                        Item
                      </th>
                      <th
                        scope="col"
                        style={{color:"#000000",textShadow:"1px 0 #000000"}}
                        class="table-border-right px-6 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                      >
                        Currency
                      </th>
                      <th
                        scope="col"
                        style={{color:"#000000",textShadow:"1px 0 #000000"}}
                        class="table-border-right px-6 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                      >
                        Jan
                      </th>
                      <th
                        scope="col"
                        style={{color:"#000000",textShadow:"1px 0 #000000"}}
                        class="table-border-right px-6 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                      >
                        Feb
                      </th>
                      <th
                        scope="col"
                        style={{color:"#000000",textShadow:"1px 0 #000000"}}
                        class="table-border-right px-6 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                      >
                        Mar
                      </th>
                      <th
                        scope="col"
                        style={{color:"#000000",textShadow:"1px 0 #000000"}}
                        class="table-border-right px-6 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                      >
                        Apr
                      </th>
                      <th
                        scope="col"
                        style={{color:"#000000",textShadow:"1px 0 #000000"}}
                        class="table-border-right px-6 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                      >
                        May
                      </th>
                      <th
                        scope="col"
                        style={{color:"#000000",textShadow:"1px 0 #000000"}}
                        class="table-border-right px-6 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                      >
                        Jun
                      </th>
                      <th
                        scope="col"
                        style={{color:"#000000",textShadow:"1px 0 #000000"}}
                        class="table-border-right px-6 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                      >
                        Jul
                      </th>
                      <th
                        scope="col"
                        style={{color:"#000000",textShadow:"1px 0 #000000"}}
                        class="table-border-right px-6 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                      >
                        Aug
                      </th>
                      <th
                          style={{color:"#000000",textShadow:"1px 0 #000000"}}
                        scope="col"
                        class="table-border-right px-6 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                      >
                        Sept
                      </th>
                      <th
                        scope="col"
                        style={{color:"#000000",textShadow:"1px 0 #000000"}}
                        class="table-border-right px-6 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                      >
                        Oct
                      </th>
                      <th style={{color:"#000000",textShadow:"1px 0 #000000"}}
                        scope="col"
                        class="table-border-right px-6 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                      >
                        Nov
                      </th>
                      <th style={{color:"#000000",textShadow:"1px 0 #000000"}}
                        scope="col"
                        class="table-border-right px-6 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                      >
                        Dec
                      </th>
                      <th style={{color:"#000000",textShadow:"1px 0 #000000"}}
                        scope="col"
                        class="table-border-right px-6 py-3 text-right text-sm font-medium font-avenir-black text-color tracking-wider"
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                                    <tbody class="bg-white divide-y divide-gray-200">
                                        {allData}

                                        <tr>
                                            <td
                                                colSpan="3"
                                                class="table-border-right p-2 text-sm font-medium font-avenir-black  text-gray-900"
                                            >

                                            </td>
                                            <td style={{color:"#000000",textShadow:"1px 0 #000000"}} class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                                {janVal}
                                            </td>
                                            <td style={{color:"#000000",textShadow:"1px 0 #000000"}} class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                                {febVal}
                                            </td>
                                            <td style={{color:"#000000",textShadow:"1px 0 #000000"}} class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                                {marVal}
                                            </td>
                                            <td style={{color:"#000000",textShadow:"1px 0 #000000"}} class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                                {aprVal}
                                            </td>
                                            <td style={{color:"#000000",textShadow:"1px 0 #000000"}} class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                                {mayVal}
                                            </td>
                                            <td style={{color:"#000000",textShadow:"1px 0 #000000"}} class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                                {junVal}
                                            </td>
                                            <td style={{color:"#000000",textShadow:"1px 0 #000000"}} class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                                {julVal}
                                            </td>
                                            <td style={{color:"#000000",textShadow:"1px 0 #000000"}} class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                                {augVal}
                                            </td>
                                            <td style={{color:"#000000",textShadow:"1px 0 #000000"}} class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                                {sepVal}
                                            </td>
                                            <td style={{color:"#000000",textShadow:"1px 0 #000000"}} class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                                {octVal}
                                            </td>
                                            <td style={{color:"#000000",textShadow:"1px 0 #000000"}} class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                                {novVal}
                                            </td>
                                            <td style={{color:"#000000",textShadow:"1px 0 #000000"}} class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                                {decVal}
                                            </td>
                                            <td style={{color:"#000000",textShadow:"1px 0 #000000"}} class="table-border-right p-2 text-sm font-medium font-avenir-black  text-color">
                                                {alltotal}
                                            </td>
                                        </tr>
                                    </tbody>
                </table>
              </div>
            </div>
            <p className="text-sm font-normal text-color p-4">
              Showing 1 to 2 of 2 entries
            </p>
          </React.Fragment>
        ) : (
          <div
            className="bg-white w-100 py-4 px-4 shadow-sm overflow-hidden cursor-pointer mb-3"
            onClick={() => this.setState({ open: !this.state.open })}
          >
            <p className="text-sm font-medium pointer text-color">
              Department Budget History
            </p>
          </div>
        )}
      </React.Fragment>
    );
  }

}
