import React, { Component } from "react";
import axios from "axios";
import { store } from 'react-notifications-component'
import { nullcheck, notify } from '../../Utils/Helper'
import Employees from "./Employees";
import { Preloader } from "../../Utils/Preloader";
import ValidationMessageComponent from "./ValidationMessage";
import notification from "../../utility/notification";

import userSettingSchema from "../../Validation/UserSettingValidation";
import cookies from 'js-cookie'
import { BsPencilSquare } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';

// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

  class PrivilegesAccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            userprevileges: [],
            mdas: [],
            mda: "",
            staff: "",
            assignModule: "",
            loading: false,
            deleteId: "",
            employeeId: "",
            mdaId: "",
            module: "Finance",
            message: "",
            showNotification: false,
            notificationSuccess: false,
            notificationMessage: "Successfully saved!",
            loader: false,
            search: '',
            deleteModal: false,

        };
    }

    handleChange = (ev) => {
        this.setState({
            [ev.target.name]: ev.target.value,
        });
    };

    handleSubmit = () => {
        let mdaId = this.state.mdaId === "" ? "" : parseInt(this.state.mdaId);
        let employeeID =
            document.getElementsByName("employeeID")[0].value == ""
                ? ""
                : parseInt(document.getElementsByName("employeeID")[0].value);
        let module = this.state.module;
        let enteredBy = this.props.employeeId.toString()


        let data = { mdaId, employeeID, module, enteredBy };

        userSettingSchema
            .validate(data)
            .then((message) => {
                this.setState({
                    loader: true,
                    message: "",
                });

                axios
                    .post(`${BASE_API_URL}/userprevilege`, { ...data })
                    .then((response) => {
                        document.getElementById("employeeID").selectedIndex = "0";
                        document.getElementById("mdaId").selectedIndex = "0";
                        this.setState({
                            mdaId: "",
                            employeeID: "",
                            module: "Finance",
                            loader: false,
                            message: "",
                        });
                        // notify(store, 'success', 'priveledge was added successfully');
                        notification({
                            title: 'Successful',
                            message: 'priveledge was added successfully',
                            type: 'success'
                      });
                        this.refreshUserPrevilege();
                    })
                    .catch((err) => {
                        this.setState({
                            loader: false,
                            message: nullcheck(err.response) == "" ? "server error" : err.response.data,
                        });

                    });
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loader: false,
                    message: err.errors,
                });
            });

        // this.setState({ loading: true }, () =>
        //     this.props.createAction(DataTypes, { data })
        // );
    };

    handleDelete = () => {
        let id = this.state.deleteId
        this.setState({
            loader: true,
            message: "",
            deleteModal: false
        });
        axios
            .delete(`${BASE_API_URL}/userprevilege?id=${id}`)
            .then((response) => {
                this.setState({
                    loader: false,
                    message: "",
                    deleteModal: false,
                    deleteId: ""
                });
                // notify(store, 'success', 'privelege was deleted successfully');
                notification({
                    title: 'Successful',
                    message: 'privelege was deleted successfully',
                    type: 'success'
              });
                this.refreshUserPrevilege();
            })
            .catch((err) => {
                this.setState({
                    loader: false,
                    message: nullcheck(err.response) == "" ? "server error" : err.response.data,
                    deleteModal: false,
                    deleteId: ""
                });
            });
    };
    componentDidMount() {
        this.refreshUserPrevilege();
        this.refreshMda();
    }

    refreshUserPrevilege() {
        this.setState({
            loader: true,
            message: "",

        })
        axios.get(`${BASE_API_URL}/userprevilege`).then((response) => {

            this.setState({
                userprevileges: response.data,
                loader: false,
                message: "",
            });
        }).catch((err) => {
            this.setState({
                loader: false,
                message: nullcheck(err.response) == "" ? "server error" : err.response.data,

            })
        });
    }
    refreshMda() {
        this.setState({
            loader: true,
            message: "",

        })
        axios.get(`${BASE_API_URL}/mda`).then((response) => {

            this.setState({
                mdas: response.data,
                loader: false,
                message: "",
            })

        }).catch((err) => {
            this.setState({
                loader: false,
                message: nullcheck(err.response) == "" ? "server error" : err.response.data,

            })
        })
    }

    render() {
        let allmdas = this.state.mdas.map((mda) => {
            return (
                <option key={mda.id} value={mda.id}>
                    {mda.name}
                </option>
            );
        });
        const searchUserPrevileges = this.state.userprevileges.filter(
            (userprevilege) => {
                return (userprevilege.employees.firstName + '' + userprevilege.employees.lastName).toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
            }

        );

        let userprevileges = searchUserPrevileges.map((userprevilege) => {
            return (
                <tr
                    style={{
                        borderBottom: "1px solid #C7C7C7",
                    }}
                >
                    <td className="px-6 py-4 text font-avenir-medium table-color">
                        {userprevilege.employees.firstName}{" "}
                        {userprevilege.employees.lastName}
                    </td>
                    <td className="px-6 py-4 text font-avenir-medium table-color">
                        {userprevilege.mdas.name}
                    </td>
                    <td className="px-6 py-4 text font-avenir-medium table-color">
                        {userprevilege.module}
                    </td>
                    <td className="px-6 py-4  text-base font-medium">
                        {true ? (
                            <a
                                className="cursor-pointer"
                                onClick={() =>
                                    this.setState({
                                        deleteModal: !this.state.deleteModal,
                                        deleteId: userprevilege.id

                                    })
                                }
                            >
                                {/* <svg
                            width="20"
                            height="20"
                            className="ml-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="#707070"
                        >
                          <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg> */}
                                {/* <img src="/delete-icon.png" className="ml-5 h-5 w-5" /> */}
                            <AiOutlineDelete style={{backgroundColor:"#43425D", color:"white", fontSize:"26px", padding:"5px"}}/>

                            </a>
                        ) : (
                                <svg
                                    className="animate-spin ml-5 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="#43425D"
                                        stroke-width="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                            )}
                    </td>
                </tr>
            );
        });

        return (
            <React.Fragment>
                {this.state.loader === true ? (
                    <Preloader />
                ) : ""}
                {this.state.deleteModal && (
                    <>
                        <div className="overlay p-10 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-3/12 my-6 mx-auto bg-white p-4">
                                {/*content*/}
                                <div className="p-10 py-4 px-4 shadow-sm overflow-hidden cursor-pointer">
                                    <p className="flex-1 mt-2 text-sm font-medium font-avenir-black text-color text-center">
                                        Are you sure you want to
                                        delete?
                                                    </p>
                                </div>
                                <div class="flex p-10 justify-center">
                                    <button onClick={() => this.handleDelete()}
                                        type="submit"
                                        class="p-10 pointer mr-5 w-40 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"

                                    >

                                        Yes
                                                    </button>
                                    <button
                                        type="button"
                                        class="w-40 bg-white pointer p-10 py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-color bg-gray-200 hover:bg-gray-50 focus:outline-none"
                                        onClick={() =>
                                            this.setState({
                                                deleteModal: !this.state.deleteModal,
                                            })
                                        }
                                    >
                                        No
                                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                )}
                {this.state.userprevileges ? (
                    <React.Fragment>
                        {this.state.open ? (
                            <div className="m-b-5">
                                <div
                                    className=" pointer m-b-5 bg-card-header py-4 px-4 shadow-sm overflow-hidden bb-green cursor-pointer"
                                    onClick={() => {
                                        this.refreshUserPrevilege();
                                        this.refreshMda();
                                this.setState({ open: !this.state.open })}}
                                >
                                    <div className="flex-between">
                                        <p className="flex-1  text-sm font-medium font-avenir-black text-color">
                                            User Settings (Privileges & Access)
                    </p>
                                        <input
                                            placeholder="Search"
                                            className="border p-5 border-solid px-2 h-8 w-50 mt-2"
                                            style={{
                                                backgroundColor: "transparent",
                                                borderColor: "#707070",
                                                width:"25%"

                                            }}
                                            name='search'
                                            onChange={this.handleChange}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </div>
                                </div>
                                <div className="p-10 w-100 flex bg-white py-4 pl-4 shadow-sm cursor-pointer">
                                    <div className="w-40 m-r-10">
                                        <div class="m-t-5 m-r-5 grid grid-cols-1 gap-y-6 gap-x-4 grid-cols-6">
                                            <div class="col-span-12">
                                                {this.state.message !== "" ? (
                                                    <ValidationMessageComponent message={this.state.message}>

                                                    </ValidationMessageComponent>
                                                    // <p>trrt</p>

                                                ) : ""
                                                }
                                                <label
                                                    for="mda"
                                                    class="block  text-sm font-medium label-color"
                                                >
                                                    MDA
                        </label>
                                                <div class="mt-1">
                                                    <select
                                                        name="mdaId"
                                                        id="mdaId"
                                                        onChange={this.handleChange}
                                                        className='border m-r-5' style={{lineHeight:"200px", fontSize:'15px', padding:'5px'}}
                                                        // class="shadow-sm focus:outline-none block w-full text-sm"
                                                    >
                                                        {allmdas}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-span-12">
                                                <label
                                                    for="staff"
                                                    class="block  text-sm font-medium label-color"
                                                >
                                                    Staff
                        </label>
                                                <div class="mt-1">
                                                    <Employees></Employees>
                                                </div>
                                            </div>

                                            <div class="pt-5 col-span-12">
                                                <div class="flex">
                                                    <button
                                                        type="submit"
                                                        class="mr-5 w-40 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                                                        onClick={this.handleSubmit}
                                                    >
                                                        {this.state.loading && (
                                                            <svg
                                                                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <circle
                                                                    class="opacity-25"
                                                                    cx="12"
                                                                    cy="12"
                                                                    r="10"
                                                                    stroke="currentColor"
                                                                    stroke-width="4"
                                                                ></circle>
                                                                <path
                                                                    class="opacity-75"
                                                                    fill="currentColor"
                                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                ></path>
                                                            </svg>
                                                        )}
                            Save Record
                          </button>
                                                    <button
                                                        type="button"
                                                        class="w-40 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-color bg-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    >
                                                        Cancel
                          </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-100 p-10" style={{ marginTop: -9 }}>
                                        <div class=" w-100 flex flex-col">
                                            <div class=" w-100 my-2 overflow-y-scroll -mx-6 lg:-mx-8">
                                                <div class="w-100 align-middle inline-block min-w-full px-6 lg:px-8">
                                                    <table class=" w-100 min-w-full">
                                                        <thead class="bg-table-header">
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                                                                >
                                                                    Staff Name
                                </th>
                                                                <th
                                                                    scope="col"
                                                                    class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                                                                >
                                                                    MDA
                                </th>
                                                                <th
                                                                    scope="col"
                                                                    class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                                                                >
                                                                    Module
                                </th>
                                                                <th
                                                                    scope="col"
                                                                    class="px-6 py-3 text-left  text-sm font-avenir-medium text-white tracking-wider"
                                                                >
                                                                    Action
                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="bg-white">{userprevileges}</tbody>
                                                    </table>
                                                    {true && (
                                                        <p className=" text-sm font-normal text-color py-4">
                                                            {/*Showing 1 to 2 of 2 entries*/}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                                <div
                                    className="bg-white pointer py-4 px-4 shadow-sm overflow-hidden cursor-pointer mb-3"
                                    onClick={() => this.setState({ open: !this.state.open })}
                                >
                                    <p className=" text-sm font-medium text-color">
                                        User Settings (Privileges & Access)
                </p>
                                </div>
                            )}
                    </React.Fragment>
                ) : (
                        <React.Fragment></React.Fragment>
                    )}
            </React.Fragment>
        );
    }
}
export default  PrivilegesAccess;
