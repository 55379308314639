import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import '../../assets/css/Dashboard.css';
import { IoMdClose } from 'react-icons/io';
import { AiOutlineRedEnvelope } from 'react-icons/ai';
import { BiCalendar } from 'react-icons/bi';
import { HiOutlineUserGroup } from 'react-icons/hi';
import Calendar from 'react-calendar';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import EventNoteIcon from '@material-ui/icons/EventNote';
import SearchInput from '../inputs/SearchInput';
import RequestDetails from '../layouts/RequestDetails';
import { get } from '../../api-services/fetch';
import IsLoading from '../common/IsLoading';
import { formatSimpleDate, formatDate, formatDateforPicker } from '../../utility/dateTime';
import Greeting from '../common/Greeting';
import LocationDetails from '../layouts/LocationDetails';
import { formatStatus } from '../../utility/general';
import notification from '../../utility/notification';
import '../../assets/css/calendar.css';
import useClickOutsideBox from '../hooks/useClickOutsideBox';
import SelectInputColored from '../inputs/SelectInputColored';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import { decodeToken } from '../../utility/auth';
import Page6Cards from '../layouts/Page6Cards'
import DashboardC from '../layouts/DashboardC'
import Cookies from 'js-cookie'

const Dashboard = ({ props, history }) => {
  // const BASE_API_URL = 'http://localhost:5000/api/v1';

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';


  const fullName = ''
  const role =''
  const time = ''
  
const [budgetInformationData, setbudgetInformationData] = useState([])
const [budgetInformationDataLength, setbudgetInformationDataLength] = useState([])
// const [budgetInformationDataLength, setbudgetInformationDataLength] = useState([])
const [mdas, setmdas] = useState([])
const [mdaNames, setmdaNames] = useState([])
const [approvalProcessId, setapprovalProcessId] = useState([])
const [approvalProcess, setapprovalProcess] = useState([])
const [departmentBudget, setdepartmentBudget] = useState([])
const [reviewData, setreviewData] = useState([])
const [departmentBudgetApprovalProcess, setdepartmentBudgetApprovalProcess] = useState([])

const [commentData, setcommentData] = useState([])
const [employeeData, setemployeeData] = useState([])
const [userolesData, setuserolesData] = useState([])
const [staffId, setstaffId] = useState([])
const [message, setmessage] = useState([])
const [loader, setloader] = useState([])
const [employeeId, setemployeeId] = useState([])
const [mdaNo, setmdaNo] = useState([])
const [submittedBudget, setsubmittedBudget] = useState([])
const [reviewedBudget, setreviewedBudget] = useState([])
const [returnedBudget, setreturnedBudget] = useState([])
const [budgetUnderReview, setbudgetUnderReview] = useState([])
const [budgetPeriod, setbudgetPeriod] = useState([])
const [fullname, setfullName] = useState([])

const [dashboardNew, setDashboardNew] = useState([])

// const [role, setrole] = useState([])
// const [time, settime] = useState([])

// setfullName(authUser.firstName + " " + authUser.lastName)
// setrole(authUser.role)
// settime(time)
 
    // fullName: "",
    // role: "",
    // time: "",
    // departmentBudgetApprovalProcess: [],
    // budgetInformationData: [],
    // budgetInformationDataLength: 0,
    // mdas: [],
    // mdaNames: [],
    // approvalProcessId: [],
    // approvalProcess: [],
    // departmentBudget: [],
    // reviewData: [],
    // commentData: [],
    // employeeData: [],
    // userolesData: [],
    // staffId: "",
    // message: "",
    // loader: "",
    // employeeId: 0,
    // mdaNo: 0,
    // submittedBudget: 0,
    // reviewedBudget: 0,
    // returnedBudget: 0,
    // budgetUnderReview: 0,
    // budgetPeriod:'',
  
    useEffect(()=>{
      getAuthorisedUser();
      getDashboardStarts();
      getDepartmentBudgetApprovalProcess();
      getBudgetInformation();
      getComment();
      getEmployees();
      getUserRoles();
      getReviews();
      getCurrentYear();
     
    },[])

    const handleGetReviewsByMda = (mdaId) => {
      let reviewDatas = reviewData;
  
      let reviewInformations = reviewDatas.filter((reviewData) => {
        return reviewData.mda.id == mdaId;
      });
      return reviewInformations;
    };

    const getPendingReview = (reviewInformations) => {
      let getPending = reviewInformations.filter((reviewInformation) => {
        return reviewInformation.approvalProcessInformations.status == 0;
      });
      return getPending;
    };

    const getCurrentYear=()=>{
      axios.get(`${BASE_API_URL}/budgetPeriod/GetCurrentPeriod`)
          .then((response)=>{
            console.log(response)
            setbudgetPeriod(response.data.length==0?"":response.data.year)
              // setState({
              //   budgetPeriod:response.data.length==0?"":response.data.year
              // })
          }).catch((err)=>{
            console.log(err)
         })

}

const getDepartmentBudgetApprovalProcess = () => {
  // this.setState({
  //   loader: true,
  //   message: "",
  // });
  axios
    .get(`${BASE_API_URL}/DepartmentBudgetApprovalProcess`)
    .then((response) => {
      console.log(response.data)
      if (response.data.length !== 0) {
        let mdaNames = response?.data?.map((a) => a.departmentBudget.mdas.name);
        let approvalProcessId = response?.data?.map((a) => a.approvalProcessId);

        // this.setState({
          setdepartmentBudgetApprovalProcess(response.data)
          setmdas(response?.data?.map((a) => a.departmentBudget.mdas))
          setapprovalProcess(response?.data?.map((a) => a.approvalProcess) )

          setmdaNames([...mdaNames])
          setapprovalProcessId([...approvalProcessId])
          setdepartmentBudget(response?.data?.map((a) => a.departmentBudget))
          setloader(false)
          setmessage("")
        // })
      }
    })
    .catch((err) => {
      // this.setState({
      //   loader: false,
      //   message: err.message,
      // });
    });
};
  
const getBudgetInformation = () => {
  // this.setState({
  //   loader: true,
  //   message: "",
  // });
  axios
    .get(`${BASE_API_URL}/budgetInformation/getBudgetInformation`)
    .then((response) => {
      console.log(response.data)
      // this.setState({
        setbudgetInformationData( response.data)
        // loader: false,
        // message: "",
      // });
    })
    .catch((err) => {
      // this.setState({
      //   loader: false,
      //   message: err.message,
      // });
    });
};

const getAuthorisedUser = () => {
  // const { cookies } = this.props;
console.log(Cookies.get("userId"))


  if (typeof Cookies.get("userId") !== "undefined") {
    let authUser = Cookies.get("userId");
    // let time = authUser.lastLoginTime.split("T")[1];
    // this.setState({
      setfullName(authUser.firstName + " " + authUser.lastName)
      // role = authUser.role
      // time=time
      setemployeeId(authUser.id) 
      setstaffId(authUser.staffID) 
    // });
  }
};

const getApprovalProcess = (approvalProcessId) => {
  let approvalProcesses = approvalProcess;
  let approvalProcessz = approvalProcesses.filter((approvalProcess) => {
    return approvalProcess.id == approvalProcessId;
  });
  return approvalProcessz.length == 0 ? "" : approvalProcess[0];
};

const getMdaTotalBudget = (approvalProcessId) => {
  let departmentBudgetApprovalProcesses = departmentBudgetApprovalProcess;
  let budgetInformations = budgetInformationData;
  let total = 0;

  let newDepartmentBudgetApprovalProcesses = departmentBudgetApprovalProcesses.filter(
    (departmentBudgetApprovalProcess) => {
      return (
        departmentBudgetApprovalProcess.approvalProcessId == approvalProcessId
      );
    }
  );
  for (const item in newDepartmentBudgetApprovalProcesses) {
    let budInfo = budgetInformationData.filter(
      (budgetInformation) => {
        return (
          budgetInformation.departmentBudgetId ==
          newDepartmentBudgetApprovalProcesses[item].departmentBudgetId
        );
      }
    );

    if (budInfo.length !== 0) total += budInfo[0].total;
    else total += 0;
  }
  return total;
};

const formatAmount = (amount) => {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: "NGN",
  });
};

const getReviews = () => {
  // this.setState({
  //   loader: true,
  //   message: "",
  // });
  axios
    .get(
      `${BASE_API_URL}/approvalProcessInformation/GetApprovalProcessInformation`
    )
    .then((response) => {
      // this.setState({
        setloader(false)
        setmessage("")
        setreviewData(response.data,)
      // });
    })
    .catch((err) => {
      // this.setState({
      //   loader: false,
      //   message: err.message,
      // });
    });

    axios.get(
      `${BASE_API_URL}/BudgetPlanning/GetApprovalProcessByMdaId?mdaId=${11}`
    )
    .then((response) => {
      console.log(response)
      console.log(response.data)
      // this.setState({
        setloader(false)
        setmessage("")
        setDashboardNew(response.data)
        // setreviewData(response.data,)
      // });
    })
    .catch((err) => {
      // this.setState({
      //   loader: false,
      //   message: err.message,
      // });
    });

    
};

const getComment = () => {
  // this.setState({
  //   loader: true,
  //   message: "",
  // });
  axios
    .get(`${BASE_API_URL}/comment`)
    .then((response) => {
      // this.setState({
        setloader(false)
        setmessage("")
        setcommentData(response.data)
      // });
    })
    .catch((err) => {
      // this.setState({
      //   loader: false,
      //   message: err.message,
      // });
    });
};

const getEmployees = () => {
  // this.setState({
  //   loader: true,
  //   message: "",
  // });
  axios
    .get(`${BASE_API_URL}/employee`)
    .then((response) => {

      setloader(false)
        setmessage("")
        setemployeeData(response.data)
      // this.setState({
        // loader: false,
        // message: "",
        // employeeData: response.data,
      // });
    })
    .catch((err) => {
      // this.setState({
      //   loader: false,
      //   message: err.message,
      // });
    });
};
const getUserRoles = () => {

 

  // this.setState({
  //   loader: true,
  //   message: "",
  // });
  axios
    .get(`${BASE_API_URL}/role/userroles`)
    .then((response) => {

      setloader(false)
      setmessage("")
      setuserolesData(response.data)

      // this.setState({
      //   loader: false,
      //   message: "",
      //   userolesData: response.data,
      // });
    })
    .catch((err) => {
      // this.setState({
      //   loader: false,
      //   message: err.message,
      // });
    });
};

const functionGetTimeOfDay = () => {
    var myDate = new Date();
    var hrs = myDate.getHours();

    var greet;

    if (hrs < 12) greet = "Good Morning";
    else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
    else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";

    return greet;
  };

  const getDashboardStarts= ()=> {
    axios
      .get(`${BASE_API_URL}/home/dashboard`)
      .then((response) => {
        let data = response.data;

        // this.setState({
          setmdaNo(data.mdaTotal)
          setsubmittedBudget(data.submittedBudget)
          setreviewedBudget(data.reviewedBudget)
          setreturnedBudget(data.returnedBudget)
          setbudgetUnderReview(data.budgetUnderReview)
        })
      // })
      .catch((err) => {
        console.log(err);
      });
  }


  return (
    <div className="w-100">
    
        {/* <div className="overlay">
          <div className="calendar" ref={wrapperRef}>
            <Calendar
              onChange={handleCalendarChange}
              value={calendar}
              className="calendar"
              selectRange
            />
          </div>
        </div> */}
   
      <div className="w-100 flex space-between flex-v-baseline">
        <Greeting />
        <LocationDetails />
      </div>

      {/* <div className='flex-between'>
        <Page6Cards
        cardTitle = "Number of MDA's"
        count = {mdaNo}
        />
        <Page6Cards cardTitle = "Submitted Budget" count = {submittedBudget}/>
        <Page6Cards cardTitle ="Reviewed Budget" count = {reviewedBudget}/>
        <Page6Cards cardTitle ="Returned Budget" count = {returnedBudget}/>
        <Page6Cards cardTitle ="Budget Under Review" count = {budgetUnderReview}/>
      </div> */}

      <div className="p-10 bold m-t-10" style={{backgroundColor:'#d5ffd5'}}>
        <p className='no-margin'>Transaction History</p>
      </div>

      <div className="table-view m-t-10">
        {/* <p>dave</p>
        <p>dave</p>
        <p>ave</p> */}
{/* <thead className="table-header">
  <tr className="table-row">
    <th className="th"><p>Document Title</p></th>
    <th className="th"><p>Upload Date</p></th>
    <th className="th"><p>Vendor/Source</p></th>
    <th className="th"><p>Status</p></th>
    <th className="th"><p>Last Treated</p></th>
    <th className="th"><p>Actions</p></th>
  </tr>
</thead> */}
{/* <div className="table-body"> */}
  {/* {!(isLoading || errorMsg) && details && details.map((item) => ( */}
    {/* <ListItem 
     title={title}
     currently={true}
     staffName="Anthony Okungbowa"
     staffTitle="HOS"
     approvalProcessInformation={
       approvalProcessInformation
     }
     budgetYear={this.budgetPeriod}
     budgetTotal={this.formatAmount(total)}
     reviewInformation={reviewInformation}
     commentData={this.state.commentData}
     employeeData={this.state.employeeData}
     userolesData={this.state.userolesData}
     pendingReview={pendingReview}
     employeeId={this.state.employeeId}
     date="08.08.2020"
     {...this.props}
     
      /> */}
  
{/* </div> */}


<div className="mt-5" >
                    {dashboardNew?.map((e, index) => {
                      let reviewInformation = handleGetReviewsByMda(
                        mdas[index]?.id
                      );
                      let approvalProcessIds = approvalProcessId[
                        index
                      ];
                      let total = getMdaTotalBudget(approvalProcessId);
                      let approvalProcessInformation = getApprovalProcess(
                        approvalProcessIds
                      );
                      let pendingReview = getPendingReview(
                        reviewInformation
                      );

                      // let title = mdaName+ " "+budgetPeriod+ " Financial Year Budget";
                      return (
                        <DashboardC
                        dashboardNew= {e}
                          // title={title}
                          currently={true}
                          staffName="Anthony Okungbowa"
                          staffTitle="HOS"
                          approvalProcessInformation={
                            approvalProcessInformation
                          }
                          budgetYear={budgetPeriod}
                          budgetTotal={formatAmount(total)}
                          reviewInformation={reviewInformation}
                          commentData={commentData}
                          employeeData={employeeData}
                          userolesData={userolesData}
                          pendingReview={pendingReview}
                          employeeId={employeeId}
                          date="08.08.2020"
                          {...props}
                        />
                      );
                    })}
                  </div>





</div>
      </div>
    

      
  );
};

export default Dashboard;



const ListItem = ({ details }) => {
  const [itemDetails, setItemDetails] = useState(details || {});
  const [showDetails, setShowDetails] = useState(false);

  const updateInfo = (newItem) => {
    setItemDetails(newItem || {});
  };

  return (
    <>
      <tr
        className={`${showDetails && 'active'} table-row pointer`}
        onClick={() => setShowDetails(!showDetails)}
        role="presentation"
      >
        <td className="td p-r" style={{ padding: '20px 10px' }}>
          {itemDetails.title}
          {showDetails && (
            <IoMdClose
              className="dropdown-close-btn pointer"
              onClick={() => setShowDetails(!showDetails)}
            />
          )}
        </td>
        {/* <td className="td" style={{ padding: '20px 10px' }}>{formatDate(itemDetails.dateReceived)}</td> */}
        {/* <td className="td" style={{ padding: '20px 10px' }}>{itemDetails.vendor}</td> */}
        <td className="td" style={{ padding: '20px 10px' }}>
          <div className={`status ${itemDetails.status}-status`}>
            {formatStatus(itemDetails.status)}
          </div>
        </td>
        <td className="td" style={{ padding: '20px 10px' }}>{formatSimpleDate(itemDetails.updatedAt || itemDetails.createdAt)}</td>
        <td style={{ display: 'flex', right: '0' }}>
          <span style={{
            border: '1px solid white', height: '30px', width: '30px', marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#ff8566'
          }}
          >
            <DeleteIcon style={{ color: 'green' }} />
          </span>
          <span style={{
            border: '1px solid white', height: '30px', width: '30px', marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#66ff99'
          }}
          >
            {' '}
            <CreateIcon style={{ color: 'green' }} />
          </span>
        </td>
      </tr>
      {showDetails && (
        <tr className="table-row bg-light-gray">
          <td colSpan={5}>
            <RequestDetails requestId={itemDetails.id} updateList={updateInfo} />
          </td>
        </tr>
      )}
    </>
  );
};
