import React from "react";

export function Preloader(props) {
  return (

    <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
      <span
        className="text-green-500 opacity-75 my-0 mx-auto block relative w-0 h-0"
        style={{ top: "40%" }}
      >
        <i class="fas fa-circle-notch fa-spin fa-5x"></i>
      </span>
    </div>
  );
}
