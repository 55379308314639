import React, { Component } from "react";

import { store } from 'react-notifications-component'
import { nullcheck, notify } from '../../Utils/Helper'
import { Preloader } from "../../Utils/Preloader";
// import ValidationMessageComponent from "./ValidationMessage";

import notification from "../../utility/notification";
 
import axios from "axios";

import Select from "react-select";
// const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';
const BASE_API_URL = 'http://localhost:5000/api/v1';


// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

function formatDefaultDate(dateString) {
    var newDate = new Date(dateString);
    newDate.setDate(newDate.getDate() + 1);

    return newDate.toISOString().split("T")[0];
}

export default class GeoCodeSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            geoCodeData: "",
            localGovernmentCouncilData: [],
            allGeoCodeData: [],
            localGovernmentCouncil: "",
            wardData: [],
            geoCodeFile: null,
            message:"",

            wardCouncil: "",
            geoCode: "512",
            geoCodeChanged: false,
            wardChanged: false,
            wardCode: "",
            lgaCode: "",
            loading: false,
            loader:false,
            deleteId: "",
            deleteModal: false,
            edit: false,
            editId: "",
            selectedLGA: null,
            selectedWard: null,
            search:"",
        };
    }

    componentDidMount() {
        this.getGeoCode();
        this.getLga();
        this.getWard();
    }

    handleExportCsv = (table_id, separator = ",") => {
        // Select rows from table_id
        var rows = document.querySelectorAll("table#" + table_id + " tr");
        // Construct csv
        var csv = [];
        for (var i = 0; i < rows.length; i++) {
            var row = [],
                cols = rows[i].querySelectorAll("td, th");
            for (var j = 0; j < cols.length; j++) {
                // Clean innertext to remove multiple spaces and jumpline (break csv)
                var data = cols[j].innerText
                    .replace(/(\r\n|\n|\r)/gm, "")
                    .replace(/(\s\s)/gm, " ");
                // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
                data = data.replace(/"/g, '""');
                // Push escaped string
                row.push('"' + data + '"');
            }
            csv.push(row.join(separator));
        }
        var csv_string = csv.join("\n");
        // Download it
        var filename =
            "export_" + table_id + "_" + new Date().toLocaleDateString() + ".csv";
        var link = document.createElement("a");
        link.style.display = "none";
        link.setAttribute("target", "_blank");
        link.setAttribute(
            "href",
            "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
        );
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    handleChange = (ev) => {
        this.setState({
            [ev.target.name]: ev.target.value,
        });
    };
    handleSelectedLGAChange = (selectedLGA) => {
        this.setState(
            { selectedLGA, localGovernmentCouncil: selectedLGA, lgaCode: "" },
            () => console.log(`Option selected:`, selectedLGA)
        );
        console.log(this.state.geoCode);
        this.getLgaById(selectedLGA.value);
    };
    handleSelectedWardChange = (selectedWard) => {
        this.setState(
            { selectedWard, wardCouncil: selectedWard, wardCode: "" },
            () => console.log(`Option selected:`, this.state.selectedLGA)
        );

        this.getWardById(selectedWard.value);
    };
    handleFile = (e) => {
        console.log(e.target.files[0]);
        this.setState({ geoCodeFile: e.target.files[0] });
    };
    checkUndefined = (val) => {
        if (typeof val == "undefined")
            return "00000000-0000-0000-0000-000000000000";
        else return val;
    };
    handleSubmit = () => {
        let lgaId = this.checkUndefined(this.state.localGovernmentCouncil.value);
        let wardId = this.checkUndefined(this.state.wardCouncil.value);
        let geoCodeName = this.state.geoCode;
        let geoCodeFile = this.state.geoCodeFile;
        let enteredBy = this.props.employeeId.toString()
    
        var formData = new FormData();
        formData.append("lgaId", lgaId);
        formData.append("wardId", wardId);
        formData.append("geoCodeName", geoCodeName);
      
        let postData = {
            lgaId : lgaId,
            code: geoCodeName,
            wardId: wardId
        }

        let data = { lgaId, wardId, geoCodeName, geoCodeFile, enteredBy };
        this.setState({
            loader: true,
            message: "",
        });
        axios
            .post(`${BASE_API_URL}/geocode`, formData)
            .then((response) => {
             
                this.setState({
                    geoCode: "512",
                    wardCouncil: null,
                    localGovernmentCouncil: null,
                    loader: false,
                    message: "",
                });
                // notify(store, 'success', 'geocodes was added successfully');
                notification({
                    title: 'Successful',
                    message: 'geocodes was added successfully',
                    type: 'success'
              });
                this.getGeoCode();
                
            })
            .catch((err) => {
                this.setState({
                    loader: false,
                    message: nullcheck(err.response) == "" ? "server error" : err.response.data,
                });
            });


    };

    handleDelete = () => {
        let id = this.state.deleteId;
        this.setState({
            deleteModal: false,
            deleteId:'',
            loader: true,
            message: "",
        });
        axios
            .delete(`${BASE_API_URL}/geocode?id=${id}`)
            .then((response) => {
          
                // notify(store, 'success', 'geocode was deleted added successfully');
                notification({
                    title: 'Successful',
                    message: 'geocode was deleted added successfully',
                    type: 'success'
              });
                this.setState({
                    deleteModal: false,
                    deleteId: '',
                    loader: false,
                    message: "",
                });
                this.getGeoCode();
               
            })
            .catch((err) => {
                this.setState({
                    deleteModal: false,
                    deleteId: '',
                    loader: false,
                   
                    message: nullcheck(err.response) == "" ? "server error" : err.response.data,
                })
               
            });
    };

    handleEdit = (data) => {
        var editLga = { value: data.lgaId, label: data.lga.lgaName };
        var editWard = { value: data.wardId, label: data.ward.wardName };
        this.setState({
            localGovernmentCouncil: editLga,
            wardCouncil: editWard,
            geoCode: data.geoCodeName,
            edit: true,
            editId: data.id,
        });
    };

    handleEditSubmit = () => {
        this.setState({
            loader: true,
            message: "",
        });
        let lgaId = this.state.localGovernmentCouncil.value;
        let wardId = this.state.wardCouncil.value;
        let geoCodeName = this.state.geoCode;
        let id = this.state.editId;
        let updatedBy = this.props.employeeId.toString()

        let data = { lgaId, wardId, geoCodeName, id, updatedBy };
        axios
            .put(`${BASE_API_URL}/geocode`, { ...data })
            .then((response) => {

                this.setState({
                    geoCode: "512",
                    wardCouncil: null,
                    localGovernmentCouncil: null,
                    loader: false,
                    message: "",
                    editId: "",
                    edit:false
                });
                // notify(store, 'success', 'geocode was updated successfully');
                notification({
                    title: 'Successful',
                    message: 'geocode was updated successfully',
                    type: 'success'
              });
                this.getGeoCode();
            })
            .catch((err) => {
                this.setState({
                    loader: false,
                    message: err.message
                });
            });

        // this.setState({ loading: true }, () =>
        //   this.props.updateAction(DataTypes.PUT_BUDGET_PERIOD, { data })
        // );
    };
    getLga = () => {
        axios.get(`${BASE_API_URL}/lga/getlga`).then((response) => {
            console.log(response.data);
            this.setState({
                localGovernmentCouncilData: response.data,
            });
        }).catch((err) => {
            this.setState({
                message: nullcheck(err.response) == "" ? "server error" : err.response.data,
            });
            
        });
    };
    getWard = () => {
        axios.get(`${BASE_API_URL}/ward/getward`).then((response) => {
            this.setState({
                wardData: response.data,
            });
        }).catch((err) => {
            this.setState({
                message: nullcheck(err.response) == "" ? "server error" : err.response.data,
            });

        });
    };
    getGeoCode = () => {
        this.setState({
            loader: true,
            message: "",
        });
        axios
            .get(`${BASE_API_URL}/geocode`)
            .then((response) => {
                console.log(response.data)
                this.setState({
                    allGeoCodeData: response.data,
                    loader: false,
                    message: ""
                });
            })
            .catch((err) => {
                this.setState({

                    loader: false,
                    message: nullcheck(err.response) == "" ? "server error" : err.response.data,
                });
            });
    };
    getWardById = (id) => {
        this.setState({
            loader: true,
            message: "",
        });
        axios.get(`${BASE_API_URL}/ward/getwardbyid?id=${id}`).then((response) => {
            let newWardCode = `${response.data[0].wardCode}`;
            let prevGeoCode = this.state.geoCode;
            let newGeoCode = "";
            if (prevGeoCode.length === 3) {
                newGeoCode = prevGeoCode + "" + newWardCode;
            } else if (prevGeoCode.length === 5) {
                newGeoCode = prevGeoCode.substring(0, 3) + "" + newWardCode;
            } else if (prevGeoCode.length === 8) {
                newGeoCode = prevGeoCode.substring(0, 6) + "" + newWardCode;
            } else if (prevGeoCode.length === 6) {
                newGeoCode = prevGeoCode + "" + newWardCode;
            }

            this.setState({
                geoCode: newGeoCode,
                wardChanged: true,
                loader: false,
                message:""
            });
        }).catch((err) => {
            this.setState({
               
                loader: false,
                message: nullcheck(err.response) == "" ? "server error" : err.response.data,
            });

        });
    };
    getLgaById = (id) => {
        // let checkWardChanged = this.state.wardChanged === true ? this.state.geoCode : "";
        axios.get(`${BASE_API_URL}/lga/getlgabyid?id=${id}`).then((response) => {
            var letGeoCode = this.state.geoCode;
            let newLgaCode = `${response.data[0].senatorialZone.senatorialCode}${response.data[0].lgaCode}`;
            var newGeoCode = "";

            if (letGeoCode.length === 3) {
                newGeoCode = letGeoCode + "" + newLgaCode;
            } else if (letGeoCode.length === 6) {
                newGeoCode = letGeoCode.substring(0, 3) + "" + newLgaCode;
            } else if (letGeoCode.length === 5) {
                newGeoCode =
                    letGeoCode.substring(0, 3) + "" + newLgaCode + letGeoCode.slice(-2);
            } else if (letGeoCode.length === 8) {
                newGeoCode =
                    letGeoCode.substring(0, 3) + "" + newLgaCode + letGeoCode.slice(-2);
            }

            let prevGeoCode = this.state.geoCode;
            this.setState({
                geoCode: newGeoCode,
                geoCodeChanged: true,
            });
        }).catch((err) => {
            this.setState({
                message: nullcheck(err.response) == "" ? "server error" : err.response.data,
            });
           
        });
    };

    render() {

        
        let searchGeoCode = this.state.allGeoCodeData.filter((geoCode) => {

            return (geoCode.geoCodeName.toLowerCase()).indexOf(this.state.search.toLowerCase()) !== -1;
        })

        //search
        let allGeoCode = searchGeoCode.map((geoCode) => {
            return (
                <tr
                    style={{
                        borderBottom: "1px solid #C7C7C7",
                    }}
                >
                    <td className="px-6 py-4 whitespace-nowrap">
                        <div className="bg-pen" onClick={() => this.handleEdit(geoCode)}>
                            <svg
                                width="18"
                                height="18"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="#fff"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                />
                            </svg>
                        </div>
                    </td>
                    <td className="px-6 py-4  text-sm font-avenir-medium table-color">
                        {geoCode.lga.lgaName}
                    </td>
                    <td className="  text-sm font-avenir-medium table-color">
                        {geoCode.ward.wardName}
                    </td>
                    <td className="px-6 py-4  text-sm font-avenir-medium table-color">
                        {geoCode.geoCodeName}
                    </td>

                    {/* <td className="px-6 py-4  text-sm font-avenir-medium table-color">
            {}
          </td> */}
                    <td className="px-6 py-4  text-sm font-avenir-medium table-color">
                        {geoCode.budgetPeriod.year}
                    </td>
                    <td className="px-6 py-4  text-sm font-avenir-medium table-color">
                        <a

                            className="cursor-pointer"
                            onClick={() =>
                                this.setState({
                                    deleteModal: !this.state.deleteModal,
                                    deleteId: geoCode.id

                                })
                            }
                        >

                            <img
                                src="/delete-icon.png"
                                className="ml-5 h-5 w-5"
                            />
                        </a>
                    </td>
                </tr>
            );
        });

        return (
            <React.Fragment>
                {this.state.loader === true ? <Preloader /> : ""}
                {this.state.deleteModal && (
                    <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-3/12 my-6 mx-auto bg-white p-4">
                                {/*content*/}
                                <div className=" py-4 px-4 shadow-sm overflow-hidden cursor-pointer">
                                    <p className="flex-1 mt-2 text-sm font-medium font-avenir-black text-color text-center">
                                        Are you sure you want to
                                        delete?
                                                    </p>
                                </div>
                                <div class="flex justify-center">
                                    <button onClick={() => this.handleDelete()}
                                        type="submit"
                                        class="mr-5 w-40 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"

                                    >

                                        Yes
                                                    </button>
                                    <button
                                        type="button"
                                        class="w-40 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-color bg-gray-200 hover:bg-gray-50 focus:outline-none"
                                        onClick={() =>
                                            this.setState({
                                                deleteModal: !this.state.deleteModal,
                                            })
                                        }
                                    >
                                        No
                                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                )}


                {this.state.open ? (
                    <div className="mb-3 w-100" >
                        <div
                            className="bg-card-header w-100 py-4 px-4 shadow-sm overflow-hidden bb-green cursor-pointer"
                            onClick={() => this.setState({ open: !this.state.open })}
                        >
                            <div class="flex-between w-100">

                                <div class="">
                                    <p className=" text-sm font-medium font-avenir-black text-color">
                                        Geo Code Setup
              </p>
                                </div>

                                <input
                                    placeholder="Search geo code"
                                    className="border border-solid px-2 h-8 w-50 mt-2"
                                    style={{
                                        backgroundColor: "transparent",
                                        borderColor: "#707070",
                                    }}
                                    name='search'
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={this.handleChange}
                                />
                            </div>
                 
                        </div>
                        <div className="w-100  p-10 flex bg-white py-4 pl-4 shadow-sm cursor-pointer">
                            <div style={{border:""}} className=" w-4  w-80 m-r-10">
                                <div class="mt-3grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div class="sm:col-span-12">
                                        {this.state.message !== "" ? (
                                            <p></p>
                                            // <ValidationMessageComponent
                                            //     message={this.state.message}
                                            // ></ValidationMessageComponent>
                                        ) : (
                                                ""
                                            )}
                                        <label
                                            for="localGovernmentCouncil"
                                            class="block  text-sm font-medium label-color"
                                        >
                                            Local Government Council
                    </label>
                                        <div class="mt-1">
                                            <Select
                                                name="localGovernmentCouncil"
                                                id="localGovernmentCouncil"
                                                value={this.state.localGovernmentCouncil}
                                                onChange={this.handleSelectedLGAChange}
                                                options={this.state.localGovernmentCouncilData}
                                            />
                                            {/* <input
                        type="text"
                        name="localGovernmentCouncil"
                        id="localGovernmentCouncil"
                        value={this.state.localGovernmentCouncil}
                        class="shadow-sm focus:outline-none block w-full sm:text-sm"
                        onChange={this.handleChange}
                      /> */}
                                        </div>
                                    </div>
                                    <div class="sm:col-span-12">
                                        <label
                                            for="wardCouncil"
                                            class="block  text-sm font-medium label-color"
                                        >
                                            Ward Council
                    </label>
                                        <div class="mt-1">
                                            <Select
                                                name="wardCouncil"
                                                id="wardCouncil"
                                                value={this.state.wardCouncil}
                                                onChange={this.handleSelectedWardChange}
                                                options={this.state.wardData}
                                            />
                                            {/* <input
                        type="date"
                        name="wardCouncil"
                        id="wardCouncil"
                        value={this.state.wardCouncil}
                        class="shadow-sm focus:outline-none block w-full sm:text-sm"
                        onChange={this.handleChange}
                      /> */}
                                        </div>
                                    </div>
                                    <div class="sm:col-span-12">
                                        <label
                                            for="geoCode"
                                            class="block  text-sm font-medium label-color"
                                        >
                                            Geo Code
                    </label>
                                        <div class="mt-1">
                                            <input 
                                            // readOnly={true}
                                                type="text"
                                                name="geoCode"
                                                id="geoCode"
                                                value={this.state.geoCode}
                                                class="shadow-sm focus:outline-none block w-full sm:text-sm"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div class="sm:col-span-12">
                                        <label
                                            for="geoCode"
                                            class="block  text-sm font-medium label-color"
                                        >
                                            Upload File
                    </label>
                                        <div class="mt-1">
                                            <input
                                                type="file"
                                                name="geoCodeFile"
                                                id="geoCodeFile"
                                                class="shadow-sm focus:outline-none block w-full sm:text-sm"
                                                onChange={this.handleFile}
                                            />
                                        </div>
                                    </div>
                                    <div class="pt-5 sm:col-span-12">
                                        <div class="flex">
                                            <button
                                                type="submit"
                                                class="mr-5 w-40 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                                                onClick={
                                                    this.state.edit
                                                        ? this.handleEditSubmit
                                                        : this.handleSubmit
                                                }
                                            >
                                                {this.state.loading && (
                                                    <svg
                                                        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            class="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            stroke-width="4"
                                                        ></circle>
                                                        <path
                                                            class="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                        ></path>
                                                    </svg>
                                                )}
                        Save Record
                      </button>
                                            <button onClick={() => this.handleExportCsv("table_info") }
                                                type="button"
                                                class="w-40 bg-red-600 py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-white bg-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Download Format
                      </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div  className="w-100 md:flex-grow" style={{ marginTop: 0,  }}>
                                <div class="w-100flex flex-col">
                                    <div class="w-100 -my-2 overflow-y-scroll">
                                        <table class="w-100 min-w-full">
                                            <thead class="bg-table-header">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        class=" text-left  text-sm font-avenir-medium  text-white tracking-wider"
                                                    >
                                                        <svg
                                                            version="1.0"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 1278.000000 1280.000000"
                                                            preserveAspectRatio="xMidYMid meet"
                                                        >
                                                            
                                                        </svg>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="px-6 py-3 text-left  text-sm font-avenir-medium  text-white tracking-wider"
                                                    >
                                                        Local Government
                            </th>
                                                    <th
                                                        scope="col"
                                                        class="px-6 py-3 text-left  text-sm font-avenir-medium  text-white tracking-wider"
                                                    >
                                                        Ward
                            </th>
                                                    <th
                                                        scope="col"
                                                        class="px-6 py-3 text-left  text-sm font-avenir-medium  text-white tracking-wider"
                                                    >
                                                        Code
                            </th>
                                                    {/* <th
                              scope="col"
                              class="px-6 py-3 text-left  text-sm font-avenir-medium  text-white tracking-wider"
                            >
                              Updated By
                            </th> */}
                                                    <th
                                                        scope="col"
                                                        class="px-6 py-3 text-left  text-sm font-avenir-medium  text-white tracking-wider"
                                                    >
                                                        Date
                            </th>
                                                    <th
                                                        scope="col"
                                                        class="px-6 py-3 text-left  text-sm font-avenir-medium  text-white tracking-wider"
                                                    >
                                                        Action
                            </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white">{allGeoCode}</tbody>
                                        </table>
                                        {true && (
                                            <p className=" text-sm font-normal text-color py-4"></p>
                                        )}

                                        <table id="table_info" hidden={true }>
                                            <thead>
                                            <tr>
                                                <td>Code</td>
                                                <td>Description</td>
                                                </tr>
                                                </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                        <div
                            className="bg-white w-100 py-4 px-4  cursor-pointer mb-3"
                            style={{ width:"100%"}}
                            onClick={() => this.setState({ open: !this.state.open })}
                        >
                            <p className=" text-sm font-medium text-color">Geo Code Setup</p>
                        </div>
                    )}
            </React.Fragment>
        );
    }
}
// import React, { useState, useEffect,useContext } from 'react';
// import { Table,Modal, Input, InputNumber,message, Popconfirm, Form, Typography,Menu,Dropdown, Select,Button,Checkbox } from 'antd';
// import { BsFillCircleFill, BsFillTrashFill, BsCheck } from 'react-icons/bs'
// import { TiPencil } from "react-icons/ti";
// import { ModalContext } from '../Contexts/ModalContext'

// import {
//   HomeOutlined,
//   DownOutlined,
//   SettingFilled,
//   CloseOutlined,
//   SearchOutlined,
//   PlusOutlined
// } from '@ant-design/icons';
// // import ApproveModal from './ApproveModal';

// import { decodeToken } from "../../utility/auth";
// // import http from '../../httpAdmin'
// // import httpA from '../../httpAgents'
// import notification from '../../utility/notification'
// import { AiFillCaretDown } from "react-icons/ai";
// import moment from 'moment';

// import axios from "axios";

// const GeoCodeSetup = (props) => {


// const [geoCode, setGeoCode] = useState([]);
// const [ward, setward] = useState([]);
// const [council, setcouncil] = useState([]);
// const [approver, setApprover] = useState([]);
// const [approverId, setApproverId] = useState();
// const [userId, setUserId] = useState();
// const [userName, setUsername] = useState();
 
// const { Option } = Select;
// const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';
  

	
// // const { 
// // handleApproversArray,approversArray,
// // reloadDev, handleReloadDev,

// // } = useContext(SwapTableContext)
// // const { show6, handleShow6 } = useContext(ModalContext)

// useEffect(()=>{

//     const r = localStorage.getItem('r');
//     let fn = localStorage.getItem('fn');
//     let ln = localStorage.getItem('ln');
//     let ui = localStorage.getItem('ui');
  
//     // r && setRole(decodeToken('r').r);
//     if (fn && ln) {
//       fn = decodeToken('fn').fn;
//       ln = decodeToken('ln').ln;
//       ui = decodeToken('ui').ui;
//       setUsername(`${fn} ${ln}`);
//     // getPending(ui)
  
//       console.log(ui)
//       setUserId(ui)
//     }
//     // toGetEnvelope(ui)


// getGeoCode()
// getLga()
// getWard()
// },[])



// const accept = () =>{
//   // http.post('approve-agent', null, {params:{
//   //   id:id
//   // }})
//   // .then(res=>{
//   //   console.log(res)
//   //   props.callAgents()
//   //    notification({
//   //   title: "Review Success",
//   //   message: 'Agent Successfuly Approved',
//   //   type: "success",
//   // });
//   // })
//   // .catch(err=>{
//   //   console.log(err)
//   //   notification({
//   //     title: "Review Error",
//   //     message: `${err.response.data.message}`,
//   //     type: "danger",
//   //   });
//   // })
// }

// const handleDecline = () =>{
//   // http.post('reject-agent', null, {params:{
//   //   id:id
//   // }})
//   // .then(res=>{
//   //   props.callAgents()

//   //   notification({
//   //     title: "Review Success",
//   //     message: 'Agent Successfuly Rejected',
//   //     type: "success",
//   //   });
//   // })
//   // .catch(err=>{
//   //   console.log(err)
//   //   notification({
//   //     title: "Review Error",
//   //     message: `${err.response.data.message}`,
//   //     type: "danger",
//   //   });
//   // })
// }
// 	const onFinish =(values)=>{
//         console.log(values)
      
//                     let lgaId = council.vakue;
//                     let wardId = ward.value;
//             //         let geoCodeName = this.state.geoCode;
//             //         let geoCodeFile = this.state.geoCodeFile;
//                     let enteredBy = userId+''
//                 let formData = {
//                     lgaId:values.council.value,
//                     wardId:values.ward.value,
//                     enteredBy:userId+'',
//                      geoCodeName:values.;
// //         formData.append("geoCodeFile", geoCodeFile);
//                 }

//         console.log(formData)

//             //         var formData = new FormData();
//             //         formData.append("lgaId", lgaId);
//             //         formData.append("wardId", wardId);
//             //         formData.append("geoCodeName", geoCodeName);
//             //         formData.append("geoCodeFile", geoCodeFile);
//             //         formData.append("enteredBy", enteredBy);
            
//             //         let data = { lgaId, wardId, geoCodeName, geoCodeFile, enteredBy };
//             //         this.setState({
//             //             loader: true,
//             //             message: "",
//             //         });
//                     axios
//                         .post(`${BASE_API_URL}/geocode`, formData)
//                         .then((response) => {
//                             console.log(response)
                         
//                             // this.setState({
//                             //     geoCode: "512",
//                             //     wardCouncil: null,
//                             //     localGovernmentCouncil: null,
//                             //     loader: false,
//                             //     message: "",
//                             // });
//                             // notify(store, 'success', 'geocodes was added successfully');
//                             this.getGeoCode();
                            
//                         })
//                         .catch((err) => {
//                             // this.setState({
//                             //     loader: false,
//                             //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
//                             // });
//                         });
            
            
//             //     };
            
//     }
// const menu = (
//   <Menu style={{width:"100%"}}>
//     <Menu.Item key="0">
//     {/* <Popconfirm title="Sure to delete?" onConfirm={() => accept()}> */}
//     <Button  block style={{border:"none"}} >Approve</Button>
//                       {/* </Popconfirm> */}
   
//     </Menu.Item>
//     <Menu.Item key="1">
//     <Button block style={{border:"none"}} >Decline</Button>
//     </Menu.Item>
//     <Menu.Divider />
    
//   </Menu>
// );

// const columns = [
   
//   {
//     title: 'Edit',
//     dataIndex: 'edit',
//   //   width: '55%',
//     editable: false,
//   },
//   {
//       title: 'Local Government Segment',
//       dataIndex: 'localGovernment',
//       // width: '55%',
//       editable: false,
//     },
//     {
//       title: 'Ward',
//       dataIndex: 'ward',
//       // width: '55%',
//       editable: false,
//     },
//   {
//     title: 'Code Type',
//     dataIndex: 'expenditure Type',
//     // width: '25%',
    
//   },
//   {
//     title: 'Date Type',
//     dataIndex: 'expenditure Type',
//     // width: '25%',
    
//   },
//   {
//     title: 'Action ',
//     dataIndex: 'action',
//     // width: '25%',
    
//   },
 
    
      
   
// ];

// const getLga = () => {
//             axios.get(`${BASE_API_URL}/lga/getlga`).then((response) => {
//                 console.log(response.data);
//                 setcouncil(response.data)
//                 // this.setState({
//                 //     localGovernmentCouncilData: response.data,
//                 // });
//             }).catch((err) => {
//                 // this.setState({
//                 //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
//                 // });
                
//             });
//         };
//         const getWard = () => {
//             axios.get(`${BASE_API_URL}/ward/getward`).then((response) => {
//                 setward(response.data)
//                 // this.setState({
//                 //     wardData: response.data,
//                 // });
//             }).catch((err) => {
//                 // this.setState({
//                 //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
//                 // });
    
//             });
//         };

// const getGeoCode = () => {
//     // this.setState({
//     //     loader: true,
//     //     message: "",
//     // });
//     axios
//         .get(`${BASE_API_URL}/geocode`)
//         .then((response) => {
//             console.log(response.data)
//             setGeoCode(response.data)
//             // this.setState({
//             //     allGeoCodeData: response.data,
//             //     loader: false,
//             //     message: ""
//             // });
//         })
//         .catch((err) => {
//             // this.setState({

//             //     loader: false,
//             //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
//             // });
//         });
// };

    
      
  
  
//   return (
      
//     <div >
       
//        {/* <IsLoading show={loading}/> */}
//             {/* <ErrorModal show={error} action={errorMessage} closeModal={handleCloseError}/> */}
//         <div className="">
         
         

//       <div style={{ width: "100%",}}>
//         {/* <Form form={form} component={false}> */}
        
//         <Form
//         onFinish={onFinish}
//         >
//             <div>
//             <Form.Item
                   
//                    className='w-100 m-r-10'
                   
//                    label="Local Government Council "
//                    name="council"
//                    rules={[
//                    {
//                    required: true,
//                    message: 'Please select who you are sending to!',
//                    },
//                    ]}
//                    >
//        <Select
//                      labelInValue
//                        onChange={(text,index)=>{
//         setApprover(index?.children)
//         setApproverId(index?.value)
                       
//                        }}
//                        showSearch
       
//                        filterOption={(input, option) =>
//         option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
//                        }
//                        filterSort={(optionA, optionB) =>
//         optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
//                        }
//              labelInValue
//              allowClear
//              style={{ width: "100%", border: "" }} 
//              placeholder="Please select"
          
//            >
//                {Array.isArray(council) && council?.map((obj) => (
       
       
       
//        <Option value={obj?.value} key={obj?.id}>{`${obj?.label}`}</Option>
//        ))}
//            </Select>
                 
//                    </Form.Item>

//                    <Form.Item
                   
//                    className='w-100 m-r-10'
                   
//                    label="Ward Council "
//                    name="ward"
//                    rules={[
//                    {
//                    required: true,
//                    message: 'Please select who you are sending to!',
//                    },
//                    ]}
//                    >
//        <Select
                     
//                      labelInValue

//                        onChange={(text,index)=>{
//         setApprover(index?.children)
//         setApproverId(index?.value)
                       
//                        }}
//                        showSearch
       
//                        filterOption={(input, option) =>
//         option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
//                        }
//                        filterSort={(optionA, optionB) =>
//         optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
//                        }
//              labelInValue
//              allowClear
//              style={{ width: "100%", border: "" }} 
//              placeholder="Please select"
          
//            >
//                {Array.isArray(ward) && ward?.map((obj) => (
       
       
       
//        <Option value={obj?.value} key={obj?.id}>{`${obj?.label}`}</Option>
//        ))}
//            </Select>

//            <Button htmlType ='submit'>Send</Button>
                 
//                    </Form.Item>
//             </div>
            
            
//             <Table
//         //   components={{
//         //     body: {
//         //       cell: EditableCell,
//         //     },
//         //   }}
//         bordered
//         dataSource={props.pending?.map((item) =>(
//         {
//         key : item.id,
//         agency: item.category,
//         requestor:item.name,
//         id:item.issuedId,
//         date:moment(item.createdAt).format('L'),
//         status:(item.isApproved === true) ? 'Approved' : (item.isApproved === false) ? 'Rejected' : 'Pending',
//         agentId: item.id,
//         email:item.email,
//         phoneNo:item.phoneNo,
//         username:item.username
        
//         }
//         ))}
//         columns={columns}
//         rowClassName="editable-row"
//         // pagination={{
//         //   onChange: cancel,
//         // }}
//         />
//         </Form>
//       {/* </Form> */}
//       </div>
//         </div>




        
//     </div>

   
//   );
// };
// export default GeoCodeSetup
