import React, { useState,useEffect } from 'react';
import { Table,Button,Modal, Input, InputNumber, Popconfirm, Form, Typography } from 'antd';
import axios from "axios";
import MonthlyBudgetFormPreview from '../layouts/MonthlyBudgetFormPreview'

import {
  CloseOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import notification from "../../utility/notification";

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';
// const BASE_API_URL = 'http://localhost:5000/api/v1';

const originData = [];

// for (let i = 0; i < 100; i++) {
//   originData.push({
//     key: i.toString(),
//     name: `Edrward ${i}`,
//     age: 32,
//     address: `London Park no. ${i}`,
//   });
// }



const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const PreviewTable = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [formData, setformData] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [editingKey, setEditingKey] = useState('');
//   const [count, setCount] = useState(1);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
    // let count = 1

    const handleCancel2 = ()=>{
      setShowPreview(!showPreview)
    }

    const toPost = ()=>{

        let postData =
        
        data.map((e)=>(
           
            {
                // key: idx+1,
            
                        id: e?.id,
                        dateCreated: e?.dateCreated,
                        enteredBy: e?.enteredBy,
                        dateUpdated: e?.dateUpdated,
                        updatedBy: e?.updatedBy,
                        isDelete: e?.isDelete,
                        dateDeleted: e?.dateDeleted,
                        project: e?.project,
                        budgetPlanId: e?.budgetPlanId,
                        programmeCode: e?.programmeCode?e?.programmeCode:'',
                        status: e?.status,
                         budgetDeptId:e.budgetDeptId,
                        projectLocation: e?.projectLocation?e?.projectLocation:'',
                        proposedBudget: +e.proposedBudget?e?.proposedBudget:'',
                        performance: e?.performance?e?.performance:'',
                        actuals: e?.actuals?e?.actuals:'',
                        mdaId: e?.mdaId
                      }
        ))
        // let poster = arrayHolder.map((a)=>())
        axios.post(`${BASE_API_URL}/BudgetPlanning`, postData 
        
                          ).then(()=>{
                            notification({
                                title: 'Successful',
                                message: 'Costs have been Allocated successfully',
                                type: 'success'
                          });

                          })
      }

useEffect(()=>{
  console.log(props?.project)
  // setData([...props?.project.budgetItem])
  // setformData( props?.project.monthlyBudget)
  
  // setData(props?.project)
//     let dat = props?.project?.map((e,idx)=>(
  // let dat =   {
  //   // key: idx+1,

  //   actuals: "",
  //   budgetPlanId:props.project.budgetPlanId,
  //   dateCreated: props.project.dateCreated,
  //   dateDeleted: props.project.dateCreated,
  //   dateUpdated: props.project.dateUpdated,
  //   economicCode:props.project.economicCode,
  //   enteredBy: props.project247.enteredBy,
  //   functionalCode: props.project.functionalCode,
  //   fundCode: props.project.fundCode,
  //   id: props.project.id,
  //   isDelete:props.project.isDelete,
  //   mdaId:props.project.mdaId,
  //   performance:props.project.performance ,
  //   programmeCode:props.project.programmeCode,
  //   project:props.project. project,
  //   projectLocation: props.project.projectLocation,
  //   proposedBudget:props.project.proposedBudget,
  //   status:props.project.status,
  //   updatedBy:props.project.updatedBy,
  //         }
      
  //   console.log('dat:',dat)
    // setData([dat])

    let dat = props?.project?.map((e,idx)=>(
       {key: idx+1,

    actuals: "",
    budgetPlanId:e?.budgetPlanId,
    dateCreated: e?.dateCreated,
    dateDeleted: e?.dateCreated,
    dateUpdated: e?.dateUpdated,
    economicCode:e?.economicCode,
    enteredBy: e?.enteredBy,
    functionalCode: e?.functionalCode,
    fundCode: e?.fundCode,
    id: e?.id,
    isDelete:e?.isDelete,
    mdaId:e?.mdaId,
    performance:e?.performance ,
    programmeCode:e?.programmeCode,
    project:e?. project,
    projectLocation: e?.projectLocation,
    proposedBudget:e?.proposedBudget,
    status:e?.status,
    updatedBy:e?.updatedBy,
    formdata:e?.monthlyBudget,
          }
    ))
    setData(dat)

},[props.project])
console.log(data)
  

  const columns = [
    {
      title: 'project/List Items',
      dataIndex: 'project',
      width: '25%',
      editable: true,
    },
    {
      title: 'programme Code',
      dataIndex: 'programmeCode',
      width: '15%',
      editable: true,
    },
    {
      title: 'project Location',
      dataIndex: 'projectLocation',
      width: '40%',
      editable: true,
    },
    {
        title: 'proposed Budget',
        dataIndex: 'proposedBudget',
        width: '40%',
        editable: true,
      },
      {
        title: 'actuals',
        dataIndex: 'actuals',
        width: '40%',
        editable: true,
      },
    // {
    //   title: 'operation',
    //   dataIndex: 'operation',
    //   render: (_, record) => {
    //     const editable = isEditing(record);
    //     return  (
    //       <a onClick={()=>{
    //         setShowPreview(!showPreview)
    //         setformData(record.formdata)
            
    //         }}>
    //         View Monthly Allocation
    //       </a>
    //     )
    //   },
    // },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'proposedBudget' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <div>
              
    <div className='p-10' style={{backgroundColor: "#66ff99",}}>{props?.project[0]?.programmeName}</div>

      <Form style={{width:"100%",padding:"20%", flexDirection:"column",}} form={form} component={false}>
        <Table
        style={{width:"100%",}}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
        {/* <Button onClick = {toPost}>Send</Button> */}
      </Form>

      <Modal
                    width="80%"
                   
                    footer={false}
                    // okText={<p style={{ maxWidth: "100px" }}>Boss</p>}
                    closeIcon={<CloseOutlined style={{ fontSize: "25px", backgroundColor: "red", color: "white", padding: "10px", marginLeft:'60px' }} />}
                    visible={showPreview} onCancel={handleCancel2}>
                    <div style={{marginTop:"-23px",}}>
                    
                    <MonthlyBudgetFormPreview formdata = {formData}/>
                    </div>
        </Modal> 
    </div>
  );
};

export default PreviewTable