import React, { useState,useEffect } from 'react';
import { Table,Button, Input, InputNumber, Popconfirm, Form, Typography } from 'antd';
import axios from "axios";
import notification from "../../utility/notification";
import { decodeToken } from "../../utility/auth";

const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';
// const BASE_API_URL = 'http://localhost:5000/api/v1';

const originData = [];

// for (let i = 0; i < 100; i++) {
//   originData.push({
//     key: i.toString(),
//     name: `Edrward ${i}`,
//     age: 32,
//     address: `London Park no. ${i}`,
//   });
// }



const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ViewGovTable = (props) => {
  const [mdaId, setMdaId] = useState([]);
  // const [mdaId, setMdaId] = useState([]);
  const { TextArea } = Input;


  
useEffect (()=>{
  //   getMdas()
  //   getEmployees()
  
  
    const r = localStorage.getItem('r');
    let fn = localStorage.getItem('fn');
    let ln = localStorage.getItem('ln');
    let ui = localStorage.getItem('ui');
    let mi = localStorage.getItem('mi');
  
    // r && setRole(decodeToken('r').r);
    if (fn && ln) {
      fn = decodeToken('fn').fn;
      ln = decodeToken('ln').ln;
      ui = decodeToken('ui').ui;
      mi = decodeToken('mi').mi;
      // setUsername(`${fn} ${ln}`);
    // getPending(ui)
    // getBudgetPlanningSummary(ui)
    // getBudgetPendingComents(ui)
    // getPendingBudgetSectors(+ui,)
    
      console.log(ui)
      setUserId(ui)
      setMdaId(mi)
    }
  
  },[])

let params = [
    {  name:  'Ministry of Communication and Orientation',amount: 	' 750,000,000.00' ,percentApproved:'	4.71%'},
 {  name:' Office of the Head of Service',	amount: ' 4,000,000.00', 	percentApproved:'0.03%'	 },
 {  name:' Directorate of Establishment, Training and Manpower Development	' , amount: '1,000,000.00 ',percentApproved:'	0.01%'	 },
 {  name:' Office of the Auditor-General (State)',amount: 	' 30,000,000.00', 	percentApproved:'0.19%'	 },
   {name: 'Office of the Auditor-General (Local Govt)',	amount: ' 5,000,000.00', 	percentApproved:'0.03%'	  },
 {  name:' Civil Service Commission',	 amount: '14,000,000.00', percentApproved:'	0.09%'	},
 {  name:' Edo State Independent Electoral Commission',	amount: ' 180,000,000.00' ,	percentApproved:'1.13%'	 },
 {  name:' Public Private Partnership - PPP',	 amount: '810,000,000.00' ,	percentApproved:'5.09%'	  },
 { name: ' Sub-Total - Administration Sector',	 amount: '15,923,404,798.51', 	percentApproved:'100.00%'	},
    ]
    







  const [form] = Form.useForm();
  const [userId, setUserId] = useState('');
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
//   const [count, setCount] = useState(1);
const [approversComments, setapproversComments] = useState('');

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
    // let count = 1

    const toPost = ()=>{

        let postData =
        
        data.map((e)=>(
            // {
            //     id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            //     dateCreated: '2022-02-11T09:59:24.439Z',
            //     enteredBy: 'string',
            //     dateUpdated: '2022-02-11T09:59:24.439Z',
            //     updatedBy: 'string',
            //     isDelete: false,
            //     dateDeleted: '2022-02-11T09:59:24.439Z',
            //     budgetPlanId:' 3fa85f64-5717-4562-b3fc-2c963f66afa6',
            //     project: 'string',
            //     programmeCode: 'string',
            //     status: 'string',
            //     projectLocation: 'string',
            //     proposedBudget: 5,
            //     performance: 'string',
            //     actuals: 'string',
            //     mdaId: 11
            //   }
            {
                // key: idx+1,
            
                        id: e.id,
                        dateCreated: e.dateCreated,
                        enteredBy: e.enteredBy,
                        dateUpdated: e.dateUpdated,
                        updatedBy: e.updatedBy,
                        isDelete: e.isDelete,
                        dateDeleted: e.dateDeleted,
                        project: e.project,
                        budgetPlanId: e.budgetPlanId,
                        programmeCode: e.programmeCode?e.programmeCode:'',
                        status: e.status,
                        projectLocation: e.projectLocation?e.projectLocation:'',
                        proposedBudget: +e.proposedBudget?e.proposedBudget:'',
                        performance: e.performance?e.performance:'',
                        actuals: e.actuals?e.actuals:'',
                        mdaId: e.mdaId
                      }
        ))
        // let poster = arrayHolder.map((a)=>())
        axios.post(`${BASE_API_URL}/BudgetPlanning`, postData 
        
                          ).then(()=>{
                            notification({
                                title: 'Successful',
                                message: 'Costs have been Allocated successfully',
                                type: 'success'
                          });

                          })
      }

      const toApprove = (batchId, status)=>{

        let postData =
          
        {
            id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            dateCreated: '2022-02-14T15:33:43.390Z',
            enteredBy: userId+'',
            dateUpdated: '2022-02-14T15:33:43.390Z',
            updatedBy: userId+'',
            isDelete: false,
            dateDeleted: '2022-02-14T15:33:43.390Z',
            text: approversComments,
            batchId: batchId,
            mdaId: +mdaId,
            approverId: +userId
          }

    
        // let poster = arrayHolder.map((a)=>())
        axios.post(`${BASE_API_URL}/ApprovalProcess/AddApprovalPlanProcess`, postData, {params:{status}})
        .then((res)=>{
            console.log(res)
            notification({
                title: 'Successful',
                message: 'Mda budget has been successfully approved',
                type: 'success'
          });
          props.showAdmin()

        }).catch((err)=>{
          notification({
            title: 'ERROR MESSAGE',
            message: `
            ${ err.response.data}`,
            type: 'danger',
            // container:'top-center'
            // animationIn: ['animate__animated', 'animate__slideIn'],
          });
        })
      }

     
useEffect(()=>{
  console.log(props.govView)
    let dat = props?.govView?.map((e,idx)=>(
        {
    key: idx+1,

            id: e.id,
            mdaId: e.mdaId,
            budgetPlanId: e.batchId,
            approvedAppropriationPercentage:e.approvedAppropriationPercentage,
            capitalExpenditure: e.mda,
          
            proposedProvision: e.proposedProvision,
            proposedAppropriation: e.proposedProvision,
          
          
            mdaId: e.mdaId
          }
      ))
    console.log('dat:',dat)
    setData(dat)
},[props.govView])
console.log(data)
  

  const columns = [
    {
        title: 'ECONOMIC CODES',
        dataIndex: 'project',
        width: '25%',
        editable: true,
      },
      {
        title: 'CAPITAL EXPENDITURE',
        dataIndex: 'capitalExpenditure',
        width: '15%',
        editable: true,
      },
      {
        title: 'PROPOSED PROVISION',
        dataIndex: 'proposedProvision',
        width: '40%',
        editable: true,
      },
      {
          title: 'APPROVED APPROPRIATION',
          dataIndex: 'proposedAppropriation',
          width: '40%',
          editable: true,
        },
        {
          title: '% OF  APPROVED APPROPRIATION',
          dataIndex: 'approvedAppropriationPercentage',
          width: '40%',
          editable: true,
        },
        {
          title: 'ACTUAL EXPENDITURE 2022',
          dataIndex: 'actuals',
          width: '40%',
          editable: true,
        },
    // {
    //   title: 'operation',
    //   dataIndex: 'operation',
    //   render: (_, record) => {
    //     const editable = isEditing(record);
    //     return editable ? (
    //       <span>
    //         <Typography.Link
    //           onClick={() => save(record.key)}
    //           style={{
    //             marginRight: 8,
    //           }}
    //         >
    //           Save
    //         </Typography.Link>
    //         <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
    //           <a>Cancel</a>
    //         </Popconfirm>
    //       </span>
    //     ) : (
    //       <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
    //         Edit
    //       </Typography.Link>
    //     );
    //   },
    // },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        // const editable = isEditing(record);
        return  (
          <span className='flex-between'>
             <Button
             className='m-r-5'
              onClick={() => toApprove(record.budgetPlanId, 'approved')}
              style={{
                marginRight: 8,
              }}
            >
              Approve
            </Button>
            <Button
              onClick={() => toApprove(record.budgetPlanId, 'declined')}
              style={{
                marginRight: 8,
              }}
            >
              Decline
            </Button>
           
          </span>
        ) 
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'proposedBudget' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <div>
      <Form style={{width:"100%"}} form={form} component={false}>
        <Table
        style={{width:"100%"}}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
      { <div className=' p-20 w-100 flex' style={{ justifyContent:'center'}}>

            {/* <div style={{width:"60%", padding:"20px", backgroundColor:"white"}}>
                <p className='bold m-b-5'>Enter comments</p>
                <TextArea value={approversComments} onChange={(e)=>{setapproversComments(e.target.value)}} style={{ border:"2px solid blue"}}  rows={4}/>
            </div> */}

       </div>}

      <div style={{marginLeft:"20px", display:"flex", justifyContent:"space-between"}}>
            {/* <Button >{'Revert'}</Button> */}
            { <Popconfirm
            placement="leftBottom"
            title={
            <div>
            <p className='bold'> Satisfied & Sure to Approve?</p>
            <p>Once Approved, it cannot be reversed.</p>
            </div>
            } >
            {/* <Button >Approve</Button> */}
            
            </Popconfirm>
          
            }
      </div>
    </div>
  );
};

export default ViewGovTable