import React, { Component, useState, useEffect, useRef } from "react";
// import { DataTypes } from "../data/Types";
// import { Preloader } from "../Utils/Preloader";
// import budgetPeriodSchema from "../Validation/BudgetPeriodValidate";
// import ValidationMessageComponent from "./ValidationMessage";
import moment from 'moment';
import { AiOutlineLink } from 'react-icons/ai';
// import { store } from 'react-notifications-component';
// import { nullcheck, notify } from '../Utils/Helper'
import { Link } from "react-router-dom";
import axios from "axios";
import { BiPencil } from 'react-icons/bi';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const BASE_API_BASE_URL = process.env.REACT_APP_BASE_MODULE_URL;





export default class DashboardC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openQueries: false,
            budgetInformationApprovalProcess: [],
            mdas: [],
            mdaName: [],
            message: "",
            loader: "",
            latitude: "",
            longtitude: "",
            location: "",
    
        };
    }

    componentDidMount() {
    console.log(this.props?.dashboardNew)

      //  this.getLocation();   
       
    }
    showPosition = (position) => {

        if (position != "") {
            this.state.latitude = position.coords.latitude
            this.state.longtitude = position.coords.longtitude
            this.getUserLocation(position.coords.latitude, position.coords.longtitude)
        }
       
    }
    getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.showPosition);
        } else {
           return ""
        }
    }
    handleChange = (ev) => {
        this.setState({
            [ev.target.name]: ev.target.value,
        });
    };
    formatDefaultDate = (dateString) => {
        var newDate = new Date(dateString);

        var strArray = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        var d = newDate.getDate();
        var m = strArray[newDate.getMonth()];
        var y = newDate.getFullYear();
        var time = newDate.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
        return "" + (d <= 9 ? "0" + d : d) + "," + m + " " + y + ";" + time;
        //newDate.setDate(newDate.getDate() + 1);

        // return newDate.toISOString().split("T")[0];
    };
    subtractDate = (dateString) => {
        var newDate = new Date(dateString);

        var todayDate = new Date();

        var diff = todayDate - newDate;

        return this.timeConversion(diff)


    }
    subtractTwoDates = (firstDate, secondDate) => {
        var _firstDate = new Date(firstDate);
        var _secondDate = new Date(secondDate)

        //var todayDate = new Date();

        var diff = _firstDate - _secondDate;

        return this.timeConversion(diff)


    }
    timeConversion = (millisec) => {
        var seconds =Math.abs((millisec / 1000).toFixed(1));

        var minutes = Math.abs((millisec / (1000 * 60)).toFixed(1));

        var hours =Math.abs((millisec / (1000 * 60 * 60)).toFixed(1));

        var days =Math.abs((millisec / (1000 * 60 * 60 * 24)).toFixed(1));

        if (seconds < 60) {
            return seconds + " Sec";
        } else if (minutes < 60) {
            return minutes + " Min";
        } else if (hours < 24) {
            return hours + " Hrs";
        } else {
            return days + " Days";
        }
    };
    handleGetComment = (approvalProcessInformationId) => {
       
        let commentData = this.props.commentData;
        let comments = commentData.filter((commentData) => {
            return (
                commentData.approvalProcessInformationId == approvalProcessInformationId
            );
        });
        return comments;
    };
    handleGetEmployee = (employeeId) => {
        let employeeData = this.props.employeeData;
        let employee = employeeData.filter((employeeData) => {
            return employeeData.id == employeeId;
        });
        return employee;
    };
    handleGetRole = (roleid) => {
        let userolesData = this.props.userolesData;
        let userroles = userolesData.filter((useroleData) => {
            return useroleData.role.id == roleid;
        });

        return userroles.length == 0 ? "" : userroles[0].role.name;
    };
    getUserLocation(latitude, longtitude) {
        let user_id = this.props.employeeId;
    
        let data = { user_id,latitude, longtitude}
        axios.post(`${BASE_API_BASE_URL}/services/api/userlocations`, { ...data }).then((response) => {

            this.setState({
                location: response.location, 
            })
            
        }).catch((err) => {
            console.log(err)
        })
    }
    handleResponseToggle = (budgetReview) => {


        let commentId = `comment${budgetReview.approvalProcessInformations.id.replace("-", "")}`;
        let submitCommentId = `submitComment${budgetReview.approvalProcessInformations.id}`;
        let respondCommentId = `respondComment${budgetReview.approvalProcessInformations.id}`;
        let cancelId = `cancel${budgetReview.approvalProcessInformations.id.replace("-", "")}`;

        document.getElementById(submitCommentId).style.display = "";
        document.getElementById(cancelId).style.display = "";
        document.getElementById(commentId).style.display = "";
        document.getElementById(respondCommentId).style.display = "none";

        //let commentId = `comment${budgetReview.approvalProcessInformations.id}`;
        //let submitCommentId = `submitComment${budgetReview.approvalProcessInformations.id}`;
        //let respondCommentId = `respondComment${budgetReview.approvalProcessInformations.id}`;


        //document.getElementById(submitCommentId).style.display = "";
        //document.getElementById(commentId).style.display = "";
        //document.getElementById(respondCommentId).style.display = "none";
    }

    handleCancelCommentToggle = (budgetReview) => {
        let commentId = `comment${budgetReview.approvalProcessInformations.id.replace("-", "")}`;
        let submitCommentId = `submitComment${budgetReview.approvalProcessInformations.id}`;
        let respondCommentId = `respondComment${budgetReview.approvalProcessInformations.id}`;
        let cancelId = `cancel${budgetReview.approvalProcessInformations.id.replace("-", "")}`;



        document.getElementById(submitCommentId).style.display = "none";
        document.getElementById(commentId).style.display = "none";
        document.getElementById(cancelId).style.display = "none";
        document.getElementById(respondCommentId).style.display = "";


    }
    handleSubmitCommentToggle = (budgetReview, commentId) => {

        let enteredBy = this.props.employeeId.toString();
        let commentInfoId = `comment${budgetReview.approvalProcessInformations.id.replace("-", "")}`;
        let cancelId = `cancel${budgetReview.approvalProcessInformations.id.replace("-", "")}`;

        let text = document.getElementById(commentInfoId).value;
        if (text === "") {
            this.setState({
                message: 'comment is required'
            })
        } else {
            this.setState({
                loader: true,
                message: "",
            });
            let submitCommentId = `submitComment${budgetReview.approvalProcessInformations.id}`;
            let respondCommentId = `respondComment${budgetReview.approvalProcessInformations.id}`;

            document.getElementById(submitCommentId).style.display = "none";
            document.getElementById(commentInfoId).style.display = "none";
            document.getElementById(respondCommentId).style.display = "";
            document.getElementById(cancelId).style.display = "none";

            let data = { enteredBy, commentId, text };

            axios.post(`${BASE_API_URL}/reply`, { ...data }).then((response) => {
                this.setState({
                    loader: false,
                    message: "",
                });
                // notify(store, 'success', 'reply added successfully');
                document.getElementById(commentInfoId).value = '';
                this.getReply();

            }).catch((err) => {
                // this.setState({
                //     loader: false,
                //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
                // });
            })
        }


    }

    


    render() {
        let reviewInformations = this.props.reviewInformation;
        let pendingReview = this.props.pendingReview;
        let approvalProcessInformation = this.props.approvalProcessInformation == "" ? "" : this.props.approvalProcessInformation;
        let initiatorFullName = "";
        if (approvalProcessInformation != "") {
            initiatorFullName = this.handleGetEmployee(
                                    approvalProcessInformation.enteredBy
                                ).length == 0 ? "" :
                                this.handleGetEmployee(
                                                        approvalProcessInformation.enteredBy
                                                    )[0].firstName +
                                                    " " +
                                                    this.handleGetEmployee(
                                                        approvalProcessInformation.enteredBy
                                                    )[0].lastName;
        }
        let pendingFullName = "";
        let pendingInfo = pendingReview.length == 0 ? "" : this.handleGetEmployee(pendingReview[0].approvalProcessInformations.nextPersonID);
        if (pendingInfo != "") {
           let _pendingFullName = pendingInfo[0].firstName + " " + pendingInfo[0].lastName;
            pendingFullName = pendingReview[0].approvalProcessInformations.isFinance == true ? "Finance"
                : pendingReview[0].approvalProcessInformations.isGovernor == true ? "Governor"
                    : pendingReview[0].approvalProcessInformations.governorEntered == true ? "Budget Approved"
                    : _pendingFullName
        


        }
         //pendingFullName = pendingReview.length == 0 ? "" : this.handleGetEmployee(pendingReview[0].approvalProcessInformations.nextPersonID)[0].firstName
         //   + " " + this.handleGetEmployee(pendingReview[0].approvalProcessInformations.nextPersonID)[0].lastName

        let lastTreated = pendingReview.length == 0 ? "" : this.formatDefaultDate(pendingReview[0].approvalProcessInformations.dateCreated)

     

        let allReviewInformations = reviewInformations.map((reviewInformation) => {

            let _dateInTray = reviewInformation.approvalProcessInformations.dateCreated
            let _dateOutTray = reviewInformation.approvalProcessInformations.dateOutOfTray;
            let dateInTray = typeof (_dateInTray) == "undefined" ? "" :_dateInTray;
            let dateOutTray = typeof (_dateOutTray) == "undefined" ? "" : _dateOutTray;
            if (_dateOutTray != null) {

                dateOutTray = this.subtractTwoDates(_dateOutTray, _dateInTray)
            } else {
                dateOutTray = this.subtractDate(dateInTray)
            }

            var commentText =
                this.handleGetComment(reviewInformation.approvalProcessInformations.id)
                    .length == 0
                    ? ""
                    : this.handleGetComment(
                        reviewInformation.approvalProcessInformations.id
                    )[0].text;

            var _employeeFullName =
                this.handleGetEmployee(
                    reviewInformation.approvalProcessInformations.nextPersonID
                ) == 0
                    ? ""
                    : this.handleGetEmployee(
                        reviewInformation.approvalProcessInformations.nextPersonID
                    )[0].firstName +
                    " " +
                    this.handleGetEmployee(
                        reviewInformation.approvalProcessInformations.nextPersonID
                    )[0].lastName;

            let employeeFullName = reviewInformation.approvalProcessInformations.isFinance == true ? "Finance"
                    : reviewInformation.approvalProcessInformations.isGovernor == true ? "Governor"
                    : reviewInformation.approvalProcessInformations.governorEntered==true?"Budget Approved"
                    : _employeeFullName

            let roleInfo = this.handleGetEmployee(reviewInformation.approvalProcessInformations.nextPersonID)


            let roleId = roleInfo.length == 0 ? 0 : roleInfo.roleId

            var role = roleId !== undefined ? this.handleGetRole(roleId) : null;

            let commentId = `comment${reviewInformation.approvalProcessInformations.id.replace("-", "")}`;
            let submitCommentId = `submitComment${reviewInformation.approvalProcessInformations.id}`;
            let respondCommentId = `respondComment${reviewInformation.approvalProcessInformations.id}`;


            let commentInfo = this.handleGetComment(reviewInformation.approvalProcessInformations.id).length == 0 ? "" : this.handleGetComment(reviewInformation.approvalProcessInformations.id)[0];
            var commentText = commentInfo == "" ? "" : commentInfo.text;
            var commentInfoId = commentInfo == "" ? "" : commentInfo.id;
            let cancelId = `cancel${reviewInformation.approvalProcessInformations.id.replace("-", "")}`;
            // let url = `/budget-review?mdaId=${reviewInformation.mda.id}`;
            let url = `/schedule-manager/budget-review?mdaId=${reviewInformation.mda.id}`;
            
         
            return (
                <tr className='p-10 m-t-10 border' style={{  borderBottom: "1px solid #C7C7C7", padding:"10px",}}>
                    <td class="p-6 flex " >
                        <img class="h-10 w-10 mr-5 rounded-full" src="/avatar.png" alt="" />
                        <p>
                            <span className="font-avenir-heavy">{employeeFullName}</span>
                            <br />
                            <span className="text-xs opacity-30 absolute" hidden>
                                {role}
                            </span>
                        </p>
                    </td>
                    <td style={{ marginRight:"10px"}} class="p-3 m-r-10 ">
                        <p>
                            {this.formatDefaultDate(dateInTray)}{" "}
                            <br />
                            <span className="text-xs opacity-30 absolute">
                                {this.subtractDate(dateInTray)}
                            </span>{" "}
                            <br />
                            <span
                                className="text-xs opacity-30"
                                style={{
                                    position: "relative",
                                    top: -12,
                                }}
                            ></span>
                        </p>
                    </td>
                    <td  class="p-3 m-r-10 ">
                        <p className='no-margin'> 
                            {
                                employeeFullName == "Budget Approved"?"Budget Approved": reviewInformation.approvalProcessInformations.status == 0
                                ? "Pending"
                                : reviewInformation.approvalProcessInformations.status == 1
                                    ? "Approved"
                                    : "Declined"}
                            <br />
                            <p className=" no-margin text-xs opacity-30 absolute">
                                {dateOutTray}
                            </p>
                            {/* <span
                                className="text-xs opacity-30"
                                style={{
                                    position: "relative",
                                    top: -12,
                                }}
                            ></span> */}
                        </p>
                    </td>
                    <td  class="p-3 ">
                        <p className="pr-5">{commentText}</p>

                        <textarea style={{ display: 'none', height:'60px' }}
                            id={commentId}
                            name=""
                            rows=""
                            class="mt-5 w-100 bg-white border border-gray-300 w-full h-40"
                        // style={{ height: "13rem", border: "none" }}
                        ></textarea>
                        <div className="flex">
                            <button style={{ display: 'none' }}
                                onClick={() => this.handleSubmitCommentToggle(reviewInformation, commentInfoId)}
                                id={submitCommentId}
                                type="submit"
                                class="mr-5 mt-5 justify-center w-36 py-2 px-4 border border-transparent rounded shadow-sm text-sm font-avenir-black text-white bg-commet-texta focus:outline-none"
                            >
                                Submit
                     </button>
                            <button

                                onClick={() => this.handleCancelCommentToggle(reviewInformation)}
                                id={cancelId}
                                type="submit"
                                style={{ backgroundColor: "#dc2626", display: 'none' }}

                                class="mr-5 mt-5 justify-center w-36 py-2 px-4 border border-transparent rounded shadow-sm text-sm font-avenir-black text-white bg-commet-texta focus:outline-none"
                            >
                                Cancel
                        </button>

                        </div>
               
                        <div className="flex">
                            {commentInfoId != "" ? (
                            <button
                            style={{borderRadius:"20px",backgroundColor:"#dfeddf"}}
                                    onClick={() => this.handleResponseToggle(reviewInformation)}
                                    id={respondCommentId}
                                className=" w-60 m-r-5 flex-align p-5 border "
                            >
                              <BiPencil style={{fontSize:"26px",}}/>

                                <span className="">Respond to Comment</span>
                            </button>
                                  ) : ""}
                            <Link to={url} className="my-5 w-60 flex-align border flex-betweenborder  ">
                                {/* <img
                                    src="/chain.png"
                                    style={{
                                        width: 26,
                                        height: 26,
                                        top: -3,
                                    }}
                                    className="ml-5 mr-3 relative"
                                /> */}
                                <AiOutlineLink style={{fontSize:"26px", color:"black",}}/>
                                <p className="">Open Details</p>
                            </Link>
                            </div>
                      
                    </td>
                </tr>
            );
        });

        return (
            <React.Fragment>
                {true ? (
                    <React.Fragment>
                        {this.state.open ? (
                            <div className="mb-3" >
                                <div
                                    className="w-100 flex-between m-t-10 pointer colorW p-10"
                                    onClick={() => this.setState({ open: !this.state.open })}
                                >
                                    <p className="flex-grow  mt-2">
                                        {this.props.title}
                                    </p>
                                    {this.props.currently && (
                                        <div  className="flex-align mr-11 mt-2">
                                            <div  className={"h-5 w-5 rounded-full mr-3 " + (pendingFullName == "Budget Approved" ? "bg-green" : "bg-yellow")} />
                                            <p className="">
                                                Currently with{" "}
                                                <span className="font-avenir-black" style={{ fontSize: 16, textShadow: "0px 1px, 1px 0px, 1px 1px" }}>
                                                    {pendingFullName}
                                                </span>{" "}
                        {/* ({this.props.staffTitle}) */}
                      </p>
                                        </div>
                                    )}

                                    {this.props.approved && (
                                        <div className="flex mr-64 mt-2">
                                            <div className="h-5 w-5 bg-green rounded-full mr-3" />
                                            <p className="">Approved</p>
                                        </div>
                                    )}

                                    {this.props.rejected && (
                                        <div className="flex mr-9 mt-2">
                                            <div className="h-5 w-5 bg-red rounded-full mr-3" />
                                            <p className="">
                                                Rejected by{" "}
                                                <span className="font-avenir-black">
                                                    {this.props.staffName}
                                                </span>{" "}
                        {/* ({this.props.staffTitle}) */}
                      </p>
                                        </div>
                                    )}
                                    <div className="border border-gray-300 h-8 ml-10 mr-5" />
                                    <p className=" mt-2">
                                        <span className="" style={{ fontSize: 16,  }}>Last treated:</span>{" "}
                                        {lastTreated}
                                    </p>
                                </div>

                                <div className="flex-between  m-t-10 w-100 py-4 overflow-hidden cursor-pointer">
                                    <div className="w-30  m-r-20 ">
                                        <div className="bg-white shadow p-10">
                                            <p className=" p-5 text-sm font-avenir-heavy p-4 text-color">
                                               {this.props.budgetYear} Budget Planning
                      </p>
                                            <div className="border border-gray-300" />
                                            <div className="pl-4 py-2">
                                                <div className="flex my-2">
                                                    <p className=" p-5 flex-1 text-sm font-avenir-black label-color">
                                                        Memo Initiation Date:{" "}
                                                        <span className="font-avenir-book">
                                                            {this.props.approvalProcessInformation == ""
                                                                ? ""
                                                                : this.formatDefaultDate(
                                                                    this.props.approvalProcessInformation
                                                                        .dateCreated
                                                                )}
                                                        </span>{" "}
                                                    </p>
                                                    {/* <img src="/calendar.png" className="mr-5" /> */}
                                                </div>
                                                <div className="my-2">
                                                    <p className=" p-5 text-sm font-avenir-black label-color">
                                                        Memo Initiator:{" "}
                                                        <span className="font-avenir-book">
                                                            {initiatorFullName } (Officer 1)
                            </span>{" "}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3 p-10  bg-white shadow">
                                            <p className="text-sm font-avenir-heavy text-color">
                                                Memo Description
                      </p>
                                            <div className="border border-gray-300" />
                                            <div className="px-4 py-2">
                                                <div className="flex my-2">
                                                    <p className=" p-5 text-left text-sm label-color">
                                                        {this.props.approvalProcessInformation == ""
                                                            ? ""
                                                            : this.props.approvalProcessInformation.text}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3 bg-white shadow">
                                            <div className="flex p-4 p-5">
                                                <p className=" p-5 flex-1  text-sm font-avenir-heavy text-color">
                                                    Cost Implication
                        </p>
                                                <p className=" p-5 text-sm  font-avenir-heavy text-color">
                                                    (N)
                        </p>
                                            </div>
                                            <div className="border border-gray-300" />
                                            <div className="pl-4 py-2">
                                                <div className="flex my-2">
                                                    <p className=" p-5 flex-1 text-sm label-color">
                                                        {this.props.budgetTotal}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-10" style={{marginLeft:"20px", width:"70%"}} >
                                        <div className="w-100">
                                            <div className="w-100">
                                                <div className="w-100">
                                                    <div className=" bg-white">
                                                        <p className="text-lg p-4 p-20 font-avenir-black text-color">
                                                            Approval Officer Comments
                            </p>
                                                        <table
                                                            id="allBudgetInformation"
                                                            class="shadow overflow-hidden border"
                                                            style={{ width: "100%" }}
                                                        >
                                                            <thead class="bg-gray-table-header">
                                                                <tr>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                                                        style={{ width: "25%", }}
                                                                    >
                                                                        Assigned Officers
                                  </th>
                                                                    <th
                                                                        scope="col"
                                                                        class=" p-5 px-3 py-3  text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                                                        style={{ width: "20%" }}
                                                                    >
                                                                        In-Tray
                                  </th>
                                                                    <th
                                                                        scope="col"
                                                                        class="p-5 px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                                                        style={{ width: "20%" }}
                                                                    >
                                                                        Out-Tray
                                  </th>
                                                                    <th
                                                                        scope="col"
                                                                        class=" p-5 px-3 py-3 text-left text-sm font-medium font-avenir-black text-color tracking-wider"
                                                                        style={{ width: "35%"}}
                                                                    >
                                                                        Comment
                                  </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody class="bg-white divide-y divide-gray-200 p-10">
                                                                {allReviewInformations}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (

//                             currentlyWith: " USERTHREE TEST"
// lastTreated: "0001-01-01T00:00:00"
// status: "Pending"
// title: "Nick Budget"
                                <div 
                                    className="w-100 m-t-10 flex-between pointer colorW p-10"
                                    onClick={() => this.setState({ open:false })}
                                >
                                    <p className="" style={{width:"30%", }}>
                                        {this.props.dashboardNew?.title}
                                    </p>
                                    {this.props.dashboardNew?.currentlyWith && (
                                        <div className="flex-align "style={{width:"30%", }}>
                                
                                            <div   className={"h-5 w-5 rounded-full m-r-5 " + (this.props.dashboardNew.status == "Approved" ? "bg-green" :"bg-yellow")} />
                                            <p className="">
                                                Currently with{" "}
                                                <span className="" style={{ fontSize: 13,  }}>
                                                    {this.props.dashboardNew?.currentlyWith}
                                                </span>{" "}
                      {/* ({this.props.staffTitle}) */}
                    </p>
                                        </div>
                                    )}

                                    {this.props.dashboardNew.status == "Approved" && (
                                        <div className=" flex-align" style={{width:"30%", }}>
                                            <div className="h-5 w-5 bg-green rounded-full m-r-3" />
                                            <p className="">Approved</p>
                                        </div>
                                    )}

                                    {this.props.rejected && (
                                        <div className="flex" style={{width:"", }}>
                                            <div className="h-5 w-5 bg-red rounded-full" />
                                            <p className="">
                                                Rejected by{" "}
                                                <span className="">
                                                    {this.props.staffName}
                                                </span>{" "}
                      {/* ({this.props.staffTitle}) */}
                    </p>
                                        </div>
                                    )}
                                    <div className="border" style={{width:"", }}/>
                                    <p style={{width:'18%'}} className="">
                                        <span className="" style={{ fontSize: 14,  }}>Last treated:</span>{" "}
                                        {moment(this.props.dashboardNew?.lastTreated).format('LL')}
                                    </p>
                                </div>
                            )}
                    </React.Fragment>
                ) : (
                    <p>hi</p>
                        // <Preloader />
                    )}
            </React.Fragment>
        );
    }
}
